import {
  CalendarColumn,
  SlotRow,
  SlotRowsByEmployee,
  SlotsAssignedToDays,
  UnknownCalendarSlot,
} from "@features/calendar/models";
import { formatISO, parseISO, min, max, getDayOfYear } from "date-fns";

import { checkIfTimerangesOverlapByTime } from "@/features/calendar/utils/time";
import { isAppointment, isBlockedSlot } from "@/features/calendar/utils/type-guards";
import {
  Timerange,
  CalendarResult,
  AppointmentFilters,
  EmployeeCalendarResult,
  Appointment,
} from "@/types";

/** we need it to render slots in calendar. appointment might have multiple treatments nested in it, so we need to flatten and sort them by date. */
export const convertCalendarItemsToSlotsFormat = (
  calendarItems: CalendarResult[],
): UnknownCalendarSlot[] => {
  // since we create a custom-shaped type for slots, it will help us to create type guards with such info. the whole logic should be moved to back-end eventually
  const typeGuardData = {
    __isSlot: true,
  };

  const setBulkPrice = (appointment: Appointment) => {
    return appointment.status === "COMPLETED"
      ? appointment.totalPrice
      : appointment.treatments.reduce((acc, treatment) => acc + treatment.suggestedPrice, 0);
  };

  const slots = calendarItems
    .map((item) => {
      if (isAppointment(item)) {
        return item.treatments.map((treatment) => {
          const isBulk = item.treatments.length > 1;

          return {
            ...treatment,
            ...typeGuardData,
            employeeName: treatment.employeeName,
            uuid: item.appointmentUuid,
            status: item.status,
            clientDisplayName: item.clientDisplayName,
            isSelfBooked: item.isSelfBooked,
            isBulk,
            note: item.note,
            paymentMethodUuid: item.paymentMethodUuid,
            bulkPrice: isBulk ? setBulkPrice(item) : null,
          };
        });
      } else if (isBlockedSlot(item)) {
        return {
          ...item,
          ...typeGuardData,
        };
      } else {
        throw new Error("Unknown calendar item type");
      }
    })
    .flat()
    .sort((a, b) => new Date(a.timeRange.from).getTime() - new Date(b.timeRange.from).getTime());

  return slots;
};

const insertSlotToRow = (
  columns: CalendarColumn[],
  slot: UnknownCalendarSlot,
): CalendarColumn[] => {
  for (let colIndex = 0; colIndex <= columns.length; colIndex++) {
    if (colIndex === columns.length) {
      columns.push([slot]);
      break;
    }

    const column = columns[colIndex];
    const lastInCol = column[column.length - 1];

    if (!checkIfTimerangesOverlapByTime(lastInCol.timeRange, slot.timeRange)) {
      columns[colIndex].push(slot);
      break;
    }
  }

  return columns;
};

/** Convert calendar data to rows and columns to display them next to each other when they're overlapping in time */
export const getSlotRows = (calendarResults: CalendarResult[]): SlotRow[] => {
  const slots = convertCalendarItemsToSlotsFormat(calendarResults);

  const slotRows = slots.reduce((rows, slot) => {
    const index = rows.findIndex((timeRange: Timerange) =>
      checkIfTimerangesOverlapByTime(timeRange, slot.timeRange),
    );

    if (~index) {
      rows[index] = {
        columns: insertSlotToRow(rows[index].columns, slot),
        from: formatISO(min([parseISO(rows[index].from), parseISO(slot.timeRange.from)])),
        to: formatISO(max([parseISO(rows[index].to), parseISO(slot.timeRange.to)])),
      };
    } else {
      rows.push({
        columns: insertSlotToRow([], slot),
        from: slot.timeRange.from,
        to: slot.timeRange.to,
      });
    }
    return rows;
  }, [] as SlotRow[]);

  return slotRows;
};

/** convert calendar data sorted by employee to format with employeeUuid and slotRows. Necessary for view with employee-splitted calendar view */
export const getSlotRowsByEmployee = (
  calendarByEmployeeResults: EmployeeCalendarResult[],
  filters: Omit<AppointmentFilters, "timeRange"> | undefined,
): SlotRowsByEmployee[] => {
  if (filters?.employeeUuid) {
    calendarByEmployeeResults = calendarByEmployeeResults.filter(
      (result) => result.employee.accountUuid === filters.employeeUuid,
    );
  }

  return calendarByEmployeeResults.map((result) => {
    const slotRows = getSlotRows(result.entries);

    return {
      employee: result.employee,
      slotRows: filterSlotRows(slotRows, { employeeUuid: result.employee.accountUuid }),
    };
  });
};

/** convert calendar data to table of days of years, e.g. {
 * @example 1: [...treatments] -- slots for 1th of january
 */
export const assignCalendarDataToDays = (
  calendarResults: CalendarResult[],
): SlotsAssignedToDays => {
  const slots = convertCalendarItemsToSlotsFormat(calendarResults);

  const slotsForDaysOfYear = slots.reduce((obj: SlotsAssignedToDays, slot) => {
    const slotDayOfYear = getDayOfYear(parseISO(slot.timeRange.from));

    if (obj[slotDayOfYear]) {
      obj[slotDayOfYear] = [...obj[slotDayOfYear], slot];
    } else {
      obj[slotDayOfYear] = [slot];
    }

    return obj;
  }, {});

  return slotsForDaysOfYear;
};

/** filter calendar groups by employee uuid. it's not done on backend for conveinvence - https://appunite.slack.com/archives/C03NT42CMPG/p1685605728866619 */
export const filterSlotRows = (
  slotRows: SlotRow[],
  filters: Omit<AppointmentFilters, "timeRange"> | undefined,
): SlotRow[] => {
  return slotRows.reduce((acc: SlotRow[], group: SlotRow) => {
    if (!filters?.employeeUuid) {
      acc.push(group);
      return acc;
    }

    // Filter out the columns that have slots matching the employeeUuid
    const filteredColumns = group.columns
      .map((col) => col.filter((slot) => slot.employeeUuid === filters.employeeUuid))
      .filter((col) => col.length > 0); // Exclude columns that are empty after filtering

    // If there are any filtered columns, add them to the accumulator
    if (filteredColumns.length > 0) {
      acc.push({ ...group, columns: filteredColumns });
    }

    return acc;
  }, []);
};

/** filter calendar groups by employee uuid. it's not done on backend for conveinvence - https://appunite.slack.com/archives/C03NT42CMPG/p1685605728866619 */
export const filterSlotsAssignedToDays = (
  slotsAssignedToDays: SlotsAssignedToDays,
  filters: Omit<AppointmentFilters, "timeRange">,
  employeeUuid?: string,
) => {
  return Object.fromEntries(
    Object.keys(slotsAssignedToDays).map((el) => {
      const filteredByEmployee = slotsAssignedToDays[Number(el)];

      if (employeeUuid) {
        return [Number(el), filteredByEmployee.filter((s) => s.employeeUuid == employeeUuid)];
      } else if (!filters.employeeUuid) {
        return [Number(el), filteredByEmployee];
      } else {
        return [
          Number(el),
          slotsAssignedToDays[Number(el)].filter((s) => s.employeeUuid == filters.employeeUuid),
        ];
      }
    }),
  );
};
