import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";

type ImagePreviewProps = {
  onClose: (e: Event | React.MouseEvent<HTMLButtonElement>) => void;
  images: string[];
  initialIndex: number;
};

export const ImagePreview = ({ onClose, images, initialIndex = 0 }: ImagePreviewProps) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [navigationVisibliity, setNavigationVisibility] = useState(true);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const toggleNavigationVisibility = () => {
    setNavigationVisibility((prevVisibility) => !prevVisibility);
  };

  const currentImage = useMemo(() => images[currentIndex], [images, currentIndex]);

  useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowLeft":
          handlePrevious();
          break;
        case "ArrowRight":
          handleNext();
          break;
        case "Escape":
          onClose(e);
          break;
        default:
          break;
      }
    };

    document.addEventListener("keydown", keyPressHandler);
    return () => document.removeEventListener("keydown", keyPressHandler);
  }, [onClose, handleNext, handlePrevious]);

  return createPortal(
    <div className="fixed left-0 top-0 z-50 box-border flex h-full w-full items-center justify-center bg-stone-900 text-stone-100">
      <button
        className="absolute right-0 top-0 m-4 rounded bg-stone-800 p-2 text-stone-100"
        onClick={onClose}>
        {t("generic.close")}
      </button>
      <div className="relative">
        {navigationVisibliity && (
          <>
            <button
              className="absolute left-0 top-1/2 m-4 -mt-4 rounded bg-stone-800 p-2 text-stone-100"
              onClick={handlePrevious}>
              <ChevronLeftIcon className="h-8 w-8 sm:h-12 sm:w-12" />
            </button>
            <button
              className="absolute right-0 top-1/2 m-4 -mt-4 rounded bg-stone-800 p-2 text-stone-100"
              onClick={handleNext}>
              <ChevronLeftIcon className="h-8 w-8 rotate-180 transform sm:h-12 sm:w-12" />
            </button>
          </>
        )}

        <img
          crossOrigin="anonymous"
          id="preview-image"
          src={currentImage}
          alt="Preview"
          className="h-full w-full"
          onClick={toggleNavigationVisibility}
        />
      </div>
    </div>,
    document.body,
  );
};
