import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { UserIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

import { EMAIL_PATTERN, NAME } from "@constants/validations";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";
import { Button } from "@components/ui/Button";
import { PhoneField } from "@components/ui/PhoneField";
import { TextField } from "@components/ui/TextField";

import { useEmployeeStore } from "@features/employees/hooks";

import { AddEmployeeInput, Role } from "@/types";
import { isNotWhitespace } from "@/utils/form";

type AddEmployeeDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const AddEmployeeDialog = ({ open, onClose }: AddEmployeeDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isValid },
    control,
    reset,
    handleSubmit,
  } = useForm<AddEmployeeInput>({
    mode: "all",
  });
  const { addEmployee, loadingAdd, addError } = useEmployeeStore();

  const handleAddClient = async (data: AddEmployeeInput) => {
    const { errors } = await addEmployee({
      ...data,
      role: Role.Employee,
    });
    if (!errors) {
      reset();
      onClose();
    }
  };

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("employees.addNew")}>
      <form className="space-y-4" onSubmit={handleSubmit(handleAddClient)} noValidate>
        <TextField
          {...register("firstName", {
            required: true,
            minLength: NAME.MIN_LENGTH,
            maxLength: NAME.MAX_LENGTH,
            validate: {
              isNotWhitespace,
            },
          })}
          label={t("generic.firstName")}
          placeholder={t("generic.firstName")}
          startIcon={<UserIcon />}
          errorMessage={
            errors.firstName &&
            t(`validation.${errors.firstName.type}`, {
              name: t("generic.firstName"),
              minLength: NAME.MIN_LENGTH,
              maxLength: NAME.MAX_LENGTH,
            })
          }
        />

        <TextField
          {...register("lastName", {
            required: true,
            minLength: NAME.MIN_LENGTH,
            maxLength: NAME.MAX_LENGTH,
            validate: {
              isNotWhitespace,
            },
          })}
          label={t("generic.lastName")}
          placeholder={t("generic.lastName")}
          startIcon={<UserIcon />}
          errorMessage={
            errors.lastName &&
            t(`validation.${errors.lastName.type}`, {
              name: t("generic.lastName"),
              minLength: NAME.MIN_LENGTH,
              maxLength: NAME.MAX_LENGTH,
            })
          }
        />

        <TextField
          {...register("email", { pattern: EMAIL_PATTERN, required: true })}
          label={t("generic.email")}
          placeholder={t("generic.email")}
          startIcon={<EnvelopeIcon />}
          errorMessage={
            errors.email &&
            t(`validation.${errors.email.type}`, {
              name: t("generic.email"),
            })
          }
        />

        <PhoneField
          name="phone"
          control={control}
          label={t("generic.phone")}
          showLabel={false}
          placeholder={t("generic.phone")}
          errorMessage={
            errors.phone &&
            t(`validation.${errors.phone.type}`, {
              name: t("generic.phone"),
            })
          }
        />

        {addError && (
          <p className="my-4 text-red-500">
            {addError.message === "auth0_user_already_created"
              ? t(`employees.errors.${addError.message}`)
              : addError.message}
          </p>
        )}

        <div className="align-center mt-4 flex flex-col">
          <Button variant="primary-inline" fullWidth disabled={loadingAdd} onClick={onClose}>
            {t("generic.cancel")}
          </Button>
          <Button type="submit" fullWidth className="mt-4" disabled={!isValid || loadingAdd}>
            {t("generic.save")}
          </Button>
        </div>
      </form>
    </DefaultDialog>
  );
};
