import { Navigate, Outlet } from "react-router-dom";

import { Spinner } from "@/components/ui/Spinner";
import { useSessionContext } from "@/providers/SessionProvider";
import type { Role, SubscriptionPlan } from "@/types";

type ProtectedRoutesProps = {
  roles?: Role[];
  plans?: SubscriptionPlan[];
  permission?: string;
};

export const ProtectedRoutes = ({ roles, plans, permission }: ProtectedRoutesProps) => {
  const { loading, role, salonPlan, permissions } = useSessionContext();

  if (loading) {
    return (
      <div className="absolute inset-1/2 h-[15vh] w-[15vh] -translate-x-1/2 -translate-y-1/2">
        <Spinner className="h-full w-full opacity-50" />
      </div>
    );
  }

  if (
    (role && roles && !roles?.includes(role)) ||
    (salonPlan && plans && !plans?.includes(salonPlan)) ||
    (permission && !permissions[permission])
  ) {
    return <Navigate to="/calendar" replace />;
  }

  return <Outlet />;
};
