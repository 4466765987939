import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type {
  BlockedSlotFormInput,
  BlockedSlotFormProps,
} from "@/features/calendar/components/BlockedSlot/BlockedSlotForm";
import { useBlockedSlotStore } from "@/features/calendar/hooks/use-blocked-slot-store";
import { useCalendarStore } from "@/features/calendar/hooks/use-calendar-store";
import { useToasts } from "@/providers/ToastsProvider";
import { setTimeToDate } from "@/utils/datetime";

export const useBlockedSlotForm = ({ onClose, defaultValues, action }: BlockedSlotFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const form = useForm<BlockedSlotFormInput>({
    defaultValues,
    shouldUnregister: true,
  });

  const { block, update, deleteSlot } = useBlockedSlotStore();
  const { refetchCalendar } = useCalendarStore({});

  const onSubmit = async (data: BlockedSlotFormInput) => {
    const { date, timeRange, title, ...rest } = data;

    const input = {
      ...rest,
      title: title ? title : t("blockedSlots.timeBlock"),
      uuid: defaultValues.uuid,
      employeeUuid: data.employeeUuid ?? defaultValues.employeeUuid,
      timeRange: {
        from: setTimeToDate(date, timeRange.from),
        to: setTimeToDate(date, timeRange.to),
      },
    };

    const { errors } = await (action === "update" ? update(input) : block(input));

    if (!errors) {
      showToast({
        title:
          action === "update" ? t("blockedSlots.toasts.updated") : t("blockedSlots.toasts.created"),
        type: "success",
      });

      if (action === "update") {
        refetchCalendar();
      }

      onClose?.();
    }
  };

  const handleDelete = async () => {
    const { errors } = await deleteSlot(defaultValues.uuid);

    if (!errors) {
      showToast({
        title: t("blockedSlots.toasts.deleted"),
        type: "success",
      });
      onClose?.();
    }
  };

  return {
    onSubmit,
    handleDelete,
    form,
  };
};
