import { useTranslation } from "react-i18next";

import { PlusIcon } from "@heroicons/react/24/outline";

import { Layout } from "@/components/layout/Layout";
import { Subheader } from "@/components/layout/subheader/Subheader";
import { Button } from "@/components/ui/Button";
import { EquipmentItems } from "@/features/equipment/components/EquipmentItems";
import { useEquipmentContext } from "@/features/equipment/providers/EquipmentProvider";
import { useSessionContext } from "@/providers/SessionProvider";

export const EquipmentView = () => {
  const { t } = useTranslation();
  const { createItem } = useEquipmentContext();
  const { permissions } = useSessionContext();

  return (
    <Layout title={t("equipment.name")}>
      <div className="-ml-4 -mr-4 mb-4 max-w-6xl rounded-xl px-4 py-8 sm:mb-8 sm:mt-4 sm:px-12 md:mx-auto">
        <Subheader
          title={t("equipment.name")}
          leftSlot={
            permissions.manage_equipment && (
              <Button startIcon={<PlusIcon />} size="small" onClick={() => createItem()}>
                {t("equipment.addNew")}
              </Button>
            )
          }
        />
        <p className="mt-4 text-sm text-stone-500 sm:mt-6 sm:text-base">
          {t("equipment.description")}
        </p>
      </div>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <EquipmentItems />
      </div>
    </Layout>
  );
};
