import { ForwardedRef, forwardRef, useEffect, useState } from "react";

import { ArrowUturnLeftIcon, UserIcon } from "@heroicons/react/24/outline";

import { Avatar } from "@/components/ui/Avatar";
import { CalendarViews } from "@/features/calendar/models";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { CalendarEmployee } from "@/types";

import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";
import { useCalendarView } from "../../contexts/CalendarViewContext";

type CalendarDayEmployeesProps = {
  employees: Array<CalendarEmployee>;
};

export const CalendarDayEmployees = forwardRef(
  ({ employees }: CalendarDayEmployeesProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [filteredEmployee, setFilteredEmployee] = useState<CalendarEmployee | undefined>(
      undefined,
    );

    const { view, setView } = useCalendarView();
    const { filters, setFilters } = useCalendarFilters();

    const { isSm } = useBreakpoint("sm");

    useEffect(() => {
      const employeeUuid = filters.employeeUuid;
      const employee = employees.find((employee) => employee.accountUuid === employeeUuid);

      setFilteredEmployee(employee);
    }, [employees, filters.employeeUuid, view, isSm]);

    const handleButtonClick = (employee: CalendarEmployee) => {
      if (view === CalendarViews.EmployeeDay) return;

      setFilteredEmployee(employee);
      setFilters({ employeeUuid: employee.accountUuid });
      setView(CalendarViews.EmployeeDay);
    };

    const handleBackButtonClick = () => {
      setFilteredEmployee(undefined);
      setFilters({ employeeUuid: undefined });
      setView(view === CalendarViews.EmployeeDay ? CalendarViews.Day : CalendarViews.Week);
    };

    return (
      <div
        ref={ref}
        className="sticky top-[90px] z-40 h-10 w-full min-w-max bg-white shadow sm:top-0 sm:pr-8">
        {!!filteredEmployee ? (
          <div className="flex h-10 border-r border-stone-100 text-sm leading-6 text-stone-500 sm:-mr-px">
            <div className="col-end-1 w-10" />
            <div className="-ml-10 flex flex-auto items-center justify-center">
              <button
                className="mr-3 h-5 rounded-md border border-stone-300 px-2 text-sm text-stone-500 shadow-sm transition-colors hover:border-stone-400 hover:text-stone-600 sm:mr-4 sm:px-3"
                onClick={handleBackButtonClick}>
                <ArrowUturnLeftIcon className="h-3 w-3 sm:h-4 sm:w-4" aria-hidden="true" />
              </button>
              <Avatar imageUrl={filteredEmployee.imageUrl} fallbackIcon={UserIcon} size="sm" />
              <span className="text-xs font-semibold text-stone-900">{filteredEmployee.name}</span>
            </div>
          </div>
        ) : (
          <div
            className="grid h-10 divide-x divide-stone-200 border-r border-stone-100 text-sm leading-6 text-stone-500 sm:-mr-px"
            style={{
              gridTemplateColumns: `repeat(${employees.length}, minmax(250px, 1fr))`,
            }}>
            <div className="col-end-1 w-10" />
            {employees.map((employee, index) => (
              <button
                key={index}
                className="flex flex-auto items-center justify-center px-3 transition-colors duration-200 last:border-transparent hover:bg-stone-100"
                onClick={() => handleButtonClick(employee)}>
                <Avatar imageUrl={employee.imageUrl} fallbackIcon={UserIcon} size="sm" />
                <span className="text-xs font-semibold text-stone-900">{employee.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  },
);
