export const DATE_FORMAT = {
  DEFAULT: "dd.MM.yyyy", // example: 01.01.2022
  DEFAULT_WITH_TIME: "dd.MM.yyyy, HH:mm", // example: 01.01.2022, 12:00
  DATE_WITH_DAY_NAME: "EEEE, dd.MM.yyyy", // example: Saturday, 01.01.2022
  TIME: "HH:mm", // example: 12:00
  DAY: "dd", // example: 01
  DAY_FIRST_LETTER: "EEEEE", // example: S
  DAY_MONTH: "dd MMMM", // example: 01 January
  DAY_MONTH_YEAR: "dd MMMM (yyyy)", // example: 01 January (2022)
  DAY_MONTH_TIME: "dd MMMM, HH:mm", // example: 01 January, 12:00
  DAY_OF_WEEK: "EEEE", // example: Saturday
  DAY_OF_WEEK_MONTH: "EEEE, dd MMMM", // example: Saturday, 01 January
  DAY_OF_WEEK_MONTH_YEAR: "EEEE, dd MMMM (yyyy)", // example: Saturday, 01 January (2022)
  MONTH: "MMMM", // example: January
  MONTH_STAND_ALONE: "LLLL", // example: January
  MONTH_STAND_ALONE_YEAR: "LLLL (yyyy)", // example: January (2022)
  MONTH_YEAR: "MMMM, yyyy", // example: January, 2022
  DAY_MONTH_YEAR_TIME: "dd MMMM (yyyy), HH:mm", // example: 01 January (2022), 12:00
};
