import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type AccountWithProfileFragment = { __typename?: 'AccountWithProfile', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null };

export const AccountWithProfileFragmentDoc = gql`
    fragment AccountWithProfile on AccountWithProfile {
  email
  firstName
  lastName
  phone
}
    `;