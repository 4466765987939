import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEmployeesPositionsMutationVariables = Types.Exact<{
  data: Types.UpdateEmployeesPositionsInput;
}>;


export type UpdateEmployeesPositionsMutation = { __typename?: 'RootMutationType', updateEmployeesPositions: string };


export const UpdateEmployeesPositionsDocument = gql`
    mutation UpdateEmployeesPositions($data: UpdateEmployeesPositionsInput!) {
  updateEmployeesPositions(data: $data)
}
    `;
export type UpdateEmployeesPositionsMutationFn = Apollo.MutationFunction<UpdateEmployeesPositionsMutation, UpdateEmployeesPositionsMutationVariables>;

/**
 * __useUpdateEmployeesPositionsMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeesPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeesPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeesPositionsMutation, { data, loading, error }] = useUpdateEmployeesPositionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEmployeesPositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeesPositionsMutation, UpdateEmployeesPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeesPositionsMutation, UpdateEmployeesPositionsMutationVariables>(UpdateEmployeesPositionsDocument, options);
      }
export type UpdateEmployeesPositionsMutationHookResult = ReturnType<typeof useUpdateEmployeesPositionsMutation>;
export type UpdateEmployeesPositionsMutationResult = Apollo.MutationResult<UpdateEmployeesPositionsMutation>;
export type UpdateEmployeesPositionsMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeesPositionsMutation, UpdateEmployeesPositionsMutationVariables>;