import { InputHTMLAttributes } from "react";
import { Control, Controller } from "react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { Value } from "react-phone-number-input/mobile";
import PhoneInput from "react-phone-number-input/react-hook-form-input";

import { PhoneIcon } from "@heroicons/react/24/outline";

import { cn } from "@/utils/utils";

type PhoneFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  control: Control<any>;
  defaultValue?: Value;
  placeholder?: string;
  className?: string;
  required?: boolean;
  errorMessage?: string;
  showLabel?: boolean;
};

export const PhoneField = ({
  label,
  name,
  control,
  defaultValue,
  placeholder,
  className,
  required,
  errorMessage,
  showLabel = true,
  ...delegated
}: PhoneFieldProps) => {
  return (
    <div className={cn("relative", { "space-y-1": showLabel })}>
      <label htmlFor={name} className={cn(showLabel ? "block text-xs text-stone-500" : "sr-only")}>
        {label || name}
      </label>

      <div className="relative flex w-full items-center">
        {" "}
        <div
          className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          aria-hidden="true">
          <PhoneIcon className={cn("h-5 w-5 text-stone-400", { "text-red-600": !!errorMessage })} />
        </div>
        <Controller
          control={control}
          name={name}
          rules={{ required, validate: (value) => value && isPossiblePhoneNumber(value) }}
          render={({ field: { onChange, value } }) => (
            <>
              <PhoneInput
                control={control}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                country="PL"
                {...delegated}
                className={cn(
                  "flex h-11 w-full appearance-none items-center rounded-md border border-stone-300 px-3 pl-10 text-sm placeholder-stone-400 shadow-sm focus:border-gold-600 focus:outline-none focus:ring-gold-600",
                  className,
                  {
                    "border-red-600 focus:border-red-600 focus:outline-none focus:ring-red-600":
                      !!errorMessage,
                  },
                )}
              />
            </>
          )}
        />
      </div>
      {errorMessage && <span className="block text-xs text-red-600">{errorMessage}</span>}
    </div>
  );
};
