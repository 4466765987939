import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EmployeeFragmentDoc } from '../fragments/Employee.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchEmployeeQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
}>;


export type FetchEmployeeQuery = { __typename?: 'RootQueryType', fetchEmployee: { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Types.Role, permissions?: any | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: any | null, treatments: Array<{ __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } };


export const FetchEmployeeDocument = gql`
    query FetchEmployee($id: UUID4!) {
  fetchEmployee(accountUuid: $id) {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useFetchEmployeeQuery__
 *
 * To run a query within a React component, call `useFetchEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEmployeeQuery(baseOptions: Apollo.QueryHookOptions<FetchEmployeeQuery, FetchEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchEmployeeQuery, FetchEmployeeQueryVariables>(FetchEmployeeDocument, options);
      }
export function useFetchEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchEmployeeQuery, FetchEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchEmployeeQuery, FetchEmployeeQueryVariables>(FetchEmployeeDocument, options);
        }
export type FetchEmployeeQueryHookResult = ReturnType<typeof useFetchEmployeeQuery>;
export type FetchEmployeeLazyQueryHookResult = ReturnType<typeof useFetchEmployeeLazyQuery>;
export type FetchEmployeeQueryResult = Apollo.QueryResult<FetchEmployeeQuery, FetchEmployeeQueryVariables>;