import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { ClientTagFragmentDoc } from '../../fragments/ClientTag.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateClientTagMutationVariables = Types.Exact<{
  data: Types.ClientTagInput;
  tagUuid: Types.Scalars['UUID4'];
}>;


export type UpdateClientTagMutation = { __typename?: 'RootMutationType', updateClientTag: { __typename?: 'ClientTag', color: string, name: string, uuid: any } };


export const UpdateClientTagDocument = gql`
    mutation UpdateClientTag($data: ClientTagInput!, $tagUuid: UUID4!) {
  updateClientTag(data: $data, tagUuid: $tagUuid) {
    ...ClientTag
  }
}
    ${ClientTagFragmentDoc}`;
export type UpdateClientTagMutationFn = Apollo.MutationFunction<UpdateClientTagMutation, UpdateClientTagMutationVariables>;

/**
 * __useUpdateClientTagMutation__
 *
 * To run a mutation, you first call `useUpdateClientTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientTagMutation, { data, loading, error }] = useUpdateClientTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *      tagUuid: // value for 'tagUuid'
 *   },
 * });
 */
export function useUpdateClientTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientTagMutation, UpdateClientTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientTagMutation, UpdateClientTagMutationVariables>(UpdateClientTagDocument, options);
      }
export type UpdateClientTagMutationHookResult = ReturnType<typeof useUpdateClientTagMutation>;
export type UpdateClientTagMutationResult = Apollo.MutationResult<UpdateClientTagMutation>;
export type UpdateClientTagMutationOptions = Apollo.BaseMutationOptions<UpdateClientTagMutation, UpdateClientTagMutationVariables>;