import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SearchTreatmentFragmentDoc } from '../fragments/SearchTreatment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchChosenTreatmentsQueryVariables = Types.Exact<{
  uuids: Array<Types.Scalars['UUID4']> | Types.Scalars['UUID4'];
}>;


export type FetchChosenTreatmentsQuery = { __typename?: 'RootQueryType', fetchChosenTreatments: Array<{ __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null }> };


export const FetchChosenTreatmentsDocument = gql`
    query FetchChosenTreatments($uuids: [UUID4!]!) {
  fetchChosenTreatments(uuids: $uuids) {
    ...SearchTreatment
  }
}
    ${SearchTreatmentFragmentDoc}`;

/**
 * __useFetchChosenTreatmentsQuery__
 *
 * To run a query within a React component, call `useFetchChosenTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchChosenTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchChosenTreatmentsQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *   },
 * });
 */
export function useFetchChosenTreatmentsQuery(baseOptions: Apollo.QueryHookOptions<FetchChosenTreatmentsQuery, FetchChosenTreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchChosenTreatmentsQuery, FetchChosenTreatmentsQueryVariables>(FetchChosenTreatmentsDocument, options);
      }
export function useFetchChosenTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchChosenTreatmentsQuery, FetchChosenTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchChosenTreatmentsQuery, FetchChosenTreatmentsQueryVariables>(FetchChosenTreatmentsDocument, options);
        }
export type FetchChosenTreatmentsQueryHookResult = ReturnType<typeof useFetchChosenTreatmentsQuery>;
export type FetchChosenTreatmentsLazyQueryHookResult = ReturnType<typeof useFetchChosenTreatmentsLazyQuery>;
export type FetchChosenTreatmentsQueryResult = Apollo.QueryResult<FetchChosenTreatmentsQuery, FetchChosenTreatmentsQueryVariables>;