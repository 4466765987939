import { Control, Controller } from "react-hook-form";

import { RadioGroup } from "@headlessui/react";

import { SERVICE_CATEGORY_COLORS } from "@/constants/colors";
import { resolveCategoryColor } from "@/utils/colors";
import { cn, pickRandomElement } from "@/utils/utils";

type ColorPickerProps = {
  name: string;
  label: string;
  control: Control<any>;
};

export const ColorPicker = ({ control, name, label }: ColorPickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      defaultValue={pickRandomElement(Object.keys(SERVICE_CATEGORY_COLORS))}
      render={({ field: { onChange, value } }) => {
        const resolvedValue = resolveCategoryColor(value);

        return (
          <RadioGroup
            value={resolvedValue}
            onChange={(newColor) => {
              onChange(newColor);
            }}>
            <RadioGroup.Label className="block text-sm font-medium text-stone-700">
              {label}
            </RadioGroup.Label>
            <div className="mt-4 flex flex-wrap">
              {Object.keys(SERVICE_CATEGORY_COLORS).map((colorKey) => (
                <RadioGroup.Option
                  key={colorKey}
                  value={colorKey}
                  className={({ active, checked }) =>
                    cn(
                      active && checked && "ring ring-offset-1",
                      !active && checked && "ring-2",
                      "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none",
                    )
                  }>
                  <div
                    className={cn(
                      "m-2 h-8 w-8 rounded-full border",
                      SERVICE_CATEGORY_COLORS[colorKey].border,
                      SERVICE_CATEGORY_COLORS[colorKey].primary,
                    )}></div>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        );
      }}
    />
  );
};
