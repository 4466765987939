import { useCreateClientTagMutation } from "@/features/clients/mutations/tags/CreateClientTag.generated";
import { useDeleteClientTagMutation } from "@/features/clients/mutations/tags/DeleteClientTag.generated";
import { useUpdateClientTagMutation } from "@/features/clients/mutations/tags/UpdateClientTag.generated";
import { ClientTagInput, namedOperations, Scalars } from "@/types";

export const useTagStore = () => {
  const [createTag, { loading: loadingCreateTag }] = useCreateClientTagMutation();
  const [updateTag, { loading: loadingUpdateTag }] = useUpdateClientTagMutation();
  const [deleteTag, { loading: loadingDeleteTag }] = useDeleteClientTagMutation();

  return {
    isMutationLoading: loadingCreateTag || loadingUpdateTag || loadingDeleteTag,
    loadingCreateTag,
    loadingUpdateTag,
    loadingDeleteTag,
    createTag(data: ClientTagInput) {
      return createTag({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.FetchSalonTags],
      });
    },
    updateTag(data: ClientTagInput, tagUuid: Scalars["UUID4"]) {
      return updateTag({
        variables: {
          data,
          tagUuid,
        },
        refetchQueries: [namedOperations.Query.FetchSalonTags],
      });
    },
    deleteTag(tagUuid: Scalars["UUID4"]) {
      return deleteTag({
        variables: {
          tagUuid,
        },
        update(cache) {
          const normalizedId = cache.identify({
            uuid: tagUuid,
            __typename: "ClientTag",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
  };
};
