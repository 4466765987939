import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RadioGroup } from "@headlessui/react";

import { ArrowRightOnRectangleIcon, DevicePhoneMobileIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";

import { useSalonSmsDetailsStore } from "@features/salon/hooks";

import { defaultSenderNameOptions } from "@/constants/messages";
import { ROUTES } from "@/features/router/constants/routes";
import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

type FormData = {
  senderName: string;
};

export const OnboardingSalonSmsDetailsView = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { logout } = useSessionContext();
  const { salonSmsDetails, updateSalonSenderName, loading } = useSalonSmsDetailsStore(true);
  const {
    control,
    formState: { isValid },
    setValue,
    handleSubmit,
  } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      senderName: defaultSenderNameOptions[0],
    },
  });

  const handleUpdateSenderName: SubmitHandler<FormData> = async (data) => {
    await updateSalonSenderName(data.senderName);

    navigate(ROUTES.SALON);
  };

  useEffect(() => {
    salonSmsDetails && setValue("senderName", salonSmsDetails.senderName, { shouldValidate: true });
  }, [salonSmsDetails]);

  return (
    <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <h1 className="mb-2 text-4xl font-bold text-stone-900">
          {t("onboarding.salonSmsDetails.title")}
        </h1>
        <p className="mb-4 text-sm font-normal text-stone-400">
          {t("onboarding.salonSmsDetails.description")}
        </p>
        <div className="fixed right-4 top-4">
          <Button
            size="small"
            variant="primary-inline"
            startIcon={<ArrowRightOnRectangleIcon />}
            onClick={logout}>
            {t("header.mainMenu.logOut")}
          </Button>
        </div>
        <form className="space-y-4" onSubmit={handleSubmit(handleUpdateSenderName)} noValidate>
          <Controller
            control={control}
            name="senderName"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <RadioGroup className="flex gap-2" value={value} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                  {t("onboarding.salonSmsDetails.title")}
                </RadioGroup.Label>
                {defaultSenderNameOptions.map((option) => (
                  <RadioGroup.Option
                    value={option}
                    key={option}
                    className={({ checked }) =>
                      cn(
                        "relative flex cursor-pointer rounded-sm px-5 py-4 shadow-md focus:outline-none",
                        checked ? "bg-gold-500 bg-opacity-75 text-white" : "bg-gold-100",
                      )
                    }>
                    {({ checked }) => (
                      <div className="flex w-full items-center justify-between">
                        <DevicePhoneMobileIcon className="mr-2 h-6 w-6" />
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label
                              as="p"
                              className={`font-medium  ${
                                checked ? "text-white" : "text-stone-900"
                              }`}>
                              {option}
                            </RadioGroup.Label>
                          </div>
                        </div>
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            )}
          />
          <p className="mb-4 text-sm font-normal text-stone-400">
            {t("onboarding.salonSmsDetails.hint")}
          </p>
          <Button type="submit" fullWidth disabled={!isValid || loading}>
            {t("generic.continue")}
          </Button>
        </form>
      </div>
    </div>
  );
};
