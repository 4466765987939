import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "@config/locales/en.json";
import translationPL from "@config/locales/pl.json";
import translationUA from "@config/locales/uk.json";

export const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
  uk: {
    translation: translationUA,
  },
};

export const importDateFnsLocale = (locale: string) => {
  switch (locale) {
    case "en":
      return import("date-fns/locale/en-US/index.js");
    case "pl":
      return import("date-fns/locale/pl/index.js");
    case "uk":
      return import("date-fns/locale/uk/index.js");
    default:
      return new Promise((_, reject) =>
        queueMicrotask(reject.bind(null, new Error(`Unknown locale: ${locale}`))),
      );
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: import.meta.env.VITE_DEFAULT_LANG || "pl",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "cookie", "navigator", "htmlTag"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      caches: ["cookie"],
    },
    supportedLngs: ["pl", "en", "uk"],
  });

document.documentElement.lang = i18n.language;
i18n.on("languageChanged", (lng) => {
  document.documentElement.setAttribute("lang", lng);
});

export default i18n;
