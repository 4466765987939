import { useMemo } from "react";

import { useFetchSalonEquipmentItemsQuery } from "../queries/FetchSalonEquipmentItems.generated";

export const useItemsStore = () => {
  const {
    data: itemsData,
    loading,
    fetchMore,
  } = useFetchSalonEquipmentItemsQuery({
    variables: {
      first: 9,
    },
  });

  const data = useMemo(
    () => itemsData?.fetchSalonEquipmentItems.edges.map((edge) => edge.node) ?? [],
    [itemsData],
  );

  return {
    loading,
    data,
    hasMore: itemsData?.fetchSalonEquipmentItems.pageInfo.hasNextPage,
    fetchMore() {
      if (fetchMore && itemsData) {
        return fetchMore({
          variables: {
            after: itemsData?.fetchSalonEquipmentItems.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
