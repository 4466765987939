import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTreatmentMutationVariables = Types.Exact<{
  treatmentUuid: Types.Scalars['UUID4'];
}>;


export type DeleteTreatmentMutation = { __typename?: 'RootMutationType', deleteTreatment: { __typename?: 'Treatment', isDeleted: boolean } };


export const DeleteTreatmentDocument = gql`
    mutation DeleteTreatment($treatmentUuid: UUID4!) {
  deleteTreatment(treatmentUuid: $treatmentUuid) {
    isDeleted
  }
}
    `;
export type DeleteTreatmentMutationFn = Apollo.MutationFunction<DeleteTreatmentMutation, DeleteTreatmentMutationVariables>;

/**
 * __useDeleteTreatmentMutation__
 *
 * To run a mutation, you first call `useDeleteTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTreatmentMutation, { data, loading, error }] = useDeleteTreatmentMutation({
 *   variables: {
 *      treatmentUuid: // value for 'treatmentUuid'
 *   },
 * });
 */
export function useDeleteTreatmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTreatmentMutation, DeleteTreatmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTreatmentMutation, DeleteTreatmentMutationVariables>(DeleteTreatmentDocument, options);
      }
export type DeleteTreatmentMutationHookResult = ReturnType<typeof useDeleteTreatmentMutation>;
export type DeleteTreatmentMutationResult = Apollo.MutationResult<DeleteTreatmentMutation>;
export type DeleteTreatmentMutationOptions = Apollo.BaseMutationOptions<DeleteTreatmentMutation, DeleteTreatmentMutationVariables>;