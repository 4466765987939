import { Trans, useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

import { AdjustmentsVerticalIcon } from "@heroicons/react/24/outline";

import { ESTETIFY_CONTACT_EMAIL } from "@/constants";
import { useSessionContext } from "@/providers/SessionProvider";

export const Maintenance = () => {
  const { t } = useTranslation();
  const { session } = useSessionContext();

  return session ? (
    <Navigate to="/calendar" />
  ) : (
    <div className="flex min-h-screen items-center bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <AdjustmentsVerticalIcon className="h-14 w-14 text-gold-500" />
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-stone-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-stone-900 sm:text-5xl">
                {t("session.maintenance.title")}
              </h1>
              <p className="mt-1 whitespace-pre-line pt-4 text-base text-stone-500">
                <Trans
                  i18nKey="session.maintenance.description"
                  values={{
                    contact: ESTETIFY_CONTACT_EMAIL,
                  }}
                  components={{
                    contact: (
                      <a
                        href={`mailto:${ESTETIFY_CONTACT_EMAIL}`}
                        className="font-medium text-gold-500 hover:text-gold-600"
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
