import { OnDataOptions } from "@apollo/client";
import { useEffect, useMemo } from "react";

import { CalendarViews } from "@features/calendar/models";

import { isAppointment, isBlockedSlot } from "@/features/calendar/utils/type-guards";
import { AppointmentFilters, AppointmentSubscriptionAction } from "@/types";
import { isTruthy } from "@/utils/utils";

import { useFetchCalendarQuery } from "../queries/FetchCalendar.generated";
import { useFetchCalendarByEmployeeQuery } from "../queries/FetchCalendarByEmployee.generated";
import {
  OnAppointmentCreatedOrUpdatedDocument,
  OnAppointmentCreatedOrUpdatedSubscription,
  useOnAppointmentCreatedOrUpdatedSubscription,
} from "../subscriptions/OnAppointmentCreatedOrUpdated.generated";
import {
  assignCalendarDataToDays,
  filterSlotRows,
  filterSlotsAssignedToDays,
  getSlotRows,
  getSlotRowsByEmployee,
} from "../utils/slots";

type UseCalendarStoreProps = {
  filters?: AppointmentFilters;
  onSubscription?: (options: OnDataOptions<OnAppointmentCreatedOrUpdatedSubscription>) => unknown;
  calendarView?: CalendarViews;
};

export const useCalendarStore = ({
  filters = {},
  onSubscription,
  calendarView,
}: UseCalendarStoreProps) => {
  const { data: calendarSubscriptionData } = useOnAppointmentCreatedOrUpdatedSubscription({
    variables: {
      filters: filters ?? {},
    },
    onData: onSubscription,
  });

  const {
    data: calendarData,
    loading: fetchCalendarLoading,
    subscribeToMore,
    refetch: refetchCalendar,
  } = useFetchCalendarQuery({
    fetchPolicy: "cache-and-network",
    variables: filters && { filters },
    skip: !filters || Object.keys(filters).length === 0 || calendarView !== CalendarViews.Month,
  });

  const {
    data: calendarDataByEmployee,
    subscribeToMore: subscribeToMoreByEmployee,
    loading: fetchCalendarByEmployeeLoading,
  } = useFetchCalendarByEmployeeQuery({
    fetchPolicy: "cache-and-network",
    variables: filters && { filters },
    skip: !filters || Object.keys(filters).length === 0,
  });

  useEffect(() => {
    const unsubscribe = subscribeToMore<OnAppointmentCreatedOrUpdatedSubscription>({
      document: OnAppointmentCreatedOrUpdatedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !subscriptionData.data ||
          subscriptionData.data.appointmentCreatedOrUpdated?.action ===
            AppointmentSubscriptionAction.Update
        ) {
          return {
            fetchCalendar: prev.fetchCalendar ?? [],
          };
        }

        return {
          fetchCalendar: [
            ...(prev.fetchCalendar ?? []),
            subscriptionData.data.appointmentCreatedOrUpdated?.appointment,
          ].filter(isTruthy),
        };
      },
      variables: {
        filters: filters ?? {},
      },
    });

    return () => unsubscribe && unsubscribe();
  }, [subscribeToMore, filters]);

  useEffect(() => {
    const unsubscribe = subscribeToMoreByEmployee<OnAppointmentCreatedOrUpdatedSubscription>({
      document: OnAppointmentCreatedOrUpdatedDocument,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          !subscriptionData.data ||
          subscriptionData.data.appointmentCreatedOrUpdated?.action ===
            AppointmentSubscriptionAction.Update
        ) {
          return {
            fetchCalendarByEmployee: prev.fetchCalendarByEmployee ?? [],
          };
        }

        const newAppointment = subscriptionData.data.appointmentCreatedOrUpdated?.appointment;

        if (!newAppointment) {
          return {
            fetchCalendarByEmployee: prev.fetchCalendarByEmployee ?? [],
          };
        }

        const employeeUuids = [
          ...new Set(newAppointment.treatments.map((treatment) => treatment.employeeUuid)),
        ];

        const newFetchCalendarByEmployee = prev.fetchCalendarByEmployee
          ? prev.fetchCalendarByEmployee.map((entry) => {
              if (employeeUuids.includes(entry.employee.accountUuid)) {
                return {
                  ...entry,
                  entries: [...entry.entries, newAppointment].filter(isTruthy),
                };
              }
              return entry;
            })
          : [];

        return {
          fetchCalendarByEmployee: newFetchCalendarByEmployee,
        };
      },
      variables: {
        filters: filters ?? {},
      },
    });
    return () => unsubscribe && unsubscribe();
  }, [subscribeToMoreByEmployee, filters]);

  const appointments = calendarData?.fetchCalendar.filter(isAppointment) ?? [];
  const blockedSlots = calendarData?.fetchCalendar.filter(isBlockedSlot) ?? [];

  const slotRows = useMemo(
    () => getSlotRows(filters.clientUuid ? appointments : calendarData?.fetchCalendar ?? []) ?? [],
    [calendarData?.fetchCalendar, appointments, filters.clientUuid],
  );

  const slotRowsByEmployee = useMemo(
    () => getSlotRowsByEmployee(calendarDataByEmployee?.fetchCalendarByEmployee ?? [], filters),
    [calendarDataByEmployee?.fetchCalendarByEmployee],
  );

  const slotsAssignedToDays = useMemo(
    () => assignCalendarDataToDays(calendarData?.fetchCalendar ?? []),
    [calendarData?.fetchCalendar],
  );

  const slotsAssignedToDaysByEmployee = useMemo(() => {
    return calendarDataByEmployee?.fetchCalendarByEmployee?.map((item) => {
      const assignedSlots = assignCalendarDataToDays(item.entries);

      return {
        employee: item.employee,
        slotsAssignedToDays: filterSlotsAssignedToDays(
          assignedSlots,
          {
            ...filters,
          },
          item.employee.accountUuid,
        ),
      };
    });
  }, [calendarDataByEmployee?.fetchCalendarByEmployee]);

  const filteredSlotRows = filterSlotRows(slotRows, filters);

  const filteredSlotsAssignedToDays = filterSlotsAssignedToDays(slotsAssignedToDays, {
    ...filters,
  });

  return {
    loading: fetchCalendarLoading || fetchCalendarByEmployeeLoading,
    calendar: calendarData?.fetchCalendar ?? [],
    calendarByEmployee: calendarDataByEmployee?.fetchCalendarByEmployee ?? [],
    slotRows: filteredSlotRows,
    slotRowsByEmployee,
    slotsAssignedToDaysByEmployee,
    slotsAssignedToDays: filteredSlotsAssignedToDays,
    appointmentsSubscriptionData: calendarSubscriptionData?.appointmentCreatedOrUpdated,
    appointments,
    blockedSlots,
    refetchCalendar,
  };
};
