import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonOpeningHoursFragmentDoc } from '../fragments/SalonOpeningHours.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonOpeningHoursQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSalonOpeningHoursQuery = { __typename?: 'RootQueryType', fetchSalonOpeningHours: { __typename?: 'SalonOpeningHours', regular?: any | null, special?: any | null } };


export const FetchSalonOpeningHoursDocument = gql`
    query FetchSalonOpeningHours {
  fetchSalonOpeningHours {
    ...SalonOpeningHours
  }
}
    ${SalonOpeningHoursFragmentDoc}`;

/**
 * __useFetchSalonOpeningHoursQuery__
 *
 * To run a query within a React component, call `useFetchSalonOpeningHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonOpeningHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonOpeningHoursQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSalonOpeningHoursQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonOpeningHoursQuery, FetchSalonOpeningHoursQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonOpeningHoursQuery, FetchSalonOpeningHoursQueryVariables>(FetchSalonOpeningHoursDocument, options);
      }
export function useFetchSalonOpeningHoursLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonOpeningHoursQuery, FetchSalonOpeningHoursQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonOpeningHoursQuery, FetchSalonOpeningHoursQueryVariables>(FetchSalonOpeningHoursDocument, options);
        }
export type FetchSalonOpeningHoursQueryHookResult = ReturnType<typeof useFetchSalonOpeningHoursQuery>;
export type FetchSalonOpeningHoursLazyQueryHookResult = ReturnType<typeof useFetchSalonOpeningHoursLazyQuery>;
export type FetchSalonOpeningHoursQueryResult = Apollo.QueryResult<FetchSalonOpeningHoursQuery, FetchSalonOpeningHoursQueryVariables>;