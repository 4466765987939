import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../fragments/PageInfo.generated';
import { SmsFragmentDoc } from '../fragments/Sms.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSentSmsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  clientUuid?: Types.InputMaybe<Types.Scalars['UUID4']>;
}>;


export type FetchSentSmsQuery = { __typename?: 'RootQueryType', fetchSentSms: { __typename?: 'SmsConnection', count: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'SmsEdge', cursor?: string | null, node: { __typename?: 'Sms', clientUuid: any, firstName?: string | null, lastName?: string | null, message?: string | null, recipient?: string | null, salonUuid: any, senderName?: string | null, sentAt?: any | null, status: Types.SmsStatus, uuid: any } }> } };


export const FetchSentSmsDocument = gql`
    query fetchSentSms($after: String, $first: Int, $clientUuid: UUID4) {
  fetchSentSms(after: $after, first: $first, clientUuid: $clientUuid) {
    count
    pageInfo {
      ...PageInfo
    }
    edges {
      cursor
      node {
        ...Sms
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${SmsFragmentDoc}`;

/**
 * __useFetchSentSmsQuery__
 *
 * To run a query within a React component, call `useFetchSentSmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSentSmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSentSmsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      clientUuid: // value for 'clientUuid'
 *   },
 * });
 */
export function useFetchSentSmsQuery(baseOptions?: Apollo.QueryHookOptions<FetchSentSmsQuery, FetchSentSmsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSentSmsQuery, FetchSentSmsQueryVariables>(FetchSentSmsDocument, options);
      }
export function useFetchSentSmsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSentSmsQuery, FetchSentSmsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSentSmsQuery, FetchSentSmsQueryVariables>(FetchSentSmsDocument, options);
        }
export type FetchSentSmsQueryHookResult = ReturnType<typeof useFetchSentSmsQuery>;
export type FetchSentSmsLazyQueryHookResult = ReturnType<typeof useFetchSentSmsLazyQuery>;
export type FetchSentSmsQueryResult = Apollo.QueryResult<FetchSentSmsQuery, FetchSentSmsQueryVariables>;