import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEquipmentItemMutationVariables = Types.Exact<{
  equipmentItemUuid: Types.Scalars['UUID4'];
}>;


export type DeleteEquipmentItemMutation = { __typename?: 'RootMutationType', deleteEquipmentItem: string };


export const DeleteEquipmentItemDocument = gql`
    mutation DeleteEquipmentItem($equipmentItemUuid: UUID4!) {
  deleteEquipmentItem(equipmentItemUuid: $equipmentItemUuid)
}
    `;
export type DeleteEquipmentItemMutationFn = Apollo.MutationFunction<DeleteEquipmentItemMutation, DeleteEquipmentItemMutationVariables>;

/**
 * __useDeleteEquipmentItemMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentItemMutation, { data, loading, error }] = useDeleteEquipmentItemMutation({
 *   variables: {
 *      equipmentItemUuid: // value for 'equipmentItemUuid'
 *   },
 * });
 */
export function useDeleteEquipmentItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEquipmentItemMutation, DeleteEquipmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEquipmentItemMutation, DeleteEquipmentItemMutationVariables>(DeleteEquipmentItemDocument, options);
      }
export type DeleteEquipmentItemMutationHookResult = ReturnType<typeof useDeleteEquipmentItemMutation>;
export type DeleteEquipmentItemMutationResult = Apollo.MutationResult<DeleteEquipmentItemMutation>;
export type DeleteEquipmentItemMutationOptions = Apollo.BaseMutationOptions<DeleteEquipmentItemMutation, DeleteEquipmentItemMutationVariables>;