import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { ClientEdit } from "@/features/clients/components/ClientFormDialog";

export const ClientEditView = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t("clients.edit")} className="bg-stone-100">
      <div className="mt-4 max-w-6xl rounded-xl bg-stone-50 px-2 pb-4 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto">
        <ClientEdit />
      </div>
    </Layout>
  );
};
