import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type ClientTagFragment = { __typename?: 'ClientTag', color: string, name: string, uuid: any };

export const ClientTagFragmentDoc = gql`
    fragment ClientTag on ClientTag {
  color
  name
  uuid
}
    `;