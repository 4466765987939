import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from "react";

import { Timerange } from "@/types";

import { getPeriodTimeRange } from "../utils/time";
import { useCalendarView } from "./CalendarViewContext";

type CalendarDateState = {
  selectedDate: Date;
  timeRange: Timerange;
};

type CalendarDateActions = {
  setSelectedDate: (date: Date) => void;
};

export type CalendarDateAPI = CalendarDateState & CalendarDateActions;

const enum ActionKind {
  SET_SELECTED_DATE = "SET_SELECTED_DATE",
  SET_TIME_RANGE = "SET_TIME_RANGE",
}

type Action =
  | { type: ActionKind.SET_SELECTED_DATE; payload: Date }
  | { type: ActionKind.SET_TIME_RANGE; payload: Timerange };

const CalendarDateContext = createContext<CalendarDateAPI | null>(null);

const calendarDateReducer = (state: CalendarDateState, action: Action): CalendarDateState => {
  switch (action.type) {
    case ActionKind.SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload,
      };
    case ActionKind.SET_TIME_RANGE:
      return {
        ...state,
        timeRange: action.payload,
      };
    default:
      return state;
  }
};

export const CalendarDateProvider = ({ children }: { children: ReactNode }) => {
  const { view } = useCalendarView();

  const [state, dispatch] = useReducer(calendarDateReducer, {
    selectedDate: new Date(),
    timeRange: getPeriodTimeRange(new Date(), view),
  });

  const actions = useMemo<CalendarDateActions>(() => {
    const setSelectedDate = (date: Date) => {
      dispatch({ type: ActionKind.SET_SELECTED_DATE, payload: date });
    };

    return {
      setSelectedDate,
    };
  }, []);

  useEffect(() => {
    const newTimeRange = getPeriodTimeRange(state.selectedDate, view);
    dispatch({ type: ActionKind.SET_TIME_RANGE, payload: newTimeRange });
  }, [state.selectedDate, view]);

  const api = useMemo<CalendarDateAPI>(() => {
    return {
      ...state,
      ...actions,
    };
  }, [state, actions]);

  return <CalendarDateContext.Provider value={api}>{children}</CalendarDateContext.Provider>;
};

export const useCalendarDate = () => {
  const context = useContext(CalendarDateContext);

  return useMemo<CalendarDateAPI>(() => {
    if (!context) {
      throw new Error("useCalendarDate must be used within a CalendarDateProvider");
    }
    return context;
  }, [context]);
};
