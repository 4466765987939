import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const currentSession: TypePolicy = relayStylePagination();

export const CurrentSession: TypePolicy = {
  fields: {
    fullName: {
      read(_, { readField }) {
        const firstName = readField<string>("firstName");
        const lastName = readField<string>("lastName");

        return `${firstName} ${lastName}`;
      },
    },
  },
};
