import { useReducer } from "react";

import { v4 as uuidv4 } from "uuid";

import { createAwsS3Url } from "@/utils/s3-utils";

export type Image = {
  file: File;
  name: string;
  url: string;
  isServerImage: boolean;
  toRemove: boolean;
};

type State = {
  images: { [key: string]: Image };
  imagesErrors: string[];
  imagesLoading: boolean;
};

type Action =
  | { type: "ADD_IMAGES"; payload: FileList }
  | { type: "LOAD_SERVER_IMAGES"; payload: string[] }
  | { type: "SET_SERVER_IMAGE_TO_REMOVE"; payload: string }
  | { type: "REMOVE_IMAGE"; payload: string }
  | { type: "SET_IMAGES_ERRORS"; payload: string[] }
  | { type: "SET_IMAGES_LOADING"; payload: boolean };

const initialState: State = {
  images: {},
  imagesErrors: [],
  imagesLoading: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "ADD_IMAGES":
      const newImages = Array.from(action.payload).reduce(
        (acc, file) => {
          const imageName = uuidv4();
          acc[imageName] = {
            file,
            name: imageName,
            url: createAwsS3Url(imageName),
            isServerImage: false,
            toRemove: false,
          };
          return acc;
        },
        { ...state.images },
      );
      return {
        ...state,
        images: newImages,
      };
    case "REMOVE_IMAGE":
      const { [action.payload]: _, ...restImages } = state.images;
      return {
        ...state,
        images: restImages,
      };
    case "LOAD_SERVER_IMAGES":
      const serverImages = action.payload.reduce((acc, url) => {
        const imageName = url.split("/").at(-1);

        if (!imageName) return acc;

        // @ts-ignore
        acc[imageName] = {
          file: null,
          name: imageName,
          url,
          isServerImage: true,
          toRemove: false,
        };
        return acc;
      }, {});
      return {
        ...state,
        images: { ...serverImages, ...state.images },
      };
    case "SET_SERVER_IMAGE_TO_REMOVE":
      return {
        ...state,
        images: {
          ...state.images,
          [action.payload]: {
            ...state.images[action.payload],
            toRemove: true,
          },
        },
      };
    case "SET_IMAGES_ERRORS":
      return {
        ...state,
        imagesErrors: action.payload,
      };
    case "SET_IMAGES_LOADING":
      return {
        ...state,
        imagesLoading: action.payload,
      };
    default:
      throw new Error();
  }
}

export const useAwsStore = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const loadServerImages = (images: string[]) => {
    dispatch({
      type: "LOAD_SERVER_IMAGES",
      payload: images,
    });
  };

  const setServerImageToRemove = (imageName: string) => {
    dispatch({ type: "SET_SERVER_IMAGE_TO_REMOVE", payload: imageName });
  };

  const addImages = (images: FileList) => {
    dispatch({ type: "ADD_IMAGES", payload: images });
  };

  const removeImage = (imageName: string) => {
    dispatch({ type: "REMOVE_IMAGE", payload: imageName });
  };

  const setImagesErrors = (errors: string[]) => {
    dispatch({ type: "SET_IMAGES_ERRORS", payload: errors });
  };

  const setImagesLoading = (isLoading: boolean) => {
    dispatch({ type: "SET_IMAGES_LOADING", payload: isLoading });
  };

  const currentImagesUrls = Object.values(state.images)
    .filter((image) => !image.toRemove)
    .map((image) => image.url);

  const getServerImagesSize = async () => {
    const serverImages = Object.values(state.images).filter(
      (image) => image.isServerImage && !image.toRemove,
    );
    const serverImagesSize = await Promise.all(
      serverImages.map(async (image) => {
        const response = await fetch(image.url);
        const blob = await response.blob();
        return blob.size;
      }),
    );

    return serverImagesSize.reduce((acc, size) => acc + size, 0);
  };

  const imagesArray = Object.values(state.images);

  return {
    images: imagesArray,
    addImages,
    removeImage,
    imagesErrors: state.imagesErrors,
    setImagesErrors,
    imagesLoading: state.imagesLoading,
    setImagesLoading,
    loadServerImages,
    setServerImageToRemove,
    currentImagesUrls,
    getServerImagesSize,
  };
};
