import { DATE_FORMAT } from "@constants/date-formats";
import { CalendarViews } from "@features/calendar/models";
import { format, isThisYear, isSameMonth } from "date-fns";

import { Timerange } from "@/types";

export const getRangeLabel = (range: Timerange, type: CalendarViews): string => {
  switch (type) {
    case CalendarViews.Day:
    case CalendarViews.EmployeeDay:
      return format(
        range.from,
        isThisYear(range.from) ? DATE_FORMAT.DAY_OF_WEEK_MONTH : DATE_FORMAT.DAY_OF_WEEK_MONTH_YEAR,
      );
    case CalendarViews.Week:
    case CalendarViews.EmployeeWeek:
      const start = format(
        range.from,
        isSameMonth(range.from, range.to) ? DATE_FORMAT.DAY : DATE_FORMAT.DAY_MONTH,
      );
      const end = format(
        range.to,
        isThisYear(range.from) && isThisYear(range.to)
          ? DATE_FORMAT.DAY_MONTH
          : DATE_FORMAT.DAY_MONTH_YEAR,
      );
      return `${start} - ${end}`;
    case CalendarViews.Month:
      return format(
        range.from,
        isThisYear(range.from) ? DATE_FORMAT.MONTH_STAND_ALONE : DATE_FORMAT.MONTH_STAND_ALONE_YEAR,
      );
    default:
      return "";
  }
};
