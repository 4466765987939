import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type PriceFragment = { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null };

export const PriceFragmentDoc = gql`
    fragment Price on Price {
  billingScheme
  currency
  price
  priceDecimal
  productId
  type
}
    `;