import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeesLedgerSummaryQueryVariables = Types.Exact<{
  month: Types.MonthInput;
}>;


export type EmployeesLedgerSummaryQuery = { __typename?: 'RootQueryType', employeesLedgerSummary: { __typename?: 'EmployeesLedgerSummary', items?: Array<{ __typename?: 'EmployeesLedgerSummaryEmployee', employeeId: any, treatmentId?: any | null, commission: { __typename?: 'Money', amount: number, currency: string }, turnover: { __typename?: 'Money', amount: number, currency: string } }> | null, month: { __typename?: 'Month', month: number, year: number } } };


export const EmployeesLedgerSummaryDocument = gql`
    query EmployeesLedgerSummary($month: MonthInput!) {
  employeesLedgerSummary(month: $month) {
    items {
      commission {
        amount
        currency
      }
      employeeId
      treatmentId
      turnover {
        amount
        currency
      }
    }
    month {
      month
      year
    }
  }
}
    `;

/**
 * __useEmployeesLedgerSummaryQuery__
 *
 * To run a query within a React component, call `useEmployeesLedgerSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesLedgerSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesLedgerSummaryQuery({
 *   variables: {
 *      month: // value for 'month'
 *   },
 * });
 */
export function useEmployeesLedgerSummaryQuery(baseOptions: Apollo.QueryHookOptions<EmployeesLedgerSummaryQuery, EmployeesLedgerSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesLedgerSummaryQuery, EmployeesLedgerSummaryQueryVariables>(EmployeesLedgerSummaryDocument, options);
      }
export function useEmployeesLedgerSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesLedgerSummaryQuery, EmployeesLedgerSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesLedgerSummaryQuery, EmployeesLedgerSummaryQueryVariables>(EmployeesLedgerSummaryDocument, options);
        }
export type EmployeesLedgerSummaryQueryHookResult = ReturnType<typeof useEmployeesLedgerSummaryQuery>;
export type EmployeesLedgerSummaryLazyQueryHookResult = ReturnType<typeof useEmployeesLedgerSummaryLazyQuery>;
export type EmployeesLedgerSummaryQueryResult = Apollo.QueryResult<EmployeesLedgerSummaryQuery, EmployeesLedgerSummaryQueryVariables>;