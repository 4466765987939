import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Tab } from "@headlessui/react";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";

import { useCountersStore } from "@features/session/hooks";

import { Spinner } from "@/components/ui/Spinner";
import { AppointmentForm } from "@/features/calendar/components/Appointment/AppointmentForm";
import {
  BlockedSlotForm,
  BlockedSlotFormProps,
} from "@/features/calendar/components/BlockedSlot/BlockedSlotForm";
import { getDefaultNearestTimerange } from "@/features/calendar/utils/time";
import { useEmployeeStore } from "@/features/employees/hooks";
import { useSessionContext } from "@/providers/SessionProvider";
import { Timerange } from "@/types";

import { useCalendarDate } from "../contexts/CalendarDateContext";

export type ColumnClickSlotProps = {
  date?: Date;
  from?: string;
  to?: string;
  employeeUuid?: string;
};

type SlotAddDialogProps = {
  props?: ColumnClickSlotProps;
  open: boolean;
  onClose: () => void;
};

const date = new Date();

export const SlotAddDialog = ({ open, props, onClose }: SlotAddDialogProps) => {
  const { t } = useTranslation();
  const { smsLimit } = useCountersStore();
  const { selectedDate } = useCalendarDate();
  const { session, permissions } = useSessionContext();

  const employeeUuid = props?.employeeUuid || session?.accountUuid;
  const { employee, loading: loadingEmployee } = useEmployeeStore(employeeUuid);

  const defaultDate = useMemo(
    () => (props?.date ? new Date(props?.date) : selectedDate),
    [props?.date, selectedDate],
  );

  const timeRange: Timerange = useMemo(
    () =>
      props?.from && props.to
        ? { from: props.from, to: props.to }
        : getDefaultNearestTimerange(date),
    [date, props],
  );

  const defaultBlockedSlotValues: BlockedSlotFormProps["defaultValues"] = useMemo(
    () => ({
      employeeUuid: employeeUuid,
      date: defaultDate,
      timeRange,
    }),
    [defaultDate, timeRange, employeeUuid],
  );

  const defaultAppointmentValues = useMemo(
    () => ({
      clientUuid: "",
      imagesUrls: [],
      treatments: [
        {
          treatmentUuid: "",
          employeeUuid: employee?.accountUuid,
          employeeName: employee?.fullName,
          timeRange,
        },
      ],
      date: defaultDate,
      sendNotification: false,
    }),
    [smsLimit, defaultDate, timeRange, employee],
  );

  const TABS = [
    {
      name: t("appointments.newAppointment"),
      isAvailable: permissions.add_appointment,
    },
    {
      name: t("blockedSlots.timeBlock"),
      isAvailable: permissions.add_slot_block,
    },
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    if (!open) {
      setSelectedTabIndex(0);
    } else {
      !TABS[0].isAvailable && setSelectedTabIndex(1);
    }
  }, [open, TABS]);

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={selectedTabIndex === 0 ? t("appointments.addNew") : t("blockedSlots.timeBlock")}>
      <Tab.Group selectedIndex={selectedTabIndex} onChange={setSelectedTabIndex}>
        <Tab.List className="box-border flex flex-wrap justify-start space-x-4 border-b border-stone-200 sm:space-x-8 sm:pt-4 md:justify-start md:pt-0">
          {TABS.map((tab) => {
            return tab.isAvailable ? (
              <Tab key={tab.name} as={Fragment}>
                <div className="w-fit cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium outline-none transition-colors focus-visible:outline-none ui-selected:border-gold-500 ui-selected:text-stone-900 ui-not-selected:border-transparent ui-not-selected:text-stone-500 ui-not-selected:hover:border-stone-300 ui-not-selected:hover:text-stone-700">
                  <span className="text-xs sm:text-sm">{tab.name}</span>
                </div>
              </Tab>
            ) : null;
          })}
        </Tab.List>
        <Tab.Panels className="py-4">
          {permissions.add_appointment && (
            <Tab.Panel>
              {!loadingEmployee ? (
                <AppointmentForm defaultValues={defaultAppointmentValues} onClose={onClose} />
              ) : (
                <div className="flex justify-center p-10">
                  <Spinner className="h-8 w-8" />
                </div>
              )}
            </Tab.Panel>
          )}
          {permissions.add_slot_block && (
            <Tab.Panel>
              <BlockedSlotForm
                onClose={onClose}
                action="create"
                defaultValues={defaultBlockedSlotValues}
              />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </DefaultDialog>
  );
};
