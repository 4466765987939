import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  BanknotesIcon,
  ClockIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { formatDuration } from "date-fns";

import { Button } from "@/components/ui/Button";
import { Label } from "@/components/ui/Label";
import { TimeAutoComplete } from "@/components/ui/TimeAutoComplete";
import { TIME_AUTOCOMPLETE_STEP } from "@/constants";
import { TIME_PATTERN } from "@/constants/validations";
import { EmployeeSelect } from "@/features/employees/components/EmployeeSelect";
import { formatPrice } from "@/features/payments/utils";
import { useSessionContext } from "@/providers/SessionProvider";
import {
  TimerangeOption,
  generateTimeOptions,
  getTimerangeDiff,
  setTimeToDate,
  timerangeIsValid,
} from "@/utils/datetime";
import { cn } from "@/utils/utils";

import type { AppointmentFormListProps } from "../../hooks/use-appointment-form";
import { AppointmentTreatmentSelect } from "../AppointmentTreatmentSelect";
import { AppointmentFormInput } from "./AppointmentForm";
import { AvailabilityInformation } from "./AvailabilityInformation";

export type AppointmentFormTreatmetsListProps = {
  onClose?: () => void;
  appointmentFormProps: AppointmentFormListProps;
};

const timeOptions = generateTimeOptions(TIME_AUTOCOMPLETE_STEP);

const mapTimeOptionsWithReferenceTime = (timeOptions: TimerangeOption[], referenceTime: string) => {
  return timeOptions.reduce<TimerangeOption[]>((acc, curr) => {
    if (timerangeIsValid(referenceTime, curr.value)) {
      acc.push({
        ...curr,
        props: {
          diff: getTimerangeDiff(referenceTime, curr.value),
        },
      });
    }

    return acc;
  }, []);
};

export const AppointmentFormTreatmetsList = ({
  appointmentFormProps,
}: AppointmentFormTreatmetsListProps) => {
  const { t } = useTranslation();
  const { isSalonPlan } = useSessionContext();
  const {
    formState: { dirtyFields, errors },
    control,
    getValues,
  } = useFormContext<AppointmentFormInput>();

  const {
    treatmentsArray,
    equipmentAvailability,
    currentEntityIndex,
    handleAddTreatment,
    handleRemoveTreatment,
    handleTreatmentSelect,
    handleEmployeeSelect,
    handleTimerangeChange,
    handleFromTimeChange,
    setCurrentEntityIndex,
    selectedTreatments,
  } = appointmentFormProps;

  const summarizeTreatmentsPrice = (treatmentsPrices: number[]) => {
    return treatmentsPrices.reduce((acc, price) => acc + price, 0);
  };

  const hasTimeRangeError = (index: number) => {
    return errors.treatments?.[index]?.timeRange?.from || errors.treatments?.[index]?.timeRange?.to;
  };

  const formatAppointmentDuration = (treatments: AppointmentFormInput["treatments"]) => {
    const firstTreatment = treatments[0];

    const lastTreatment = treatments[treatments.length - 1];
    const now = new Date();

    const from = new Date(setTimeToDate(now, firstTreatment.timeRange.from)).getTime();
    const to = new Date(setTimeToDate(now, lastTreatment.timeRange.to)).getTime();

    const duration = to - from;

    const formattedDuration = formatDuration({
      minutes: Math.floor(duration / 1000 / 60) % 60,
      hours: Math.floor(duration / 1000 / 60 / 60),
    });

    return formattedDuration;
  };

  return (
    <ul
      role="list"
      className="relative z-[1] @container sm:rounded-md sm:border sm:border-stone-300 sm:p-4 sm:shadow-sm">
      {treatmentsArray.fields.map((entity, index) => {
        const employeeName = getValues(`treatments.${index}.employeeName`);
        const treatment = selectedTreatments[index];

        return (
          <li key={entity.id}>
            <div className="group">
              {treatmentsArray.fields.length > 1 && (
                <div
                  className={cn(
                    currentEntityIndex !== treatmentsArray.fields.length - 1 &&
                      currentEntityIndex === index
                      ? "overflow-hidden"
                      : "overflow-visible",
                    "relative w-full cursor-pointer p-0",
                  )}
                  onClick={() => setCurrentEntityIndex(index)}>
                  <span
                    className="absolute left-8 top-5 -ml-4 hidden h-full w-[2px] bg-stone-300 @[400px]:flex"
                    aria-hidden="true"
                  />
                  <div className="relative flex items-start">
                    <div className="relative -ml-4 hidden @[400px]:block">
                      <div className="mt-2 flex items-center justify-center rounded-md bg-stone-50 px-4 py-1.5">
                        <div className="text-xs font-medium text-stone-500">
                          {getValues(`treatments.${index}.timeRange.from`)}
                        </div>
                      </div>
                    </div>
                    <div className="min-w-0 flex-1 py-1 sm:py-2">
                      <div
                        key={getValues(`treatments.${index}.treatmentUuid`)}
                        className={`bg-${treatment.color}-50 border text-sm border-${treatment.color}-200 sm:text-sm text-${treatment.color}-900
                    hover:shadow
                    hover:shadow-${treatment.color}-50
                    flex flex-wrap justify-between rounded-md px-4 py-2 sm:flex-row`}>
                        <div className="inline-flex w-full justify-between gap-x-2">
                          <span className="text-left">
                            {getValues(`treatments.${index}.treatmentName`) ||
                              t("appointments.form.newTreatment")}
                          </span>
                          <Button
                            variant="danger-inline"
                            className="mt-1 self-start"
                            onClick={() => handleRemoveTreatment(index)}>
                            <TrashIcon className="h-4 w-4" />
                          </Button>
                        </div>
                        <div className="mr-4 mt-2 flex items-center sm:mt-1">
                          <UserIcon className="mr-1 h-3.5 w-3.5 shrink-0" aria-hidden="true" />
                          {employeeName}
                        </div>
                        <div className="mt-2 flex w-auto items-center sm:mb-0">
                          <BanknotesIcon className="mr-1 h-3.5 w-3.5" aria-hidden="true" />
                          {formatPrice({
                            price: treatment.price,
                            currency: "PLN",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {treatmentsArray.fields.length > 1 && index === treatmentsArray.fields.length - 1 && (
                <div className="relative">
                  <div className="relative flex items-start">
                    <div className="relative -ml-4 hidden @[400px]:block">
                      <div className="mt-2 flex items-center justify-center rounded-md bg-stone-50 px-4 py-1.5">
                        <div className="text-xs font-medium text-stone-500">
                          {getValues(`treatments.${index}.timeRange.to`)}
                        </div>
                      </div>
                    </div>
                    <div className="flex w-full justify-between rounded-md py-2 sm:mt-1">
                      <div className="flex items-center px-4 text-sm text-stone-700">
                        <span className="mr-2 font-medium text-stone-700">
                          {t("generic.time")}:
                        </span>
                        <ClockIcon
                          className="text-stone-700flex mr-1 h-3.5 w-3.5"
                          aria-hidden="true"
                        />
                        <span className="inline-flex text-sm font-normal lowercase text-stone-700">
                          {formatAppointmentDuration(treatmentsArray.fields)}
                        </span>
                      </div>
                      <div className="flex items-center px-4 text-sm text-stone-700">
                        <span className="mr-3 font-medium text-stone-700">
                          {t("generic.total")}:
                        </span>
                        <BanknotesIcon
                          className={`mr-1 h-3.5 w-3.5 text-stone-700`}
                          aria-hidden="true"
                        />
                        {formatPrice({
                          price: summarizeTreatmentsPrice(selectedTreatments.map((t) => t.price)),
                          currency: "PLN",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={cn(
                  currentEntityIndex === index || treatmentsArray.fields.length === 1
                    ? ""
                    : "hidden",
                  treatmentsArray.fields.length > 1 && "-mx-4 bg-stone-200 px-4",
                  currentEntityIndex === index &&
                    treatmentsArray.fields.length > 1 &&
                    "my-2 pb-4 pt-3",
                  "space-y-4",
                )}>
                <AppointmentTreatmentSelect
                  control={control}
                  name={`treatments.${index}.treatmentUuid`}
                  label={t("services.serviceName")}
                  onChange={(option) => handleTreatmentSelect(index, option)}
                  selectBy="value"
                />
                <div className="flex flex-1 flex-col-reverse flex-wrap items-start gap-4 sm:flex-row sm:flex-nowrap">
                  <div className={cn("w-full", { "sm:w-1/2": !isSalonPlan })}>
                    <Label htmlFor={`treatments.${index}.timeRange.from`}>
                      {t("generic.time")}
                    </Label>
                    <div className="flex flex-1 flex-col items-center gap-1">
                      <div className="flex w-full gap-4">
                        <Controller
                          control={control}
                          name={`treatments.${index}.timeRange.from`}
                          rules={{
                            pattern: TIME_PATTERN,
                            required: true,
                            deps: [`treatments.${index}.timeRange.to`],
                          }}
                          render={({ field: { onChange: formOnChange, value = "" } }) => (
                            <TimeAutoComplete
                              timeOptions={timeOptions}
                              label={t("generic.from")}
                              onChange={(data) => {
                                formOnChange(data);

                                if (getValues(`treatments.${index}.treatmentUuid`)) {
                                  handleFromTimeChange(index, data);
                                }
                              }}
                              value={value}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`treatments.${index}.timeRange.to`}
                          rules={{
                            pattern: TIME_PATTERN,
                            required: true,
                            deps: [`treatments.${index}.timeRange.from`],
                            validate: (value) =>
                              timerangeIsValid(
                                getValues(`treatments.${index}.timeRange.from`),
                                value,
                              ),
                          }}
                          render={({ field: { onChange: formOnChange, value = "" } }) => (
                            <TimeAutoComplete
                              timeOptions={mapTimeOptionsWithReferenceTime(
                                timeOptions,
                                getValues(`treatments.${index}.timeRange.from`),
                              )}
                              onChange={(data) => {
                                formOnChange(data);
                                handleTimerangeChange(index, data);
                              }}
                              label={t("generic.to")}
                              value={value}
                            />
                          )}
                        />
                      </div>
                      {hasTimeRangeError(index) && (
                        <p className="mt-1 w-full rounded-md bg-red-50 px-2.5 py-1.5 text-xs text-red-600">
                          {t("errors.invalidTimeRange")}
                        </p>
                      )}
                    </div>
                  </div>
                  {isSalonPlan && (
                    <EmployeeSelect
                      control={control}
                      name={`treatments.${index}.employeeUuid`}
                      onChange={(option) => {
                        handleEmployeeSelect(index, option);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            {treatmentsArray.fields.length - 1 === index && (
              <Button
                variant="primary-inline"
                size="small"
                startIcon={<PlusIcon />}
                onClick={() => handleAddTreatment(index)}
                disabled={!getValues(`treatments.${index}.treatmentUuid`)}
                className={cn(
                  treatmentsArray.fields.length > 1 ? "!mt-2" : "!mt-4",
                  "font-normal",
                )}>
                {t("appointments.form.addTreatment")}
              </Button>
            )}
          </li>
        );
      })}

      {!!dirtyFields.treatments && !equipmentAvailability.isAvailable && (
        <div className="py-4">
          <AvailabilityInformation
            show={!!dirtyFields.treatments && !equipmentAvailability.isAvailable}
            loading={!equipmentAvailability.isAvailable && equipmentAvailability.loading}
          />
        </div>
      )}
    </ul>
  );
};
