import React, { createContext, ReactNode, useContext, useState } from "react";
import { createPortal } from "react-dom";

import { Viewport } from "@radix-ui/react-toast";

import { Toast, ToastProps } from "@components/Toast";

type ToastBase = Omit<ToastProps, "open" | "setOpen">;

type ToastsContextProps = {
  showToast: (data: ToastBase) => void;
};

type Toasts = (ToastBase & { id: number })[];

export const ToastsContext = createContext<ToastsContextProps>({} as ToastsContextProps);

export const ToastsProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Toasts>([]);

  const handleShowToast = (data: ToastBase) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      {
        id: new Date().getTime(),
        ...data,
      },
    ]);
  };
  const handleToastClose = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastsContext.Provider
      value={{
        showToast: handleShowToast,
      }}>
      {children}
      {toasts.map((toast) => (
        <Toast key={toast.id} open onClose={() => handleToastClose(toast.id)} {...toast} />
      ))}

      {createPortal(
        <Viewport className="fixed bottom-0 right-0 z-[51] m-4 flex w-[24rem] max-w-full list-none flex-col gap-2 outline-0" />,
        document.body,
      )}
    </ToastsContext.Provider>
  );
};

export const useToasts = () => useContext(ToastsContext);
