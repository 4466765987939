import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SearchTreatmentFragment = { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null };

export const SearchTreatmentFragmentDoc = gql`
    fragment SearchTreatment on SearchTreatment {
  categoryColor
  categoryName
  categoryUuid
  treatmentName
  treatmentUuid
  treatmentDuration
  treatmentPrice
  treatmentPriceFrom
  treatmentPriceTo
  treatmentIsExactPrice
  treatmentDescription
}
    `;