import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SalonOpeningHoursFragment = { __typename?: 'SalonOpeningHours', regular?: any | null, special?: any | null };

export const SalonOpeningHoursFragmentDoc = gql`
    fragment SalonOpeningHours on SalonOpeningHours {
  regular
  special
}
    `;