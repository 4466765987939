import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCategoriesPositionsMutationVariables = Types.Exact<{
  data: Types.UpdateCategoriesPositionsInput;
}>;


export type UpdateCategoriesPositionsMutation = { __typename?: 'RootMutationType', updateCategoriesPositions: string };


export const UpdateCategoriesPositionsDocument = gql`
    mutation UpdateCategoriesPositions($data: UpdateCategoriesPositionsInput!) {
  updateCategoriesPositions(data: $data)
}
    `;
export type UpdateCategoriesPositionsMutationFn = Apollo.MutationFunction<UpdateCategoriesPositionsMutation, UpdateCategoriesPositionsMutationVariables>;

/**
 * __useUpdateCategoriesPositionsMutation__
 *
 * To run a mutation, you first call `useUpdateCategoriesPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoriesPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoriesPositionsMutation, { data, loading, error }] = useUpdateCategoriesPositionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCategoriesPositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoriesPositionsMutation, UpdateCategoriesPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoriesPositionsMutation, UpdateCategoriesPositionsMutationVariables>(UpdateCategoriesPositionsDocument, options);
      }
export type UpdateCategoriesPositionsMutationHookResult = ReturnType<typeof useUpdateCategoriesPositionsMutation>;
export type UpdateCategoriesPositionsMutationResult = Apollo.MutationResult<UpdateCategoriesPositionsMutation>;
export type UpdateCategoriesPositionsMutationOptions = Apollo.BaseMutationOptions<UpdateCategoriesPositionsMutation, UpdateCategoriesPositionsMutationVariables>;