import { useTranslation } from "react-i18next";

import { Popover } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { cn } from "@/utils/utils";

import { useClientsContext } from "../providers/ClientsProvider";
import { TagFilter } from "./TagFilter";

export const ClientsDirectoryActionsMenu = () => {
  const { t } = useTranslation();
  const { deselectAllClients, selectVisibleClients, clientsStore, selectedClients } =
    useClientsContext();
  const { clients } = clientsStore;

  const ACTIONS = [
    {
      label: t("actions.selectVisible"),
      value: "select-visible",
      props: {
        onClick: () => selectVisibleClients(clients),
      },
      disabled: false,
    },
    {
      label: t("actions.deselectAll"),
      value: "deselect-all",
      props: {
        onClick: () => deselectAllClients(),
      },
      disabled: Object.values(selectedClients).every((isSelected) => !isSelected),
    },
  ];

  return (
    <div className="mx-2 mb-4 inline-flex items-center gap-2">
      <Popover>
        <Popover.Button className="appearance-none focus:outline-none">
          <div className="group relative inline-flex items-center">
            <span className="text-xs text-stone-500 group-hover:text-stone-700">
              {t("actions.select")}
            </span>
            <ChevronDownIcon className="-mb-0.5 ml-0.5 h-3.5 w-3.5 text-stone-500 group-hover:text-stone-700" />
          </div>
        </Popover.Button>
        <div className="block">
          <Popover.Panel className="overflow-y absolute z-40 mt-1 grid max-h-[1000%] overflow-y-scroll rounded border bg-white py-1 text-stone-600 shadow-md">
            {({ close }) => (
              <>
                {ACTIONS.map((action) => (
                  <div
                    key={action.value}
                    className={cn(
                      action.disabled
                        ? "cursor-not-allowed text-stone-400"
                        : "cursor-pointer text-stone-600 hover:bg-stone-100 hover:text-stone-700",
                      "px-4 py-2.5 text-xs",
                    )}
                    onClick={() => {
                      if (action.disabled) return;
                      action.props.onClick();
                      close();
                    }}>
                    {action.label}
                  </div>
                ))}
              </>
            )}
          </Popover.Panel>
        </div>
      </Popover>
      <TagFilter />
    </div>
  );
};
