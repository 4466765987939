import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { HeadCategoryFragmentDoc } from '../fragments/HeadCategory.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchHeadCategoryQueryVariables = Types.Exact<{
  categoryUuid: Types.Scalars['UUID4'];
}>;


export type FetchHeadCategoryQuery = { __typename?: 'RootQueryType', fetchHeadCategory: { __typename?: 'HeadCategory', name: string, uuid: any, updatedAt?: any | null, salonUuid: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any }> | null } };


export const FetchHeadCategoryDocument = gql`
    query FetchHeadCategory($categoryUuid: UUID4!) {
  fetchHeadCategory(categoryUuid: $categoryUuid) {
    ...HeadCategory
  }
}
    ${HeadCategoryFragmentDoc}`;

/**
 * __useFetchHeadCategoryQuery__
 *
 * To run a query within a React component, call `useFetchHeadCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchHeadCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchHeadCategoryQuery({
 *   variables: {
 *      categoryUuid: // value for 'categoryUuid'
 *   },
 * });
 */
export function useFetchHeadCategoryQuery(baseOptions: Apollo.QueryHookOptions<FetchHeadCategoryQuery, FetchHeadCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchHeadCategoryQuery, FetchHeadCategoryQueryVariables>(FetchHeadCategoryDocument, options);
      }
export function useFetchHeadCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchHeadCategoryQuery, FetchHeadCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchHeadCategoryQuery, FetchHeadCategoryQueryVariables>(FetchHeadCategoryDocument, options);
        }
export type FetchHeadCategoryQueryHookResult = ReturnType<typeof useFetchHeadCategoryQuery>;
export type FetchHeadCategoryLazyQueryHookResult = ReturnType<typeof useFetchHeadCategoryLazyQuery>;
export type FetchHeadCategoryQueryResult = Apollo.QueryResult<FetchHeadCategoryQuery, FetchHeadCategoryQueryVariables>;