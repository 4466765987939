import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnSmsLimitUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type OnSmsLimitUpdatedSubscription = { __typename?: 'RootSubscriptionType', smsLimitUpdated: number };


export const OnSmsLimitUpdatedDocument = gql`
    subscription OnSmsLimitUpdated {
  smsLimitUpdated
}
    `;

/**
 * __useOnSmsLimitUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnSmsLimitUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSmsLimitUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSmsLimitUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSmsLimitUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSmsLimitUpdatedSubscription, OnSmsLimitUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnSmsLimitUpdatedSubscription, OnSmsLimitUpdatedSubscriptionVariables>(OnSmsLimitUpdatedDocument, options);
      }
export type OnSmsLimitUpdatedSubscriptionHookResult = ReturnType<typeof useOnSmsLimitUpdatedSubscription>;
export type OnSmsLimitUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnSmsLimitUpdatedSubscription>;