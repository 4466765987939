import { useTranslation } from "react-i18next";

import { Layout } from "@components/layout/Layout";

import { SalonProfileForm } from "@features/salon/components/SalonProfileForm";

import { Subheader } from "@/components/layout/subheader/Subheader";
import { Spinner } from "@/components/ui/Spinner";
import { useSalonOpeningHoursStore, useSalonStore } from "@/features/salon/hooks";
import { useBreakpoint } from "@/hooks/use-breakpoint";

export const SalonProfileEditView = () => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoint("md");
  const { salon, loading: loadingSalon } = useSalonStore();
  const { regular: regularOpeningHours, loading: loadingOpeningHours } =
    useSalonOpeningHoursStore();

  const loading = loadingSalon || loadingOpeningHours;

  return (
    <Layout title={t("salonProfile.edit")}>
      <div className="mt-4 flex max-w-6xl flex-col rounded-xl px-2 pb-4 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <Subheader title={t("salonProfile.edit")} backUrl={isMd ? undefined : `/salon`} />
        {!loading && salon ? (
          <SalonProfileForm salon={salon} regularOpeningHours={regularOpeningHours} />
        ) : (
          <Spinner />
        )}
      </div>
    </Layout>
  );
};
