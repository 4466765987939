import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { TextAreaField } from "@components/ui/TextAreaField";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { useSendSmsStore } from "@/features/messages/hooks";
import { useToasts } from "@/providers/ToastsProvider";
import { Client } from "@/types";

import { countCharacters } from "../utils/countCharacters";
import { SMSCharacterCounter } from "./SMSCharacterCounter";

type SendSMSDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  client: Client;
};

type FormData = {
  message: string;
};

export const SendSMSDialog = ({ open, setOpen, client }: SendSMSDialogProps) => {
  const { t } = useTranslation();
  const { sendSmsToClients, loading } = useSendSmsStore();
  const { showToast } = useToasts();

  const {
    register,
    reset,
    watch,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      message: "",
    },
  });

  const message = watch("message");

  const messageCharacters = countCharacters(message);

  const handleSendMessage: SubmitHandler<FormData> = async (data: FormData) => {
    const { errors } = await sendSmsToClients({
      message: data.message,
      clientsUuids: [client.uuid],
    });

    if (errors) {
      showToast({
        type: "error",
        title: t("messages.sendSmsDialogErrorTitle"),
        description: t("messages.sendSmsDialogErrorMessage"),
      });
      return;
    }

    if (!loading) {
      reset();

      setOpen(false);

      showToast({
        type: "success",
        title: t("messages.sendSmsDialogSuccessTitle"),
        description: t("messages.sendSmsDialogSuccessMessage", { client: client.fullName }),
      });
    }
  };

  const handleOnClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <DefaultDialog
      open={open}
      onClose={handleOnClose}
      title={t("messages.sendSmsTo", { client: client.fullName })}>
      <form onSubmit={handleSubmit(handleSendMessage)} noValidate>
        <div>
          <TextAreaField
            {...register("message", { required: true, minLength: 3, maxLength: 10000 })}
            label={t("messages.messageContent")}
            errorMessage={
              errors.message &&
              t(`validation.${errors.message.type}`, {
                name: t("messages.messageContent"),
                minLength: 3,
                maxLength: 10000,
              })
            }
          />
          <SMSCharacterCounter messageCharacters={messageCharacters} />
        </div>
        {messageCharacters.encoding === "UTF16" && (
          <div className="mt-4">
            <Alert
              type="warning"
              title={t("messages.specialCharacters")}
              description={`${t(
                "messages.specialCharactersInfo",
              )} ${messageCharacters.shortenByChars.map((char, index) =>
                messageCharacters.shortenByChars.length - 1 === index ? ` ${char}` : ` ${char}`,
              )}.`}
            />
          </div>
        )}
        {messageCharacters.messages > 1 && (
          <div className="mt-4">
            <Alert
              type="warning"
              title={t("messages.messagesCount")}
              description={t("messages.messagesCountInfo", {
                count: messageCharacters.messages,
              })}
            />
          </div>
        )}
        <Button type="submit" fullWidth className="mt-4" disabled={!isValid || loading}>
          {t("generic.send")}
        </Button>
      </form>
    </DefaultDialog>
  );
};
