import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeSubscriptionPlanMutationVariables = Types.Exact<{
  subscriptionPriceId: Types.Scalars['String'];
}>;


export type ChangeSubscriptionPlanMutation = { __typename?: 'RootMutationType', changeSubscriptionPlan: string };


export const ChangeSubscriptionPlanDocument = gql`
    mutation ChangeSubscriptionPlan($subscriptionPriceId: String!) {
  changeSubscriptionPlan(subscriptionPriceId: $subscriptionPriceId)
}
    `;
export type ChangeSubscriptionPlanMutationFn = Apollo.MutationFunction<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>;

/**
 * __useChangeSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useChangeSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSubscriptionPlanMutation, { data, loading, error }] = useChangeSubscriptionPlanMutation({
 *   variables: {
 *      subscriptionPriceId: // value for 'subscriptionPriceId'
 *   },
 * });
 */
export function useChangeSubscriptionPlanMutation(baseOptions?: Apollo.MutationHookOptions<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>(ChangeSubscriptionPlanDocument, options);
      }
export type ChangeSubscriptionPlanMutationHookResult = ReturnType<typeof useChangeSubscriptionPlanMutation>;
export type ChangeSubscriptionPlanMutationResult = Apollo.MutationResult<ChangeSubscriptionPlanMutation>;
export type ChangeSubscriptionPlanMutationOptions = Apollo.BaseMutationOptions<ChangeSubscriptionPlanMutation, ChangeSubscriptionPlanMutationVariables>;