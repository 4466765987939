import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { Switch } from "@/components/ui/Switch";

import { EMPLOYEE_PERMISSIONS, EMPLOYEE_ROLES_PERMISSIONS } from "../constants";

export const EmployeePermissions = () => {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();

  const handleRoleButtonClick = (permissions: string[]) => {
    const formPermissions = getValues("permissions") as Record<string, boolean>;
    const rolePermissions = permissions.reduce<Record<string, boolean>>((acc, permission) => {
      acc[permission] = true;
      return acc;
    }, {});

    const newPermissions = Object.keys(formPermissions).reduce<Record<string, boolean>>(
      (acc, permission) => {
        acc[permission] = rolePermissions[permission] ?? false;
        return acc;
      },
      {},
    );

    setValue("permissions", newPermissions, { shouldDirty: true });
  };

  const handlePermissionChange = (permissionName: string, newValue: boolean) => {
    if (permissionName === "delete_client" && newValue) {
      setValue("permissions.edit_client", true, { shouldDirty: true });
      setValue("permissions.client_details_view", true, { shouldDirty: true });
    }

    if (permissionName === "edit_client" && newValue) {
      setValue("permissions.client_details_view", true, { shouldDirty: true });
    }

    if (permissionName === "client_details_view" && !newValue) {
      setValue("permissions.edit_client", false, { shouldDirty: true });
      setValue("permissions.delete_client", false, { shouldDirty: true });
    }

    if (permissionName === "delete_employee" && newValue) {
      setValue("permissions.edit_employee", true, { shouldDirty: true });
    }

    if (permissionName === "edit_employee" && !newValue) {
      setValue("permissions.delete_employee", false, { shouldDirty: true });
    }

    if (permissionName === "delete_tag" && newValue) {
      setValue("permissions.edit_tag", true, { shouldDirty: true });
    }

    if (permissionName === "edit_tag" && !newValue) {
      setValue("permissions.delete_tag", false, { shouldDirty: true });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2 sm:flex-nowrap">
        {EMPLOYEE_ROLES_PERMISSIONS.map((role) => (
          <Button
            key={role.name}
            variant="secondary-outline"
            className="w-full"
            size="small"
            onClick={() => handleRoleButtonClick(role.permissions)}>
            {t(`employees.employeeProfile.permissions.roles.${role.name}`)}
          </Button>
        ))}
      </div>

      <div className="space-y-6 pt-5 sm:rounded-md sm:border sm:border-stone-200 sm:px-6 sm:pb-5 sm:shadow-sm">
        {EMPLOYEE_PERMISSIONS.map((section) => (
          <div key={section.type} className="flex flex-col gap-3">
            <div className="text-xs font-medium uppercase text-stone-500">
              {t(`generic.${section.type}`)}
            </div>
            {section.permissions.map((permission) => (
              <div key={permission} className="mb-1 flex items-center gap-4">
                <Switch
                  name={`permissions.${permission}`}
                  control={control}
                  className="flex justify-between self-start text-sm text-stone-500"
                  label={permission}
                  onChange={(_, newValue) => handlePermissionChange(permission, newValue)}
                />
                <span className="text-sm text-stone-900">
                  {t(`employees.employeeProfile.permissions.permissions.${permission}`)}
                </span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
