import { useTranslation } from "react-i18next";

import { ArchiveBoxIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { useItemsStore } from "@/features/equipment/hooks/use-items-store";
import { useEquipmentContext } from "@/features/equipment/providers/EquipmentProvider";

export const EquipmentItems = () => {
  const { t } = useTranslation();

  const { updateItem } = useEquipmentContext();
  const items = useItemsStore();

  if (items.loading) {
    return (
      <div className="pt-32">
        <Spinner />
      </div>
    );
  }

  if (!items.data.length) {
    return (
      <div className="flex h-96 min-h-full flex-col justify-center text-center">
        <ArchiveBoxIcon className="mx-auto h-10 w-10 text-stone-400" aria-hidden="true" />
        <h3 className="mt-2 text-sm font-semibold text-stone-900">{t("equipment.noEquipment")}</h3>
        <p className="mt-1 text-sm text-stone-500">{t("equipment.notAdded")}</p>
      </div>
    );
  }

  return (
    <section className="grid gap-4">
      <div className="grid auto-rows-[1fr] gap-8 md:grid-cols-3">
        {items.data.map((item) => (
          <button key={item.uuid} onClick={() => updateItem(item)}>
            <article className="h-full rounded border p-4 transition-colors hover:bg-stone-100">
              <h3 className="text-lg font-bold">{item.name}</h3>
              <p>{item.description}</p>
            </article>
          </button>
        ))}
      </div>
      {items.hasMore && (
        <div className="flex justify-center">
          <Button variant="primary-outline" onClick={() => items.fetchMore()}>
            {t("generic.loadMore")}
          </Button>
        </div>
      )}
    </section>
  );
};
