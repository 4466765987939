import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, Transition } from "@headlessui/react";

import type { ConfirmationDialogProps } from "@hooks/use-confirmation-dialog";

import { Button } from "@/components/ui/Button";

enum ConfirmationDialogResult {
  Confirm,
  Deny,
}

export const ConfirmationDialog = ({
  dialogState,
  children,
}: ConfirmationDialogProps<ConfirmationDialogResult>) => {
  const { t } = useTranslation();
  const state = dialogState.internalState;

  const handleClose = (result: ConfirmationDialogResult) => {
    dialogState.close();
    state.onClose?.call(null, result);
    switch (result) {
      case ConfirmationDialogResult.Confirm:
        state.onConfirm?.call(null);
        break;
      case ConfirmationDialogResult.Deny:
        state.onDeny?.call(null);
        break;
    }
  };

  return (
    <Transition.Root appear show={state.open} as={Fragment}>
      <Dialog as="div" onClose={() => {}} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-stone-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-220 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-220 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full">
              <Dialog.Panel className="flex h-auto w-full max-w-md flex-col justify-between rounded-xl bg-stone-50 px-6 py-5 text-left shadow-xl transition-all">
                <Dialog.Title
                  as="h4"
                  className="-ml-6 -mr-6 mb-2 px-6 font-serif text-lg leading-6 text-stone-700">
                  {state.title}
                </Dialog.Title>

                <div className="mt-2 text-stone-500">{state.message}</div>
                {children && <div className="mt-2">{children}</div>}
                <div className="mt-8 grid grid-cols-2 gap-4">
                  <Button
                    variant="primary-outline"
                    fullWidth
                    onClick={() => handleClose(ConfirmationDialogResult.Deny)}>
                    {state.denyLabel ? (
                      <span>{state.denyLabel}</span>
                    ) : (
                      <span>{t("generic.deny")}</span>
                    )}
                  </Button>
                  <Button fullWidth onClick={() => handleClose(ConfirmationDialogResult.Confirm)}>
                    {state.confirmLabel ? (
                      <span>{state.confirmLabel}</span>
                    ) : (
                      <span>{t("generic.confirm")}</span>
                    )}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
