import { useTranslation } from "react-i18next";

import { PencilIcon } from "@heroicons/react/24/outline";

import { Layout } from "@components/layout/Layout";
import { Subheader } from "@components/layout/subheader/Subheader";
import { Button } from "@components/ui/Button";

import { SalonOpeningHours } from "@features/salon/components/SalonOpeningHours";
import { SpecialAvailabilityList } from "@features/salon/components/SpecialAvailabilityList";

import { useSessionContext } from "@/providers/SessionProvider";

export const OpeningHoursView = () => {
  const { t } = useTranslation();
  const { permissions } = useSessionContext();

  return (
    <Layout title={t("sidebar.navigation.mySalon.children.openingHours")}>
      <div className="-ml-4 -mr-4 mb-4 max-w-6xl rounded-xl bg-stone-50 px-4 py-8 sm:mb-8 sm:mt-4 sm:px-12 md:mx-auto">
        <Subheader
          title={t("sidebar.navigation.mySalon.children.openingHours")}
          leftSlot={
            permissions.manage_opening_hours ? (
              <Button to="/opening-hours/edit" size="small" startIcon={<PencilIcon />}>
                {t("generic.edit")}
              </Button>
            ) : (
              <></>
            )
          }
        />
      </div>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-stone-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <div className="grid gap-8 py-4 lg:grid-cols-[3fr,_2fr]">
          <div>
            <h2 className="text-lg font-medium text-stone-900">{t("openingHours.regular")}</h2>
            <SalonOpeningHours />
          </div>
          <div>
            <h2 className="text-lg font-medium text-stone-900">{t("openingHours.irregular")}</h2>
            <div className="py-4">
              <SpecialAvailabilityList />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
