import { useTranslation } from "react-i18next";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import * as Popover from "@radix-ui/react-popover";

import type { FeaturesGroups, Plan } from "@/features/payments/models";
import { cn } from "@/utils/utils";

type DetailsTableProps = {
  plans: Plan[];
  featuresGroups: FeaturesGroups;
};

export const DetailsTable = ({ plans, featuresGroups }: DetailsTableProps) => {
  const { t } = useTranslation();

  return (
    <div>
      {/* up to lg */}
      <section aria-labelledby="mobile-comparison-heading" className="block lg:hidden">
        <div className="mx-auto mt-20 max-w-2xl text-center">
          <h2 className="text-3xl font-medium tracking-tight text-stone-900">
            {t("pricing.compareTitle")}
          </h2>
          <p className="mt-2 text-lg text-stone-600">{t("pricing.compareDescription")}</p>
        </div>
        <div className="mx-auto max-w-2xl">
          <div className="mt-16 border-t border-stone-300 pb-3 pt-4 ">
            <p className="text-md w-1/3 font-bold">{t("pricing.tiers.starter.name")}</p>
            <p className="w-100 text-sm sm:w-2/3 md:w-1/2">
              {t("pricing.tiers.starter.description")}
            </p>

            {featuresGroups.map((group) => (
              <div className="mt-4" key={group.category.title}>
                <p className="border-y border-stone-300 bg-stone-200/50 px-4 py-3 text-sm font-bold">
                  {group.category.title}
                </p>
                <div className="flex flex-col rounded-sm text-sm">
                  {group.features.map((feature) => (
                    <div
                      className="flex justify-between border-b border-stone-200 px-2 py-4 sm:p-4"
                      key={feature.title}>
                      <div className="block lg:hidden">
                        <Popover.Root>
                          <Popover.Trigger className="inline-flex items-center gap-1">
                            {feature.title}
                            <span>
                              {feature.description && (
                                <InformationCircleIcon className="h-4 w-4 text-gold-600" />
                              )}
                            </span>
                          </Popover.Trigger>
                          <Popover.Anchor />
                          <Popover.Portal>
                            <Popover.Content className="max-w-sm rounded-sm bg-white p-4 text-stone-500 shadow">
                              {feature.description}
                            </Popover.Content>
                          </Popover.Portal>
                        </Popover.Root>
                      </div>
                      <div>{feature.tiers[0].value}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="mt-16 border-t border-stone-300 pb-3 pt-4 ">
            <p className="text-md w-1/3 font-bold">{t("pricing.tiers.salon.name")}</p>
            <p className="w-100 text-sm sm:w-2/3 md:w-1/2">
              {t("pricing.tiers.salon.description")}
            </p>

            {featuresGroups.map((group) => (
              <div className="mt-4" key={group.category.title}>
                <p className="border-y border-stone-300 bg-stone-200/50 px-4 py-3 text-sm font-bold">
                  {group.category.title}
                </p>
                <div className="flex flex-col rounded-sm text-sm">
                  {group.features.map((feature) => (
                    <div
                      className="flex justify-between border-b border-stone-200 px-2 py-4 sm:p-4"
                      key={feature.title}>
                      <Popover.Root>
                        <Popover.Trigger className="inline-flex items-center gap-1">
                          {feature.title}
                          <span>
                            {feature.description && (
                              <InformationCircleIcon className="h-4 w-4 text-gold-600" />
                            )}
                          </span>
                        </Popover.Trigger>
                        <Popover.Anchor />
                        <Popover.Portal>
                          <Popover.Content className="max-w-sm rounded-sm bg-white p-4 text-stone-500 shadow">
                            {feature.description}
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root>
                      <div>{feature.tiers[1].value}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* lg+ */}
      <section className="hidden px-0 lg:block">
        <div className="mx-auto mt-20 max-w-2xl text-center">
          <h2 id="pricing-title" className="text-3xl font-medium tracking-tight text-stone-900">
            {t("pricing.compareTitle")}
          </h2>
          <p className="mt-2 text-lg text-stone-600">{t("pricing.compareDescription")}</p>
        </div>
        <div className="mx-auto max-w-7xl">
          <div className="mt-16 flex border-t border-stone-300 pb-3 pt-4 font-bold">
            <p className="ml-auto w-1/3 px-4 text-center text-lg">
              {t("pricing.tiers.starter.name")}
            </p>
            <p className="w-1/3 pl-4 text-center text-lg">{t("pricing.tiers.salon.name")}</p>
          </div>
          {featuresGroups.map((featureGroup) => (
            <div key={featureGroup.category.title}>
              <div className="w-100 -mt-px flex border-y border-stone-300 bg-stone-200/50 py-3 pr-4">
                <h3 className="pl-4 text-sm font-bold text-stone-900">
                  {featureGroup.category.title}
                </h3>
              </div>
              <table className="relative w-full">
                <caption className="sr-only">{t("pricing.screenReader.featureComparison")}</caption>
                <thead>
                  <tr className="text-left">
                    <th scope="col">
                      <span className="sr-only">{t("pricing.screenReader.feature")}</span>
                    </th>
                    {plans.map(
                      (plan) =>
                        plan.compared && (
                          <th key={plan.name} scope="col">
                            <span className="sr-only">
                              {plan.name} {t("pricing.screenReader.plan")}
                            </span>
                          </th>
                        ),
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-stone-200">
                  {featureGroup.features.map((feature) => (
                    <tr key={feature.title}>
                      <th
                        scope="row"
                        className="w-1/4 py-3 pl-4 pr-4 text-left text-sm font-medium text-stone-600">
                        <Popover.Root>
                          <Popover.Trigger className="inline-flex items-center gap-1">
                            {feature.title}
                            <span>
                              {feature.description && (
                                <InformationCircleIcon className="h-4 w-4 text-gold-600" />
                              )}
                            </span>
                          </Popover.Trigger>
                          <Popover.Anchor />
                          <Popover.Portal>
                            <Popover.Content className="max-w-sm rounded-sm bg-white p-4 text-stone-500 shadow">
                              {feature.description}
                            </Popover.Content>
                          </Popover.Portal>
                        </Popover.Root>
                      </th>
                      {feature.tiers.map((tier, tierIdx) => (
                        <td
                          key={tier.title}
                          className={cn(
                            tierIdx === feature.tiers.length - 1 ? "pl-4" : "px-4",
                            "relative w-1/4 py-0 text-center text-stone-600",
                          )}>
                          <span className="relative h-full w-full py-3">{tier.value}</span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};
