import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import { PlusIcon, UserGroupIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";
import { Spinner } from "@components/ui/Spinner";

import { bindDialogState, useDialog } from "@hooks/use-dialog";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import {
  ClientFormDialog,
  ClientFormDialogStateProps,
} from "@/features/clients/components/ClientFormDialog";
import { ClientProfile } from "@/features/clients/components/ClientProfile";
import { ClientsDirectory } from "@/features/clients/components/ClientsDirectory";
import { ClientSearch } from "@/features/clients/components/ClientSearch";
import { useClientsContext } from "@/features/clients/providers/ClientsProvider";
import { Client } from "@/types";

import { ClientsDirectoryActionsMenu } from "./ClientsDirectoryActionsMenu";

export const ClientList = () => {
  const { t } = useTranslation();
  const { ref, height } = useResizeDetector();
  const { clientsStore, isTagsSelected } = useClientsContext();
  const [asideHeight, setAsideHeight] = useState(height);
  const [open, setOpen] = useState(false);
  const dialog = useDialog<ClientFormDialogStateProps>();

  useEffect(() => {
    setAsideHeight(height);
  }, [height]);

  const { clientsDirectory, loading, hasMore, loadingFetchMore, searchQuery, fetchMore } =
    clientsStore;

  const [selectedClient, setSelectedClient] = useState<Client | undefined>(undefined);

  useEffect(() => {
    if (selectedClient) {
      return;
    }

    const firstClientInDirectory = Object.values(clientsDirectory)?.[0]?.[0];
    if (firstClientInDirectory) {
      setSelectedClient(firstClientInDirectory);
    }
  }, [clientsDirectory, selectedClient]);

  const handleClientSelect = (client: Client) => {
    setSelectedClient(client);
    setOpen(true);
  };

  const isClientsDirectoryEmpty = Object.keys(clientsDirectory).length === 0;

  if (isClientsDirectoryEmpty && !searchQuery && !isTagsSelected && !selectedClient) {
    {
      return loading ? (
        <div className="flex justify-center p-10">
          <Spinner className="h-8 w-8" />
        </div>
      ) : (
        <div className="flex h-96 min-h-full flex-col justify-center text-center">
          <UserGroupIcon className="mx-auto h-10 w-10 text-stone-400" aria-hidden="true" />
          <h3 className="mt-2 text-sm font-semibold text-stone-900">{t("clients.noClients")}</h3>
          <p className="mt-1 text-sm text-stone-500">{t("clients.addFirstClient")}</p>
          <div className="mt-6">
            <Button
              startIcon={<PlusIcon />}
              size="small"
              className="mx-auto"
              onClick={() => dialog.open()}>
              {t("clients.addNew")}
            </Button>
          </div>
          <ClientFormDialog {...bindDialogState(dialog)} title={t("clients.addNew")} />
        </div>
      );
    }
  }

  if (loading || !selectedClient) {
    return (
      <div className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </div>
    );
  }

  return (
    <div className="@container">
      <div className="md:min-h-auto relative z-0 -mx-4 mt-6 flex min-h-[60vh] flex-1 overflow-hidden rounded-lg bg-white px-4 pt-1 @[670px]:m-0 @[670px]:p-0 sm:mt-0 sm:pt-0">
        <main
          ref={ref}
          className="custom-client-profile-height relative z-0 order-last hidden h-full overflow-y-auto border-l border-stone-200 px-4 focus:outline-none @[670px]:flex @[670px]:flex-1">
          <ClientProfile client={selectedClient} />
        </main>

        <aside
          style={{ maxHeight: asideHeight ? `${asideHeight}px` : "100%" }}
          className="order-first flex w-full flex-col @[670px]:w-72 lg:w-80 xl:w-96">
          <div className="mb-1 @[670px]:mx-4">
            <ClientSearch />
            <ClientsDirectoryActionsMenu />
          </div>
          {isClientsDirectoryEmpty && !searchQuery && !isTagsSelected ? (
            <div className="flex justify-center border-t p-10">
              <Spinner className="h-8 w-8" />
            </div>
          ) : isClientsDirectoryEmpty && searchQuery && selectedClient ? (
            <div className="flex border-t px-4 pt-4">
              <p className="text-sm text-stone-500">
                {t("clients.clientSearchNoResultsInfo", {
                  searchQuery,
                })}
              </p>
            </div>
          ) : (
            <div className="h-full @[670px]:pb-28">
              <ClientsDirectory
                clientsDirectory={clientsDirectory}
                handleClientSelect={handleClientSelect}
                selectedClient={selectedClient}
                hasMore={hasMore}
                loadingFetchMore={loadingFetchMore}
                fetchMore={fetchMore}
              />
            </div>
          )}
        </aside>

        {!asideHeight && (
          <DefaultDialog open={open} title={t("generic.details")} onClose={() => setOpen(false)}>
            <ClientProfile client={selectedClient} />
          </DefaultDialog>
        )}
      </div>
    </div>
  );
};
