import { AddTreatmentInput, namedOperations, UpdateTreatmentInput } from "@/types";

import { useAddTreatmentMutation } from "../mutations/AddTreatment.generated";
import {
  DeleteTreatmentMutationVariables,
  useDeleteTreatmentMutation,
} from "../mutations/DeleteTreatment.generated";
import { useUpdateTreatmentMutation } from "../mutations/UpdateTreatment.generated";
import { useFetchTreatmentQuery } from "../queries/FetchTreatment.generated";

export const useTreatmentStore = (treatmentUuid?: string) => {
  const [addTreatment, { loading: loadingAdd }] = useAddTreatmentMutation();
  const [updateTreatment, { loading: loadingUpdate }] = useUpdateTreatmentMutation();
  const [deleteTreatment] = useDeleteTreatmentMutation();
  const { data, loading } = useFetchTreatmentQuery({
    variables: { treatmentUuid },
    skip: !treatmentUuid,
  });

  return {
    treatment: data?.fetchTreatment,
    loading,
    loadingAdd,
    loadingUpdate,
    data: data?.fetchTreatment,
    deleteTreatment({ treatmentUuid }: DeleteTreatmentMutationVariables) {
      return deleteTreatment({
        variables: { treatmentUuid },
        update(cache) {
          cache.evict({
            id: cache.identify({
              uuid: treatmentUuid,
              __typename: "Treatment",
            }),
          });
          cache.evict({
            id: cache.identify({
              treatmentUuid,
              __typename: "SearchTreatment",
            }),
          });
          cache.gc();
        },
      });
    },
    updateTreatment(data: UpdateTreatmentInput, id: string) {
      return updateTreatment({
        variables: {
          treatmentUuid: id,
          data,
        },
        refetchQueries: [namedOperations.Query.FetchTreatments],
        update(cache) {
          cache.evict({ fieldName: "fetchAllTreatments" });
          cache.gc();
        },
      });
    },
    addTreatment(data: AddTreatmentInput) {
      return addTreatment({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchTreatments],
        update(cache) {
          cache.evict({ fieldName: "fetchAllTreatments" });
          cache.gc();
        },
      });
    },
  };
};
