import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { PriceFragmentDoc } from './Price.generated';
export type ProductFragment = { __typename?: 'Product', name?: string | null, priceId?: string | null, stripeId?: string | null, type?: string | null, price?: { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null } | null };

export const ProductFragmentDoc = gql`
    fragment Product on Product {
  name
  price {
    ...Price
  }
  priceId
  stripeId
  type
}
    ${PriceFragmentDoc}`;