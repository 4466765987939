import { Colors, DEPRECATED_CATEGORY_COLORS, SERVICE_CATEGORY_COLORS } from "@/constants/colors";
import type { ServiceCategoryColor } from "@/features/services/models";

export const resolveCategoryColor = (color: string): ServiceCategoryColor => {
  const newColorKey =
    DEPRECATED_CATEGORY_COLORS[color as keyof typeof DEPRECATED_CATEGORY_COLORS] || color;
  const resolvedColor = SERVICE_CATEGORY_COLORS[newColorKey as Colors];

  if (!resolvedColor) {
    return SERVICE_CATEGORY_COLORS["stone"];
  }

  return resolvedColor;
};
