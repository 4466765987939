import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";

import { useDebounce } from "@hooks/use-debounce";

import { Client } from "@/types";

import { useFetchClientQuery } from "../queries/FetchClient.generated";
import {
  useFetchSalonClientsQuery,
  FetchSalonClientsQueryVariables,
} from "../queries/FetchSalonClients.generated";

type ClientsStoreProps = Pick<
  FetchSalonClientsQueryVariables,
  "sortBy" | "sortOrder" | "filters"
> & {
  selectedOptionId?: string;
};

export type ClientOfDirectory = Client & {
  isSelected: boolean;
};

export const CLIENTS_FETCH_LIMIT = 20;

export const useClientsStore = ({
  sortBy,
  sortOrder,
  selectedOptionId,
  filters,
}: ClientsStoreProps) => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const [clientUuidToInclude, setClientUuidToInclude] = useState<string | undefined>();

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data, fetchMore, refetch, networkStatus } = useFetchSalonClientsQuery({
    variables: { limit: CLIENTS_FETCH_LIMIT, sortBy, sortOrder, filters },
    notifyOnNetworkStatusChange: true,
  });

  const { data: clientToIncludeData } = useFetchClientQuery({
    variables: { id: clientUuidToInclude },
    skip: !clientUuidToInclude,
  });

  useEffect(() => {
    refetch({ query: debouncedSearchQuery, filters });
  }, [debouncedSearchQuery, filters]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready && selectedOptionId && !searchQuery) {
      setClientUuidToInclude(
        !data?.fetchSalonClients.edges.find(({ node }) => node.uuid === selectedOptionId)
          ? selectedOptionId
          : undefined,
      );
    }
  }, [selectedOptionId, networkStatus]);

  const clientOptions = [
    ...(clientToIncludeData ? [{ node: clientToIncludeData.fetchClient }] : []),
    ...(data?.fetchSalonClients.edges || []),
  ].map(({ node }) => ({
    value: node.uuid,
    label: node.fullName,
    props: node,
  }));

  const transformClientDataToDirectory = (dataArray: any = []) => {
    const groupedData: Record<string, ClientOfDirectory[]> = {};

    dataArray.forEach((data: any) => {
      const initial = data.node.lastName.charAt(0).toUpperCase();

      if (!groupedData[initial]) {
        groupedData[initial] = [];
      }

      groupedData[initial].push(data.node);
    });

    return groupedData;
  };

  return {
    loading: networkStatus === NetworkStatus.loading,
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    clients: data?.fetchSalonClients.edges.map(({ node }) => node) || [],
    clientsCount: data?.fetchSalonClients.edges.length || 0,
    clientsDirectory: transformClientDataToDirectory(data?.fetchSalonClients.edges) || {},
    clientOptions,
    count: data?.fetchSalonClients.count || 0,
    hasMore: !!data?.fetchSalonClients.pageInfo.hasNextPage,
    searchQuery,
    refetchWithQuery(query?: string) {
      setSearchQuery(query);
    },
    fetchMore(limit = CLIENTS_FETCH_LIMIT) {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchSalonClients.pageInfo.endCursor,
            limit,
          },
        });
      }
    },
  };
};
