import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CurrentSessionFragment = { __typename?: 'CurrentSession', firstName?: string | null, lastName?: string | null, fullName: string, isOnboarded: boolean, salonName?: string | null, salonPlan?: Types.SubscriptionPlan | null, email: string, phone?: string | null, role?: Types.Role | null, permissions?: any | null, accountUuid?: any | null, stripeCustomerId?: string | null, trialUsed?: boolean | null };

export const CurrentSessionFragmentDoc = gql`
    fragment CurrentSession on CurrentSession {
  firstName
  lastName
  fullName @client
  isOnboarded
  salonName
  salonPlan
  email
  phone
  role
  permissions
  accountUuid
  stripeCustomerId
  trialUsed
}
    `;