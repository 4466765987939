import { useTranslation } from "react-i18next";

import { CalendarViews, DisplayedMonthDay } from "@features/calendar/models";
import { format } from "date-fns";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { DATE_FORMAT } from "@/constants/date-formats";
import { AppointmentStatus } from "@/types";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarView } from "../../contexts/CalendarViewContext";
import { CalendarMonthCellSlot } from "./CalendarMonthCellSlot";

type CalendarMonthCellProps = {
  day: DisplayedMonthDay;
};

export const CalendarMonthCell = ({ day }: CalendarMonthCellProps) => {
  const { t } = useTranslation();
  const { isLg } = useBreakpoint("lg");
  const { setView } = useCalendarView();

  const { setSelectedDate } = useCalendarDate();

  const handleDaySelect = (date: Date) => {
    setSelectedDate(date);
    setView(isLg ? CalendarViews.Day : CalendarViews.Week);
  };

  if (isLg) {
    return (
      <div
        key={day.date.getTime()}
        className={cn(
          day.isCurrentMonth ? "bg-white" : "bg-stone-50 text-stone-500",
          "relative px-2 py-1",
        )}>
        <time
          dateTime={format(day.date, DATE_FORMAT.DEFAULT)}
          className={
            day.isToday
              ? "flex h-6 w-6 items-center justify-center rounded-full bg-gold-500 font-semibold text-white"
              : undefined
          }>
          {format(day.date, "d")}
        </time>
        {day.slots.length > 0 && (
          <ol className="mt-1 space-y-1">
            {day.slots.slice(0, 2).map((slot) => (
              <CalendarMonthCellSlot
                key={
                  slot.__typename === "BlockedSlot"
                    ? slot.uuid
                    : `${slot.uuid}-${slot.appointmentTreatmentUuid}`
                }
                slot={slot}
              />
            ))}
            {day.slots.length > 2 && (
              <li className="text-stone-500">
                <button type="button" onClick={() => handleDaySelect(day.date)}>
                  {t("generic.plusCountMore", { count: day.slots.length - 2 })}
                </button>
              </li>
            )}
          </ol>
        )}
      </div>
    );
  }

  return (
    <button
      key={day.date.getTime()}
      type="button"
      className={cn(
        day.isCurrentMonth ? "bg-white" : "bg-stone-50",
        (day.isSelected || day.isToday) && "font-semibold",
        day.isSelected && "text-white",
        !day.isSelected && day.isToday && "text-gold-500",
        !day.isSelected && day.isCurrentMonth && !day.isToday && "text-stone-900",
        !day.isSelected && !day.isCurrentMonth && !day.isToday && "text-stone-500",
        "flex min-h-[3.5rem] flex-col p-1 hover:bg-stone-100 focus:z-10 sm:px-3 sm:py-2",
      )}
      onClick={() => handleDaySelect(day.date)}>
      <time
        dateTime={format(day.date, DATE_FORMAT.DEFAULT)}
        className={cn(
          day.isSelected && "flex h-6 w-6 items-center justify-center rounded-full",
          day.isSelected && day.isToday && "bg-gold-700",
          day.isSelected && !day.isToday && "bg-gold-500",
          "ml-auto",
        )}>
        {format(day.date, "d")}
      </time>
      <span className="sr-only">{day.slots.length} events</span>
      {day.slots.length > 0 && (
        <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
          {day.slots.map((slot) => (
            <span
              key={slot.uuid}
              className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-stone-400"
              style={{
                backgroundColor:
                  slot.status === AppointmentStatus.Scheduled
                    ? slot.categoryColor || undefined
                    : undefined,
              }}
            />
          ))}
        </span>
      )}
    </button>
  );
};
