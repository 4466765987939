import { useTranslation } from "react-i18next";

import { Breadcrumbs } from "@components/layout/header/Breadcrumbs";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { TopUpSmsAction } from "@features/payments/components/TopUpSmsAction";

import { AnnouncementDialog } from "@/components/dialogs/AnnouncementDialog";
import { Button } from "@/components/ui/Button";
import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@/features/account/components/SendFeedbackDialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { Role } from "@/types";
import { cn } from "@/utils/utils";

export const Header = () => {
  const { isFreePlan, role } = useSessionContext();

  const { isMd } = useBreakpoint("md");
  const { t } = useTranslation();

  const announcementDialogState = useDialog();
  const feedbackDialogState = useDialog<SendFeedbackProps>();

  return isMd ? (
    <header
      className={cn(
        "sticky top-0 z-30 flex h-full items-center justify-between bg-gold-100 pl-2 pr-0 pt-0 sm:border-b-0 sm:py-4 sm:pl-4 md:p-0",
        "before:absolute before:-bottom-4 before:left-0 before:size-4 before:bg-gold-100 before:content-['']",
        "after:absolute after:-bottom-4 after:left-0 after:size-4 after:rounded-tl-full after:bg-gold-50 after:content-['']",
      )}>
      <div className="flex w-full items-center justify-between px-4 py-2">
        <div className="flex gap-4">
          <Breadcrumbs />
          {isFreePlan && role === Role.Owner && (
            <div className="hidden sm:flex">
              <div className="relative rounded-full px-4 py-1 text-xs text-red-600 ring-1 ring-red-900/10 hover:ring-red-900/20">
                {t("announcement.banner.title")}{" "}
                <Button
                  variant="danger-inline"
                  onClick={announcementDialogState.open}
                  className="font-semibold no-underline">
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  {t("announcement.banner.cta")} <span aria-hidden="true">&rarr;</span>
                </Button>
              </div>
            </div>
          )}
        </div>
        <TopUpSmsAction />
      </div>
      {isFreePlan && role === Role.Owner && (
        <>
          <AnnouncementDialog
            {...bindDialogState(announcementDialogState)}
            sendFeedback={feedbackDialogState.open}
          />
          <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
        </>
      )}
    </header>
  ) : null;
};
