import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ClientFragmentDoc } from '../fragments/Client.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchClientQueryVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
}>;


export type FetchClientQuery = { __typename?: 'RootQueryType', fetchClient: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Types.Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } };


export const FetchClientDocument = gql`
    query FetchClient($id: UUID4!) {
  fetchClient(clientUuid: $id) {
    ...Client
  }
}
    ${ClientFragmentDoc}`;

/**
 * __useFetchClientQuery__
 *
 * To run a query within a React component, call `useFetchClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchClientQuery(baseOptions: Apollo.QueryHookOptions<FetchClientQuery, FetchClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchClientQuery, FetchClientQueryVariables>(FetchClientDocument, options);
      }
export function useFetchClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchClientQuery, FetchClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchClientQuery, FetchClientQueryVariables>(FetchClientDocument, options);
        }
export type FetchClientQueryHookResult = ReturnType<typeof useFetchClientQuery>;
export type FetchClientLazyQueryHookResult = ReturnType<typeof useFetchClientLazyQuery>;
export type FetchClientQueryResult = Apollo.QueryResult<FetchClientQuery, FetchClientQueryVariables>;