import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MakePaymentMutationVariables = Types.Exact<{
  data: Types.MakePaymentInput;
}>;


export type MakePaymentMutation = { __typename?: 'RootMutationType', makePayment: { __typename?: 'Session', url: string } };


export const MakePaymentDocument = gql`
    mutation MakePayment($data: MakePaymentInput!) {
  makePayment(data: $data) {
    url
  }
}
    `;
export type MakePaymentMutationFn = Apollo.MutationFunction<MakePaymentMutation, MakePaymentMutationVariables>;

/**
 * __useMakePaymentMutation__
 *
 * To run a mutation, you first call `useMakePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makePaymentMutation, { data, loading, error }] = useMakePaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMakePaymentMutation(baseOptions?: Apollo.MutationHookOptions<MakePaymentMutation, MakePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakePaymentMutation, MakePaymentMutationVariables>(MakePaymentDocument, options);
      }
export type MakePaymentMutationHookResult = ReturnType<typeof useMakePaymentMutation>;
export type MakePaymentMutationResult = Apollo.MutationResult<MakePaymentMutation>;
export type MakePaymentMutationOptions = Apollo.BaseMutationOptions<MakePaymentMutation, MakePaymentMutationVariables>;