import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentTreatmentFragmentDoc } from '../fragments/AppointmentTreatment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchClientAppointmentQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type FetchClientAppointmentQuery = { __typename?: 'RootQueryType', fetchClientAppointment: { __typename?: 'ClientAppointment', treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };


export const FetchClientAppointmentDocument = gql`
    query FetchClientAppointment($token: String!) {
  fetchClientAppointment(token: $token) {
    treatments {
      ...AppointmentTreatment
    }
  }
}
    ${AppointmentTreatmentFragmentDoc}`;

/**
 * __useFetchClientAppointmentQuery__
 *
 * To run a query within a React component, call `useFetchClientAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchClientAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchClientAppointmentQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useFetchClientAppointmentQuery(baseOptions: Apollo.QueryHookOptions<FetchClientAppointmentQuery, FetchClientAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchClientAppointmentQuery, FetchClientAppointmentQueryVariables>(FetchClientAppointmentDocument, options);
      }
export function useFetchClientAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchClientAppointmentQuery, FetchClientAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchClientAppointmentQuery, FetchClientAppointmentQueryVariables>(FetchClientAppointmentDocument, options);
        }
export type FetchClientAppointmentQueryHookResult = ReturnType<typeof useFetchClientAppointmentQuery>;
export type FetchClientAppointmentLazyQueryHookResult = ReturnType<typeof useFetchClientAppointmentLazyQuery>;
export type FetchClientAppointmentQueryResult = Apollo.QueryResult<FetchClientAppointmentQuery, FetchClientAppointmentQueryVariables>;