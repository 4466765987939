import isSameDay from "date-fns/isSameDay";

import { namedOperations, UpdateSalonOpeningHoursInput } from "@/types";

import { SpecialAvailabilityDay } from "../models/specialHours";
import { useUpdateSalonOpeningHoursMutation } from "../mutations/UpdateSalonOpeningHours.generated";
import { useFetchSalonOpeningHoursQuery } from "../queries/FetchSalonOpeningHours.generated";
import { encodeRegularAvailability, encodeSpecialAvailability } from "../utils";

export const useSalonOpeningHoursStore = () => {
  const { data, loading: loadingFetch } = useFetchSalonOpeningHoursQuery();
  const [update, { loading: loadingUpdate }] = useUpdateSalonOpeningHoursMutation();

  return {
    loading: loadingFetch || loadingUpdate,
    loadingUpdate,
    loadingFetch,
    regular: encodeRegularAvailability(JSON.parse(data?.fetchSalonOpeningHours?.regular || "[]")),
    special: encodeSpecialAvailability(JSON.parse(data?.fetchSalonOpeningHours?.special || "[]")),
    update(data: UpdateSalonOpeningHoursInput) {
      return update({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchSalonOpeningHours],
      });
    },
    addSpecial(entity: SpecialAvailabilityDay) {
      const special = [
        ...encodeSpecialAvailability(JSON.parse(data?.fetchSalonOpeningHours?.special || "[]")),
        entity,
      ];

      return update({
        variables: {
          data: { special: JSON.stringify(special) },
        },
        refetchQueries: [namedOperations.Query.FetchSalonOpeningHours],
      });
    },
    updateSpecial(entity: SpecialAvailabilityDay) {
      const special = encodeSpecialAvailability(
        JSON.parse(data?.fetchSalonOpeningHours?.special || "[]"),
      );
      const index = special.findIndex(({ date }) => isSameDay(date, entity.date));

      if (~index) special[index] = entity;

      return update({
        variables: {
          data: { special: JSON.stringify(special) },
        },
        refetchQueries: [namedOperations.Query.FetchSalonOpeningHours],
      });
    },
    deleteSpecial(entity: SpecialAvailabilityDay) {
      const special = encodeSpecialAvailability(
        JSON.parse(data?.fetchSalonOpeningHours?.special || "[]"),
      ).filter(({ date }) => !isSameDay(date, entity.date));

      return update({
        variables: {
          data: { special: JSON.stringify(special) },
        },
        refetchQueries: [namedOperations.Query.FetchSalonOpeningHours],
      });
    },
  };
};
