import { useEffect, useMemo, useState } from "react";
import { Control, useWatch } from "react-hook-form";

import { useClientsStore } from "@features/clients/hooks";

import { ClientFormDialogStateProps } from "@/features/clients/components/ClientFormDialog";
import { useDialog } from "@/hooks/use-dialog";
import { ClientSortBy, SortOrder } from "@/types";
import { Option } from "@/utils/select-utils";

type UseClientSelectProps = {
  name: string;
  control: Control<any>;
};

export const useClientSelect = ({ name, control }: UseClientSelectProps) => {
  const clientAddDialog = useDialog<ClientFormDialogStateProps>();
  const clientEditDialog = useDialog<ClientFormDialogStateProps>();

  const value = useWatch({
    control,
    name,
  });

  const [selectedClient, setSelectedClient] = useState<Option | undefined>(undefined);

  const {
    clientOptions,
    loading,
    loadingFetchMore,
    hasMore,
    count,
    searchQuery,
    refetchWithQuery,
    fetchMore,
  } = useClientsStore({
    sortBy: ClientSortBy.Name,
    sortOrder: SortOrder.Asc,
    selectedOptionId: value,
  });

  const clientOptionsVersion = useMemo(
    () => clientOptions.map((client) => client.label).join(","),
    [clientOptions],
  );

  useEffect(() => {
    if (value) {
      setSelectedClient(clientOptions.find((option) => option.value === value));
    }
  }, [value, clientOptions.length, clientOptionsVersion]);

  return {
    clientAddDialog,
    clientEditDialog,
    clientOptions,
    loading,
    loadingFetchMore,
    hasMore,
    count,
    searchQuery,
    refetchWithQuery,
    fetchMore,
    formValue: value,
    selectedClient,
    setSelectedClient,
  };
};
