import { ForwardedRef, forwardRef, InputHTMLAttributes } from "react";

import { cn } from "@/utils/utils";

type TextAreaFieldProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  name: string;
  placeholder?: string;
  className?: string;
  errorMessage?: string;
  showLabel?: boolean;
};

export const TextAreaField = forwardRef(
  (
    {
      label,
      name,
      placeholder,
      className,
      errorMessage,
      showLabel = true,
      ...delegated
    }: TextAreaFieldProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ) => {
    const displayName = label || name;

    return (
      <div className={cn("relative", showLabel && "space-y-1")}>
        <label
          htmlFor={name}
          className={cn(showLabel ? "block text-xs text-stone-500" : "sr-only")}>
          {displayName}
        </label>
        <textarea
          ref={ref}
          name={name}
          id={name}
          className={cn(
            "block h-28 w-full resize-none appearance-none rounded-md border border-stone-300 px-3 py-3 text-sm placeholder-stone-400 focus:border-gold-600 focus:outline-none focus:ring-gold-600 disabled:cursor-not-allowed disabled:bg-stone-100 disabled:text-stone-400",
            className,
            {
              "border-red-600 focus:border-red-600 focus:outline-none focus:ring-red-600":
                !!errorMessage,
            },
          )}
          placeholder={placeholder}
          {...delegated}></textarea>
        <div>
          <span className="block text-xs text-red-600">{errorMessage && errorMessage}</span>
        </div>
      </div>
    );
  },
);
