import { Control, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useEmployeesStore } from "@features/employees/hooks";

import { Select } from "@/components/ui/Select";
import { Option, getOptionByValue } from "@/utils/select-utils";

type EmployeeSelectProps = {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  onChange?: (data?: Option) => void;
  onClear?: () => void;
};

export const EmployeeSelect = ({
  disabled,
  name,
  control,
  label,
  onChange,
  onClear,
}: EmployeeSelectProps) => {
  const { t } = useTranslation();

  const value = useWatch({ name: name, control: control });

  const { employeesOptions, loading } = useEmployeesStore({
    selectedOptionId: value,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: formOnChange, value = "" } }) => (
        <Select
          name={name}
          label={label ?? t("employees.employee")}
          value={value}
          selectBy="value"
          disabled={loading || disabled}
          options={employeesOptions}
          onClear={() => {
            formOnChange("");
            onClear && onClear();
          }}
          onChange={(data) => {
            formOnChange(data);
            onChange && onChange(getOptionByValue(data, employeesOptions) as Option);
          }}
          placeholder={t("placeholders.selectEmployee")}
        />
      )}
    />
  );
};
