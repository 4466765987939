import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ArrowPathIcon, ArrowUpTrayIcon, CheckIcon } from "@heroicons/react/24/outline";

import { SENDER_NAME_MAX_LENGTH, SENDER_NAME_OPTIONS } from "@features/messages/constants";

import { Alert } from "@components/ui/Alert";
import { Button } from "@components/ui/Button";
import { Radio } from "@components/ui/Radio";
import { TextField } from "@components/ui/TextField";

import { useSalonSmsDetailsStore } from "@features/messages/hooks/use-salon-sms-details-store";

import { defaultSenderNameOptions } from "@/constants/messages";
import { useSessionContext } from "@/providers/SessionProvider";
import { useToasts } from "@/providers/ToastsProvider";
import { isNotWhitespace } from "@/utils/form";
import { cn } from "@/utils/utils";

type SmsSenderNameFormProps = {
  senderName: string;
  senderNameApproved: boolean;
};

type FormData = {
  senderNameOption: string;
  senderNameCustom: string | undefined;
};

export const SmsSenderNameForm = ({ senderName, senderNameApproved }: SmsSenderNameFormProps) => {
  const { t } = useTranslation();
  const { isFreePlan } = useSessionContext();
  const { showToast } = useToasts();

  const { updateSalonSenderName, loading } = useSalonSmsDetailsStore();

  const isBeingVerified = useMemo(
    () => !defaultSenderNameOptions.includes(senderName) && !senderNameApproved,
    [senderNameApproved],
  );

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      senderNameCustom: !defaultSenderNameOptions.includes(senderName) ? senderName : "",
      senderNameOption: !defaultSenderNameOptions.includes(senderName) ? "custom" : senderName,
    },
  });

  const senderNameOption = watch("senderNameOption");
  const senderNameCustom = watch("senderNameCustom");

  const isApproved = useMemo(
    () => senderNameApproved && senderNameCustom === senderName,
    [senderNameCustom, senderName],
  );

  const handleSalonSenderNameUpdate = async (data: FormData) => {
    const { errors } = await updateSalonSenderName(data.senderNameCustom ?? data.senderNameOption);

    if (errors) {
      showToast({
        title: errors[0].message,
        type: "error",
      });
    } else {
      showToast({
        title: data.senderNameCustom
          ? t("smsSettings.sentForVerification")
          : t("smsSettings.senderNameUpdated"),
        type: data.senderNameCustom ? "info" : "success",
      });
      reset(data);
    }
  };

  const senderNameOptionsMapped = SENDER_NAME_OPTIONS.map((option) => ({
    ...option,
    disabled: isFreePlan && option.value === "custom",
    badge: isFreePlan && option.value === "custom" ? t("generic.salon") : undefined,
  }));

  return (
    <>
      <form noValidate onSubmit={handleSubmit(handleSalonSenderNameUpdate)} className="-mt-2">
        <Radio name="senderNameOption" control={control} options={senderNameOptionsMapped} />
        {senderNameOption === "custom" ? (
          <div className="grid gap-4 pt-4">
            <Alert
              type={isApproved ? "success" : "info"}
              title={
                isApproved ? (
                  <span>{t("smsSettings.customSenderNameVerified")}</span>
                ) : (
                  <span>{t("smsSettings.setCustomSenderName")}</span>
                )
              }
              description={isApproved ? <></> : <span>{t("smsSettings.verificationInfo")}</span>}>
              <div className="flex w-full flex-col gap-x-4 gap-y-2 sm:flex-row">
                <TextField
                  {...register("senderNameCustom", {
                    required: true,
                    maxLength: SENDER_NAME_MAX_LENGTH,
                    validate: {
                      isNotWhitespace,
                    },
                    deps: ["senderNameOption"],
                  })}
                  className={cn(isBeingVerified && "text-stone-400")}
                  disabled={isFreePlan || isBeingVerified}
                  placeholder={t("placeholders.customSenderName")}
                  label={t("onboarding.salonSmsDetails.senderNameOptions.custom")}
                />
                {isBeingVerified && (
                  <div className="flex items-center justify-center gap-2 text-stone-400 sm:mt-3">
                    <ArrowPathIcon className="h-4 w-4" />
                    <span>{t("smsSettings.awaitsVerification")}</span>
                  </div>
                )}
                {!isBeingVerified && !isApproved && (
                  <div className="mb-1 w-full self-end sm:w-auto">
                    <Button
                      startIcon={<ArrowUpTrayIcon />}
                      type="submit"
                      variant="primary"
                      fullWidth
                      className="h-[44px]"
                      disabled={
                        loading || !isValid || isFreePlan || senderNameCustom === senderName
                      }>
                      {t("smsSettings.sendForVerification")}
                    </Button>
                  </div>
                )}
              </div>
            </Alert>
          </div>
        ) : (
          <div className="pt-4">
            <Button
              startIcon={<CheckIcon />}
              variant="primary-outline"
              type="submit"
              size="small"
              disabled={loading || !isDirty}>
              {t("smsSettings.saveSenderName")}
            </Button>
          </div>
        )}
      </form>
    </>
  );
};
