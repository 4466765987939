import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export const some = (obj: object) => Object.values(obj).some((item) => item);

export function isTruthy<T>(value?: T | undefined | null | false): value is T {
  return !!value;
}

export function pickRandomElement<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export const removeEmptyValues = (object: Record<string, unknown>) => {
  return Object.fromEntries(Object.entries(object).filter(([_key, value]) => isTruthy(value)));
};

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(...inputs));

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
