import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Switch } from "@/components/ui/Switch";

import { useAnalyticsStore } from "../hooks/use-analytics-store";

export const ConsentSettings = () => {
  const { isAnalyticsEnabled, setAnalyticsConsent } = useAnalyticsStore();
  const { t } = useTranslation();

  const { control, reset } = useForm({
    defaultValues: {
      isAnalyticsEnabled,
    },
  });

  useEffect(
    () =>
      reset({
        isAnalyticsEnabled,
      }),
    [isAnalyticsEnabled],
  );

  return (
    <form>
      <Switch
        name="isAnalyticsEnabled"
        control={control}
        label={t("cookies.allowForCookiesUsage")}
        onChange={(_, e) => setAnalyticsConsent(e)}
        showLabel
      />
    </form>
  );
};
