import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemainingAppointmentsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RemainingAppointmentsQuery = { __typename?: 'RootQueryType', fetchRemainingAppointments?: number | null };


export const RemainingAppointmentsDocument = gql`
    query RemainingAppointments {
  fetchRemainingAppointments
}
    `;

/**
 * __useRemainingAppointmentsQuery__
 *
 * To run a query within a React component, call `useRemainingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemainingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemainingAppointmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemainingAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<RemainingAppointmentsQuery, RemainingAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemainingAppointmentsQuery, RemainingAppointmentsQueryVariables>(RemainingAppointmentsDocument, options);
      }
export function useRemainingAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemainingAppointmentsQuery, RemainingAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemainingAppointmentsQuery, RemainingAppointmentsQueryVariables>(RemainingAppointmentsDocument, options);
        }
export type RemainingAppointmentsQueryHookResult = ReturnType<typeof useRemainingAppointmentsQuery>;
export type RemainingAppointmentsLazyQueryHookResult = ReturnType<typeof useRemainingAppointmentsLazyQuery>;
export type RemainingAppointmentsQueryResult = Apollo.QueryResult<RemainingAppointmentsQuery, RemainingAppointmentsQueryVariables>;