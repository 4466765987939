import { UpdateSmsTemplateInput } from "@/types";

import { useUpdateSalonSmsTemplateMutation } from "../mutations/UpdateSalonSMSTemplate.generated";
export const useSalonSmsTemplateStore = () => {
  const [updateSalonSmsTemplate] = useUpdateSalonSmsTemplateMutation();

  return {
    updateTemplate(data: UpdateSmsTemplateInput) {
      return updateSalonSmsTemplate({
        variables: {
          data,
        },
      });
    },
  };
};
