import { isNotWhitespace } from "@/utils/form";
export const EMAIL_PATTERN =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NAME = {
  MIN_LENGTH: 1,
  MAX_LENGTH: 73,
};

export const TAG = {
  MAX_LENGTH: 15,
};

export const EMAIL = {
  required: true,
  pattern: EMAIL_PATTERN,
};

export const PASSWORD = {
  required: true,
  minLength: 8,
  maxLength: 32,
};

export const TIME_PATTERN = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;

export const GENERIC_NAME = {
  required: true,
  maxLength: 128,
  validate: {
    isNotWhitespace,
  },
};

export const TREATMENTS = {
  price: {
    required: true,
    pattern: /^\d*(\.\d{1,2})?$/,
    min: 1,
    max: 100000,
  },
  duration: {
    required: true,
    pattern: /^(0|[1-9]\d*)?$/,
    min: 10,
    max: 1440,
  },
};

export const POSTAL_CODE = {
  pattern: /^[0-9]{2}(?:-[0-9]{3})?$/,
};
