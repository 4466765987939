import { formatDuration } from "date-fns";

import { MinLeadTimeOptionsInMinutes } from "@/types";

export const SALON_PROFILE_SOCIALS = ["facebook", "instagram"] as const;
export const SALON_PROFILE_LINKS = ["terms", "privacy"] as const;

export const SELF_BOOKING_MIN_LEAD_TIME_OPTIONS: {
  value: MinLeadTimeOptionsInMinutes;
  label: string;
}[] = [
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_15,
    label: formatDuration({ minutes: 15 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_30,
    label: formatDuration({ minutes: 30 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_60,
    label: formatDuration({ hours: 1 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_120,
    label: formatDuration({ hours: 2 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_180,
    label: formatDuration({ hours: 3 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_360,
    label: formatDuration({ hours: 6 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_720,
    label: formatDuration({ hours: 12 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_1440,
    label: formatDuration({ days: 1 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_2880,
    label: formatDuration({ days: 2 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_4320,
    label: formatDuration({ days: 3 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_7200,
    label: formatDuration({ days: 5 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_10080,
    label: formatDuration({ days: 7 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_14400,
    label: formatDuration({ days: 10 }),
  },
  {
    value: MinLeadTimeOptionsInMinutes.Minutes_20160,
    label: formatDuration({ days: 14 }),
  },
];
