import { useTranslation } from "react-i18next";

import { CalendarDaysIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";

import { useCountersStore } from "@features/session/hooks";

import { Badge } from "@/components/ui/Badge";
import { Spinner } from "@/components/ui/Spinner";
import { GetSmsPackageDialog } from "@/features/payments/components/GetSmsPackageDialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

export const SidebarAccountDetails = () => {
  const { t } = useTranslation();
  const { salonPlan, isFreePlan, isOwner } = useSessionContext();
  const { remainingAppointments, loadingRemainingAppointments, smsLimit, loadingSmsLimit } =
    useCountersStore();
  const dialogState = useDialog();

  const loading = loadingRemainingAppointments || loadingSmsLimit;

  if (loading) {
    <div className="px-16">
      <Spinner />
    </div>;
  }

  return (
    <div className="w-full">
      <div
        className={cn(
          "rounded-md border border-dashed border-gold-400 bg-gold-100 px-2 pb-1 pt-3 shadow md:my-4",
        )}>
        <div className="border-b border-stone-300 pb-2">
          <Badge
            color="gold"
            bordered
            className={cn(
              "rounded py-1 font-semibold",
              !isFreePlan ? "bg-gold-500 text-gold-100" : "bg-stone-400 text-stone-50",
            )}>
            {t(`header.salonMenu.${salonPlan?.toLowerCase()}Plan`)}
          </Badge>
        </div>

        {isFreePlan && (
          <div className="border-b border-stone-300 pb-2">
            <div className="flex flex-col">
              <div className="flex items-center gap-1">
                <CalendarDaysIcon className="h-5 w-5 text-stone-600" />
                <span className="py-2 text-sm text-stone-600">
                  {t("appointments.amountLeft", { count: remainingAppointments })}
                </span>
              </div>

              <div className="flex items-center justify-between gap-4">
                <p className="text-[11px] text-stone-500">{t("sidebar.details.freePlanCTA")}</p>
                <Button
                  variant="primary-inline"
                  size="tiny"
                  to="/subscriptions"
                  className="shrink-0 text-xs">
                  {t("header.salonMenu.removeLimits")}
                </Button>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <div
            className={cn(
              "flex items-center gap-1",
              smsLimit > 0 ? "text-stone-600" : "text-red-500",
            )}>
            <EnvelopeIcon className="h-5 w-5" />
            <span className="py-2 text-sm">{t("header.smsMenu.quantityLeft", [smsLimit])}</span>
          </div>
          {isOwner && (
            <Button
              variant="primary-inline"
              size="tiny"
              onClick={() => dialogState.open()}
              className="shrink-0 text-xs">
              {t("actions.buySMS")}
            </Button>
          )}
        </div>
      </div>
      <GetSmsPackageDialog {...bindDialogState(dialogState)} />
    </div>
  );
};
