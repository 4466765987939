import { useState } from "react";

type DialogConfig<Props, Result> = {
  open: boolean;
  props?: Props;
  onClose?: (result: Result) => void;
};

type DialogState<Props, Result> = {
  open: (props?: Props) => void;
  close: () => void;
  internalState: DialogConfig<Props, Result>;
};

export const useDialog = <Props = unknown, Result = unknown>() => {
  const [state, setState] = useState({
    open: false,
    onConfirm: () => {},
  } as DialogConfig<Props, Result>);

  return {
    open(props?: Props) {
      setState((prevState) => ({
        ...prevState,
        props,
        open: true,
      }));
    },
    close() {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    },
    internalState: state,
  };
};

export const bindDialogState = <Props = unknown, Result = unknown>(
  state: DialogState<Props, Result>,
) => ({
  open: state.internalState.open,
  props: state.internalState.props,
  onClose: (result?: Result) => {
    if (state.internalState.onClose && result) state.internalState.onClose(result);

    state.close();
  },
});

export type DialogProps<Props, Result> = {
  dialogState: ReturnType<typeof useDialog<Props, Result>>;
};
