import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSmsLimitQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSmsLimitQuery = { __typename?: 'RootQueryType', fetchSmsLimit: number };


export const FetchSmsLimitDocument = gql`
    query FetchSmsLimit {
  fetchSmsLimit
}
    `;

/**
 * __useFetchSmsLimitQuery__
 *
 * To run a query within a React component, call `useFetchSmsLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSmsLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSmsLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSmsLimitQuery(baseOptions?: Apollo.QueryHookOptions<FetchSmsLimitQuery, FetchSmsLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSmsLimitQuery, FetchSmsLimitQueryVariables>(FetchSmsLimitDocument, options);
      }
export function useFetchSmsLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSmsLimitQuery, FetchSmsLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSmsLimitQuery, FetchSmsLimitQueryVariables>(FetchSmsLimitDocument, options);
        }
export type FetchSmsLimitQueryHookResult = ReturnType<typeof useFetchSmsLimitQuery>;
export type FetchSmsLimitLazyQueryHookResult = ReturnType<typeof useFetchSmsLimitLazyQuery>;
export type FetchSmsLimitQueryResult = Apollo.QueryResult<FetchSmsLimitQuery, FetchSmsLimitQueryVariables>;