import { useMemo } from "react";

import { BanknotesIcon, UserIcon } from "@heroicons/react/24/outline";

import { PaymentMethod } from "@/components/PaymentMethod";
import { formatPrice } from "@/features/payments/utils";
import { AppointmentStatus, AppointmentTreatment } from "@/types";

type AppointmentDetailsTreatmentProps = {
  treatment: AppointmentTreatment;
  appointmentStatus: AppointmentStatus;
  appointmentPaymentMethod: string | null;
};

export const AppointmentDetailsTreatment = ({
  treatment,
  appointmentStatus,
  appointmentPaymentMethod,
}: AppointmentDetailsTreatmentProps) => {
  const formattedPrice = useMemo(() => {
    return formatPrice({
      price:
        appointmentStatus === AppointmentStatus.Completed
          ? treatment.finalPrice
          : treatment.suggestedPrice,
      currency: "PLN",
    });
  }, [treatment, appointmentStatus]);

  return (
    <div className="min-w-0 flex-1 py-1">
      <div
        key={treatment.treatmentUuid}
        className={`bg-${treatment.categoryColor}-50 border text-sm border-${treatment.categoryColor}-200 sm:text-sm text-${treatment.categoryColor}-900 flex flex-wrap justify-between rounded-md px-4 py-2 sm:flex-row`}>
        <div className="w-full">{treatment.treatmentName}</div>
        <div className="mr-4 mt-2 flex items-center sm:mt-1">
          <UserIcon className="mr-1 h-3.5 w-3.5 shrink-0" aria-hidden="true" />
          {treatment.employeeName}
        </div>
        <div className="mt-2 flex w-auto items-center sm:mb-0">
          {appointmentStatus === AppointmentStatus.Completed && appointmentPaymentMethod ? (
            <PaymentMethod
              paymentMethodUuid={appointmentPaymentMethod}
              showLabel={false}
              className="mr-1 h-3.5 w-3.5"
            />
          ) : (
            <BanknotesIcon className="mr-1 h-3.5 w-3.5" aria-hidden="true" />
          )}
          {formattedPrice}
        </div>
      </div>
    </div>
  );
};
