import { useEffect } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Radio } from "@components/ui/Radio";

import { resources } from "@/lib/i18n";

type FormData = {
  language: string;
};

export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const { control, handleSubmit } = useForm<FormData>({
    mode: "all",
    defaultValues: {
      language: i18n.language,
    },
  });

  const language = useWatch({ control, name: "language" });
  useEffect(() => {
    if (i18n.language !== language) {
      handleSave({ language });
    }
  }, [language, i18n.language]);

  const handleSave: SubmitHandler<FormData> = ({ language }) => {
    i18n.changeLanguage(language);
    window.location.assign(window.location.pathname);
  };

  return (
    <form onSubmit={handleSubmit(handleSave)} noValidate className="-mt-2">
      <Radio
        name="language"
        control={control}
        options={Object.keys(resources).map((languageKey) => ({
          value: languageKey,
          label: t("generic.languageLabel", { lng: languageKey }),
        }))}
      />
    </form>
  );
};
