import { useTranslation } from "react-i18next";

import { ArrowsUpDownIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";

type EmployeeListActionsProps = {
  isDraggable?: boolean;
  handleOrder?: () => void;
  handleSaveOrder?: () => void;
};

export const EmployeeListActions = ({
  isDraggable,
  handleOrder,
  handleSaveOrder,
}: EmployeeListActionsProps) => {
  const { t } = useTranslation();

  const button = {
    label: isDraggable ? t("generic.saveOrder") : t("generic.changeOrder"),
    onClick: isDraggable ? handleSaveOrder : handleOrder,
  };

  return (
    <div className="mt-6 inline-flex w-full items-center gap-2 border-b border-b-stone-200 px-5 pb-4">
      <Button
        size="small"
        variant="secondary-inline"
        onClick={button.onClick}
        className="appearance-none focus:outline-none">
        <div className="group relative inline-flex items-center">
          {!isDraggable && (
            <ArrowsUpDownIcon className="mr-0.5 h-3.5 w-3.5 text-stone-500 group-hover:text-stone-700" />
          )}
          <span className="text-xs text-stone-500 group-hover:text-stone-700">{button.label}</span>
        </div>
      </Button>
    </div>
  );
};
