import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";

import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { AnimatePresence, motion, Variants } from "framer-motion";

import { AnnouncementDialog } from "@/components/dialogs/AnnouncementDialog";
import { Button } from "@/components/ui/Button";
import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@/features/account/components/SendFeedbackDialog";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { Role } from "@/types";
import { cn } from "@/utils/utils";

import { useCalendarDate } from "../contexts/CalendarDateContext";

interface MotionVariantsProps {
  direction: number;
}

const variants: Variants = {
  enter: (custom: MotionVariantsProps) => ({
    x: custom.direction > 0 ? 300 : -300,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (custom: MotionVariantsProps) => ({
    x: custom.direction < 0 ? 300 : -300,
    opacity: 0,
  }),
};

export const CalendarDaysNavigation = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => {
  const { isSm } = useBreakpoint("sm");
  const { t } = useTranslation();
  const { selectedDate, setSelectedDate } = useCalendarDate();
  const { isFreePlan, role } = useSessionContext();

  const initialWeekStart = useMemo(() => {
    return selectedDate
      ? startOfWeek(selectedDate, { weekStartsOn: 1 })
      : startOfWeek(new Date(), { weekStartsOn: 1 });
  }, [selectedDate]);

  const [currentWeekStart, setCurrentWeekStart] = useState(initialWeekStart);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    if (selectedDate) {
      const newWeekStart = startOfWeek(selectedDate, { weekStartsOn: 1 });
      setCurrentWeekStart(newWeekStart);
    }
  }, [selectedDate]);

  const handleNextWeek = () => {
    setDirection(1);
    setCurrentWeekStart((prev) => {
      const newWeekStart = addWeeks(prev, 1);
      if (selectedDate) {
        setSelectedDate(addWeeks(selectedDate, 1));
      } else {
        setSelectedDate(newWeekStart);
      }
      return newWeekStart;
    });
  };

  const handlePrevWeek = () => {
    setDirection(-1);
    setCurrentWeekStart((prev) => {
      const newWeekStart = subWeeks(prev, 1);
      if (selectedDate) {
        setSelectedDate(subWeeks(selectedDate, 1));
      } else {
        setSelectedDate(newWeekStart);
      }
      return newWeekStart;
    });
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleNextWeek(),
    onSwipedRight: () => handlePrevWeek(),
    trackMouse: true,
    delta: 50,
  });

  const month = useMemo(() => {
    const start = format(currentWeekStart, "LLLL");
    const endOfCurrentWeek = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
    const endMonth = format(endOfCurrentWeek, "LLLL");
    return isSameMonth(currentWeekStart, endOfCurrentWeek) ? start : `${start} - ${endMonth}`;
  }, [currentWeekStart]);

  const days = useMemo(() => {
    return eachDayOfInterval({
      start: currentWeekStart,
      end: endOfWeek(currentWeekStart, { weekStartsOn: 1 }),
    });
  }, [currentWeekStart]);

  const renderWeekDays = useMemo(() => {
    return days.map((day, index) => (
      <button
        key={index}
        type="button"
        className="flex w-full flex-col items-center pb-2"
        onClick={() => setSelectedDate(day)}>
        {format(day, "EEE")}{" "}
        <span
          className={cn("flex h-6 w-6 items-center justify-center font-semibold text-stone-900", {
            "rounded-md bg-gold-200 text-gold-700": isSameDay(day, selectedDate),
          })}>
          {format(day, "dd")}
        </span>
      </button>
    ));
  }, [days, selectedDate, setSelectedDate]);

  const announcementDialogState = useDialog();
  const feedbackDialogState = useDialog<SendFeedbackProps>();

  return (
    <div
      ref={ref}
      className="absolute left-0 right-0 top-0 z-40 flex-none border bg-white sm:hidden">
      {!isSm && (
        <div>
          {isFreePlan && role === Role.Owner && (
            <div className="mx-2 mt-2 flex sm:hidden">
              <div className="relative w-full rounded-full px-4 py-1 text-center text-xs text-red-600 ring-1 ring-red-900/10 hover:ring-red-900/20">
                {t("announcement.banner.title")}{" "}
                <Button
                  variant="danger-inline"
                  onClick={announcementDialogState.open}
                  className="font-semibold no-underline">
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  <span aria-hidden="true">&rarr;</span>
                </Button>
              </div>
            </div>
          )}
          <time className="flex justify-center border-b border-stone-100 py-2 text-xs uppercase tracking-wide text-stone-500 first-letter:capitalize">
            {month}
          </time>
        </div>
      )}
      <div {...handlers} className="relative h-14 overflow-hidden">
        <AnimatePresence custom={{ direction }} initial={false}>
          <motion.div
            key={currentWeekStart.toISOString()}
            className="absolute left-0 top-0 grid w-full grid-cols-7 text-xs leading-6 text-stone-500"
            custom={{ direction }}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}>
            {renderWeekDays}
          </motion.div>
        </AnimatePresence>
      </div>
      {isFreePlan && role === Role.Owner && (
        <>
          <AnnouncementDialog
            {...bindDialogState(announcementDialogState)}
            sendFeedback={feedbackDialogState.open}
          />
          <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
        </>
      )}
    </div>
  );
});
