import type { AppointmentTreatmentSlot, CalendarBlockedSlot } from "@/features/calendar/models";
import type { Appointment, BlockedSlot } from "@/types";

export const isAppointment = (value: Record<string, unknown>): value is Appointment => {
  return value?.__typename === "Appointment";
};

export const isBlockedSlot = (value: Record<string, unknown>): value is BlockedSlot => {
  return value?.__typename === "BlockedSlot";
};

export const isAppointmentTreatmentSlot = (
  value: Record<string, unknown>,
): value is AppointmentTreatmentSlot => {
  return !!(value?.__typename === "AppointmentTreatment" && value?.__isSlot);
};

export const isCalendarBlockedSlot = (
  value: Record<string, unknown>,
): value is CalendarBlockedSlot => {
  return !!(value.__typename === "BlockedSlot" && value?.__isSlot);
};
