import { useMemo } from "react";

import { Maybe } from "@/types";
import { cn } from "@/utils/utils";

type AvatarProps = {
  imageUrl?: Maybe<string>;
  fallbackIcon: React.ForwardRefExoticComponent<
    React.SVGProps<SVGSVGElement> & React.RefAttributes<SVGSVGElement>
  >;
  alt?: string;
  size?: "sm" | "md" | "lg";
  className?: string;
};

export const Avatar = ({
  fallbackIcon: FallbackIcon,
  imageUrl,
  size = "sm",
  alt,
  className,
}: AvatarProps) => {
  const resolveSize = (size: "sm" | "md" | "lg") => {
    switch (size) {
      case "sm":
        return "size-7";
      case "md":
        return "size-8";
      case "lg":
        return "size-9";
    }
  };

  const avatarSize = useMemo(() => resolveSize(size), [size]);

  return imageUrl ? (
    <img
      src={imageUrl}
      crossOrigin="anonymous"
      alt={alt}
      className={cn("mr-2 rounded-full border border-stone-300", avatarSize, className)}
    />
  ) : (
    <div
      className={cn(
        "mr-2 flex items-center justify-center rounded-full border border-stone-300 bg-stone-100",
        avatarSize,
        className,
      )}>
      <FallbackIcon className="size-3 text-stone-500" aria-hidden="true" />
    </div>
  );
};
