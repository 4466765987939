import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ClientFragmentDoc } from '../fragments/Client.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddClientMutationVariables = Types.Exact<{
  data: Types.AddClientInput;
}>;


export type AddClientMutation = { __typename?: 'RootMutationType', addClient: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Types.Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } };


export const AddClientDocument = gql`
    mutation AddClient($data: AddClientInput!) {
  addClient(data: $data) {
    ...Client
  }
}
    ${ClientFragmentDoc}`;
export type AddClientMutationFn = Apollo.MutationFunction<AddClientMutation, AddClientMutationVariables>;

/**
 * __useAddClientMutation__
 *
 * To run a mutation, you first call `useAddClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutation, { data, loading, error }] = useAddClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddClientMutation(baseOptions?: Apollo.MutationHookOptions<AddClientMutation, AddClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientMutation, AddClientMutationVariables>(AddClientDocument, options);
      }
export type AddClientMutationHookResult = ReturnType<typeof useAddClientMutation>;
export type AddClientMutationResult = Apollo.MutationResult<AddClientMutation>;
export type AddClientMutationOptions = Apollo.BaseMutationOptions<AddClientMutation, AddClientMutationVariables>;