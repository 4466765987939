import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Spinner } from "@components/ui/Spinner";
import { Switch } from "@components/ui/Switch";

import { useSalonSmsDetailsStore } from "@features/salon/hooks";

import { Button } from "@/components/ui/Button";
import { Select } from "@/components/ui/Select";

type FormData = {
  sendReminders: boolean;
  appointmentReminderSendInAdvanceHours: number;
};

export const SmsDetails = () => {
  const { t } = useTranslation();
  const { salonSmsDetails, updateSalonSmsDetails, loading } = useSalonSmsDetailsStore();

  const APPOINTMENT_REMINDER_SEND_IN_ADVANCE_HOURS_OPTIONS = [
    { value: 24, label: t("smsSettings.reminderOptions.HOURS_24") },
    { value: 48, label: t("smsSettings.reminderOptions.HOURS_48") },
    { value: 72, label: t("smsSettings.reminderOptions.HOURS_72") },
    { value: 120, label: t("smsSettings.reminderOptions.HOURS_120") },
    { value: 168, label: t("smsSettings.reminderOptions.HOURS_168") },
  ];

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm<FormData>({
    mode: "all",
    shouldUnregister: true,
    defaultValues: {
      sendReminders: salonSmsDetails?.sendReminders ?? false,
      appointmentReminderSendInAdvanceHours:
        salonSmsDetails?.appointmentReminderSendInAdvanceHours ?? 24,
    },
  });

  useEffect(() => {
    if (salonSmsDetails) {
      reset({
        sendReminders: salonSmsDetails.sendReminders,
        appointmentReminderSendInAdvanceHours:
          salonSmsDetails.appointmentReminderSendInAdvanceHours,
      });
    }
  }, [salonSmsDetails, reset]);

  const handleSalonSMSDetailsSubmit = (data: FormData) => {
    updateSalonSmsDetails(data);
  };

  return (
    <form onSubmit={handleSubmit(handleSalonSMSDetailsSubmit)} className="max-w-sm">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex grow flex-col gap-8">
            <Switch
              showLabel
              name="sendReminders"
              control={control}
              label={t("smsSettings.sendReminders")}
              className="flex justify-between text-sm text-stone-500"
            />
            <Controller
              control={control}
              name="appointmentReminderSendInAdvanceHours"
              render={({ field: { onChange, value = "" } }) => (
                <Select
                  name="appointmentReminderSendInAdvanceHours"
                  label={t("smsSettings.appointmentReminderSendInAdvanceHours")}
                  onChange={onChange}
                  value={value}
                  options={APPOINTMENT_REMINDER_SEND_IN_ADVANCE_HOURS_OPTIONS}
                  selectBy="value"
                />
              )}
            />
          </div>
          <div className="mt-5">
            <Button
              variant="primary-outline"
              type="submit"
              size="small"
              className="w-fit"
              disabled={loading || !isDirty || !isValid}>
              {t("form.saveChanges")}
            </Button>
          </div>
        </>
      )}
    </form>
  );
};
