import React from "react";
import { useTranslation } from "react-i18next";
import PullToRefresh from "react-simple-pull-to-refresh";

import { Header } from "@components/layout/header/Header";
import { Meta, MetaProps } from "@components/Meta";

import { useSidebar } from "@/hooks/use-sidebar";
import { useUserAgent } from "@/hooks/use-user-agent";
import { cn } from "@/utils/utils";

import { Spinner } from "../ui/Spinner";
import { BottomNavigation } from "./BottomNavigation";
import { Sidebar } from "./sidebar/Sidebar";

type LayoutProps = MetaProps & {
  children: React.ReactNode;
  title?: string;
  className?: string;
};

export const Layout = ({ children, title, className }: LayoutProps) => {
  const { t } = useTranslation();
  const { toggleOpen, setShrinked, state } = useSidebar();
  const { isShrinked, isOpen } = state;
  const { isMobileApp, isIOS } = useUserAgent();

  return (
    <PullToRefresh
      isPullable={isMobileApp}
      canFetchMore={false}
      pullDownThreshold={200}
      maxPullDownDistance={230}
      resistance={2.5}
      backgroundColor="#f5f5f4"
      refreshingContent={
        <div className="flex h-full items-center bg-stone-100 py-8">
          <Spinner className="size-8" />
        </div>
      }
      pullingContent={
        <p className="flex h-auto flex-1 items-center justify-center py-8 text-sm text-gold-500">
          &#8615;&nbsp;&nbsp;{t("actions.refresh")}&nbsp;&nbsp;&#8615;
        </p>
      }
      onRefresh={async () => await window.location.reload()}>
      <div className={className}>
        <Meta title={title} />
        <Sidebar
          isShrinked={isShrinked}
          isOpen={isOpen}
          setIsOpen={toggleOpen}
          setSidebarIsShrinked={setShrinked}
        />
        <div
          className={cn(
            isShrinked ? "md:pl-16" : "md:pl-48 xl:pl-56",
            "w-full bg-gold-100 transition-all duration-300 ease-in-out",
          )}>
          <Header />
          <main
            className={cn(
              isIOS ? "pb-12" : "pb-16",
              "relative h-screen flex-1 select-none overflow-y-auto overscroll-none rounded-t-xl bg-gold-50 px-2 pt-4 xs:px-4 md:h-[calc(100vh-52px)] md:select-text md:rounded-none md:pb-2",
            )}>
            {children}
          </main>
        </div>
        <BottomNavigation openSidebar={toggleOpen} isOpen={isOpen} />
      </div>
    </PullToRefresh>
  );
};
