import { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";

import type { CustomActionDialogProps, DialogAction } from "@hooks/use-custom-action-dialog";

import { Button } from "@/components/ui/Button";

export const CustomActionDialog = <Result,>({
  dialogState,
  children,
}: CustomActionDialogProps<Result>) => {
  const state = dialogState.internalState;

  const handleClose = (action: DialogAction<Result>) => {
    dialogState.close();
    if (action.result !== undefined) {
      state.onClose?.call(null, action.result);
    }
    action.onClick?.();
  };

  return (
    <Transition.Root appear show={state.open} as={Fragment}>
      <Dialog as="div" onClose={() => {}} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-stone-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-40">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-220 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-220 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full">
              <Dialog.Panel className="flex h-auto w-full max-w-md flex-col justify-between rounded-xl bg-stone-50 px-6 py-5 text-left shadow-xl transition-all">
                <Dialog.Title
                  as="h4"
                  className="-ml-6 -mr-6 mb-2 px-6 font-serif text-lg leading-6 text-stone-700">
                  {state.title}
                </Dialog.Title>

                <div className="mt-2 text-stone-500">{state.message}</div>
                {children && <div className="mt-2">{children}</div>}
                <div className="mt-8 grid grid-cols-2 gap-4">
                  {state.actions.map((action, idx) => (
                    <Button
                      key={idx}
                      variant={action.variant || "primary"}
                      fullWidth
                      onClick={() => handleClose(action)}>
                      {action.label}
                    </Button>
                  ))}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
