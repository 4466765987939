import { useFetchBulkAppointmentsPaginatedQuery } from "@/features/calendar/queries/FetchBulkAppointmentsPaginated.generated";
import { AppointmentFilters, SortOrder } from "@/types";

type UseCalendarStoreProps = {
  filters?: AppointmentFilters;
  after?: string | null;
  first?: number | null;
  sortByDatetime?: SortOrder;
};

export const useAppointmentsStore = ({
  filters,
  after = null,
  first = 10,
  sortByDatetime = SortOrder.Desc,
}: UseCalendarStoreProps) => {
  const {
    fetchMore,
    loading: loadingAppointmentsPaginated,
    data: appointmentsPaginated,
  } = useFetchBulkAppointmentsPaginatedQuery({
    fetchPolicy: "cache-and-network",
    variables: filters && { filters, after, first, sortByDatetime },
    skip: !filters,
  });

  return {
    loadingAppointmentsPaginated,
    appointmentsPaginated:
      appointmentsPaginated?.fetchBulkAppointmentsPaginated.edges.map(({ node }) => node) ?? [],
    count: appointmentsPaginated?.fetchBulkAppointmentsPaginated?.count ?? 0,
    fetchMore: () =>
      fetchMore({
        variables: {
          after: appointmentsPaginated?.fetchBulkAppointmentsPaginated?.pageInfo?.endCursor ?? null,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            fetchBulkAppointmentsPaginated: {
              ...fetchMoreResult.fetchBulkAppointmentsPaginated,
              edges: [
                ...prev.fetchBulkAppointmentsPaginated.edges,
                ...fetchMoreResult.fetchBulkAppointmentsPaginated.edges,
              ],
            },
          };
        },
      }),
    hasNextPage:
      appointmentsPaginated?.fetchBulkAppointmentsPaginated?.pageInfo?.hasNextPage ?? false,
  };
};
