import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchGeneralNumbersQueryVariables = Types.Exact<{
  timeRange: Types.TimerangeInput;
}>;


export type FetchGeneralNumbersQuery = { __typename?: 'RootQueryType', fetchGeneralNumbers: { __typename?: 'GeneralNumbers', canceledAppointments: number, finalizedAppointments: number, newClients: number, totalRevenue: number } };


export const FetchGeneralNumbersDocument = gql`
    query FetchGeneralNumbers($timeRange: TimerangeInput!) {
  fetchGeneralNumbers(timeRange: $timeRange) {
    canceledAppointments
    finalizedAppointments
    newClients
    totalRevenue
  }
}
    `;

/**
 * __useFetchGeneralNumbersQuery__
 *
 * To run a query within a React component, call `useFetchGeneralNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGeneralNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGeneralNumbersQuery({
 *   variables: {
 *      timeRange: // value for 'timeRange'
 *   },
 * });
 */
export function useFetchGeneralNumbersQuery(baseOptions: Apollo.QueryHookOptions<FetchGeneralNumbersQuery, FetchGeneralNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchGeneralNumbersQuery, FetchGeneralNumbersQueryVariables>(FetchGeneralNumbersDocument, options);
      }
export function useFetchGeneralNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchGeneralNumbersQuery, FetchGeneralNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchGeneralNumbersQuery, FetchGeneralNumbersQueryVariables>(FetchGeneralNumbersDocument, options);
        }
export type FetchGeneralNumbersQueryHookResult = ReturnType<typeof useFetchGeneralNumbersQuery>;
export type FetchGeneralNumbersLazyQueryHookResult = ReturnType<typeof useFetchGeneralNumbersLazyQuery>;
export type FetchGeneralNumbersQueryResult = Apollo.QueryResult<FetchGeneralNumbersQuery, FetchGeneralNumbersQueryVariables>;