/* eslint-disable import/no-duplicates */
import { DATE_FORMAT } from "@constants/date-formats";
import { setTimeToDate } from "@utils/datetime";
import { eachDayOfInterval, startOfWeek, endOfWeek, format } from "date-fns";
import { enUS } from "date-fns/locale";

import { DecodedEmployeeScheduleDay, EmployeeSchedule } from "../models";

export const weekdays = eachDayOfInterval({
  start: startOfWeek(new Date(), { weekStartsOn: 1 }),
  end: endOfWeek(new Date(), { weekStartsOn: 1 }),
}).map((date) => format(date, DATE_FORMAT.DAY_OF_WEEK, { locale: enUS }));

export const encodeEmployeeWorkSchedule = (
  regular: DecodedEmployeeScheduleDay[],
): EmployeeSchedule =>
  weekdays.reduce((availability: EmployeeSchedule, weekday: string) => {
    const dayRegulars = regular.filter((entity) => entity.weekday === weekday);

    availability[weekday] = {
      is_open: dayRegulars[0]?.is_open ?? false,
      ranges: dayRegulars
        .map(({ from, to }) => ({ from, to }))
        .sort(
          (a, b) =>
            setTimeToDate(new Date(), a.from).getTime() -
            setTimeToDate(new Date(), b.from).getTime(),
        ),
    };

    return availability;
  }, {});

export const decodeEmployeeWorkSchedule = (data: EmployeeSchedule): DecodedEmployeeScheduleDay[] =>
  weekdays.reduce((regular: DecodedEmployeeScheduleDay[], weekday: string) => {
    const day = data[weekday];

    return [
      ...regular,
      ...day?.ranges.map((range) => ({
        weekday,
        is_open: day.is_open,
        ...range,
      })),
    ];
  }, []);
