import { useMemo, useReducer } from "react";

import { getDate, getDaysInMonth, getMonth, getYear } from "date-fns";

const START_YEAR = 1970;

type SelectedOption = {
  year: number;
  month: number;
  day: number;
};

type DatePickerOptions = {
  year: number[];
  month: number[];
  day: number[];
};

type MobileDatePickerState = {
  options: DatePickerOptions;
  selectedOption: SelectedOption;
};

const enum ActionType {
  SET_SELECTED_OPTION = "SET_SELECTED_OPTION",
  UPDATE_OPTIONS = "UPDATE_OPTIONS",
}

type MobileDatePickerActions =
  | { type: ActionType.SET_SELECTED_OPTION; payload: SelectedOption }
  | { type: ActionType.UPDATE_OPTIONS; payload: { year: number; month: number } };

const mobileDatePickerReducer = (
  state: MobileDatePickerState,
  action: MobileDatePickerActions,
): MobileDatePickerState => {
  switch (action.type) {
    case ActionType.SET_SELECTED_OPTION:
      const newDays = getDaysInMonth(new Date(action.payload.year, action.payload.month));
      if (newDays !== state.options.day.length) {
        return {
          ...state,
          selectedOption: action.payload,
          options: {
            ...state.options,
            day: range(1, newDays),
          },
        };
      } else {
        return { ...state, selectedOption: action.payload };
      }
    default:
      return state;
  }
};

export const useMobileDatePicker = (initialDate: Date = new Date()) => {
  const initialOption = {
    year: getYear(initialDate),
    month: getMonth(initialDate),
    day: getDate(initialDate),
  };

  const [state, dispatch] = useReducer(mobileDatePickerReducer, {
    selectedOption: initialOption,
    options: generateOptions(initialOption.year, initialOption.month),
  });

  const setSelectedOption = (selectedOption: SelectedOption) => {
    const daysInSelectedMonth = getDaysInMonth(new Date(selectedOption.year, selectedOption.month));

    if (selectedOption.day > daysInSelectedMonth) {
      selectedOption = { ...selectedOption, day: daysInSelectedMonth };
    }

    dispatch({ type: ActionType.SET_SELECTED_OPTION, payload: selectedOption });
  };

  return useMemo(() => {
    return {
      ...state,
      setSelectedOption,
    };
  }, [state, setSelectedOption]);
};

const range = (start: number, length: number) => Array.from({ length }, (_, i) => start + i);

const generateOptions = (year: number, month: number): DatePickerOptions => {
  return {
    year: range(START_YEAR, 101),
    month: range(0, 12),
    day: range(1, getDaysInMonth(new Date(year, month))),
  };
};
