import { ReactNode, useState } from "react";

import { Drawer } from "vaul";

type BottomSheetProps = {
  children: ReactNode;
  trigger: ReactNode;
  title?: string;
  showHandle?: boolean;
  isDismissable?: boolean;
  onBlur?: () => void;
};

export const BottomSheet = ({
  children,
  trigger,
  title = "",
  showHandle = false,
  isDismissable = true,
  onBlur,
}: BottomSheetProps) => {
  const [open, setOpen] = useState(false);

  const handleBlur = () => {
    onBlur && onBlur();
    setOpen(false);
  };

  return (
    <Drawer.Root
      dismissible={isDismissable}
      open={open}
      onOpenChange={(e) => {
        setOpen(e);
      }}>
      <Drawer.Trigger asChild>{trigger}</Drawer.Trigger>
      <Drawer.Portal>
        <Drawer.Overlay className="fixed inset-0 z-50 bg-black/40" />
        <Drawer.Content
          className="h-fill fixed bottom-0 left-0 right-0 z-50 mt-24 flex flex-col rounded-t-[10px] bg-stone-100 p-4"
          onBlur={handleBlur}>
          {showHandle && (
            <Drawer.Handle
              className="mx-auto mt-2 h-1 w-8 rounded-full bg-stone-500"
              aria-label="Handle"
            />
          )}
          <Drawer.Title className="mb-4 font-medium">{title}</Drawer.Title>
          {children}
          <Drawer.Description />
        </Drawer.Content>
        <Drawer.Overlay />
      </Drawer.Portal>
    </Drawer.Root>
  );
};
