import { Role } from "@/types";

export const ROLE_LABELS_MAP: Record<string, string> = {
  [Role.Owner]: "employees.role.owner",
  [Role.Employee]: "employees.role.employee",
};

export const MIN_OPTIONS_FOR_SEARCH = 5;

export const EMPLOYEE_PERMISSIONS = [
  {
    type: "calendar",
    permissions: [
      "add_slot_block",
      "edit_slot_block",
      "delete_slot_block",
      "add_appointment",
      "edit_appointment",
      "delete_appointment",
      "accept_appointment",
      "finalize_appointment",
      "unfinalize_appointment",
    ],
  },
  {
    type: "clients",
    permissions: [
      "client_details_view",
      "add_client",
      "edit_client",
      "delete_client",
      "send_sms",
      "send_bulk_sms",
    ],
  },
  { type: "tags", permissions: ["add_tag", "edit_tag", "delete_tag"] },
  { type: "employees", permissions: ["add_employee", "edit_employee", "delete_employee"] },
  {
    type: "other",
    permissions: [
      "manage_equipment",
      "manage_opening_hours",
      "manage_treatments",
      "manage_salon_profile",
      "manage_sms",
    ],
  },
  { type: "statistics", permissions: ["statistics_view"] },
];

export const EMPLOYEE_ROLES_PERMISSIONS = [
  {
    name: "employee",
    permissions: [
      "add_slot_block",
      "edit_slot_block",
      "delete_slot_block",
      "add_appointment",
      "edit_appointment",
      "delete_appointment",
      "accept_appointment",
    ],
  },
  {
    name: "employee_plus",
    permissions: [
      "add_slot_block",
      "edit_slot_block",
      "delete_slot_block",
      "add_appointment",
      "edit_appointment",
      "delete_appointment",
      "accept_appointment",
      "finalize_appointment",
      "client_details_view",
      "add_client",
      "edit_client",
      "delete_client",
      "add_tag",
      "edit_tag",
      "delete_tag",
    ],
  },
  {
    name: "manager",
    permissions: [
      "add_slot_block",
      "edit_slot_block",
      "delete_slot_block",
      "add_appointment",
      "edit_appointment",
      "delete_appointment",
      "accept_appointment",
      "finalize_appointment",
      "unfinalize_appointment",
      "client_details_view",
      "add_client",
      "edit_client",
      "delete_client",
      "add_tag",
      "edit_tag",
      "delete_tag",
      "add_employee",
      "edit_employee",
      "delete_employee",
      "manage_equipment",
      "manage_opening_hours",
      "manage_treatments",
      "manage_salon_profile",
      "manage_sms",
      "send_sms",
      "send_bulk_sms",
      "statistics_view",
    ],
  },
];
