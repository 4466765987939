import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type EquipmentItemFragment = { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> };

export const EquipmentItemFragmentDoc = gql`
    fragment EquipmentItem on EquipmentItem {
  uuid
  name
  description
  treatments {
    treatmentName
    treatmentUuid
  }
}
    `;