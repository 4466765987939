import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PlusIcon } from "@heroicons/react/24/outline";

import { useCountersStore } from "@features/session/hooks";

import { CustomActionDialog } from "@/components/dialogs/CustomActionDialog";
import { Button } from "@/components/ui/Button";
import { useCustomActionDialog } from "@/hooks/use-custom-action-dialog";
import { useUserAgent } from "@/hooks/use-user-agent";
import { useSessionContext } from "@/providers/SessionProvider";
import { sendEvent } from "@/utils/google-analytics";
import { cn } from "@/utils/utils";

import { useCalendarDialogs } from "../contexts/CalendarDialogContext";

export const CalendarFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isIOS } = useUserAgent();
  const { isFreePlan, permissions } = useSessionContext();
  const { remainingAppointments, loadingRemainingAppointments } = useCountersStore();
  const { openAddSlotDialog } = useCalendarDialogs();
  const customActionDialogState = useCustomActionDialog();

  const isAbleToAddAppointment = useMemo(
    () => !isFreePlan || remainingAppointments > 0,
    [isFreePlan, remainingAppointments],
  );

  const upgradeCta = () => {
    sendEvent("upgrade_cta", "payments", "calendar_footer");
    customActionDialogState.open({
      title: t("appointments.reachedLimitHeader"),
      message: t("appointments.reachedLimitDescription"),
      actions: [
        {
          label: t("generic.close"),
          variant: "primary-outline",
          onClick: customActionDialogState.close,
        },
        {
          label: t("actions.subscribe"),
          onClick: () => {
            customActionDialogState.close();
            navigate("/subscriptions");
          },
        },
      ],
    });
  };

  return (
    <div
      className={cn(
        isIOS ? "bottom-16" : "bottom-20",
        "fixed right-4 rounded-full p-1 drop-shadow-[0_-4px_4px_rgb(0,0,0,0.07)]",
      )}>
      <Button
        className="rounded-xl p-2.5"
        fullWidth
        disabled={
          loadingRemainingAppointments ||
          (!permissions.add_appointment && !permissions.add_slot_block)
        }
        onClick={isAbleToAddAppointment ? () => openAddSlotDialog() : upgradeCta}>
        <PlusIcon className="h-6 w-6" />
      </Button>
      <CustomActionDialog dialogState={customActionDialogState} />
    </div>
  );
};
