import { useState, FormEvent, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { cn } from "@/utils/utils";

import { Spinner } from "../Spinner";

export type SelectSearchProps = {
  name: string;
  noResult: boolean;
  onSearch?: (query: string) => void;
  placeholder?: string;
};

export const SelectSearch = ({ name, noResult, onSearch, placeholder }: SelectSearchProps) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>("");

  const callbackRef = useCallback(
    (element: HTMLInputElement) => element && setTimeout(() => element.focus(), 0),
    [],
  );

  const handleSearch = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    onSearch && onSearch(value);
    setQuery(value);
  };

  const handleClearSearch = () => {
    onSearch && onSearch("");
    setQuery("");
  };

  useEffect(() => {
    return () => handleClearSearch();
  }, []);

  return (
    <>
      <div className="sticky top-0 z-10 px-2 py-1">
        <MagnifyingGlassIcon className="pointer-events-none absolute inset-y-1/2 left-4 h-[18px] w-[18px] -translate-y-1/2 text-stone-500" />
        <input
          type="text"
          name={`${name}_search`}
          id={`${name}_search`}
          ref={callbackRef}
          placeholder={placeholder && placeholder}
          value={query}
          onChange={handleSearch}
          className={cn(
            "block w-full rounded-md border border-stone-300 bg-stone-50 px-2 py-2 pl-9 text-sm shadow-sm focus:border-stone-400 focus:ring-0",
            { ["pr-9"]: query.length > 0 },
          )}
        />
        {query.length > 0 && (
          <XMarkIcon
            className="absolute inset-y-1/2 right-4 h-5 w-5 -translate-y-1/2 cursor-pointer text-stone-500"
            onClick={handleClearSearch}
          />
        )}
      </div>
      {noResult && (
        <>
          {query.length > 0 ? (
            <div className="py-4 text-center text-xs text-stone-500">
              {t("generic.noSearchResults", { query })}
            </div>
          ) : (
            <div className="flex justify-center py-3">
              <Spinner className="h-6 w-6" />
            </div>
          )}
        </>
      )}
    </>
  );
};
