import { useTranslation } from "react-i18next";

import { Spinner } from "@components/ui/Spinner";

import { SmsDetails } from "@features/messages/components/SmsDetails";
import { SmsSenderNameForm } from "@features/messages/components/SmsSenderNameForm";

import { useSalonSmsDetailsStore } from "@features/salon/hooks/use-salon-sms-details-store";

import { FormSection } from "@/components/layout/FormSection";

import { SmsTemplates } from "./SmsTemplates";

export const SmsSettings = () => {
  const { t } = useTranslation();
  const { salonSmsDetails, fetchLoading } = useSalonSmsDetailsStore();

  return (
    <div className="grid gap-2 divide-y-[1px]">
      <FormSection
        headingText={t("onboarding.salonSmsDetails.title")}
        descriptionText={t("smsSettings.smsSenderNameDescription")}>
        {fetchLoading ? (
          <Spinner />
        ) : (
          salonSmsDetails?.senderName && (
            <SmsSenderNameForm
              senderName={salonSmsDetails?.senderName}
              senderNameApproved={salonSmsDetails?.senderNameApproved}
            />
          )
        )}
      </FormSection>
      <FormSection
        headingText={t("smsSettings.sendingSmsRules")}
        descriptionText={t("smsSettings.sendingSmsRulesDescription")}>
        <SmsDetails />
      </FormSection>
      <FormSection
        headingText={t("settings.smsTemplates")}
        descriptionText={t("messages.templates.description")}>
        <SmsTemplates />
      </FormSection>
    </div>
  );
};
