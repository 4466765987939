import i18n from "@/lib/i18n";
import { Treatment } from "@/types";

import { CONVERT_PRICE_MULTIPLIER } from "../constants";

export const integerToPrice = (value: number) => value / CONVERT_PRICE_MULTIPLIER;
export const priceToInteger = (value: number) => value * CONVERT_PRICE_MULTIPLIER;

export const formatPrice = ({
  price,
  currency = "PLN",
  convertFromInteger = true,
}: {
  price: number;
  currency?: string;
  convertFromInteger?: boolean;
}) => {
  return new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency,
  }).format(convertFromInteger ? integerToPrice(price) : price);
};

/**
 * @example { isExactPrice: true, price: 1000 }
 * // 10,00 zł
 * @example { isExactPrice: false, priceFrom: 1000, priceTo: 2000 }
 * // 10,00 zł - 20,00 zł
 */
export const formatTreatmentPrice = ({
  isExactPrice,
  priceFrom,
  priceTo,
  price,
}: Pick<Treatment, "isExactPrice" | "priceFrom" | "priceTo" | "price">): string | null => {
  if (isExactPrice) {
    if (!price) return null;

    return formatPrice({ price });
  }

  if (!priceFrom || !priceTo) return null;

  return `${formatPrice({ price: priceFrom })} - ${formatPrice({
    price: priceTo,
  })}`;
};
