import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";

import { DATE_FORMAT } from "@/constants/date-formats";

import "@/lib/react-datepicker.css";

export const DatePicker = (props: ReactDatePickerProps & { borderless?: boolean }) => {
  return (
    <ReactDatePicker
      {...props}
      calendarClassName={props.borderless ? "borderless" : ""}
      dateFormat={props.dateFormat || DATE_FORMAT.DEFAULT}
      formatWeekDay={(date) => date.toString().substr(0, 1).toUpperCase()}
      popperModifiers={[
        {
          name: "arrow",
          options: {
            padding: ({ popper, reference }) => ({
              right: Math.min(popper.width, reference.width) - 24,
            }),
          },
        },
      ]}
    />
  );
};
