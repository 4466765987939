import {
  AccountNameUpdateInput,
  AccountUpdateInput,
  FeedbackInput,
  namedOperations,
} from "@/types";

import { useSendFeedbackMutation } from "../mutations/SendFeedback.generated";
import { useUpdateAccountMutation } from "../mutations/UpdateAccount.generated";
import { useUpdateAccountNameMutation } from "../mutations/UpdateAccountName.generated";

export const useAccountStore = () => {
  const [updateAccount, { loading: loadingUpdateAccount, error: updateAccountError }] =
    useUpdateAccountMutation();
  const [updateAccountName, { loading: loadingUpdateAccountName, error: updateAccountNameError }] =
    useUpdateAccountNameMutation();
  const [sendFeedback, { loading: loadingSendFeedback }] = useSendFeedbackMutation();

  return {
    loadingUpdateAccount,
    loadingUpdateAccountName,
    loadingSendFeedback,
    updateAccountError,
    updateAccountNameError,
    updateAccount(data: AccountUpdateInput) {
      return updateAccount({
        variables: { data },
        refetchQueries: [namedOperations.Query.CurrentSession],
      });
    },
    updateAccountName(data: AccountNameUpdateInput) {
      return updateAccountName({
        variables: { data },
        refetchQueries: [namedOperations.Query.CurrentSession],
      });
    },
    sendFeedback(data: FeedbackInput) {
      return sendFeedback({
        variables: { data },
      });
    },
  };
};
