import { formatDuration } from "date-fns";

/** 3600 -> 1 hour */
export const formatTreatmentDuration = (duration: number, language: string) => {
  const formattedDuration = formatDuration({
    minutes: Math.floor(duration / 60) % 60,
    hours: Math.floor(duration / 60 / 60),
  });

  // workaround for polish poor pluralization that omits 1 hour in date-fns
  if (language === "pl" && formattedDuration.includes("godzina")) {
    return formattedDuration.replace("godzina", "1 godzina");
  }

  return formattedDuration;
};
