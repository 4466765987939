import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TagFormProps } from "@/features/clients/components/tags/TagFormDialog";
import { useToasts } from "@/providers/ToastsProvider";
import { ClientTagInput } from "@/types";

import { useTagStore } from "./use-tag-store";

export const useTagForm = ({ action, tagUuid, onClose, defaultValues, onSubmit }: TagFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const form = useForm<ClientTagInput>({
    defaultValues,
  });

  const { createTag, updateTag, deleteTag, isMutationLoading } = useTagStore();

  const handleTagDelete = async () => {
    const { errors } = await deleteTag(tagUuid);

    if (!errors) {
      onClose();
      showToast({
        title: t("tags.toasts.deleted"),
      });
    }
  };

  const handleTagSubmit: SubmitHandler<ClientTagInput> = async (data) => {
    if (action === "create") {
      const { data: tagMutation, errors } = await createTag(data);

      if (!errors) {
        onClose();
        showToast({
          title: t("tags.toasts.created"),
        });
      }

      if (tagMutation?.createClientTag && onSubmit) {
        onSubmit(tagMutation?.createClientTag);
      }
    }

    if (action === "update") {
      const { data: tagMutation, errors } = await updateTag(data, tagUuid);

      if (!errors) {
        onClose();
        showToast({
          title: t("tags.toasts.updated"),
        });

        if (tagMutation?.updateClientTag && onSubmit) {
          onSubmit(tagMutation?.updateClientTag);
        }
      }
    }
  };

  return {
    isMutationLoading,
    form,
    handleTagDelete,
    handleTagSubmit,
  };
};
