import { ComponentType, ReactNode } from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import * as ToastPrimitive from "@radix-ui/react-toast";
import { t } from "i18next";

import { SyncSessionProvider } from "@/providers/SyncSessionProvider";

import { ToastsProvider } from "./ToastsProvider";

type Provider = {
  component: ComponentType<any>;
  props: Record<string, unknown>;
};

const combineComponents = (...components: Provider[]) => {
  return components.reduce(
    (AccumulatedComponents, { component: CurrentComponent, props }) => {
      const NextComponent = ({ children }: { children: ReactNode }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent {...props}>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
      return NextComponent;
    },
    ({ children }: { children: ReactNode }) => <>{children}</>,
  );
};

const providers = [
  {
    component: ToastPrimitive.Provider,
    props: {
      label: t("generic.notification"),
    },
  },
  {
    component: ToastsProvider,
    props: {},
  },
  {
    component: Auth0Provider,
    props: {
      domain: import.meta.env.VITE_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
      redirectUri: window.location.origin,
      scope: "read:current_user",
      audience: import.meta.env.VITE_API_URL,
      useRefreshTokens: true,
      cacheLocation: "localstorage",
    },
  },
  {
    component: SyncSessionProvider,
    props: {},
  },
];

export const AppNestedProviders = combineComponents(...providers);
