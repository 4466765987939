import {
  SALON_PROFILE_LINKS,
  SALON_PROFILE_SOCIALS,
} from "@/features/salon/constants/salonProfile";
import { MaxLeadTimeOptionsInDays, MinLeadTimeOptionsInMinutes } from "@/types";

import type { SalonProfileLink, SalonProfileSocial } from "../models/salonProfile";

export const isSalonSocial = (value: any): value is SalonProfileSocial => {
  return SALON_PROFILE_SOCIALS.includes(value);
};

export const isSalonLink = (value: any): value is SalonProfileLink => {
  return SALON_PROFILE_LINKS.includes(value);
};

export const SELF_BOOKING_MIN_LEAD_TIME_OPTIONS = [
  MinLeadTimeOptionsInMinutes.Minutes_15,
  MinLeadTimeOptionsInMinutes.Minutes_30,
  MinLeadTimeOptionsInMinutes.Minutes_60,
  MinLeadTimeOptionsInMinutes.Minutes_120,
  MinLeadTimeOptionsInMinutes.Minutes_180,
  MinLeadTimeOptionsInMinutes.Minutes_360,
  MinLeadTimeOptionsInMinutes.Minutes_720,
  MinLeadTimeOptionsInMinutes.Minutes_1440,
  MinLeadTimeOptionsInMinutes.Minutes_2880,
  MinLeadTimeOptionsInMinutes.Minutes_4320,
  MinLeadTimeOptionsInMinutes.Minutes_7200,
  MinLeadTimeOptionsInMinutes.Minutes_10080,
  MinLeadTimeOptionsInMinutes.Minutes_14400,
  MinLeadTimeOptionsInMinutes.Minutes_20160,
];

export const SELF_BOOKING_MAX_LEAD_TIME_OPTIONS = [
  MaxLeadTimeOptionsInDays.Days_7,
  MaxLeadTimeOptionsInDays.Days_14,
  MaxLeadTimeOptionsInDays.Days_30,
  MaxLeadTimeOptionsInDays.Days_60,
  MaxLeadTimeOptionsInDays.Days_90,
  MaxLeadTimeOptionsInDays.Days_180,
  MaxLeadTimeOptionsInDays.Days_365,
  MaxLeadTimeOptionsInDays.Days_730,
];
