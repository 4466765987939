import { useEffect } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";

import setDefaultOptions from "date-fns/setDefaultOptions";

import { importDateFnsLocale } from "@/lib/i18n";

export const useDefaultDateOptions = () => {
  const { i18n } = useTranslation();

  const setDefaults = async () => {
    try {
      const locale = (await importDateFnsLocale(i18n.language)) as Locale;

      setDefaultOptions({ locale, weekStartsOn: 1 });
      registerLocale(i18n.language, locale);
      setDefaultLocale(i18n.language);
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    setDefaults();
  }, [i18n.language]);
};
