import { ReactNode, ReactElement, cloneElement } from "react";
import { useTranslation } from "react-i18next";

import { Transition } from "@headlessui/react";

import {
  CheckCircleIcon,
  XMarkIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import * as ToastPrimitive from "@radix-ui/react-toast";

import { cn } from "@/utils/utils";

export type ToastProps = {
  title?: string;
  description?: ReactNode;
  type?: "success" | "warning" | "info" | "error";
  duration?: number;
  open: boolean;
  actionIcons?: ReactElement[];
  manualClose?: boolean;
  setOpen?: (open: boolean) => void;
  onClose?: () => void;
};

export const Toast = ({
  title,
  description,
  type = "success",
  duration,
  actionIcons,
  open,
  manualClose = false,
  setOpen,
  onClose,
}: ToastProps) => {
  const { t } = useTranslation();

  const handleOpenChange: React.Dispatch<React.SetStateAction<boolean>> = (updaterOrValue) => {
    const isOpen = typeof updaterOrValue === "function" ? updaterOrValue(true) : updaterOrValue;
    setOpen && setOpen(isOpen);
    !isOpen && onClose && onClose();
  };

  return (
    <Transition
      show={open}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0">
      <ToastPrimitive.Root
        className={cn("flex items-center gap-4 rounded-lg px-6 py-4 text-stone-700 shadow-md", {
          "bg-green-50 text-green-800": type === "success",
          "bg-gold-50 text-gold-800": type === "warning",
          "bg-blue-50 text-blue-800": type === "info",
          "bg-red-50 text-red-800": type === "error",
        })}
        open={open}
        duration={duration}
        onOpenChange={(open) => !manualClose && handleOpenChange(open)}>
        <div className="w-full">
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex items-start gap-2">
              {type === "warning" && (
                <ExclamationTriangleIcon
                  className="mt-0.5 h-4 w-4 text-gold-800"
                  aria-hidden="true"
                />
              )}
              {type === "error" && (
                <XCircleIcon className="mt-0.5 h-4 w-4 text-red-800" aria-hidden="true" />
              )}
              {type === "success" && (
                <CheckCircleIcon className="mt-0.5 h-4 w-4 text-green-800" aria-hidden="true" />
              )}
              {type === "info" && (
                <InformationCircleIcon
                  className="mt-0.5 h-4 w-4 text-blue-800"
                  aria-hidden="true"
                />
              )}
              <div className="flex gap-4">
                {title && (
                  <ToastPrimitive.Title className="inline-flex w-full text-sm font-medium">
                    {title}
                  </ToastPrimitive.Title>
                )}
              </div>
            </div>
            <div className={"flex items-center gap-4"}>
              {actionIcons?.map((icon, index) =>
                cloneElement(icon, { className: "w-4 h-4 cursor-pointer", key: index }),
              )}
              <ToastPrimitive.Close
                aria-label={t("generic.close")}
                onClick={() => manualClose && handleOpenChange(false)}>
                <XMarkIcon className="h-4 w-4" />
              </ToastPrimitive.Close>
            </div>
          </div>
          {description && (
            <ToastPrimitive.Description
              className={cn("mt-2 text-sm", {
                "text-green-700": type === "success",
                "text-gold-700": type === "warning",
                "text-blue-700": type === "info",
                "text-red-700": type === "error",
              })}>
              {description}
            </ToastPrimitive.Description>
          )}
        </div>
      </ToastPrimitive.Root>
    </Transition>
  );
};
