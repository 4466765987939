import { useContext } from "react";

import { usePaymentsStore } from "@/features/payments/hooks";
import { SubscriptionsContext } from "@/features/payments/providers/SubscriptionsProvider";

export const useProductsStore = () => {
  const { salonTier } = usePaymentsStore();
  const contextValue = useContext(SubscriptionsContext);

  if (!contextValue) {
    throw new Error("Subscriptions context wasn't provided");
  }

  const { product, setProduct, loading } = contextValue;

  return {
    product,
    setProduct,
    orderSalonPlan: () => salonTier && setProduct(salonTier),
    loading,
  };
};
