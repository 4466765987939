import { NetworkStatus } from "@apollo/client";

import { useFetchSentSmsQuery } from "../queries/FetchSentSms.generated";
export const useSentSmsStore = (clientUuid: string) => {
  const { data, networkStatus, fetchMore } = useFetchSentSmsQuery({
    variables: {
      first: 3,
      clientUuid,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    loading: networkStatus === NetworkStatus.loading,
    sentSms: data?.fetchSentSms.edges.map(({ node }) => node) || [],
    hasMore: !!data?.fetchSentSms.pageInfo.hasNextPage,
    fetchMore() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data?.fetchSentSms.pageInfo.endCursor,
          },
        });
      }
    },
  };
};
