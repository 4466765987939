import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddHeadCategoryMutationVariables = Types.Exact<{
  data: Types.AddHeadCategoryInput;
}>;


export type AddHeadCategoryMutation = { __typename?: 'RootMutationType', addHeadCategory: { __typename?: 'HeadCategory', insertedAt?: any | null } };


export const AddHeadCategoryDocument = gql`
    mutation AddHeadCategory($data: AddHeadCategoryInput!) {
  addHeadCategory(data: $data) {
    insertedAt
  }
}
    `;
export type AddHeadCategoryMutationFn = Apollo.MutationFunction<AddHeadCategoryMutation, AddHeadCategoryMutationVariables>;

/**
 * __useAddHeadCategoryMutation__
 *
 * To run a mutation, you first call `useAddHeadCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHeadCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHeadCategoryMutation, { data, loading, error }] = useAddHeadCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddHeadCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddHeadCategoryMutation, AddHeadCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHeadCategoryMutation, AddHeadCategoryMutationVariables>(AddHeadCategoryDocument, options);
      }
export type AddHeadCategoryMutationHookResult = ReturnType<typeof useAddHeadCategoryMutation>;
export type AddHeadCategoryMutationResult = Apollo.MutationResult<AddHeadCategoryMutation>;
export type AddHeadCategoryMutationOptions = Apollo.BaseMutationOptions<AddHeadCategoryMutation, AddHeadCategoryMutationVariables>;