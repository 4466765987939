import { useState } from "react";

import { ImagePreview } from "./ImagePreview";

type ImagesGalleryProps = {
  imagesUrls: { imageUrl: string }[];
};

export const ImagesGallery = ({ imagesUrls }: ImagesGalleryProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImageIndex, setPreviewImageIndex] = useState(0);

  return (
    <>
      <div className="mt-1 flex flex-wrap gap-3 sm:grid sm:grid-cols-4">
        {imagesUrls.map((image, index) => (
          <div
            key={image.imageUrl}
            onClick={() => {
              setPreviewImageIndex(index);
              setIsPreviewOpen(true);
            }}
            className="group relative aspect-[3/2] max-h-24 max-w-full flex-shrink-0 cursor-pointer rounded-md border border-stone-300 bg-stone-100">
            <img
              crossOrigin="anonymous"
              className="h-full w-full rounded-md object-cover"
              src={image.imageUrl}
            />
          </div>
        ))}
      </div>
      {isPreviewOpen && (
        <ImagePreview
          images={(imagesUrls && imagesUrls.map((image) => image.imageUrl)) || []}
          initialIndex={previewImageIndex}
          onClose={(e: Event | React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setIsPreviewOpen(false);
          }}
        />
      )}
    </>
  );
};
