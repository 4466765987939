import Appsignal from "@appsignal/javascript";
import { plugin as bcPlugin } from "@appsignal/plugin-breadcrumbs-console";
import { plugin as pdPlugin } from "@appsignal/plugin-path-decorator";
import { plugin as wePlugin } from "@appsignal/plugin-window-events";

export const appsignal = new Appsignal({
  key: import.meta.env.VITE_APPSIGNAL_API_KEY,
});

appsignal.use(bcPlugin());
appsignal.use(wePlugin());
appsignal.use(pdPlugin());
