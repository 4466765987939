import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { Image } from "@/hooks/use-aws-store";
import { validateImages } from "@/utils/form";
import { cn } from "@/utils/utils";

type ImagesUploaderProps = {
  images: Image[];
  addImages: (files: FileList) => void;
  imagesErrors: string[];
  setImagesErrors: (errors: string[]) => void;
  removeImage: (imageName: string) => void;
  getServerImagesSize?: () => Promise<number>;
  handleRemoveFromServer?: (imageName: string) => void;
  showGalleryLabel?: boolean;
  multiple?: boolean;
  className?: string;
  acceptableImageSizeInMB?: string;
};

export const ImagesUploader = ({
  images,
  addImages,
  imagesErrors,
  setImagesErrors,
  removeImage,
  getServerImagesSize,
  handleRemoveFromServer,
  showGalleryLabel = true,
  multiple = true,
  acceptableImageSizeInMB = "10",
  className,
}: ImagesUploaderProps) => {
  const { t } = useTranslation();

  const handleImageUploadClick = () => {
    const fileInput = document.getElementById("image-upload") as HTMLInputElement;
    fileInput.click();
  };

  const handleFileRemove = (imageName: string) => {
    removeImage(imageName);

    const fileInput = document.getElementById("image-upload") as HTMLInputElement;
    fileInput.value = "";
  };

  const handleImageInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setImagesErrors([]);
    const files = e.target.files;
    let serverImagesSize = 0;
    const hasServerImages = images.some((image) => image.isServerImage);

    if (hasServerImages && getServerImagesSize) {
      serverImagesSize = await getServerImagesSize();
    }

    if (!files) return;

    const errors = validateImages(
      files,
      localImagesSize + serverImagesSize,
      +acceptableImageSizeInMB * 1000000,
    );

    if (errors?.length === 0) {
      addImages(files);
    } else {
      setImagesErrors(errors);
    }
  };

  if (imagesErrors.length > 0) {
    setTimeout(() => {
      setImagesErrors([]);
    }, 3000);
  }

  const localImagesSize = useMemo(() => {
    return images.reduce((acc, image) => {
      if (!image.isServerImage) {
        return acc + image.file.size;
      }
      return acc;
    }, 0);
  }, [images]);

  const visibleImages = useMemo(() => {
    return images.filter((image) => !image.toRemove);
  }, [images]);

  return (
    <div className="mt-2">
      <div className="flex items-center gap-2">
        <Button
          size="tiny"
          variant="secondary-outline"
          startIcon={<PlusIcon />}
          onClick={handleImageUploadClick}
          disabled={!multiple && images.filter((image) => !image.toRemove).length > 0}>
          {multiple ? t("actions.uploadImages") : t("actions.uploadImage")}
        </Button>
        {imagesErrors.length > 0 ? (
          <span className="text-xs text-red-600">{imagesErrors[0]}</span>
        ) : (
          <span className="text-xs text-stone-400">
            {t("generic.imagesFormat", {
              size: acceptableImageSizeInMB,
            })}
          </span>
        )}
      </div>
      <input
        id="image-upload"
        accept="image/*"
        className="sr-only"
        type="file"
        multiple={multiple}
        onChange={handleImageInputChange}
      />

      {visibleImages.length > 0 && (
        <div className="mt-4">
          {showGalleryLabel && (
            <label className="block text-xs text-stone-500">{t("generic.images")}</label>
          )}
          <div className="mt-1 flex flex-wrap justify-start gap-3 sm:grid sm:grid-cols-4">
            {visibleImages.map((image) => (
              <Fragment key={image.name}>
                {image.isServerImage ? (
                  <div
                    className={cn(
                      "group relative aspect-[3/2] max-h-24 max-w-full flex-shrink-0 rounded-md border border-stone-300 bg-stone-100",
                      className,
                    )}>
                    <img
                      crossOrigin="anonymous"
                      id="appointment-image"
                      className="h-full w-full rounded-md object-cover"
                      alt=""
                      src={image.url}
                    />
                    <Button
                      size="tiny"
                      variant="neutral"
                      className="absolute right-1 top-1 rounded-md bg-stone-100 p-1 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                      onClick={() => handleRemoveFromServer && handleRemoveFromServer(image.name)}>
                      <TrashIcon className="h-4 w-4 cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                    </Button>
                  </div>
                ) : (
                  <div
                    className={cn(
                      "group relative aspect-[3/2] max-h-24 max-w-full flex-shrink-0 rounded-md border border-stone-300 bg-stone-100",
                      className,
                    )}>
                    <img
                      crossOrigin="anonymous"
                      id="logo-preview"
                      className="h-full w-full rounded-md object-cover"
                      src={URL.createObjectURL(image.file)}
                    />
                    <Button
                      size="tiny"
                      variant="neutral"
                      className="absolute right-1 top-1 rounded-md bg-stone-100 p-1 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                      onClick={() => handleFileRemove(image.name)}>
                      <TrashIcon className="h-4 w-4 cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
                    </Button>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
