import { useState, ReactNode } from "react";

type ConfirmationDialogConfig<Result> = {
  title: string;
  message: ReactNode;
  confirmLabel?: string;
  denyLabel?: string;
  onClose?: (result: Result) => void;
  onConfirm?: () => void;
  onDeny?: () => void;
};

type ConfirmDialogStateInternal<Result> = ConfirmationDialogConfig<Result> & {
  open: boolean;
};

export const useConfirmationDialog = <Result>() => {
  const [state, setState] = useState({
    open: false,
    title: "",
    message: null,
    onClose: () => {},
    onConfirm: () => {},
    onDeny: () => {},
  } as ConfirmDialogStateInternal<Result>);

  return {
    open(config: ConfirmationDialogConfig<Result>) {
      setState({
        ...config,
        open: true,
      });
    },
    close() {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    },
    internalState: state,
  };
};

export type ConfirmationDialogProps<Result> = {
  dialogState: ReturnType<typeof useConfirmationDialog<Result>>;
  children?: ReactNode;
};
