import { Fragment, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Listbox } from "@headlessui/react";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";

import { Float } from "@headlessui-float/react";
import clsx from "clsx";

import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import {
  AppointmentDetailsDialog,
  AppointmentDetailsProps,
} from "@/features/calendar/components/Appointment/AppointmentDetailsDialog";
import { useAppointmentsStore } from "@/features/calendar/hooks/use-appointments-store";
import { useDialog, bindDialogState } from "@/hooks/use-dialog";
import { AppointmentStatus } from "@/types";
import { Option } from "@/utils/select-utils";

import { ClientAppointment } from "./ClientAppointment";

type ClientAppointmentProps = {
  clientUuid: string;
};

export const ClientAppointments = ({ clientUuid }: ClientAppointmentProps) => {
  const { t } = useTranslation();

  const detailsDialogState = useDialog<AppointmentDetailsProps>();
  const [filters, setFilters] = useState({
    clientUuid,
  });

  const { appointmentsPaginated, loadingAppointmentsPaginated, hasNextPage, fetchMore } =
    useAppointmentsStore({
      filters: { ...filters },
      after: null,
    });

  const VISITS_FILTERS: Option[] = useMemo(
    () => [
      {
        label: t("appointments.filters.all"),
        value: t("appointments.filters.all"),
        props: {
          index: 0,
          type: "all",
          filters: {
            statuses: [
              AppointmentStatus.Scheduled,
              AppointmentStatus.Completed,
              AppointmentStatus.Closed,
              AppointmentStatus.Canceled,
              AppointmentStatus.CanceledByClient,
              AppointmentStatus.Requested,
            ],
            timeRange: {
              from: undefined,
              to: undefined,
            },
          },
        },
      },
      {
        label: t("appointments.filters.scheduled"),
        value: t("appointments.filters.scheduled"),
        props: {
          index: 1,
          type: "upcoming",
          filters: {
            statuses: [AppointmentStatus.Scheduled],
            timeRange: {
              from: new Date().toISOString(),
              to: undefined,
            },
          },
        },
      },
      {
        label: t("appointments.filters.requested"),
        value: t("appointments.filters.requested"),
        props: {
          index: 2,
          type: "requested",
          filters: {
            statuses: [AppointmentStatus.Requested],
            timeRange: {
              from: undefined,
              to: undefined,
            },
          },
        },
      },
      {
        label: t("appointments.filters.unfinalized"),
        value: t("appointments.filters.unfinalized"),
        props: {
          index: 3,
          type: "unfinalized",
          filters: {
            statuses: [AppointmentStatus.Scheduled],
            timeRange: {
              from: undefined,
              to: new Date().toISOString(),
            },
          },
        },
      },
      {
        label: t("appointments.filters.completed"),
        value: t("appointments.filters.completed"),
        props: {
          index: 4,
          type: "ended",
          filters: {
            statuses: [AppointmentStatus.Completed, AppointmentStatus.Closed],
            timeRange: {
              from: undefined,
              to: undefined,
            },
          },
        },
      },
      {
        label: t("appointments.filters.canceled"),
        value: t("appointments.filters.canceled"),
        props: {
          index: 5,
          type: "canceled",
          filters: {
            statuses: [AppointmentStatus.Canceled, AppointmentStatus.CanceledByClient],
            timeRange: {
              from: undefined,
              to: undefined,
            },
          },
        },
      },
    ],
    [appointmentsPaginated],
  );

  const [selectedOption, setSelectedOption] = useState(VISITS_FILTERS[0]);

  useEffect(() => {
    if (clientUuid) {
      setFilters((filters) => ({ ...filters, clientUuid }));
    }
  }, [clientUuid]);

  const fetchAppointmentsByType = (index: number) => {
    setFilters((filters) => ({ ...filters, ...VISITS_FILTERS[index].props.filters }));
  };

  return (
    <section className="flex flex-1 flex-col">
      <div className="-mx-4 flex border-b border-stone-200 px-4 py-4">
        <Listbox
          value={selectedOption}
          onChange={(data) => {
            setSelectedOption(data);
            fetchAppointmentsByType(data.props.index);
          }}>
          <div className="relative w-full">
            <Float
              as="div"
              className="relative w-full"
              placement="bottom"
              offset={4}
              flip={10}
              zIndex={10}
              floatingAs={Fragment}>
              <Listbox.Button className="relative flex h-11 w-full appearance-none items-center rounded-md border border-stone-300 px-3 text-left text-sm focus:outline-none focus:ring-stone-400">
                <span className="block truncate">{selectedOption.label}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-stone-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              <Listbox.Options className="mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-stone-300 focus:outline-none">
                {VISITS_FILTERS.map((option, index) => (
                  <Listbox.Option key={index} as={Fragment} value={option}>
                    {({ active }) => (
                      <li
                        className={clsx(
                          "relative cursor-pointer select-none px-4 py-2 text-stone-600",
                          {
                            "bg-stone-50 text-stone-700": active,
                            "bg-stone-100": selectedOption.value === option.value,
                          },
                        )}>
                        {option.label}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Float>
          </div>
        </Listbox>
      </div>
      <div className="-mx-4 flex flex-1 flex-col text-center text-sm @container">
        <div className="flex flex-1 flex-col">
          {loadingAppointmentsPaginated ? (
            <div className="py-12">
              <Spinner />
            </div>
          ) : appointmentsPaginated.length === 0 && !loadingAppointmentsPaginated ? (
            <div className="py-12">{t("generic.noData")}</div>
          ) : (
            <div className="flex max-h-fit flex-1 flex-col gap-4 overflow-y-scroll sm:max-h-[60vh]">
              <div className="divide-y divide-stone-100 py-2">
                {appointmentsPaginated.map((appointment) => (
                  <ClientAppointment key={appointment.appointmentUuid} appointment={appointment} />
                ))}
              </div>
              {hasNextPage && (
                <div className="mt-auto p-4">
                  <Button
                    variant="primary-outline"
                    fullWidth
                    size="small"
                    loading={loadingAppointmentsPaginated}
                    onClick={() => {
                      fetchMore();
                    }}>
                    {t("generic.loadMore")}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <AppointmentDetailsDialog {...bindDialogState(detailsDialogState)} />
    </section>
  );
};
