import type { ServiceCategoryColor } from "@features/services/models";

import { AppointmentStatus } from "@/types";

import { CalendarViews } from "../models";

export const LIMIT_PER_MONTH = 50;
export const DANGER_LIMIT = 5;
export const WARNING_LIMIT = 20;

export const SCALE_MINUTES_PER_STEP = 5;
export const SCALE_STEPS_PER_DAY = (60 / SCALE_MINUTES_PER_STEP) * 24;
export const DEFAULT_APPOINTMENT_DURATION_SEC = 1800; // 30 minutes
export const DEFAULT_APPOINTMENT_DURATION_STEPS =
  DEFAULT_APPOINTMENT_DURATION_SEC / 60 / SCALE_MINUTES_PER_STEP; // 30 minutes

export const MINUTES_PER_DAY = 24 * 60;
export const AUTOSCROLL_OFFSET = 30; // value in minutes - top offset for auto scroll when we entering calendar day/week view

export const TOUCH_EVENT_TIME_LIMIT = 2000; // clear start position after 2000 mil, so that it is not treated as click
export const TOUCH_EVENT_DISTANCE_LIMIT = 20; // distance between start & end point after which event is not treated as click

export const FINALIZED_COLOR: ServiceCategoryColor = {
  primary: "bg-stone-200",
  secondary: "bg-stone-50",
  tertiary: "bg-stone-100",
  border: "border-stone-200",
  primaryText: "text-stone-900",
};

export const ATTENTION_COLOR: ServiceCategoryColor = {
  primary: "bg-red-600",
  secondary: "bg-red-50",
  tertiary: "bg-red-100",
  border: "border-red-600",
  primaryText: "text-white",
  secondaryText: "text-red-900",
};

export const APPOINTMENT_STATUSES_EXTERNAL_CLIENT: AppointmentStatus[] = [
  AppointmentStatus.Requested,
  AppointmentStatus.CanceledByClient,
];

export const GENERAL_CALENDAR_VIEWS: CalendarViews[] = [
  CalendarViews.Day,
  CalendarViews.Week,
  CalendarViews.Month,
];

export const EMPLOYEE_CALENDAR_VIEWS: CalendarViews[] = [
  CalendarViews.EmployeeDay,
  CalendarViews.EmployeeWeek,
];

export const NUMBER_OF_DAYS_IN_WEEK = 7;
