import { TypePolicy } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

const fetchSalonEquipmentItems: TypePolicy = relayStylePagination();

export const EquipmentItem: TypePolicy = {
  fields: {},
};

export const itemsQueryFields = { fetchSalonEquipmentItems };
