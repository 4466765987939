import { useTranslation } from "react-i18next";

import { Alert } from "@/components/ui/Alert";
import { formatPrice } from "@/features/payments/utils";
import { useSalonPaymentMethodsStore } from "@/features/salon/hooks/use-salon-payment-methods-store";
import { Appointment } from "@/types";

type AppointmentFinalizationSummaryProps = {
  appointment: Appointment;
};

export const AppointmentFinalizationSummary = ({
  appointment,
}: AppointmentFinalizationSummaryProps) => {
  const { t } = useTranslation();
  const { salonPaymentMethods } = useSalonPaymentMethodsStore();

  const selectedPaymentMethod = salonPaymentMethods?.find(
    (method) => method.uuid === appointment.paymentMethodUuid,
  );

  return (
    <Alert type="success" textSize="xs">
      <div>
        <span>
          {t("appointments.statusInfo.completed.description", {
            price: formatPrice({ price: appointment.totalPrice, currency: "PLN" }),
          })}{" "}
        </span>
        {appointment.paymentMethodUuid !== null ? (
          <span>
            {t(`appointments.statusInfo.completed.paymentMethod.${selectedPaymentMethod?.name}`)}
          </span>
        ) : (
          <span>{t("appointments.statusInfo.completed.paymentMethod.noPaymentMethod")}</span>
        )}
      </div>
    </Alert>
  );
};
