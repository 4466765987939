import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  ListBulletIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import { useUserAgent } from "@/hooks/use-user-agent";
import { cn } from "@/utils/utils";

type BottomNavigationProps = {
  openSidebar?: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
};

type BottomNavigationItem = {
  icon: JSX.Element;
  label: string;
  id: string;
  href?: string;
  action?: () => void;
};

export const BottomNavigation = ({ openSidebar, isOpen }: BottomNavigationProps) => {
  const { t } = useTranslation();
  const { isIOS } = useUserAgent();

  const NAVIGATION_ITEMS: BottomNavigationItem[] = [
    {
      icon: <ListBulletIcon className="size-6" />,
      label: t("generic.menu"),
      action: () => openSidebar && openSidebar(!isOpen),
      id: "bottom_navigation_menu",
    },
    {
      icon: <CalendarDaysIcon className="size-6" />,
      label: t("generic.calendar"),
      href: "/calendar",
      id: "bottom_navigation_calendar",
    },
    {
      icon: <UserGroupIcon className="size-6" />,
      label: t("generic.clients"),
      href: "/clients",
      id: "bottom_navigation_clients",
    },
    {
      icon: <BuildingStorefrontIcon className="size-6" />,
      label: t("generic.salon"),
      href: "/salon",
      id: "bottom_navigation_salon",
    },
  ];

  return (
    <div
      className={cn(
        isIOS ? "h-12" : "h-16",
        "fixed bottom-0 left-0 right-0 z-50 rounded-t-xl bg-gold-100 pt-3 md:hidden",
      )}>
      <ul className="flex text-xs text-stone-500">
        {NAVIGATION_ITEMS.map((item) => (
          <div key={item.id} className="flex-1">
            <div className="flex flex-col items-center">
              {item.action ? (
                <button onClick={item.action} className="flex flex-col items-center justify-center">
                  {item.icon}
                  <span>{item.label}</span>
                </button>
              ) : (
                <NavLink
                  to={item.href ?? "#"}
                  className={({ isActive }) =>
                    cn(
                      "flex flex-col items-center justify-center",
                      isActive ? "text-gold-600" : "text-stone-500",
                    )
                  }>
                  {item.icon}
                  <span>{item.label}</span>
                </NavLink>
              )}
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};
