import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSalonMutationVariables = Types.Exact<{
  data: Types.UpdateSalonInput;
}>;


export type UpdateSalonMutation = { __typename?: 'RootMutationType', updateSalon: { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: Types.PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: any | null, description?: string | null, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: Types.MinLeadTimeOptionsInMinutes, maxLeadTime: Types.MaxLeadTimeOptionsInDays } } };


export const UpdateSalonDocument = gql`
    mutation UpdateSalon($data: UpdateSalonInput!) {
  updateSalon(data: $data) {
    salonUuid
    name
    phoneType
    phone
    mobile
    email
    city
    links
    logoImageUrl
    backgroundImageUrl
    postalCode
    street
    streetNumber
    socials
    description
    slug
    selfBooking {
      enabled
      slotDuration
      automaticalSlotApproval
      automaticalSlotApprovalSms
      minLeadTime
      maxLeadTime
    }
  }
}
    `;
export type UpdateSalonMutationFn = Apollo.MutationFunction<UpdateSalonMutation, UpdateSalonMutationVariables>;

/**
 * __useUpdateSalonMutation__
 *
 * To run a mutation, you first call `useUpdateSalonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalonMutation, { data, loading, error }] = useUpdateSalonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSalonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalonMutation, UpdateSalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalonMutation, UpdateSalonMutationVariables>(UpdateSalonDocument, options);
      }
export type UpdateSalonMutationHookResult = ReturnType<typeof useUpdateSalonMutation>;
export type UpdateSalonMutationResult = Apollo.MutationResult<UpdateSalonMutation>;
export type UpdateSalonMutationOptions = Apollo.BaseMutationOptions<UpdateSalonMutation, UpdateSalonMutationVariables>;