import { useEffect, useReducer } from "react";

export type SidebarState = {
  isOpen: boolean;
  isShrinked: boolean;
};

export type SidebarAction = { type: "TOGGLE_OPEN" } | { type: "SET_SHRINKED"; isShrinked: boolean };

enum ActionType {
  TOGGLE_OPEN = "TOGGLE_OPEN",
  SET_SHRINKED = "SET_SHRINKED",
}

function reducer(state: SidebarState, action: SidebarAction): SidebarState {
  switch (action.type) {
    case ActionType.TOGGLE_OPEN:
      return { ...state, isOpen: !state.isOpen };
    case ActionType.SET_SHRINKED:
      return { ...state, isShrinked: action.isShrinked };
    default:
      return state;
  }
}

export const useSidebar = () => {
  const [state, dispatch] = useReducer(reducer, undefined, () => ({
    isOpen: false,
    isShrinked:
      typeof window !== "undefined" ? localStorage.getItem("sidebarIsShrinked") === "true" : false,
  }));

  const toggleOpen = () => {
    dispatch({ type: ActionType.TOGGLE_OPEN });
  };

  const setShrinked = (isShrinked: boolean) => {
    dispatch({ type: ActionType.SET_SHRINKED, isShrinked });
  };

  useEffect(() => {
    localStorage.setItem("sidebarIsShrinked", JSON.stringify(state.isShrinked));
  }, [state.isShrinked]);

  return { state, toggleOpen, setShrinked };
};
