import { EmployeesLedgerSetSettlementBlueprintInput, namedOperations } from "@/types";
import { Option } from "@/utils/select-utils";

import { useAddEmployeeCommissionMutation } from "../mutations/AddEmployeeCommission.generated";
import { useDeleteEmployeeCommissionMutation } from "../mutations/DeleteEmployeeCommission.generated";
import { useEmployeesLedgerBlueprintsQuery } from "../queries/EmployeesLedgerBlueprints.generated";

type UseEmployeeCommission = {
  employeeId: string;
  treatmentOptions?: Option[];
};

export const useEmployeeCommission = ({ employeeId, treatmentOptions }: UseEmployeeCommission) => {
  const { data, loading, error } = useEmployeesLedgerBlueprintsQuery({
    variables: {
      employeeId: employeeId,
    },
  });

  const [addEmployeeCommissionMutation, { loading: loadingAdd }] =
    useAddEmployeeCommissionMutation();

  const [deleteEmployeeCommissionMutation] = useDeleteEmployeeCommissionMutation();

  const commissionsBlueprintsGroupedByTreatment = data?.employeesLedgerBlueprints.reduce<
    Record<string, any[]>
  >((acc, commission) => {
    const key = commission.treatmentId || "general";

    if (!acc[key]) {
      acc[key] = [];
    }

    if (treatmentOptions && key !== "general") {
      const treatment = treatmentOptions.find((treatment) => treatment.value === key);

      if (treatment) {
        acc[key].push({
          ...commission,
          treatmentName: treatment.label,
        });
      }
    } else {
      acc[key].push(commission);
    }

    return acc;
  }, {});

  const { general, ...treatments } = commissionsBlueprintsGroupedByTreatment || {};

  return {
    hasBlueprints: !!data?.employeesLedgerBlueprints.length,
    generalBlueprints: general,
    treatmentBlueprints: treatments,
    addEmployeeCommission(data: EmployeesLedgerSetSettlementBlueprintInput) {
      return addEmployeeCommissionMutation({
        variables: {
          data,
        },
        refetchQueries: [namedOperations.Query.EmployeesLedgerBlueprints],
      });
    },
    deleteEmployeeCommissionMutation(id: string) {
      return deleteEmployeeCommissionMutation({
        variables: {
          id,
        },
        refetchQueries: [namedOperations.Query.EmployeesLedgerBlueprints],
      });
    },
    loading,
    loadingAdd,
    error,
  };
};
