import type { ServiceCategoryColor } from "../../features/services/models";

export const MIN_OPTIONS_FOR_SEARCH = 5;

export type ColorsVariations = {
  primary: string;
  secondary: string;
  tertiary: string;
  border: string;
  primaryText: string;
  secondaryText?: string;
};

export type DeprecatedColors =
  | "amber"
  | "lime"
  | "teal"
  | "cyan"
  | "sky"
  | "violet"
  | "purple"
  | "gray";

export type Colors =
  | "gold"
  | "orange"
  | "olive"
  | "zanah"
  | "green"
  | "nebula"
  | "ocean"
  | "blue"
  | "indigo"
  | "haze"
  | "snuff"
  | "fuchsia"
  | "pink"
  | "pearl"
  | "stone"
  | "red";

export const DEPRECATED_CATEGORY_COLORS: Record<DeprecatedColors, Colors> = {
  amber: "gold",
  lime: "zanah",
  teal: "ocean",
  cyan: "blue",
  sky: "haze",
  violet: "fuchsia",
  purple: "indigo",
  gray: "stone",
};

export const SERVICE_CATEGORY_COLORS: Record<Colors | string, ServiceCategoryColor> = {
  gold: {
    primary: "bg-gold-200",
    secondary: "bg-gold-50",
    tertiary: "bg-gold-100",
    border: "border-gold-200",
    primaryText: "text-gold-900",
  },
  orange: {
    primary: "bg-orange-200",
    secondary: "bg-orange-50",
    tertiary: "bg-orange-100",
    border: "border-orange-200",
    primaryText: "text-orange-900",
  },
  olive: {
    primary: "bg-olive-200",
    secondary: "bg-olive-50",
    tertiary: "bg-olive-100",
    border: "border-olive-200",
    primaryText: "text-olive-900",
  },
  zanah: {
    primary: "bg-zanah-200",
    secondary: "bg-zanah-50",
    tertiary: "bg-zanah-100",
    border: "border-zanah-200",
    primaryText: "text-zanah-900",
  },
  green: {
    primary: "bg-green-200",
    secondary: "bg-green-50",
    tertiary: "bg-green-100",
    border: "border-green-200",
    primaryText: "text-green-900",
  },
  nebula: {
    primary: "bg-nebula-200",
    secondary: "bg-nebula-50",
    tertiary: "bg-nebula-100",
    border: "border-nebula-200",
    primaryText: "text-nebula-900",
  },
  ocean: {
    primary: "bg-ocean-200",
    secondary: "bg-ocean-50",
    tertiary: "bg-ocean-100",
    border: "border-ocean-200",
    primaryText: "text-ocean-900",
  },
  blue: {
    primary: "bg-blue-200",
    secondary: "bg-blue-50",
    tertiary: "bg-blue-100",
    border: "border-blue-200",
    primaryText: "text-blue-900",
  },
  indigo: {
    primary: "bg-indigo-200",
    secondary: "bg-indigo-50",
    tertiary: "bg-indigo-100",
    border: "border-indigo-200",
    primaryText: "text-indigo-900",
  },
  haze: {
    primary: "bg-haze-200",
    secondary: "bg-haze-50",
    tertiary: "bg-haze-100",
    border: "border-haze-200",
    primaryText: "text-haze-900",
  },
  snuff: {
    primary: "bg-snuff-200",
    secondary: "bg-snuff-50",
    tertiary: "bg-snuff-100",
    border: "border-snuff-200",
    primaryText: "text-snuff-900",
  },
  fuchsia: {
    primary: "bg-fuchsia-200",
    secondary: "bg-fuchsia-50",
    tertiary: "bg-fuchsia-100",
    border: "border-fuchsia-200",
    primaryText: "text-fuchsia-900",
  },
  pink: {
    primary: "bg-pink-200",
    secondary: "bg-pink-50",
    tertiary: "bg-pink-100",
    border: "border-pink-200",
    primaryText: "text-pink-900",
  },
  pearl: {
    primary: "bg-pearl-200",
    secondary: "bg-pearl-50",
    tertiary: "bg-pearl-100",
    border: "border-pearl-200",
    primaryText: "text-pearl-900",
  },
};

type BadgeColors = {
  background: string;
  border: string;
  text: string;
};

export const BADGE_COLORS: Record<Colors | string, BadgeColors> = {
  orange: {
    background: "bg-orange-50",
    border: "border-orange-200",
    text: "text-orange-900",
  },
  green: {
    background: "bg-green-50",
    border: "border-green-200",
    text: "text-green-900",
  },
  blue: {
    background: "bg-blue-50",
    border: "border-blue-200",
    text: "text-blue-900",
  },
  indigo: {
    background: "bg-indigo-50",
    border: "border-indigo-200",
    text: "text-indigo-900",
  },
  fuchsia: {
    background: "bg-fuchsia-50",
    border: "border-fuchsia-200",
    text: "text-fuchsia-900",
  },
  stone: {
    background: "bg-stone-50",
    border: "border-stone-200",
    text: "text-stone-900",
  },
  red: {
    background: "bg-red-50",
    border: "border-red-200",
    text: "text-red-900",
  },
  gold: {
    background: "bg-gold-50",
    border: "border-gold-200",
    text: "text-gold-900",
  },
  olive: {
    background: "bg-olive-50",
    border: "border-olive-200",
    text: "text-olive-900",
  },
  zanah: {
    background: "bg-zanah-50",
    border: "border-zanah-200",
    text: "text-zanah-900",
  },
  nebula: {
    background: "bg-nebula-50",
    border: "border-nebula-200",
    text: "text-nebula-900",
  },
  ocean: {
    background: "bg-ocean-50",
    border: "border-ocean-200",
    text: "text-ocean-900",
  },
  haze: {
    background: "bg-haze-50",
    border: "border-haze-200",
    text: "text-haze-900",
  },
  snuff: {
    background: "bg-snuff-50",
    border: "border-snuff-200",
    text: "text-snuff-900",
  },
  pink: {
    background: "bg-pink-50",
    border: "border-pink-200",
    text: "text-pink-900",
  },
  pearl: {
    background: "bg-pearl-50",
    border: "border-pearl-200",
    text: "text-pearl-900",
  },
};
