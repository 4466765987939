import { cloneElement } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { Control, Controller, FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT } from "@/constants/date-formats";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { Icon } from "@/models";
import { cn } from "@/utils/utils";

import { DatePicker } from "./DatePicker";
import { MobileDatePicker } from "./MobileDatePicker";

type DateFieldProps = Omit<ReactDatePickerProps, "onChange"> & {
  label?: string;
  name: string;
  control: Control<any>;
  required?: boolean;
  placeholder?: string;
  showLabel?: boolean;
  className?: string;
  startIcon?: Icon;
  error?: FieldError;
};

export const DateField = ({
  label,
  name,
  control,
  required,
  placeholder,
  showLabel = true,
  className,
  startIcon,
  error,
  ...delegated
}: DateFieldProps) => {
  const { t } = useTranslation();
  const { isSm } = useBreakpoint("sm");

  return (
    <div className={cn("relative", className)}>
      <label htmlFor={name} className={showLabel ? "block text-xs text-stone-500" : "sr-only"}>
        {label || name}
      </label>
      {startIcon && (
        <div
          className={cn("pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3", {
            "mb-4": !!error,
          })}
          aria-hidden="true">
          {cloneElement(startIcon, {
            className: cn("h-5 w-5 text-stone-400", startIcon.props.className, {
              "text-red-600": !!error,
            }),
          })}
        </div>
      )}
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value = "" } }) => {
          return isSm ? (
            <DatePicker
              selected={value}
              onChange={onChange}
              placeholderText={placeholder}
              dateFormat={delegated.dateFormat || DATE_FORMAT.DEFAULT}
              className={label ? "mt-1" : ""}
              customInput={
                <input
                  className={cn(
                    "flex h-11 w-full appearance-none items-center rounded-md border border-stone-300 px-3 text-sm placeholder-stone-400 focus:border-gold-600 focus:outline-none focus:ring-gold-600",
                    {
                      "border-red-600 focus:border-red-600 focus:outline-none focus:ring-red-600":
                        !!error,
                      "pl-10": !!startIcon,
                    },
                  )}
                />
              }
              {...delegated}
            />
          ) : (
            <MobileDatePicker value={value} onChange={onChange} {...delegated} />
          );
        }}
      />
      {error && (
        <span className="block text-xs text-red-600">
          {t(`validation.${error.type}`, { name })}
        </span>
      )}
    </div>
  );
};
