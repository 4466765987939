import { ReactNode } from "react";

import { CalendarDateProvider } from "../contexts/CalendarDateContext";
import { CalendarDialogProvider } from "../contexts/CalendarDialogContext";
import { CalendarFiltersProvider } from "../contexts/CalendarFiltersContext";
import { CalendarViewProvider } from "../contexts/CalendarViewContext";

export const CalendarProvider = ({ children }: { children: ReactNode }) => {
  return (
    <CalendarViewProvider>
      <CalendarDateProvider>
        <CalendarFiltersProvider>
          <CalendarDialogProvider>{children}</CalendarDialogProvider>
        </CalendarFiltersProvider>
      </CalendarDateProvider>
    </CalendarViewProvider>
  );
};
