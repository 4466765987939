import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelAppointmentMutationVariables = Types.Exact<{
  id: Types.Scalars['UUID4'];
  sendNotification: Types.Scalars['Boolean'];
}>;


export type CancelAppointmentMutation = { __typename?: 'RootMutationType', cancelAppointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };


export const CancelAppointmentDocument = gql`
    mutation CancelAppointment($id: UUID4!, $sendNotification: Boolean!) {
  cancelAppointment(appointmentUuid: $id, sendNotification: $sendNotification) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<CancelAppointmentMutation, CancelAppointmentMutationVariables>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendNotification: // value for 'sendNotification'
 *   },
 * });
 */
export function useCancelAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(CancelAppointmentDocument, options);
      }
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>;