import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { Transition } from "@headlessui/react";

import {
  EnvelopeIcon,
  HashtagIcon,
  HomeIcon,
  MapPinIcon,
  TicketIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { Button } from "@components/ui/Button";
import { Radio } from "@components/ui/Radio";
import { TextField } from "@components/ui/TextField";

import { useClientStore } from "@features/clients/hooks";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { FormSection } from "@/components/layout/FormSection";
import { Subheader } from "@/components/layout/subheader/Subheader";
import { Meta } from "@/components/Meta";
import { PhoneField } from "@/components/ui/PhoneField";
import { Spinner } from "@/components/ui/Spinner";
import { TextAreaField } from "@/components/ui/TextAreaField";
import { EMAIL_PATTERN, NAME } from "@/constants/validations";
import { TagsSelect } from "@/features/clients/components/tags/TagsSelect";
import { useClientForm } from "@/features/clients/hooks/use-client-form";
import { useBreakpoint } from "@/hooks/use-breakpoint";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { useToasts } from "@/providers/ToastsProvider";
import { Client } from "@/types";
import { isNotWhitespace } from "@/utils/form";
import { capitalize } from "@/utils/utils";

export type ClientFormProps = {
  onClose?: () => void;
  action: "create" | "update";
  client?: Client;
  children?: React.ReactNode;
  onSave?: (data: Client) => void;
};

export type ClientFormDialogStateProps = {
  clientUuid?: Client["uuid"];
};

export type ClientFormDialogProps = {
  props?: ClientFormDialogStateProps;
  onClose: () => void;
  open: boolean;
  title: string;
  onSave?: (data: Client) => void;
};

export const ClientFormDialog = ({
  onClose,
  open,
  title,
  onSave,
  props,
}: ClientFormDialogProps) => {
  const { client, loading } = useClientStore(props?.clientUuid);

  const action = props?.clientUuid ? "update" : "create";

  return (
    <DefaultDialog title={title} onClose={onClose} open={open} maxHeight>
      {!loading && <ClientForm action={action} onSave={onSave} onClose={onClose} client={client} />}
    </DefaultDialog>
  );
};

export const ClientForm = ({ client, onClose, action, children, onSave }: ClientFormProps) => {
  const { t } = useTranslation();
  const { session, permissions } = useSessionContext();

  const { form, handleClientSubmit, clientGenderSelectOptions, isMutationLoading, mutationError } =
    useClientForm({
      client,
      action,
      onClose,
      onSave,
    });

  const {
    register,
    formState: { errors, isValid, isDirty },
    control,
    handleSubmit,
  } = form;

  return (
    <FormProvider {...form}>
      <form
        className="grid gap-2 divide-y-[1px] @container xl:gap-8"
        onSubmit={(event) => {
          // prevent form from submitting twice when in nested dialog, e.g. appointment form
          event.preventDefault();
          handleSubmit(handleClientSubmit)();
          event.stopPropagation();
        }}
        noValidate>
        <FormSection
          headingText={t("clients.clientProfile.generalInfo.header")}
          descriptionText={t("clients.clientProfile.generalInfo.description")}>
          <div className="grid gap-x-6 gap-y-6 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
            <TextField
              {...register("firstName", {
                onChange(event) {
                  const { value } = event.target;

                  const capitalized = capitalize(value);

                  event.target.value = capitalized.trim();
                },
                required: true,
                minLength: NAME.MIN_LENGTH,
                maxLength: NAME.MAX_LENGTH,
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("generic.firstName")}
              placeholder={t("generic.firstName")}
              startIcon={<UserIcon />}
              errorMessage={
                errors.firstName &&
                t(`validation.${errors.firstName.type}`, {
                  name: t("generic.firstName"),
                  minLength: NAME.MIN_LENGTH,
                  maxLength: NAME.MAX_LENGTH,
                })
              }
            />

            <TextField
              {...register("lastName", {
                onChange(event) {
                  const { value } = event.target;

                  const capitalized = capitalize(value);

                  event.target.value = capitalized.trim();
                },
                required: true,
                minLength: NAME.MIN_LENGTH,
                maxLength: NAME.MAX_LENGTH,
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("generic.lastName")}
              placeholder={t("generic.lastName")}
              startIcon={<UserIcon />}
              errorMessage={
                errors.lastName &&
                t(`validation.${errors.lastName.type}`, {
                  name: t("generic.lastName"),
                  minLength: NAME.MIN_LENGTH,
                  maxLength: NAME.MAX_LENGTH,
                })
              }
            />

            <PhoneField
              name="phone"
              control={control}
              label={t("generic.phone")}
              placeholder={t("generic.phone")}
              errorMessage={
                errors.phone &&
                t(`validation.${errors.phone.type}`, {
                  name: t("generic.phone"),
                })
              }
            />

            <TextField
              {...register("email", {
                onChange(event) {
                  event.target.value = event.target.value.trim();
                },
                pattern: EMAIL_PATTERN,
              })}
              label={t("generic.email")}
              placeholder={t("generic.email")}
              startIcon={<EnvelopeIcon />}
              errorMessage={
                errors.email &&
                t(`validation.${errors.email.type}`, {
                  name: t("generic.email"),
                })
              }
            />
          </div>
        </FormSection>

        <FormSection
          headingText={t("clients.clientProfile.internalNote.header")}
          descriptionText={t("clients.clientProfile.internalNote.description")}>
          <TextAreaField
            {...register("note", { maxLength: 500 })}
            label={t("appointments.form.note")}
            placeholder={t("generic.note")}
            errorMessage={
              errors.note &&
              t(`validation.${errors.note.type}`, {
                name: t("generic.note"),
              })
            }
          />
        </FormSection>
        <FormSection
          headingText={t("clients.clientProfile.tags.header")}
          descriptionText={t("clients.clientProfile.tags.description")}>
          <TagsSelect name="tagsUuids" control={control} />
        </FormSection>
        {action === "update" && (
          <FormSection
            headingText={t("clients.clientProfile.additionalInfo.header")}
            descriptionText={t("clients.clientProfile.additionalInfo.description")}>
            <Radio
              name="sex"
              control={control}
              label={t("generic.sex")}
              options={clientGenderSelectOptions}
              errorMessage={
                errors.sex &&
                t(`validation.${errors.sex.type}`, {
                  name: t("generic.sex"),
                })
              }
            />

            <TextField
              {...register("street", {
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("generic.street")}
              placeholder={t("generic.street")}
              startIcon={<HomeIcon />}
              errorMessage={
                errors.street &&
                t(`validation.${errors.street.type}`, {
                  name: t("generic.street"),
                })
              }
            />

            <TextField
              {...register("streetNumber", {
                validate: {
                  isNotWhitespace,
                },
              })}
              label={t("generic.streetNumber")}
              placeholder={t("generic.streetNumber")}
              startIcon={<HashtagIcon />}
              errorMessage={
                errors.streetNumber &&
                t(`validation.${errors.streetNumber.type}`, {
                  name: t("generic.streetNumber"),
                })
              }
            />

            <div className="grid grid-cols-2 gap-4">
              <TextField
                {...register("city", {
                  validate: {
                    isNotWhitespace,
                  },
                })}
                label={t("generic.city")}
                placeholder={t("generic.city")}
                startIcon={<MapPinIcon />}
                errorMessage={
                  errors.city &&
                  t(`validation.${errors.city.type}`, {
                    name: t("generic.city"),
                  })
                }
              />
              <TextField
                {...register("postalCode")}
                label={t("generic.postalCode")}
                placeholder={t("generic.postalCode")}
                startIcon={<TicketIcon />}
                mask="99-999"
                errorMessage={
                  errors.postalCode &&
                  t(`validation.${errors.postalCode.type}`, {
                    name: t("generic.postalCode"),
                  })
                }
              />
            </div>
          </FormSection>
        )}

        {/* An owner never should be able to remove his own account from this place */}
        {client?.uuid !== session?.accountUuid &&
          permissions.delete_client &&
          action === "update" &&
          children && (
            <FormSection
              headingText={t("clients.delete")}
              descriptionText={t("clients.deleteDescription")}>
              {children}
            </FormSection>
          )}

        {mutationError && <p className="my-4 text-red-500">{mutationError.message}</p>}

        <div className="flex w-full flex-auto flex-col justify-end gap-4 pt-8 sm:flex-row">
          <Button
            variant="primary-outline"
            disabled={isMutationLoading}
            to="/clients"
            className="w-full sm:w-48">
            {t("generic.cancel")}
          </Button>
          <Button
            type="submit"
            variant="primary"
            className="h-[42px] w-full sm:w-48"
            disabled={isMutationLoading || !isValid || !isDirty || Object.keys(errors).length > 0}>
            {t("generic.save")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export const ClientEdit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showToast } = useToasts();
  const { id } = useParams();
  const { isMd } = useBreakpoint("md");
  const { client, loading, fetchError, deleteClient } = useClientStore(id);

  const confirmationDialogState = useConfirmationDialog();

  const handleClientRemove = () => {
    id &&
      confirmationDialogState.open({
        title: t("generic.areYouSure"),
        message: t("clients.deleteClientPrompt"),
        onConfirm: async () => {
          const { errors } = await deleteClient(id);
          if (errors) {
            showToast({
              type: "error",
              title: t("generic.oops"),
              description: t("generic.somethingWentWrong"),
            });
          } else {
            navigate("/clients");
          }
        },
      });
  };

  return fetchError ? (
    <Navigate to="/clients" />
  ) : (
    <>
      <Meta title={t("clients.edit")} />
      <div className="sm:mb-4 md:mx-auto">
        <Subheader
          title={`${client?.firstName} ${client?.lastName}`}
          backUrl={isMd ? undefined : `/clients`}
        />
      </div>
      <Transition
        show={loading}
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="flex justify-center p-10">
        <Spinner className="h-8 w-8" />
      </Transition>
      <Transition
        show={!loading}
        appear
        as="div"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0">
        {client ? (
          <ClientForm client={client} action="update">
            <Button className="max-w-xs" variant="danger" onClick={handleClientRemove}>
              {t("clients.delete")}
            </Button>
          </ClientForm>
        ) : (
          <Navigate to="/clients" />
        )}
      </Transition>
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </>
  );
};
