import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UndoAppointmentFinalizationMutationVariables = Types.Exact<{
  appointmentUuid: Types.Scalars['UUID4'];
}>;


export type UndoAppointmentFinalizationMutation = { __typename?: 'RootMutationType', undoAppointmentFinalization: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };


export const UndoAppointmentFinalizationDocument = gql`
    mutation UndoAppointmentFinalization($appointmentUuid: UUID4!) {
  undoAppointmentFinalization(appointmentUuid: $appointmentUuid) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;
export type UndoAppointmentFinalizationMutationFn = Apollo.MutationFunction<UndoAppointmentFinalizationMutation, UndoAppointmentFinalizationMutationVariables>;

/**
 * __useUndoAppointmentFinalizationMutation__
 *
 * To run a mutation, you first call `useUndoAppointmentFinalizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoAppointmentFinalizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoAppointmentFinalizationMutation, { data, loading, error }] = useUndoAppointmentFinalizationMutation({
 *   variables: {
 *      appointmentUuid: // value for 'appointmentUuid'
 *   },
 * });
 */
export function useUndoAppointmentFinalizationMutation(baseOptions?: Apollo.MutationHookOptions<UndoAppointmentFinalizationMutation, UndoAppointmentFinalizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoAppointmentFinalizationMutation, UndoAppointmentFinalizationMutationVariables>(UndoAppointmentFinalizationDocument, options);
      }
export type UndoAppointmentFinalizationMutationHookResult = ReturnType<typeof useUndoAppointmentFinalizationMutation>;
export type UndoAppointmentFinalizationMutationResult = Apollo.MutationResult<UndoAppointmentFinalizationMutation>;
export type UndoAppointmentFinalizationMutationOptions = Apollo.BaseMutationOptions<UndoAppointmentFinalizationMutation, UndoAppointmentFinalizationMutationVariables>;