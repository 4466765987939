import { useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Transition } from "@headlessui/react";

import { useAppointmentStore } from "@/features/calendar/hooks/use-appointment-store";
import { useCalendarStore } from "@/features/calendar/hooks/use-calendar-store";
import { useBreakpoint } from "@/hooks/use-breakpoint";

import { useCalendarDate } from "../contexts/CalendarDateContext";
import { useCalendarDialogs } from "../contexts/CalendarDialogContext";
import { useCalendarFilters } from "../contexts/CalendarFiltersContext";
import { useCalendarView } from "../contexts/CalendarViewContext";
import { CalendarViews } from "../models";
import { convertCalendarItemsToSlotsFormat } from "../utils/slots";
import { CalendarDayContent } from "./CalendarDay/CalendarDayContent";
import { CalendarEmployeeWeekContent } from "./CalendarEmployeeWeek/CalendarEmployeeWeekContent";
import { CalendarLoader } from "./CalendarLoader";
import { CalendarMonthContent } from "./CalendarMonth/CalendarMonthContent";
import { CalendarWeekContent } from "./CalendarWeek/CalendarWeekContent";

const useHandleAppointmentSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [appointmentUuid, setAppointmentUuid] = useState<string>();
  const { appointment } = useAppointmentStore({ id: appointmentUuid });
  const { selectAppointment } = useCalendarDialogs();

  useLayoutEffect(() => {
    const appointmentUuid = searchParams.get("appointmentUuid");

    if (appointmentUuid) {
      setAppointmentUuid(appointmentUuid);
      setSearchParams({});
    }
  }, [searchParams]);

  useLayoutEffect(() => {
    if (appointment) {
      const slot = convertCalendarItemsToSlotsFormat([appointment])[0];

      if (slot) {
        selectAppointment(slot);
      }
    }
  }, [appointment]);
};

export const CalendarContent = () => {
  const { isSm } = useBreakpoint("sm");
  const { view } = useCalendarView();
  const { timeRange } = useCalendarDate();
  const { filters } = useCalendarFilters();

  const { slotRowsByEmployee } = useCalendarStore({
    filters: { timeRange, ...filters },
    calendarView: view,
  });

  useHandleAppointmentSelection();

  const renderContent = () => {
    if ([CalendarViews.Day, CalendarViews.EmployeeDay].includes(view)) {
      return <CalendarDayContent />;
    }

    if (CalendarViews.Week === view) {
      if (isSm) {
        return slotRowsByEmployee.length > 1 ? (
          <CalendarWeekContent />
        ) : (
          <CalendarEmployeeWeekContent />
        );
      }
      return <CalendarDayContent />;
    }

    if (CalendarViews.EmployeeWeek === view) {
      return isSm ? <CalendarEmployeeWeekContent /> : <CalendarDayContent />;
    }

    if (CalendarViews.Month === view) {
      return <CalendarMonthContent />;
    }

    return <CalendarLoader />;
  };

  return (
    <div className="relative -mx-2 -mb-4 xs:-mx-4 sm:mx-0 sm:mb-0">
      <Transition
        show={true}
        appear
        as="div"
        enter="transition-opacity duration-300 delay-100 absolute"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="h-full w-full">
        {renderContent()}
      </Transition>
    </div>
  );
};
