import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnAppointmentCreatedOrUpdatedSubscriptionVariables = Types.Exact<{
  filters: Types.AppointmentFilters;
}>;


export type OnAppointmentCreatedOrUpdatedSubscription = { __typename?: 'RootSubscriptionType', appointmentCreatedOrUpdated?: { __typename?: 'SubscriptionAppointment', action: Types.AppointmentSubscriptionAction, appointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } } | null };


export const OnAppointmentCreatedOrUpdatedDocument = gql`
    subscription OnAppointmentCreatedOrUpdated($filters: AppointmentFilters!) {
  appointmentCreatedOrUpdated(filters: $filters) {
    action
    appointment {
      ...Appointment
    }
  }
}
    ${AppointmentFragmentDoc}`;

/**
 * __useOnAppointmentCreatedOrUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnAppointmentCreatedOrUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnAppointmentCreatedOrUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnAppointmentCreatedOrUpdatedSubscription({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useOnAppointmentCreatedOrUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnAppointmentCreatedOrUpdatedSubscription, OnAppointmentCreatedOrUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnAppointmentCreatedOrUpdatedSubscription, OnAppointmentCreatedOrUpdatedSubscriptionVariables>(OnAppointmentCreatedOrUpdatedDocument, options);
      }
export type OnAppointmentCreatedOrUpdatedSubscriptionHookResult = ReturnType<typeof useOnAppointmentCreatedOrUpdatedSubscription>;
export type OnAppointmentCreatedOrUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnAppointmentCreatedOrUpdatedSubscription>;