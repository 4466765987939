import { cloneElement } from "react";

import { Icon } from "@/models";

type HeaderAvatarProps = {
  icon: Icon;
};

export const HeaderAvatar = ({ icon }: HeaderAvatarProps) => (
  <div className="flex h-6 w-6 rounded-full bg-gold-500">
    {cloneElement(icon, {
      className: "p-1 text-gold-100",
    })}
  </div>
);
