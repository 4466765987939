import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SmsFragment = { __typename?: 'Sms', clientUuid: any, firstName?: string | null, lastName?: string | null, message?: string | null, recipient?: string | null, salonUuid: any, senderName?: string | null, sentAt?: any | null, status: Types.SmsStatus, uuid: any };

export const SmsFragmentDoc = gql`
    fragment Sms on Sms {
  clientUuid
  firstName
  lastName
  message
  recipient
  salonUuid
  senderName
  sentAt
  status
  uuid
  status
}
    `;