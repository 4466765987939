import { MonthInput } from "@/types";

import { useEmployeesLedgerSummaryQuery } from "../queries/EmployeesLedgerSummary.generated";

export type CommissionsSummaryByEmployee = Record<
  string,
  {
    total: {
      commission: number;
      turnover: number;
      profit: number;
    };
    treatments: Record<
      string,
      {
        commission: number;
        turnover: number;
        profit: number;
      }
    >;
  }
>;

export const useCommissionsStore = ({ month }: { month: MonthInput }) => {
  const { data, loading, error } = useEmployeesLedgerSummaryQuery({
    variables: {
      month: month,
    },
  });

  const commissionsSummaryGroupedByEmployee: CommissionsSummaryByEmployee =
    data?.employeesLedgerSummary.items?.reduce<CommissionsSummaryByEmployee>((acc, commission) => {
      const employeeId = commission.employeeId;

      if (!acc[employeeId]) {
        acc[employeeId] = {
          total: {
            commission: 0,
            turnover: 0,
            profit: 0,
          },
          treatments: {},
        };
      }

      if (!commission.treatmentId) {
        acc[employeeId].total.commission = commission.commission.amount;
        acc[employeeId].total.turnover = commission.turnover.amount;
        acc[employeeId].total.profit = commission.turnover.amount - commission.commission.amount;
      } else {
        const treatmentId = commission.treatmentId;

        if (!acc[employeeId].treatments[treatmentId]) {
          acc[employeeId].treatments[treatmentId] = {
            commission: 0,
            turnover: 0,
            profit: 0,
          };
        }

        acc[employeeId].treatments[treatmentId].commission = commission.commission.amount;
        acc[employeeId].treatments[treatmentId].turnover = commission.turnover.amount;
        acc[employeeId].treatments[treatmentId].profit =
          commission.turnover.amount - commission.commission.amount;
      }

      return acc;
    }, {}) || {};

  const totalSummary = data?.employeesLedgerSummary.items?.reduce<{
    commission: number;
    turnover: number;
    profit: number;
  }>(
    (acc, commission) => {
      if (commission.treatmentId) {
        return acc;
      }

      acc.commission += commission.commission.amount;
      acc.turnover += commission.turnover.amount;
      acc.profit += commission.turnover.amount - commission.commission.amount;

      return acc;
    },
    { commission: 0, turnover: 0, profit: 0 },
  );

  return {
    commissionsSummary: commissionsSummaryGroupedByEmployee || {},
    totalSummary: totalSummary || { commission: 0, turnover: 0, profit: 0 },
    loading,
    error,
  };
};
