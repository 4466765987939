import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  ArrowPathIcon,
  BuildingStorefrontIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ChevronRightIcon,
  ClockIcon,
  Cog6ToothIcon,
  LifebuoyIcon,
  ListBulletIcon,
  RectangleStackIcon,
  UserGroupIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@features/account/components/SendFeedbackDialog";

import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { Role } from "@/types";
import { sendEvent } from "@/utils/google-analytics";
import { cn } from "@/utils/utils";

import { NavigationItem } from "../types/sidebar";

export const MobileNavigationItems = () => {
  const { t } = useTranslation();
  const { role, permissions } = useSessionContext();

  const feedbackDialogState = useDialog<SendFeedbackProps>();

  const navigation: NavigationItem[] = useMemo(
    () => [
      {
        name: t("sidebar.navigation.appointments.children.calendar"),
        icon: CalendarDaysIcon,
        href: "/calendar",
      },
      {
        name: t("sidebar.navigation.appointments.children.clients"),
        icon: UserGroupIcon,
        href: "/clients",
      },
      {
        name: t("sidebar.navigation.appointments.children.statistics"),
        icon: ChartBarIcon,
        href: "/statistics",
        hasPermission: permissions.statistics_view,
      },
      {
        name: t("sidebar.navigation.mySalon.children.salonProfile"),
        icon: BuildingStorefrontIcon,
        href: "/salon",
      },
      {
        name: t("sidebar.navigation.mySalon.children.employees"),
        icon: UsersIcon,
        href: "/employees",
      },
      {
        name: t("sidebar.navigation.mySalon.children.openingHours"),
        icon: ClockIcon,
        href: "/opening-hours",
      },
      {
        name: t("sidebar.navigation.mySalon.children.servicesAndPrices"),
        icon: ListBulletIcon,
        href: "/services",
      },
      {
        name: t("equipment.name"),
        icon: RectangleStackIcon,
        href: "/equipment",
      },
      {
        name: t("settings.title"),
        icon: Cog6ToothIcon,
        href: "/settings",
      },
      {
        name: t("subscriptions.title"),
        icon: ArrowPathIcon,
        href: "/subscriptions",
        roles: [Role.Owner],
      },
      {
        name: t("footer.iNeedHelp"),
        icon: LifebuoyIcon,
        action: () => {
          sendEvent("support_button", "contact", "footer");
          feedbackDialogState.open();
        },
      },
    ],
    [],
  );

  const navigationCategories = useMemo<NavigationItem[]>(
    () =>
      role
        ? navigation.filter((category) => {
            return (
              (!category.roles || category.roles.includes(role)) &&
              (category.hasPermission === undefined || category.hasPermission === true)
            );
          })
        : [],
    [role, navigation],
  );

  const navItemClass = {
    button: "group flex w-full items-center px-2 py-4 text-stone-700 text-sm",
    icon: "mr-3 size-5 flex-shrink-0",
    chevron: "ml-auto size-5 text-stone-700",
  };

  return (
    <nav className="divide-y divide-dashed divide-stone-300 px-3 pb-4">
      {navigationCategories.map((category) => (
        <Fragment key={category.name}>
          {category.action ? (
            <button onClick={category.action} className={navItemClass.button}>
              <category.icon className={navItemClass.icon} />
              {category.name}
              <ChevronRightIcon className={navItemClass.chevron} />
            </button>
          ) : (
            <NavLink
              to={category.href ?? "#"}
              className={({ isActive }) =>
                cn(navItemClass.button, { "font-medium text-gold-600": isActive })
              }>
              <category.icon className={navItemClass.icon} />
              {category.name}
              <ChevronRightIcon className={navItemClass.chevron} />
            </NavLink>
          )}
        </Fragment>
      ))}
      {feedbackDialogState.internalState.open && (
        <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
      )}
    </nav>
  );
};
