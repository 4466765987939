import { forwardRef, ForwardedRef, LabelHTMLAttributes } from "react";

import { cn } from "@/utils/utils";

type LabelProps = {
  screenreaderOnly?: boolean;
};

export const Label = forwardRef(
  (
    { screenreaderOnly, ...delegated }: LabelProps & LabelHTMLAttributes<HTMLLabelElement>,
    ref: ForwardedRef<HTMLLabelElement>,
  ) => {
    return (
      <label
        ref={ref}
        className={cn("block pb-1 text-xs text-stone-500", { "sr-only": screenreaderOnly })}
        {...delegated}
      />
    );
  },
);
