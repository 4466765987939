import { Suspense, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PencilSquareIcon, PlusIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { ImagesGallery } from "@/components/ui/ImagesGallery";
import { useAppointmentStore } from "@/features/calendar/hooks/use-appointment-store";
import { useToasts } from "@/providers/ToastsProvider";
import {
  AppointmentFragment,
  UpdateBulkAppointmentInput,
  UpdateBulkAppointmentTreatmentInput,
} from "@/types";
import { setTimeToDate } from "@/utils/datetime";

import { ClientAppointmentNoteForm } from "./ClientAppointmentNoteForm";

type ClientAppointmentNoteProps = {
  note?: string | null;
  imagesUrls?: { imageUrl: string }[] | null;
  appointmentUuid: string;
};

type FormData = Omit<UpdateBulkAppointmentInput, "treatments"> & {
  treatments: Array<
    UpdateBulkAppointmentTreatmentInput & {
      treatmentName?: string;
      employeeName?: AppointmentFragment["treatments"][number]["employeeName"];
    }
  >;
  date: Date;
};

export const ClientAppointmentNote = ({
  note,
  imagesUrls,
  appointmentUuid,
}: ClientAppointmentNoteProps) => {
  const { t } = useTranslation();
  const { appointment, update } = useAppointmentStore({
    id: appointmentUuid,
    withSuggestedPrice: true,
  });
  const [isEdit, setIsEdit] = useState(false);
  const { showToast } = useToasts();

  const handleEditSubmit: SubmitHandler<FormData> = async (data) => {
    if (!appointment) return;

    try {
      await update({
        appointmentUuid: appointment?.appointmentUuid,
        clientUuid: data.clientUuid || undefined,
        note: data.note,
        imagesUrls: data.imagesUrls,
        sendNotification: false,
        treatments: data.treatments.map(
          ({ appointmentTreatmentUuid, treatmentUuid, employeeUuid, timeRange }) => ({
            appointmentTreatmentUuid,
            treatmentUuid,
            employeeUuid,
            timeRange: {
              from: setTimeToDate(data.date, timeRange.from),
              to: setTimeToDate(data.date, timeRange.to),
            },
          }),
        ),
      });

      showToast({
        title: t("notifications.clients.appointmentNoteUpdated"),
        type: "success",
      });

      setIsEdit(false);
    } catch (error) {
      console.error("Error updating appointment", error);
    }
  };

  if (isEdit && appointment) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <ClientAppointmentNoteForm
          appointment={appointment}
          onSubmit={(data) => handleEditSubmit(data)}
          onCancelClick={() => setIsEdit(false)}
        />
      </Suspense>
    );
  }

  if (!note && (!imagesUrls || imagesUrls?.length === 0)) {
    return (
      <div className="rounded-md bg-stone-100 px-4 py-5">
        <p className="mb-4 text-center">{t("clients.noAppointmentNote")}</p>
        <Button variant="primary" size="small" className="mx-auto" onClick={() => setIsEdit(true)}>
          <PlusIcon className="mr-1 h-4 w-4" />
          {t("generic.add")}
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        {note ? <p className="rounded-md bg-stone-100 px-4 py-3">{note}</p> : null}
      </div>
      {imagesUrls && imagesUrls.length > 0 && <ImagesGallery imagesUrls={imagesUrls} />}
      <div className="ml-auto mt-2 flex">
        <Button variant="primary" size="small" onClick={() => setIsEdit(true)}>
          {t("generic.edit")}
          <PencilSquareIcon className="ml-1 h-3.5 w-3.5" />
        </Button>
      </div>
    </div>
  );
};
