import { addDays, formatISO } from "date-fns";

import { timeRange } from "@/features/statistics/models";
import { useFetchGeneralNumbersQuery } from "@/features/statistics/queries/FetchGeneralNumbers.generated";

import { TIME_RANGE_DIFFERENCE } from "../constants/index";

export const useGeneralNumbersStore = (timeRange: timeRange) => {
  const { data, loading } = useFetchGeneralNumbersQuery({
    variables: {
      timeRange: {
        from: formatISO(addDays(Date.now(), TIME_RANGE_DIFFERENCE[timeRange])),
        to: formatISO(Date.now()),
      },
    },
  });

  return {
    loading,
    generalNumbers: data?.fetchGeneralNumbers,
  };
};
