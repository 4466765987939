import { useTranslation } from "react-i18next";

import { SupportButton } from "@components/SupportButton";
import { Button } from "@components/ui/Button";

export const NotFoundView = () => {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen items-center bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-gold-500 sm:text-5xl ">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-stone-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-stone-900 sm:text-5xl">
                {t("session.notFound.title")}
              </h1>
              <p className="mt-1 pt-4 text-base text-stone-500">
                {t("session.notFound.description")}
              </p>
            </div>
            <div className="mt-10 flex flex-col gap-4 sm:border-l sm:border-transparent sm:pl-6">
              <Button to="/calendar" fullWidth>
                {t("session.notFound.goBack")}
              </Button>
              <div>
                <SupportButton variant="primary-outline" fullWidth className="text-gold-500" />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
