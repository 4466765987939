import { cn } from "@/utils/utils";

export const AnimatedExpandIcon = ({ open }: { open: boolean }) => (
  <svg
    className={cn(
      "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-stone-400",
      open ? "rotate-90 text-stone-400" : "text-stone-300",
    )}
    viewBox="0 0 20 20"
    aria-hidden="true">
    <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
  </svg>
);
