import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type OrderFragment = { __typename?: 'Order', accountEmail: string, accountUuid: any, orderItems?: any | null, paymentMode?: string | null, uuid: any };

export const OrderFragmentDoc = gql`
    fragment Order on Order {
  accountEmail
  accountUuid
  orderItems
  paymentMode
  uuid
}
    `;