import { Sex, SmsStatus } from "@/types";

export const SEX_LABELS_MAP: Record<string, string> = {
  [Sex.Male]: "generic.male",
  [Sex.Female]: "generic.female",
};

export const SMS_STATUS_LABELS_MAP: Record<string, string> = {
  [SmsStatus.Sent]: "messages.smsSent",
  [SmsStatus.Discarded]: "messages.smsDiscarded",
};

export const MIN_OPTIONS_FOR_SEARCH = 2;

export const MAX_VISIBLE_PROFILE_TAGS = 3;
