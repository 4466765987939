import { namedOperations } from "@/types";

import {
  SendSmsToClientsMutationVariables,
  useSendSmsToClientsMutation,
} from "../mutations/SendSmsToClients.generated";

export const useSendSmsStore = () => {
  const [sendSmsToClients, { loading }] = useSendSmsToClientsMutation({
    refetchQueries: [namedOperations.Query.fetchSentSms],
  });

  return {
    loading,
    sendSmsToClients(data: SendSmsToClientsMutationVariables) {
      return sendSmsToClients({
        variables: data,
      });
    },
  };
};
