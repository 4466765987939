import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import {
  EllipsisVerticalIcon,
  ListBulletIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Float } from "@headlessui-float/react";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { Button } from "@/components/ui/Button";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { HeadCategory } from "@/types";
import { cn } from "@/utils/utils";

import { useHeadCategoryStore } from "../../hooks";
import {
  HeadCategoryFormDialog,
  HeadCategoryFormProps,
} from "../headCategories/HeadCategoryFormDialog";
import { Category } from "./Category";

type GroupProps = {
  group: HeadCategory;
  showDndHandle?: boolean;
};

export const Group = ({ group, showDndHandle }: GroupProps) => {
  const { t } = useTranslation();
  const { categories } = useHeadCategoryStore(group.uuid);
  const dialogState = useDialog<HeadCategoryFormProps>();
  const confirmationDialogState = useConfirmationDialog();
  const { permissions } = useSessionContext();
  const { deleteHeadCategory: deleteGroup } = useHeadCategoryStore();

  const categoryId = useMemo(() => categories.map((category) => category.uuid), [categories]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: group.uuid,
    data: {
      type: "group",
      group,
    },
    disabled: !permissions.manage_treatments || !showDndHandle,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleGroupEdit = ({ uuid }: HeadCategory) =>
    dialogState.open({ headCategoryUuid: uuid, onRemove: handleGroupRemove });

  const handleGroupRemove: (headCategory: HeadCategory) => Promise<boolean> = ({
    uuid,
    name,
  }: HeadCategory) =>
    new Promise((resolve) =>
      confirmationDialogState.open({
        title: t("services.headCategories.delete"),
        message: t("services.headCategories.deleteWarning", { name }),
        onConfirm: async () => {
          await deleteGroup({ categoryUuid: uuid });
          resolve(true);
        },
        onDeny: () => resolve(false),
      }),
    );

  return (
    <>
      <div ref={setNodeRef} style={style} className="relative select-none rounded-lg bg-white p-4">
        {isDragging && (
          <div className="absolute inset-0 z-10 rounded-lg border border-dashed border-gold-200 bg-gold-50" />
        )}
        <div className="group flex items-center justify-between gap-3">
          <div className="mt-1 flex grow justify-between gap-1">
            <div className="flex items-center gap-2">
              {showDndHandle && permissions.manage_treatments && (
                <div
                  {...attributes}
                  {...listeners}
                  className={cn(
                    isDragging ? "cursor-grabbing" : "cursor-grab",
                    "mx-1 flex h-6 w-4 scale-90 select-none items-center justify-center rounded-sm bg-white sm:m-0 sm:scale-[65%]",
                  )}>
                  <div className="grid h-full w-full grid-cols-2 items-center justify-center px-[2px] py-1">
                    {Array.from({ length: 6 }).map((_, index) => (
                      <span
                        key={index}
                        className="m-auto h-1 w-1 rounded-full bg-stone-300 hover:bg-stone-400"
                      />
                    ))}
                  </div>
                </div>
              )}
              <div className="text-xl font-semibold text-stone-700">{group.name}</div>
            </div>
            {permissions.manage_treatments && (
              <Menu as="div" className="relative inline-block text-left">
                <Float offset={2} placement={"bottom-end"} portal>
                  <Menu.Button className="flex size-8 items-center justify-center rounded-full transition-all sm:bg-transparent sm:hover:bg-stone-100">
                    <EllipsisVerticalIcon className="size-6 text-stone-700" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items
                      as="ul"
                      className="w-fill my-2 min-w-28 origin-top-right divide-y divide-stone-100 rounded-md bg-white shadow-lg ring-1 ring-stone-300 focus:outline-none">
                      <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
                        {({ active }) => (
                          <Button
                            variant="neutral"
                            onClick={() => {
                              handleGroupEdit(group);
                            }}
                            className={cn(
                              "group flex w-full items-center px-4 py-2 text-xs",
                              active && "bg-stone-100",
                            )}>
                            <PencilSquareIcon className="mr-3 size-4" aria-hidden="true" />
                            {t("generic.edit")}
                          </Button>
                        )}
                      </Menu.Item>
                      <Menu.Item as="li" className="py-1 text-red-500 group-hover:text-stone-600">
                        {({ active }) => (
                          <Button
                            variant="neutral"
                            onClick={() => {
                              handleGroupRemove(group);
                            }}
                            className={cn(
                              "group flex w-full items-center px-4 py-2 text-xs",
                              active && "bg-stone-100",
                            )}>
                            <TrashIcon className="mr-3 size-4" aria-hidden="true" />
                            {t("generic.delete")}
                          </Button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Float>
              </Menu>
            )}
          </div>
        </div>
        <div className="mb-2 mt-5 divide-y divide-dashed divide-stone-200 sm:my-5">
          {categories.length > 0 ? (
            <SortableContext items={categoryId} strategy={verticalListSortingStrategy}>
              {categories.map((category) => (
                <Category
                  key={category.uuid}
                  category={category}
                  showDndHandle={categories.length > 1}
                />
              ))}
            </SortableContext>
          ) : (
            <div className="flex flex-col justify-center rounded-lg border border-dashed border-stone-200 px-4 py-6 text-center">
              <ListBulletIcon className="mx-auto mb-2 size-8 text-stone-700" />
              <span className="mb-0.5 text-xs font-semibold text-slate-900">
                {t("services.categories.noCategories")}
              </span>
              <p className="mb-4 text-sm text-stone-500">
                {t("services.categories.addFirstCategory", {
                  group: group.name,
                })}
              </p>
            </div>
          )}
        </div>
      </div>

      <HeadCategoryFormDialog {...bindDialogState(dialogState)} />
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </>
  );
};
