import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Disclosure } from "@headlessui/react";

import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { addMonths, format } from "date-fns";

import { Alert } from "@/components/ui/Alert";
import { Select } from "@/components/ui/Select";
import { useEmployeesStore } from "@/features/employees/hooks";
import { useCommissionsStore } from "@/features/employees/hooks/use-commissions-store";
import { formatPrice } from "@/features/payments/utils";
import { useTreatmentsSearchStore } from "@/features/services/hooks";
import { cn } from "@/utils/utils";

export const CommissionsSummary = () => {
  const { t } = useTranslation();
  const { employees } = useEmployeesStore();
  const { treatments } = useTreatmentsSearchStore();

  const startDate = new Date(2024, 9, 1);
  const currentDate = new Date();

  const generateMonthOptions = () => {
    const options = [];
    let date = startDate;

    while (isBeforeOrSameMonth(date, currentDate)) {
      options.push({
        value: format(date, "yyyy-MM"),
        label: format(date, "LLLL yyyy"),
      });
      date = addMonths(date, 1);
    }

    return options;
  };

  const isBeforeOrSameMonth = (date1: Date, date2: Date) => {
    return (
      date1.getFullYear() < date2.getFullYear() ||
      (date1.getFullYear() === date2.getFullYear() && date1.getMonth() <= date2.getMonth())
    );
  };

  const options = generateMonthOptions();

  const currentMonthValue = format(currentDate, "yyyy-MM");
  const initialMonth = options.find((option) => option.value === currentMonthValue)
    ? currentMonthValue
    : format(startDate, "yyyy-MM");

  const [selectedMonth, setSelectedMonth] = useState<string>(initialMonth);

  const { commissionsSummary, totalSummary } = useCommissionsStore({
    month: {
      month: parseInt(selectedMonth.split("-")[1]),
      year: parseInt(selectedMonth.split("-")[0]),
    },
  });

  const findEmployeeById = useCallback(
    (employeeId: string) => {
      return employees.find((employee) => employee.accountUuid === employeeId)?.fullName || null;
    },
    [employees],
  );

  const findTreatmentById = useCallback(
    (treatmentId: string) => {
      return (
        treatments.find((treatment) => treatment.treatmentUuid === treatmentId)?.treatmentName ||
        null
      );
    },
    [treatments],
  );

  return (
    <div className="mt-4 flex w-full flex-col gap-4 @container">
      <Alert type="info">{t("employees.employeeProfile.commissions.info")}</Alert>

      <Select
        name="month"
        label={t("generic.month")}
        options={options}
        selected={selectedMonth}
        value={selectedMonth}
        multiple={false}
        onChange={(value) => setSelectedMonth(value as string)}
        selectBy="value"
      />

      {Object.keys(commissionsSummary).length > 0 ? (
        <div className="rounded-lg bg-white px-4 pb-4 @[600px]:px-8 @[600px]:py-8">
          <div className="flex flex-col gap-2">
            <div className="mb-2 hidden gap-4 pr-8 text-xs font-semibold text-stone-500 @[600px]:flex">
              <div className="flex-1">{t("employees.employee")}</div>
              <div className="flex-1">{t("generic.turnover")}</div>
              <div className="flex-1">{t("generic.commission")}</div>
              <div className="flex-1">{t("generic.profit")}</div>
            </div>
            <div className="divide-y divide-dashed divide-stone-200">
              {Object.entries(commissionsSummary).map(([employeeId, commission]) => {
                return (
                  <Disclosure as="div" key={employeeId} className="py-6 text-sm text-stone-700">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center">
                          <div className="flex w-full flex-col gap-1 text-left @[600px]:flex-row @[600px]:gap-4">
                            <div className="mb-1 flex-1 font-semibold">
                              {findEmployeeById(employeeId)}
                            </div>
                            <div className="flex-1 text-xs text-stone-500 @[600px]:text-sm">
                              <span className="@[600px]:hidden">{t("generic.turnover")}: </span>
                              {formatPrice({ price: commission.total.turnover })}
                            </div>
                            <div className="flex-1 text-xs text-stone-500 @[600px]:text-sm">
                              <span className="@[600px]:hidden">{t("generic.commission")}: </span>
                              {formatPrice({ price: commission.total.commission })}
                            </div>
                            <div className="flex-1 text-xs text-stone-500 @[600px]:text-sm">
                              <span className="@[600px]:hidden">{t("generic.profit")}: </span>
                              {formatPrice({ price: commission.total.profit })}
                            </div>
                          </div>
                          <div className="flex size-8 items-center justify-center self-start rounded-full transition-all hover:bg-stone-100">
                            <ChevronRightIcon
                              className={cn(
                                "size-6 text-stone-500",
                                open ? "rotate-90 transform transition-transform" : "",
                              )}
                            />
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel
                          as="div"
                          className="-mx-4 mt-4 rounded-lg bg-stone-50 py-4 pl-4 pr-12">
                          {Object.entries(commission.treatments).map(([treatmentId, treatment]) => {
                            return (
                              <div
                                key={treatmentId}
                                className="mb-2 flex flex-col gap-1 py-2 text-xs text-stone-500 @[600px]:flex-row @[600px]:gap-4">
                                <div className="mb-1 flex-1 font-medium text-stone-700">
                                  {findTreatmentById(treatmentId)}
                                </div>
                                <div className="flex-1">
                                  <span className="@[600px]:hidden">{t("generic.turnover")}: </span>
                                  {formatPrice({ price: treatment.turnover })}
                                </div>
                                <div className="flex-1">
                                  <span className="@[600px]:hidden">
                                    {t("generic.commission")}:{" "}
                                  </span>
                                  {formatPrice({ price: treatment.commission })}
                                </div>
                                <div className="flex-1">
                                  <span className="@[600px]:hidden">{t("generic.turnover")}: </span>
                                  {formatPrice({ price: treatment.profit })}
                                </div>
                              </div>
                            );
                          })}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                );
              })}
            </div>
            <div className="flex flex-col gap-1 border-t pt-4 text-sm text-stone-700 @[600px]:flex-row @[600px]:gap-4 @[600px]:pr-8 @[600px]:font-medium">
              <div className="mb-2 flex-1 text-sm font-medium">{t("generic.total")}:</div>
              <div className="flex flex-1 justify-between">
                <span className="@[600px]:hidden">{t("generic.turnover")}: </span>
                <div>{formatPrice({ price: totalSummary.turnover })}</div>
              </div>
              <div className="flex flex-1 justify-between">
                <span className="@[600px]:hidden">{t("generic.commission")}: </span>
                <div>{formatPrice({ price: totalSummary.commission })}</div>
              </div>
              <div className="flex flex-1 justify-between">
                <span className="@[600px]:hidden">{t("generic.profit")}: </span>
                <div>{formatPrice({ price: totalSummary.profit })}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex min-h-40 items-center justify-center rounded-lg border border-dashed border-stone-200 text-sm text-stone-700">
          {t("employees.employeeProfile.commissions.noSummary")}
        </div>
      )}
    </div>
  );
};
