import { useMediaQuery } from "react-responsive";

import defaultConfig from "tailwindcss/defaultConfig";
import defaultTheme from "tailwindcss/defaultTheme";
import resolveConfig from "tailwindcss/resolveConfig";
import { Config } from "tailwindcss/types";

type Screens = typeof defaultTheme.screens;

const { theme } = resolveConfig(defaultConfig as Config);

const breakpoints = theme?.screens as Screens;
type BreakpointKey = keyof typeof breakpoints;

export const useBreakpoint = <K extends BreakpointKey>(breakpointKey: K) => {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });
  const capitalizedKey = breakpointKey[0].toUpperCase() + breakpointKey.substring(1);
  type Key = `is${Capitalize<K>}`;
  return {
    [`is${capitalizedKey}`]: bool,
  } as Record<Key, boolean>;
};
