import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import {
  BanknotesIcon,
  ClockIcon,
  EllipsisVerticalIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Float } from "@headlessui-float/react";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { Badge } from "@/components/ui/Badge";
import { Button } from "@/components/ui/Button";
import { formatTreatmentPrice } from "@/features/payments/utils";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import i18n from "@/lib/i18n";
import { useSessionContext } from "@/providers/SessionProvider";
import { Treatment as TreatmentType } from "@/types";
import { cn } from "@/utils/utils";

import { useTreatmentStore } from "../../hooks";
import { formatTreatmentDuration } from "../../utils";
import { TreatmentFormDialog, TreatmentFormProps } from "../treatments/TreatmentFormDialog";

type TreatmentProps = {
  treatment: TreatmentType;
  showDndHandle?: boolean;
};

export const Treatment = ({ treatment, showDndHandle }: TreatmentProps) => {
  const { t } = useTranslation();
  const { deleteTreatment } = useTreatmentStore();
  const dialogState = useDialog<TreatmentFormProps>();
  const confirmationDialogState = useConfirmationDialog();
  const { permissions } = useSessionContext();

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: treatment.uuid,
    data: {
      type: "treatment",
      treatment,
    },
    disabled: !permissions.manage_treatments || !showDndHandle,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleTreatmentEdit = (treatment: TreatmentType) =>
    dialogState.open({
      treatment,
      onRemove: handleTreatmentRemove,
    });

  const handleTreatmentRemove: (treatment: TreatmentType) => Promise<boolean> = ({ uuid, name }) =>
    new Promise((resolve) =>
      confirmationDialogState.open({
        title: t("services.treatments.delete"),
        message: t("services.treatments.deleteWarning", { name }),
        onConfirm: async () => {
          await deleteTreatment({ treatmentUuid: uuid });
          resolve(true);
        },
        onDeny: () => resolve(false),
      }),
    );

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="group flex grow select-none justify-between gap-1 bg-white py-6">
      {isDragging && (
        <div className="absolute inset-0 z-10 rounded-lg border border-dashed border-gold-200 bg-gold-50" />
      )}
      <div className="flex gap-2">
        {showDndHandle && permissions.manage_treatments && (
          <div
            {...attributes}
            {...listeners}
            className={cn(
              isDragging ? "cursor-grabbing" : "cursor-grab",
              "mx-1 flex h-6 w-4 scale-90 select-none items-center justify-center rounded-sm bg-white sm:m-0 sm:scale-[65%]",
            )}>
            <div className="grid h-full w-full grid-cols-2 items-center justify-center px-[2px] py-1">
              {Array.from({ length: 6 }).map((_, index) => (
                <span
                  key={index}
                  className="m-auto h-1 w-1 rounded-full bg-stone-300 hover:bg-stone-400"
                />
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-2 sm:flex-row">
            <div className="order-2 sm:order-1">{treatment.name}</div>
            {treatment.isPublic && (
              <Badge color="blue" size="sm" bordered className="order-1 self-start sm:order-2">
                {t("selfBooking.public_f")}
              </Badge>
            )}
          </div>
          <div className="flex gap-3 text-sm text-stone-500">
            <div className="flex items-center gap-0.5">
              <BanknotesIcon className="size-4 text-stone-500" />
              {formatTreatmentPrice({
                price: treatment.price,
                isExactPrice: treatment.isExactPrice,
                priceFrom: treatment.priceFrom,
                priceTo: treatment.priceTo,
              })}
            </div>
            <div className="flex items-center gap-0.5">
              <ClockIcon className="size-4 text-stone-500" />
              {formatTreatmentDuration(treatment.duration, i18n.language)}
            </div>
          </div>
        </div>
      </div>
      {permissions.manage_treatments && (
        <Menu as="div" className="relative inline-block text-left">
          <Float offset={2} placement={"bottom-end"} portal>
            <Menu.Button className="flex size-8 items-center justify-center rounded-full transition-all hover:bg-stone-100">
              <EllipsisVerticalIcon className="size-6 text-stone-700" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items
                as="ul"
                className="w-fill my-2 min-w-28 origin-top-right divide-y divide-stone-100 rounded-md bg-white shadow-lg ring-1 ring-stone-300 focus:outline-none">
                <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
                  {({ active }) => (
                    <Button
                      variant="neutral"
                      onClick={() => {
                        handleTreatmentEdit(treatment);
                      }}
                      className={cn(
                        "group flex w-full items-center px-4 py-2 text-xs",
                        active && "bg-stone-100",
                      )}>
                      <PencilSquareIcon className="mr-3 size-4" aria-hidden="true" />
                      {t("generic.edit")}
                    </Button>
                  )}
                </Menu.Item>
                <Menu.Item as="li" className="py-1 text-red-500 group-hover:text-stone-600">
                  {({ active }) => (
                    <Button
                      variant="neutral"
                      onClick={() => {
                        handleTreatmentRemove(treatment);
                      }}
                      className={cn(
                        "group flex w-full items-center px-4 py-2 text-xs",
                        active && "bg-stone-100",
                      )}>
                      <TrashIcon className="mr-3 size-4" aria-hidden="true" />
                      {t("generic.delete")}
                    </Button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Float>
        </Menu>
      )}

      <TreatmentFormDialog {...bindDialogState(dialogState)} />
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </div>
  );
};
