import { useTranslation } from "react-i18next";

import { ClockIcon } from "@heroicons/react/24/outline";

import { DATE_FORMAT } from "@/constants/date-formats";
import { Appointment } from "@/types";

import { formatAppointmentDuration, formatDateTime } from "../../utils/time";

type AppointmentDetailsDatetimeInfoProps = {
  appointment: Appointment;
};

export const AppointmentDetailsDatetimeInfo = ({
  appointment,
}: AppointmentDetailsDatetimeInfoProps) => {
  const { i18n } = useTranslation();

  return (
    <div className="mb-2 w-full flex-col text-xs capitalize text-stone-600 sm:flex-row sm:flex-wrap sm:text-sm">
      <div className="mr-3 inline-flex align-text-bottom">
        {formatDateTime(appointment.treatments[0].timeRange.from, DATE_FORMAT.DATE_WITH_DAY_NAME)}
      </div>
      <div className="inline-flex items-center align-text-bottom">
        <ClockIcon className="mr-0.5 hidden h-3.5 w-3.5 text-gray-700 sm:flex" aria-hidden="true" />
        {formatDateTime(appointment.treatments[0].timeRange.from)}
        {" - "}
        {formatDateTime(appointment.treatments[appointment.treatments.length - 1].timeRange.to)}
        <span className="ml-1 inline-flex lowercase">
          ({formatAppointmentDuration(appointment, i18n.language)})
        </span>
      </div>
    </div>
  );
};
