import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CategoryFragmentDoc } from './Category.generated';
export type HeadCategoryFragment = { __typename?: 'HeadCategory', name: string, uuid: any, updatedAt?: any | null, salonUuid: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any }> | null };

export const HeadCategoryFragmentDoc = gql`
    fragment HeadCategory on HeadCategory {
  categories {
    ...Category
  }
  name
  uuid
  updatedAt
  salonUuid
  isDeleted
  insertedAt
}
    ${CategoryFragmentDoc}`;