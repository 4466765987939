import { useMemo } from "react";

import { useCheckEquipmentsForTreatmentsAvailabilityQuery } from "@/features/equipment/queries/CheckEquipmentsForTreatmentsAvailability.generated";
import { CheckEquipmentsForTreatmentsAvailabilityInput } from "@/types";

export const useEquipmentAvailability = (
  input: CheckEquipmentsForTreatmentsAvailabilityInput[],
) => {
  const { data, loading } = useCheckEquipmentsForTreatmentsAvailabilityQuery({
    variables: {
      data: input,
    },
    skip: input.length === 0,
  });

  const availability = useMemo(() => data?.checkEquipmentsForTreatmentsAvailability ?? [], [data]);

  const isAvailable = useMemo(() => {
    const treatmentHasNoEquipmentsAssigned = availability.length === 0;
    const treatmentHasFreeEquipments = availability.some(
      (item) => !(item.availableEquipments.length === 0),
    );

    return treatmentHasNoEquipmentsAssigned || treatmentHasFreeEquipments;
  }, [availability]);

  return {
    loading,
    data,
    isAvailable,
  };
};
