import React, { useLayoutEffect } from "react";

type MainWrapperProps = {
  children: React.ReactNode;
};

export const MainWrapper = ({ children }: MainWrapperProps) => {
  useLayoutEffect(() => {
    const setValue = () => {
      document.body.style.setProperty("--screenH", `${window.innerHeight}px`);
    };

    setValue();

    window.addEventListener("resize", setValue);

    return () => {
      window.removeEventListener("resize", setValue);
    };
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-gold-50">
      <div className="flex-1 overflow-auto">{children}</div>
    </div>
  );
};
