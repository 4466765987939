import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonSmsDetailsFragmentDoc } from '../fragments/SalonSmsDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonSmsDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchSalonSmsDetailsQuery = { __typename?: 'RootQueryType', fetchSalonSmsDetails: { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean } };


export const FetchSalonSmsDetailsDocument = gql`
    query FetchSalonSmsDetails {
  fetchSalonSmsDetails {
    ...SalonSmsDetails
  }
}
    ${SalonSmsDetailsFragmentDoc}`;

/**
 * __useFetchSalonSmsDetailsQuery__
 *
 * To run a query within a React component, call `useFetchSalonSmsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonSmsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonSmsDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSalonSmsDetailsQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonSmsDetailsQuery, FetchSalonSmsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonSmsDetailsQuery, FetchSalonSmsDetailsQueryVariables>(FetchSalonSmsDetailsDocument, options);
      }
export function useFetchSalonSmsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonSmsDetailsQuery, FetchSalonSmsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonSmsDetailsQuery, FetchSalonSmsDetailsQueryVariables>(FetchSalonSmsDetailsDocument, options);
        }
export type FetchSalonSmsDetailsQueryHookResult = ReturnType<typeof useFetchSalonSmsDetailsQuery>;
export type FetchSalonSmsDetailsLazyQueryHookResult = ReturnType<typeof useFetchSalonSmsDetailsLazyQuery>;
export type FetchSalonSmsDetailsQueryResult = Apollo.QueryResult<FetchSalonSmsDetailsQuery, FetchSalonSmsDetailsQueryVariables>;