import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { TreatmentFragmentDoc } from '../fragments/Treatment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchTreatmentsQueryVariables = Types.Exact<{
  categoryUuid: Types.Scalars['UUID4'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchTreatmentsQuery = { __typename?: 'RootQueryType', fetchTreatments: { __typename?: 'TreatmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'TreatmentEdge', node: { __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean } }> } };


export const FetchTreatmentsDocument = gql`
    query FetchTreatments($categoryUuid: UUID4!, $first: Int, $after: String, $query: String) {
  fetchTreatments(
    categoryUuid: $categoryUuid
    first: $first
    after: $after
    query: $query
  ) {
    count
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        ...Treatment
      }
    }
  }
}
    ${TreatmentFragmentDoc}`;

/**
 * __useFetchTreatmentsQuery__
 *
 * To run a query within a React component, call `useFetchTreatmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTreatmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTreatmentsQuery({
 *   variables: {
 *      categoryUuid: // value for 'categoryUuid'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFetchTreatmentsQuery(baseOptions: Apollo.QueryHookOptions<FetchTreatmentsQuery, FetchTreatmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchTreatmentsQuery, FetchTreatmentsQueryVariables>(FetchTreatmentsDocument, options);
      }
export function useFetchTreatmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchTreatmentsQuery, FetchTreatmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchTreatmentsQuery, FetchTreatmentsQueryVariables>(FetchTreatmentsDocument, options);
        }
export type FetchTreatmentsQueryHookResult = ReturnType<typeof useFetchTreatmentsQuery>;
export type FetchTreatmentsLazyQueryHookResult = ReturnType<typeof useFetchTreatmentsLazyQuery>;
export type FetchTreatmentsQueryResult = Apollo.QueryResult<FetchTreatmentsQuery, FetchTreatmentsQueryVariables>;