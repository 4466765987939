import { useState, forwardRef, ForwardedRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PhotoIcon } from "@heroicons/react/24/outline";

export const ImageField = forwardRef(
  ({ loadedLogoUrl, errorMessage, ...delegated }: any, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const { setValue } = useForm();

    const handleUpload = async () => {
      const fileInput = document.getElementById("logo-upload") as HTMLInputElement;
      fileInput.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          setValue("logoImageUrl", event.target?.result);
        };
        reader.readAsDataURL(file);
        setSelectedFile(file);
      }
    };

    return (
      <div className="sm:col-span-6">
        <label className="block text-xs text-stone-500">{t("generic.logo")}</label>
        <input
          id="logo-upload"
          accept="image/*"
          className="sr-only"
          type="file"
          {...delegated}
          onChange={(e) => {
            delegated.onChange(e);
            handleFileChange(e);
          }}
          ref={ref}
        />
        <div className="mt-1 flex items-center">
          <div className="h-16 w-16 flex-shrink-0 rounded-full bg-stone-100">
            {(selectedFile && !errorMessage) || loadedLogoUrl ? (
              <img
                crossOrigin="anonymous"
                id="logo-preview"
                className="h-full w-full rounded-full"
                alt=""
                src={
                  selectedFile && !errorMessage ? URL.createObjectURL(selectedFile) : loadedLogoUrl
                }
              />
            ) : (
              <div className="flex h-full items-center justify-center">
                <PhotoIcon className="h-7 w-7 text-stone-300" />
              </div>
            )}
          </div>
          <button
            type="button"
            onClick={handleUpload}
            className="ml-5 rounded-md border border-gold-500 bg-white px-3 py-2 text-sm font-medium leading-4 text-gold-600 shadow-sm hover:bg-stone-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            {t("generic.change")}
          </button>
        </div>
        <span className="block text-xs text-red-600">{errorMessage && errorMessage}</span>
      </div>
    );
  },
);
