import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ConfirmationDialog } from "@/components/dialogs/ConfirmationDialog";
import { usePaymentsStore } from "@/features/payments/hooks";
import { useConfirmationDialog } from "@/hooks/use-confirmation-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { useToasts } from "@/providers/ToastsProvider";
import { PaymentMode, Product, SubscriptionPlan } from "@/types";

type SubscriptionsProviderProps = {
  children: ReactNode;
};

export type SubscriptionsContextInterface = {
  product: Product | null;
  setProduct: Dispatch<SetStateAction<Product | null>>;
  loading: boolean;
} | null;

export const SubscriptionsContext = createContext<SubscriptionsContextInterface>(null);

export const SubscriptionsProvider = ({ children }: SubscriptionsProviderProps) => {
  const [product, setProduct] = useState<Product | null>(null);

  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { makeOrder, makePayment, changeSubscriptionPlan, loading } = usePaymentsStore();
  const { salonPlan, session } = useSessionContext();
  const confirmationDialogState = useConfirmationDialog();

  const showErrorToast = () =>
    showToast({
      type: "error",
      title: t("generic.oops"),
      description: t("generic.somethingWentWrong"),
    });

  const handlePlanChange = async (product: Product) => {
    if (!product.priceId) {
      return showErrorToast();
    }

    if (salonPlan === SubscriptionPlan.Free) {
      const result = await makeOrder({
        orderItems: JSON.stringify([{ price: product.priceId, quantity: 1 }]),
        paymentMode: "subscription",
      });
      if (result.errors) {
        return showErrorToast();
      }
      const { data, errors } = await makePayment({
        accountUuid: session?.accountUuid,
        orderItems: result.data?.makeOrder?.orderItems,
        orderUuid: result.data?.makeOrder?.uuid,
        paymentMode: PaymentMode.Subscription,
      });
      if (errors) {
        return showErrorToast();
      } else if (data?.makePayment?.url) {
        window.location.href = data.makePayment.url;
      }
    } else {
      confirmationDialogState.open({
        title: t("generic.areYouSure"),
        message: t(
          `subscriptions.${
            salonPlan === SubscriptionPlan.Salon
              ? "downgradeSubscriptionConfirmation"
              : "upgradeSubscriptionConfirmation"
          }`,
        ),
        onConfirm: async () => {
          if (product.priceId) {
            const { errors } = await changeSubscriptionPlan(product.priceId);

            if (errors) {
              return showErrorToast();
            } else {
              showToast({
                type: "success",
                title: t("subscriptions.successfullyChanged"),
              });
            }
          }
        },
      });
    }
  };

  useEffect(() => {
    if (product) {
      handlePlanChange(product);
    }
  }, [product]);

  const contextValue = useMemo(
    () => ({
      product,
      setProduct,
      loading: loading.loadingMakePayment || loading.loadingMakeOrder,
    }),
    [product, setProduct, loading.loadingMakePayment, loading.loadingMakeOrder],
  );

  return (
    <SubscriptionsContext.Provider value={contextValue}>
      {children}
      <ConfirmationDialog dialogState={confirmationDialogState} />
    </SubscriptionsContext.Provider>
  );
};
