import { Listbox } from "@headlessui/react";

import { ChevronUpDownIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { Button } from "@/components/ui/Button";
import { Option } from "@/utils/select-utils";

type ClientSelectButtonProps = {
  name: string;
  selectedOption: Option | undefined;
  placeholder: string;
  onEdit?: (clientUuid: string) => void;
  onClear?: () => void;
};

export const ClientSelectButton = ({
  selectedOption,
  placeholder,
  name,
  onEdit,
  onClear,
}: ClientSelectButtonProps) => {
  return (
    <Listbox.Button
      as="div"
      id={name}
      className="relative flex h-11 w-full cursor-pointer appearance-none items-center rounded-md border border-stone-300 bg-white pl-3 pr-20 text-left text-sm transition-colors duration-300 hover:border-stone-400 focus:border-stone-400 focus:outline-none focus:ring-stone-400 disabled:bg-stone-100">
      {selectedOption ? (
        <p className="truncate text-stone-600">{selectedOption.label}</p>
      ) : (
        <p className="text-stone-400">{placeholder}</p>
      )}
      <div className="absolute inset-y-2 right-0 flex items-center gap-1.5 pr-2">
        {onEdit && selectedOption && (
          <Button
            variant="neutral"
            className="p-0"
            onClick={(e) => {
              e.preventDefault();
              onEdit(selectedOption?.value as string);
              e.stopPropagation();
            }}>
            <PencilIcon
              className="h-4 w-4 text-stone-400 hover:text-stone-600"
              aria-hidden="true"
            />
          </Button>
        )}

        {onClear && selectedOption && (
          <Button
            variant="neutral"
            className="p-0"
            onClick={(e) => {
              e.preventDefault();
              onClear();
              e.stopPropagation();
            }}>
            <XMarkIcon className="h-5 w-5 text-stone-400 hover:text-stone-600" aria-hidden="true" />
          </Button>
        )}

        <span className="pointer-events-none -ml-0.5 flex items-center">
          <ChevronUpDownIcon className="h-5 w-5 text-stone-400" aria-hidden="true" />
        </span>
      </div>
    </Listbox.Button>
  );
};
