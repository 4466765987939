import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  autoUpdate,
  size,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react-dom-interactions";

import { TimerangeOption, convertMinutesToHoursAndMinutes } from "@/utils/datetime";
import { cn } from "@/utils/utils";

type OptionProps = {
  children: React.ReactNode;
  active: boolean;
};

const Option = forwardRef<HTMLDivElement, OptionProps & React.HTMLProps<HTMLDivElement>>(
  ({ children, active, ...rest }, ref) => {
    const id = useId();
    return (
      <div
        ref={ref}
        role="option"
        id={id}
        aria-selected={active}
        className={cn(
          "flex cursor-pointer select-none justify-center px-4 py-2",
          active ? "bg-gold-200" : "text-stone-900 hover:bg-gold-100 hover:text-gold-900",
        )}
        {...rest}
        style={rest.style}>
        {children}
      </div>
    );
  },
);

type AutocompleteProps = {
  value: string;
  options: TimerangeOption[];
  ignoreFilter?: boolean;
  defaultOptionIndex?: number;
  onChange: (data: ChangeEvent<HTMLInputElement> | string) => void;
  renderField: (props: Record<string, unknown>) => ReactNode;
};

export const AutoComplete = ({
  value,
  options,
  ignoreFilter,
  defaultOptionIndex = 0,
  onChange,
  renderField,
}: AutocompleteProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const filteredOptions = useMemo(
    () =>
      ignoreFilter
        ? options
        : options.filter((item) => item.value.toLowerCase().startsWith(value.toLowerCase())),
    [value, options, ignoreFilter],
  );

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { x, y, reference, floating, strategy, context, refs } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  useLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this effect runs when
    // the styles have **not yet** been applied to the element. A rAF ensures
    // we wait until the position is ready, and also runs before paint.
    // https://floating-ui.com/docs/react-dom#effects
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listRef.current[activeIndex]?.scrollIntoView({ block: "nearest" });
      }
    });
  }, [activeIndex]);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context, { role: "listbox" }),
    useDismiss(context),
    useListNavigation(context, {
      listRef,
      activeIndex,
      onNavigate: setActiveIndex,
      virtual: true,
      loop: true,
      focusItemOnHover: false,
    }),
  ]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }

    onChange(event);
  };

  const handleKeyDown = (event: KeyboardEvent<Element>) => {
    if (event.key === "Enter" && activeIndex != null && filteredOptions[activeIndex]) {
      event.preventDefault();
      setInputValue(filteredOptions[activeIndex].value);
      setActiveIndex(null);
      setOpen(false);
    }

    if (event.key === "Tab") {
      setOpen(false);
    }
  };

  const setInputValue = (data: string) => {
    onChange(data);
  };

  return (
    <>
      {renderField({
        ...getReferenceProps({
          ref: reference,
          value,
          "aria-autocomplete": "list",
          onChange: handleChange,
          onKeyDown: handleKeyDown,
          onClick: () => {
            setActiveIndex(defaultOptionIndex);
            setOpen(true);
          },
        }),
      })}
      {open && filteredOptions.length > 0 && (
        <div
          className="z-10 mt-2 w-full overflow-auto rounded border border-stone-300 bg-white py-1 text-sm shadow-lg ring-0 focus:outline-none"
          {...getFloatingProps({
            ref: floating,
            style: {
              position: strategy,
              left: x ?? 0,
              top: y ?? 0,
            },
          })}>
          {filteredOptions.map((option, index) => (
            <Option
              {...getItemProps({
                key: option.value,
                ref(node) {
                  listRef.current[index] = node;
                },
                onClick() {
                  setInputValue(option.value);
                  setOpen(false);
                  refs.reference.current?.focus();
                },
              })}
              active={activeIndex === index}>
              <div className="flex w-full flex-col justify-start">
                <span>{option.value}</span>
                {option.props?.diff && (
                  <span className="text-xs text-stone-400">
                    + {convertMinutesToHoursAndMinutes(Math.abs(option.props.diff))}
                  </span>
                )}
              </div>
            </Option>
          ))}
        </div>
      )}
    </>
  );
};
