import { useMemo, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SEX_LABELS_MAP } from "@/features/clients/constants";
import { useClientStore } from "@/features/clients/hooks/use-client-store";
import { useToasts } from "@/providers/ToastsProvider";
import type { AddClientInput, AddClientMutation, Client, UpdateClientMutation } from "@/types";

import { ClientFormProps } from "../components/ClientFormDialog";

type Input = AddClientInput;

export const useClientForm = ({ client, action, onClose, onSave }: ClientFormProps) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();

  const tagsUuids = useMemo(() => client?.tags.map((tag) => tag.uuid), [client?.tags]);

  const form = useForm<Input>({
    mode: "onBlur",
    shouldUnregister: true,
    defaultValues: {
      ...client,
      tagsUuids: tagsUuids ?? [],
    },
  });

  const {
    loadingUpdate,
    updateError,
    updateClient,
    addClient,
    addError,
    loadingAdd,
    deleteClient,
  } = useClientStore(client?.uuid);

  const clientGenderSelectOptions = useMemo(
    () =>
      Object.keys(SEX_LABELS_MAP).map((key) => ({
        value: key,
        label: t(SEX_LABELS_MAP[key]),
      })),
    [SEX_LABELS_MAP],
  );

  const mutationError = updateError || addError;
  const isMutationLoading = loadingUpdate || loadingAdd;

  const handleClientSubmit: SubmitHandler<Input> = async (data) => {
    const { errors, data: clientData } =
      action === "update" ? await updateClient(data, client?.uuid) : await addClient(data);

    const clientResponse: Client =
      action === "update"
        ? (clientData as UpdateClientMutation).updateClient
        : (clientData as AddClientMutation).addClient;

    if (!errors) {
      clientResponse && onSave && onSave(clientResponse);
      showToast({
        title:
          action === "update" ? t("clients.updateClientSuccess") : t("clients.addClientSuccess"),
      });
      onClose?.();
    }
  };

  /* re-render form when tagsUuids change - this won't happen on prop change as expected, because defaultValues matter only on first render */
  useEffect(() => {
    form.reset({
      ...form.getValues(),
      tagsUuids,
    });
  }, [tagsUuids]);

  return {
    form,
    clientGenderSelectOptions,
    mutationError,
    isMutationLoading,
    handleClientSubmit,
    deleteClient,
  };
};
