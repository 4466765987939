import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHeadCategoriesPositionsMutationVariables = Types.Exact<{
  data: Types.UpdateHeadCategoriesPositionsInput;
}>;


export type UpdateHeadCategoriesPositionsMutation = { __typename?: 'RootMutationType', updateHeadCategoriesPositions: string };


export const UpdateHeadCategoriesPositionsDocument = gql`
    mutation UpdateHeadCategoriesPositions($data: UpdateHeadCategoriesPositionsInput!) {
  updateHeadCategoriesPositions(data: $data)
}
    `;
export type UpdateHeadCategoriesPositionsMutationFn = Apollo.MutationFunction<UpdateHeadCategoriesPositionsMutation, UpdateHeadCategoriesPositionsMutationVariables>;

/**
 * __useUpdateHeadCategoriesPositionsMutation__
 *
 * To run a mutation, you first call `useUpdateHeadCategoriesPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHeadCategoriesPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHeadCategoriesPositionsMutation, { data, loading, error }] = useUpdateHeadCategoriesPositionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateHeadCategoriesPositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHeadCategoriesPositionsMutation, UpdateHeadCategoriesPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHeadCategoriesPositionsMutation, UpdateHeadCategoriesPositionsMutationVariables>(UpdateHeadCategoriesPositionsDocument, options);
      }
export type UpdateHeadCategoriesPositionsMutationHookResult = ReturnType<typeof useUpdateHeadCategoriesPositionsMutation>;
export type UpdateHeadCategoriesPositionsMutationResult = Apollo.MutationResult<UpdateHeadCategoriesPositionsMutation>;
export type UpdateHeadCategoriesPositionsMutationOptions = Apollo.BaseMutationOptions<UpdateHeadCategoriesPositionsMutation, UpdateHeadCategoriesPositionsMutationVariables>;