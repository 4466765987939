import { NavLink } from "react-router-dom";

import { ChevronRightIcon } from "@heroicons/react/24/outline";

import { Employee } from "@/types";

type SidebarAccountButtonProps = {
  setIsOpen: (value: boolean) => void;
  employee: Employee;
};

export const SidebarAccountButton = ({ setIsOpen, employee }: SidebarAccountButtonProps) => {
  return (
    <NavLink
      to="/settings?tab=account"
      onClick={() => setIsOpen(false)}
      className="my-4 flex rounded-md border border-dashed border-gold-400 bg-gold-100 px-3 py-2 shadow">
      <div className="flex w-full items-center justify-between">
        <div>
          <span className="font-serif text-lg text-stone-700">{employee?.fullName}</span>
          <span className="-mt-0.5 block text-xs text-stone-500">{employee?.email}</span>
        </div>
        <ChevronRightIcon className="size-5 text-gold-500" />
      </div>
    </NavLink>
  );
};
