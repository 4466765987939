import { ReactNode, useState } from "react";

import type { ButtonVariant } from "./../components/ui/Button";

export type DialogAction<Result> = {
  label: string;
  variant?: ButtonVariant;
  result?: Result;
  onClick: () => void;
};

type CustomActionDialogConfig<Result> = {
  title: string;
  message: ReactNode;
  actions: DialogAction<Result>[];
  onClose?: (result: Result) => void;
};

type CustomActionDialogStateInternal<Result> = CustomActionDialogConfig<Result> & {
  open: boolean;
};

export const useCustomActionDialog = <Result>() => {
  const [state, setState] = useState({
    open: false,
    title: "",
    message: null,
    actions: [],
    onClose: () => {},
  } as CustomActionDialogStateInternal<Result>);

  return {
    open(config: CustomActionDialogConfig<Result>) {
      setState({
        ...config,
        open: true,
      });
    },
    close() {
      setState((prevState) => ({
        ...prevState,
        open: false,
      }));
    },
    internalState: state,
  };
};

export type CustomActionDialogProps<Result> = {
  dialogState: ReturnType<typeof useCustomActionDialog<Result>>;
  children?: ReactNode;
};
