import { ReactNode } from "react";

import {
  CheckCircleIcon,
  XCircleIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import { cn } from "@/utils/utils";

type AlertProps = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  children?: ReactNode;
  type?: "warning" | "error" | "success" | "info";
  textSize?: "xs" | "sm";
};

export const Alert = ({
  title,
  description,
  children,
  type = "info",
  textSize = "sm",
}: AlertProps) => {
  return (
    <div
      className={cn("rounded-lg p-4", {
        "bg-gold-50": type === "warning",
        "bg-red-50": type === "error",
        "bg-green-50": type === "success",
        "bg-blue-50": type === "info",
      })}>
      <div className="flex">
        <div className="flex-shrink-0">
          {type === "warning" && (
            <ExclamationTriangleIcon className="h-5 w-5 text-gold-600" aria-hidden="true" />
          )}
          {type === "error" && <XCircleIcon className="h-5 w-5 text-red-600" aria-hidden="true" />}
          {type === "success" && (
            <CheckCircleIcon className="h-5 w-5 text-green-600" aria-hidden="true" />
          )}
          {type === "info" && (
            <InformationCircleIcon className="h-5 w-5 text-blue-600" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3 grid gap-2 text-sm">
          {title && (
            <h3
              className={cn("font-medium", {
                "text-gold-700": type === "warning",
                "text-red-700": type === "error",
                "text-green-700": type === "success",
                "text-blue-700": type === "info",
              })}>
              {title}
            </h3>
          )}
          <div
            className={cn("mt-0.5 grid justify-items-start gap-4", {
              "text-gold-700": type === "warning",
              "text-red-700": type === "error",
              "text-green-700": type === "success",
              "text-blue-700": type === "info",
              "text-xs": textSize === "xs",
              "text-sm": textSize === "sm",
            })}>
            {description && <p>{description}</p>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
