import { t } from "i18next";

export const MESSAGES_VARIABLES = {
  appointmentDate: {
    name: t("messages.templates.appointmentDate"),
    value: "appointment_date",
    example: "12/09/2022",
  },
  appointmentTime: {
    name: t("messages.templates.appointmentTime"),
    value: "appointment_time",
    example: "14:00",
  },
  cancelLink: {
    name: t("messages.templates.cancelLink"),
    value: "cancel_link",
    example: "https://estetify.pl/c/7pLJfXU",
  },
  clientName: {
    name: t("messages.templates.clientName"),
    value: "client_name",
    example: "Aleksandra",
  },
};

export const SENDER_NAME_OPTIONS = [
  {
    value: "Salon",
    label: t("onboarding.salonSmsDetails.senderNameOptions.salon"),
  },
  {
    value: "Gabinet",
    label: t("onboarding.salonSmsDetails.senderNameOptions.cabinet"),
  },
  {
    value: "custom",
    label: t("onboarding.salonSmsDetails.senderNameOptions.custom"),
  },
];

export const SENDER_NAME_MAX_LENGTH = 11;

export const GSM_7BIT_REGEXP =
  /^[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&'()*+,\-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà]*$/;
export const GSM_7BIT_EXT_REGEXP =
  /^[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&'()*+,\-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~\]|€]*$/;
export const GSM_7BIT_EXT_CHAR_REGEXP = /^[\^{}\\[~\]|€]$/;
