import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "@/components/ui/Button";
import { TextAreaField } from "@/components/ui/TextAreaField";
import { AppointmentFormInput } from "@/features/calendar/components/Appointment/AppointmentEditForm";
import { ImagesUploader } from "@/features/calendar/components/Appointment/ImagesUploader";
import { useAppointmentForm } from "@/features/calendar/hooks/use-appointment-form";
import { isoTimeRangeToTime } from "@/features/calendar/utils/time";
import { useAwsStore } from "@/hooks/use-aws-store";
import { useSessionContext } from "@/providers/SessionProvider";
import { Appointment } from "@/types";
import { removeImageFromS3, uploadImageToS3 } from "@/utils/s3-utils";

type ClientAppointmentNoteFormProps = {
  onSubmit: SubmitHandler<AppointmentFormInput>;
  onCancelClick: () => void;
  appointment: Appointment;
};

export const ClientAppointmentNoteForm = ({
  onSubmit,
  onCancelClick,
  appointment,
}: ClientAppointmentNoteFormProps) => {
  const { t } = useTranslation();
  const { isSalonPlan } = useSessionContext();

  const defaultValues = {
    ...appointment,
    treatments: appointment.treatments.map((treatment) => ({
      ...treatment,
      timeRange: isoTimeRangeToTime(treatment.timeRange),
    })),
    date: new Date(appointment.treatments[0].timeRange.from),
    sendNotification: false,
    imagesUrls: appointment.imagesUrls?.map((image) => image.imageUrl) || [],
  };

  const {
    addImages,
    removeImage,
    images,
    loadServerImages,
    imagesErrors,
    setImagesErrors,
    imagesLoading,
    setImagesLoading,
    getServerImagesSize,
    setServerImageToRemove,
    currentImagesUrls,
  } = useAwsStore();
  const { form } = useAppointmentForm({
    defaultValues,
    appointment,
  });

  const {
    register,
    setValue,
    formState: { errors, isValid, isDirty },
    handleSubmit,
  } = form;

  const imagesToRemove = useMemo(() => {
    return images.filter((image) => image.toRemove);
  }, [images]);

  const imagesToUpload = useMemo(() => {
    return images.filter((image) => !image.isServerImage);
  }, [images]);

  const handleSubmitClick = async (form: AppointmentFormInput) => {
    setImagesLoading(true);

    try {
      if (imagesToUpload.length > 0) {
        await Promise.all(
          imagesToUpload.map((image) => {
            return uploadImageToS3(image.file, image.name);
          }),
        );
      }
    } catch (error) {
      console.error("Error while uploading images", error);
    } finally {
      setImagesLoading(false);
    }

    try {
      if (imagesToRemove.length > 0) {
        await Promise.all(imagesToRemove.map((image) => removeImageFromS3(image.name)));
      }
    } catch (error) {
      console.error("Error while removing images", error);
    } finally {
      setImagesLoading(false);
    }

    await onSubmit(form);
    setImagesLoading(false);
  };

  useEffect(() => {
    const serverImages = appointment.imagesUrls?.map((image) => image.imageUrl) || [];

    if (serverImages.length > 0) {
      loadServerImages(serverImages);
    }
  }, [appointment.imagesUrls]);

  useEffect(() => {
    setValue("imagesUrls", currentImagesUrls, { shouldDirty: true });
  }, [currentImagesUrls.length]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleSubmitClick)} noValidate>
        <div>
          <TextAreaField
            {...register("note", { maxLength: 500 })}
            label={t("appointments.form.note")}
            placeholder={t("generic.note")}
            errorMessage={
              errors.note &&
              t(`validation.${errors.note.type}`, {
                name: t("generic.note"),
                maxLength: 500,
              })
            }
          />
          {isSalonPlan && (
            <ImagesUploader
              images={images}
              imagesErrors={imagesErrors}
              addImages={addImages}
              removeImage={removeImage}
              setImagesErrors={setImagesErrors}
              handleRemoveFromServer={setServerImageToRemove}
              getServerImagesSize={getServerImagesSize}
            />
          )}
        </div>
        <div className="flex justify-end gap-2 pt-4">
          <Button variant="secondary-outline" size="small" onClick={onCancelClick}>
            {t("generic.cancel")}
          </Button>
          <Button
            disabled={!isValid || !isDirty || imagesLoading}
            loading={imagesLoading}
            size="small"
            type="submit">
            {imagesLoading ? t("appointments.weAreSavingImages") : t("generic.save")}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
