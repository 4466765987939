import {
  createContext,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  Dispatch,
  useEffect,
} from "react";

import { useClientsStore } from "@/features/clients/hooks";
import { useTagsStore } from "@/features/clients/hooks/tags/use-tags-store";
import {
  Client,
  ClientFilters,
  ClientSortBy,
  FetchSalonClientsQueryVariables,
  SortOrder,
} from "@/types";

type ClientsContextProps = {
  filters: ClientFilters;
  setFilters: Dispatch<SetStateAction<ClientFilters>>;
  query: FetchSalonClientsQueryVariables["query"];
  setQuery: Dispatch<SetStateAction<FetchSalonClientsQueryVariables["query"]>>;
  filteredTagsSelectOptions: ReturnType<typeof useTagsStore>["tagsSelectOptions"];
  toggleClientSelection: (uuid: string) => void;
  selectedClients: Record<string, boolean>;
  deselectAllClients: () => void;
  selectVisibleClients: (visibleClients: Client[]) => void;
  isTagsSelected: boolean;
  clientsStore: ReturnType<typeof useClientsStore>;
  count: number;
  clientsCount: number;
};

type ClientsProviderProps = {
  children: React.ReactNode;
};

const ClientsContext = createContext<ClientsContextProps | null>(null);

export const ClientsProvider = ({ children }: ClientsProviderProps) => {
  const [query, setQuery] = useState<FetchSalonClientsQueryVariables["query"]>(undefined);
  const [filters, setFilters] = useState<ClientsContextProps["filters"]>({
    tagsUuids: [],
  });
  const [selectedClients, setSelectedClients] = useState<Record<string, boolean>>({});

  const { tagsSelectOptions } = useTagsStore();

  const toggleClientSelection = (uuid: string) => {
    setSelectedClients((prev) => {
      const newState = { ...prev };
      newState[uuid] = !newState[uuid];
      return newState;
    });
  };

  const deselectAllClients = () => {
    setSelectedClients({});
  };

  const selectVisibleClients = (visibleClients: Client[]) => {
    const newSelectedClients = { ...selectedClients };
    visibleClients.forEach((client: Client) => {
      if (client.phone) newSelectedClients[client.uuid] = true;
    });
    setSelectedClients(newSelectedClients);
  };

  const filteredTagsSelectOptions = tagsSelectOptions.filter((tag) =>
    filters.tagsUuids && filters?.tagsUuids.length > 0
      ? filters.tagsUuids.includes(tag.value)
      : true,
  );

  const isTagsSelected = filters.tagsUuids ? filters?.tagsUuids.length > 0 : false;

  const clientsStore = useClientsStore({
    sortBy: ClientSortBy.Name,
    sortOrder: SortOrder.Asc,
    filters,
  });

  const { refetchWithQuery, count, clientsCount } = clientsStore;
  useEffect(() => {
    refetchWithQuery(query || undefined);
  }, [query]);

  const value = useMemo(() => {
    return {
      filters,
      setFilters,
      filteredTagsSelectOptions,
      toggleClientSelection,
      selectedClients,
      deselectAllClients,
      selectVisibleClients,
      isTagsSelected,
      clientsStore,
      query,
      count,
      clientsCount,
      setQuery,
    };
  }, [
    filters,
    setFilters,
    filteredTagsSelectOptions,
    toggleClientSelection,
    isTagsSelected,
    deselectAllClients,
    selectVisibleClients,
    selectedClients,
    clientsStore,
    query,
    count,
    clientsCount,
    setQuery,
  ]);

  return <ClientsContext.Provider value={value}>{children}</ClientsContext.Provider>;
};

export const useClientsContext = () => {
  const context = useContext(ClientsContext);

  if (!context) {
    throw new Error(`${useClientsContext.name} must be used within an ${ClientsProvider.name}`);
  }

  return context;
};
