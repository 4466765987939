import { useFetchIncomeNumbersQuery } from "@/features/statistics/queries/FetchIncomeNumbers.generated";
import { StatisticsPeriod, StatisticsDisplayFormat } from "@/types";

export const useIncomeNumbersStore = (period: StatisticsPeriod) => {
  const displayFormat =
    period === StatisticsPeriod.Day ? StatisticsDisplayFormat.Hour : StatisticsDisplayFormat.Day;

  const { data, loading } = useFetchIncomeNumbersQuery({
    variables: {
      period,
      displayFormat,
    },
  });

  return {
    loading,
    income: data?.fetchIncomeNumbers,
  };
};
