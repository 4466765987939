import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
  HeadObjectCommand,
} from "@aws-sdk/client-s3";

export const AWS_CONFIG = {
  bucketName: import.meta.env.VITE_AWS_S3_BUCKET as string,
  region: import.meta.env.VITE_AWS_REGION as string,
  accessKeyId: import.meta.env.VITE_AWS_ACCESS_KEY as string,
  secretAccessKey: import.meta.env.VITE_AWS_SECRET_ACCESS_KEY as string,
};

export const uploadImageToS3 = async (image: File, fileName: string) => {
  const s3 = new S3Client({
    region: AWS_CONFIG.region,
    credentials: {
      accessKeyId: AWS_CONFIG.accessKeyId,
      secretAccessKey: AWS_CONFIG.secretAccessKey,
    },
  });

  const command = new PutObjectCommand({
    Bucket: AWS_CONFIG.bucketName,
    Key: fileName,
    Body: image,
    ContentType: image.type,
  });

  await s3.send(command);
};

export const removeImageFromS3 = async (key: string) => {
  const s3 = new S3Client({
    region: AWS_CONFIG.region,
    credentials: {
      accessKeyId: AWS_CONFIG.accessKeyId,
      secretAccessKey: AWS_CONFIG.secretAccessKey,
    },
  });

  const command = new DeleteObjectCommand({
    Bucket: AWS_CONFIG.bucketName,
    Key: key,
  });

  await s3.send(command);
};

export const getS3FileSize = async (key: string) => {
  const s3 = new S3Client({
    region: AWS_CONFIG.region,
    credentials: {
      accessKeyId: AWS_CONFIG.accessKeyId,
      secretAccessKey: AWS_CONFIG.secretAccessKey,
    },
  });

  const command = new HeadObjectCommand({
    Bucket: AWS_CONFIG.bucketName,
    Key: key,
  });

  const response = await s3.send(command);

  return response.ContentLength;
};

export const createAwsS3Url = (fileName: string) => {
  return `https://${AWS_CONFIG.bucketName}.s3.${AWS_CONFIG.region}.amazonaws.com/${fileName}`;
};
