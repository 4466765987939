import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ArrowsRightLeftIcon,
  BanknotesIcon,
  CreditCardIcon,
  GiftTopIcon,
} from "@heroicons/react/24/outline";

import { FormSection } from "@/components/layout/FormSection";
import { Button } from "@/components/ui/Button";
import { Spinner } from "@/components/ui/Spinner";
import { useSalonPaymentMethodsStore } from "@/features/salon/hooks/use-salon-payment-methods-store";
import { BlikIcon } from "@/icons/BlikIcon";
import { useToasts } from "@/providers/ToastsProvider";
import { SalonPaymentMethodInput, SalonPaymentMethods } from "@/types";
import { cn } from "@/utils/utils";

import { PaymentMethodsFormField } from "./PaymentMethodsFormField";

type SalonPaymentIcons = {
  name: SalonPaymentMethods;
  icon: React.ReactNode;
};

const SALON_PAYMENT_METHODS: SalonPaymentIcons[] = [
  {
    name: SalonPaymentMethods.Cash,
    icon: <BanknotesIcon className="mr-3 h-5 w-5 shrink-0" />,
  },
  {
    name: SalonPaymentMethods.CreditCard,
    icon: <CreditCardIcon className="mr-3 h-5 w-5 shrink-0" />,
  },
  {
    name: SalonPaymentMethods.BankTransfer,
    icon: <ArrowsRightLeftIcon className="mr-3 h-5 w-5 shrink-0" />,
  },
  {
    name: SalonPaymentMethods.Blik,
    icon: <BlikIcon className={cn("mr-3 h-5 w-5 shrink-0", "py-[3px]")} />,
  },
  {
    name: SalonPaymentMethods.Voucher,
    icon: <GiftTopIcon className="mr-3 h-5 w-5 shrink-0" />,
  },
];

type FormInput = {
  paymentMethods: {
    [key: string]: {
      enabled: boolean;
    };
  };
};

export const SaleSettings = () => {
  const { t } = useTranslation();
  const { salonPaymentMethods, fetchLoading, loading, updateSalonPaymentMethods } =
    useSalonPaymentMethodsStore();
  const { showToast } = useToasts();

  const salonPaymentMethodsMapped = salonPaymentMethods?.map((paymentMethod) => {
    const paymentMethodMapped = SALON_PAYMENT_METHODS.find(
      (item) => item.name === paymentMethod?.name,
    );

    return {
      ...paymentMethod,
      icon: paymentMethodMapped?.icon,
    };
  });

  const methods = useForm<FormInput>();

  const {
    reset,
    handleSubmit,
    formState: { isDirty, isValid },
  } = methods;

  const onSubmit = (data: FormInput) => {
    const props: SalonPaymentMethodInput[] = Object.keys(data.paymentMethods).map((key) => ({
      uuid: key,
      enabled: data.paymentMethods[key].enabled,
    }));

    try {
      updateSalonPaymentMethods(props);
      showToast({
        title: t("generic.updated"),
        description: t("settings.saleSettings.updateSuccess"),
      });
      reset({
        paymentMethods: props.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.uuid]: {
              enabled: curr.enabled,
            },
          }),
          {},
        ),
      });
    } catch (error) {
      showToast({
        title: t("generic.error"),
        description: t("settings.saleSettings.updateError"),
      });
    }
  };

  return (
    <div className="grid gap-2 divide-y-[1px]">
      <FormSection
        headingText={t("settings.saleSettings.salonPaymentMethods")}
        descriptionText={t("settings.saleSettings.salonPaymentMethodsDescription")}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fetchLoading ? (
              <Spinner />
            ) : (
              <ul className="mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2">
                {salonPaymentMethodsMapped?.map((paymentMethod) => (
                  <PaymentMethodsFormField
                    key={paymentMethod?.uuid}
                    paymentMethod={paymentMethod}
                  />
                ))}
              </ul>
            )}
            <Button
              type="submit"
              variant="primary"
              size="medium"
              disabled={loading || !isDirty || !isValid}>
              {t("generic.save")}
            </Button>
          </form>
        </FormProvider>
      </FormSection>
    </div>
  );
};
