import { createContext, useContext, useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";

import { Spinner } from "@/components/ui/Spinner";
type SyncSessionProviderProps = {
  children: React.ReactNode;
};

type SyncSessionContextProps = {
  token: string;
} | null;

export const SyncSessionContext = createContext<SyncSessionContextProps>(null);

/** Provide app when Auth0 token is available. This is mandatory for GraphQL subscriptions, because Phoenix Websockets require sync token, instead of async one. It can possibly be done better way without being blocking. */
export const SyncSessionProvider = ({ children }: SyncSessionProviderProps) => {
  const [token, setToken] = useState<string | null>(null);

  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    const paths = window.location.pathname.split("/");

    // Temporary redirect from https://app.estetify.pl/c/:token to https://estetify.pl/c/:token
    if (paths[1] === "c") {
      window.location.href = `https://estetify.pl/c/${paths[2]}`;
      return;
    }

    if (isLoading) {
      return;
    }

    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => setToken(token));
      return;
    }

    loginWithRedirect({
      screen_hint: "login",
    });
  }, [isAuthenticated, isLoading]);

  return token ? (
    <SyncSessionContext.Provider value={{ token }}>{children}</SyncSessionContext.Provider>
  ) : (
    <div className="absolute inset-1/2 h-[15vh] w-[15vh] -translate-x-1/2 -translate-y-1/2">
      <Spinner className="h-full w-full opacity-50" />
    </div>
  );
};

export const useSyncSessionContext = () => {
  const context = useContext(SyncSessionContext);

  if (context === undefined) {
    throw new Error("useSyncSessionContext was used outside of provider");
  }

  if (context?.token === undefined) {
    throw new Error("useSyncSessionContext used when token was undefined");
  }

  return {
    ...context,
  };
};
