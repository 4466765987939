import { useTranslation } from "react-i18next";

import { CheckIcon } from "@heroicons/react/24/outline";

import { Plan } from "@/features/payments/models";
import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

type PlanCardProps = {
  plan: Plan;
  activePeriod: "monthly" | "annually";
};

export const PlanCard = ({ plan, activePeriod }: PlanCardProps) => {
  const { t } = useTranslation();
  const { salonPlan } = useSessionContext();

  return (
    <section className="flex h-full flex-col overflow-hidden rounded-sm rounded-xl bg-white px-3 py-6 shadow-lg shadow-stone-900/5 xs:px-6 sm:py-8">
      <h3 className="flex items-center text-sm font-semibold text-stone-900">
        {plan.name} {salonPlan === plan.value && <span>&nbsp;({t("generic.current")})</span>}
      </h3>
      <p className="relative mt-5 flex text-3xl tracking-tight text-stone-900">
        {plan.price.monthly === plan.price.annually ? (
          plan.price.monthly
        ) : (
          <>
            <span
              aria-hidden={activePeriod === "annually"}
              className={cn(
                "transition duration-300",
                activePeriod === "annually" &&
                  "pointer-events-none translate-x-6 select-none opacity-0",
              )}>
              {plan.price.monthly}
            </span>
            <span
              aria-hidden={activePeriod === "monthly"}
              className={cn(
                "absolute left-0 top-0 transition duration-300",
                activePeriod === "monthly" &&
                  "pointer-events-none -translate-x-6 select-none opacity-0",
              )}>
              {plan.price.annually}{" "}
              <span className="text-sm text-gold-700">({t("pricing.youSave")} 20%)</span>
            </span>
          </>
        )}
      </p>
      {plan.netPrice.monthly && activePeriod === "monthly" && (
        <p className="mt-1 text-sm lowercase text-stone-500">{plan.netPrice.monthly}</p>
      )}
      {plan.netPrice.annually && activePeriod === "annually" && (
        <p className="mt-1 text-sm lowercase text-stone-500">{plan.netPrice.annually}</p>
      )}

      <p className="mt-3 text-sm text-stone-700">{plan.description}</p>
      <div className="mt-6">
        <ul role="list" className="-my-2 divide-y divide-stone-200 text-sm text-stone-700">
          {plan.mainFeatures?.map((feature) => (
            <li key={feature.id} className="flex py-2">
              <CheckIcon className="h-6 w-6 flex-none text-gold-700" />
              <span className="ml-4">{feature.value}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4 flex w-full py-2 lg:justify-start">{plan.button}</div>
    </section>
  );
};
