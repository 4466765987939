import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { ListBulletIcon } from "@heroicons/react/24/outline";

import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Category as CategoryType, HeadCategory } from "@/types";

import { useCategoriesStore, useHeadCategoriesStore } from "../hooks";
import { Category } from "./services/Category";
import { Group } from "./services/Group";

type ServicesProps = {
  groups: HeadCategory[];
};

export const Services = ({ groups }: ServicesProps) => {
  const { t } = useTranslation();
  const { updateHeadCategoriesPositions } = useHeadCategoriesStore();
  const { updateCategoriesPositions } = useCategoriesStore();
  const groupsId = useMemo(() => groups.map((group) => group.uuid), [groups]);
  const [activeGroup, setActiveGroup] = useState<HeadCategory | null>(null);
  const [activeCategory, setActiveCategory] = useState<CategoryType | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8,
      },
    }),
  );

  const handleGroupDragEnd = (activeGroupId: string, overGroupId: string): void => {
    if (activeGroupId === overGroupId) return;

    const activeGroupIndex = groupsId.indexOf(activeGroupId);
    const overGroupIndex = groupsId.indexOf(overGroupId);

    const newGroupsId = [...groupsId];
    newGroupsId.splice(activeGroupIndex, 1);
    newGroupsId.splice(overGroupIndex, 0, activeGroupId);

    updateHeadCategoriesPositions({
      data: {
        sortedHeadCategoryUuids: newGroupsId,
      },
    });
  };

  const handleCategoryDragEnd = (
    activeCategory: CategoryType,
    overCategory: CategoryType,
  ): void => {
    if (!activeCategory || !overCategory) return;

    const activeGroup = groups.find((group) =>
      group.categories?.some((cat) => cat.uuid === activeCategory.uuid),
    );
    const overGroup = groups.find((group) =>
      group.categories?.some((cat) => cat.uuid === overCategory.uuid),
    );

    if (!activeGroup || !overGroup || activeGroup.uuid !== overGroup.uuid) return;
    if (!activeGroup.categories) return;

    const groupId = activeGroup.uuid;
    const categoryIds = activeGroup.categories.map((cat) => cat.uuid);

    const activeIndex = categoryIds.indexOf(activeCategory.uuid);
    const overIndex = categoryIds.indexOf(overCategory.uuid);

    if (activeIndex === overIndex) return;

    const newCategoryIds = [...categoryIds];
    newCategoryIds.splice(activeIndex, 1);
    newCategoryIds.splice(overIndex, 0, activeCategory.uuid);

    updateCategoriesPositions({
      data: {
        headCategoryUuid: groupId,
        sortedCategoryUuids: newCategoryIds,
      },
    });
  };

  const onDragEnd = (event: DragEndEvent) => {
    setActiveGroup(null);
    setActiveCategory(null);

    const { active, over } = event;

    if (!over) return;

    const activeType = active.data.current?.type;
    const overType = over.data.current?.type;

    switch (activeType) {
      case "group":
        if (overType === "group") {
          handleGroupDragEnd(active.id as string, over.id as string);
        }
        break;
      case "category":
        if (overType === "category") {
          const activeCategory = event.active.data.current?.category as CategoryType;
          const overCategory = event.over?.data.current?.category as CategoryType;
          handleCategoryDragEnd(activeCategory, overCategory);
        }
        break;
      default:
        break;
    }
  };

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === "group") {
      const group = event.active.data.current.group as HeadCategory;
      setActiveGroup(group);
      return;
    }

    if (event.active.data.current?.type === "category") {
      const category = event.active.data.current.category as CategoryType;
      setActiveCategory(category);
      return;
    }
  };

  if (!groups.length) {
    return (
      <div className="flex flex-col justify-center rounded-lg border border-dashed border-stone-200 px-4 py-6 text-center">
        <ListBulletIcon className="mx-auto mb-2 size-8 text-stone-700" />
        <span className="mb-0.5 text-xs font-semibold text-slate-900">
          {t("services.headCategories.noGroups")}
        </span>
        <p className="mb-4 text-sm text-stone-500">{t("services.headCategories.addFirstGroup")}</p>
      </div>
    );
  }

  return (
    <DndContext
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      collisionDetection={closestCorners}
      modifiers={[restrictToParentElement]}
      sensors={sensors}>
      <div className="relative -mx-4 flex h-fit grow flex-col rounded-lg bg-white py-2 sm:mx-0 sm:px-4 sm:py-6">
        <SortableContext items={groupsId} strategy={verticalListSortingStrategy}>
          {groups.map((group) => (
            <Group key={group.uuid} group={group} showDndHandle={groups.length > 1} />
          ))}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay className="select-none">
          {activeGroup && <Group group={activeGroup} />}
          {activeCategory && <Category category={activeCategory} />}
        </DragOverlay>,
        document.body,
      )}
    </DndContext>
  );
};
