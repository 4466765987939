import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { parseISO } from "date-fns";

import { Spinner } from "@/components/ui/Spinner";
import { integerToPrice } from "@/features/payments/utils/index";
import { CHART_OPTIONS } from "@/features/statistics/constants";
import { useIncomeNumbersStore } from "@/features/statistics/hooks/use-income-numbers-store";
import { StatisticsPeriod } from "@/types";

import { timeRange } from "../models/index";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

type StatisticsChartProps = {
  timeRange: timeRange;
};

const CHART_COLORS = {
  border: "#9778ff",
  background: "#9778ff1A",
};

export const StatisticsChart = ({ timeRange }: StatisticsChartProps) => {
  const { t, i18n } = useTranslation();

  const period =
    timeRange === "TODAY"
      ? StatisticsPeriod.Day
      : timeRange === "WEEK"
      ? StatisticsPeriod.Week
      : StatisticsPeriod.Month;

  const { income, loading } = useIncomeNumbersStore(period);

  const data = useMemo(
    () => ({
      labels: income?.map((number) => {
        const date = parseISO(number.date);

        return period === StatisticsPeriod.Day
          ? date.toLocaleTimeString(i18n.language, {
              hour: "2-digit",
              minute: "2-digit",
            })
          : date.toLocaleDateString(i18n.language, {
              month: "2-digit",
              day: "2-digit",
            });
      }),
      datasets: [
        {
          label: t("statistics.income"),
          data: income?.map((number) => integerToPrice(number.income || 0)),
          borderColor: CHART_COLORS.border,
          backgroundColor: CHART_COLORS.background,
          fill: "start",
        },
      ],
    }),
    [income],
  );

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="h-[40vh] w-full">
      <Line options={CHART_OPTIONS} data={data} />
    </div>
  );
};
