import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { ErrorBoundary } from "@appsignal/react";
import { appsignal } from "@utils/appsignal";

import { AppNestedProviders } from "@providers/AppNestedProviders";

import { Meta } from "@/components/Meta";
import { ApolloClientProvider } from "@/providers/ApolloClientProvider";

import App from "./App";
import { SessionProvider } from "./providers/SessionProvider";

import "./lib/i18n";
import "./main.css";

const rootElement = document.getElementById("root");

const root = createRoot(rootElement as Element);

window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

root.render(
  <StrictMode>
    <ErrorBoundary instance={appsignal} action="main">
      <AppNestedProviders>
        <HelmetProvider>
          <Meta />
          <BrowserRouter>
            <ApolloClientProvider>
              <SessionProvider>
                <App />
              </SessionProvider>
            </ApolloClientProvider>
          </BrowserRouter>
        </HelmetProvider>
      </AppNestedProviders>
    </ErrorBoundary>
  </StrictMode>,
);
