import { DATE_FORMAT } from "@constants/date-formats";
import { TIME_PATTERN } from "@constants/validations";
import {
  add,
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  set,
  startOfDay,
} from "date-fns";

export type TimerangeOption = {
  value: string;
  props?: {
    diff?: number;
  };
};

/**
 * @example setTimeToDate(new Date(), "13:15")
 * // Mon Mar 06 2023 13:15:42 GMT+0100 (Central European Standard Time
 */
export const setTimeToDate = (date: Date, time: string) => {
  const isValidTime = new RegExp(TIME_PATTERN).test(time);
  const hours = isValidTime ? Number(time.split(":")[0]) : 0;
  const minutes = isValidTime ? Number(time.split(":")[1]) : 0;

  return set(date, {
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  });
};

const hoursToSeconds = (hours: number) => hours * 3600;
const minutesToSeconds = (minutes: number) => minutes * 60;

// Function to format time as "HH:mm"
const formatTime = (hours: number, minutes: number) => {
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
};

export const addTime = (time: string, secondsToAdd: number) => {
  const [hoursStr, minutesStr] = time.split(":");
  let hours = Number(hoursStr);
  let minutes = Number(minutesStr);
  let totalSeconds = hoursToSeconds(hours) + minutesToSeconds(minutes) + secondsToAdd;

  // Normalize totalSeconds to be within a day
  totalSeconds = ((totalSeconds % (24 * 3600)) + 24 * 3600) % (24 * 3600);

  // Convert back to hours and minutes
  hours = Math.floor(totalSeconds / 3600);
  minutes = Math.floor((totalSeconds % 3600) / 60);

  return formatTime(hours, minutes);
};

export const getDayHours = (): string[] => {
  return Array.from({ length: 24 }, (x, i) => i).map(
    (hour) => hour.toString().padStart(2, "0") + ":00",
  );
};

export const getDaysOffset = (start: Date, date: Date): number =>
  differenceInCalendarDays(start, date) + 1;

export const getTimerangeDiff = (from: string, to: string) => {
  const date = new Date();
  return differenceInMinutes(setTimeToDate(date, from), setTimeToDate(date, to));
};

export const timerangeIsValid = (from: string, to: string): boolean => {
  const diff = getTimerangeDiff(from, to);
  return diff < 0;
};

export const generateTimeOptions = (step: number): TimerangeOption[] => {
  if (step < 1 || step > 60) {
    return [];
  }

  const steps = 1440 / step;
  const options = [];
  const start = startOfDay(new Date());

  for (let stepIndex = 0; stepIndex < steps; stepIndex++) {
    options.push({
      value: format(add(start, { minutes: stepIndex * step }), DATE_FORMAT.TIME),
      props: {},
    });
  }

  return options;
};

export const getTimeFieldMask = (value: string): Array<string | RegExp> => {
  const firstNumber = Number(value[0]);
  let secondPart;

  switch (true) {
    case firstNumber < 2:
      secondPart = /[0-9]/;
      break;
    case firstNumber === 2:
      secondPart = /[0-3]/;
      break;
    default:
      secondPart = "";
      break;
  }

  return [/[0-9]/, secondPart, ":", /[0-5]/, /[0-9]/].filter((pattern) => pattern);
};

export const getNormalizedTimeValue = (value: string): string => {
  return value.replace(/:*$/, "").replace(/^([3-9])$/, "0$1");
};

export const isValidTimeRange = (from: string, to: string): boolean => {
  const fromParts = from.split(":");
  const toParts = to.split(":");

  const fromHour = parseInt(fromParts[0], 10);
  const fromMinute = parseInt(fromParts[1], 10);

  const toHour = parseInt(toParts[0], 10);
  const toMinute = parseInt(toParts[1], 10);

  if (fromHour < toHour) {
    return true;
  } else if (fromHour === toHour) {
    return fromMinute < toMinute;
  }

  return false;
};

export const convertMinutesToHoursAndMinutes = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  let result = "";

  if (hours > 0) {
    result += `${hours}h`;
  }

  if (remainingMinutes > 0) {
    result += (hours > 0 ? " " : "") + `${remainingMinutes}min`;
  }

  return result || "0min";
};
