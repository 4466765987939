import { useTranslation } from "react-i18next";

import { AppointmentDetailsDatetimeInfo } from "@/features/calendar/components/Appointment/AppointmentDetailsDatetimeInfo";
import { AppointmentDetailsTreatments } from "@/features/calendar/components/Appointment/AppointmentDetailsTreatments";
import { Appointment } from "@/types";

type ClientUpcomingAppointmentProps = {
  upcomingAppointment: Appointment | null;
};

export const ClientUpcomingAppointment = ({
  upcomingAppointment,
}: ClientUpcomingAppointmentProps) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto mt-8 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
      <h2 className="mb-2 text-sm font-medium text-stone-500">
        <span>{t("clients.upcomingAppointment")}</span>
      </h2>
      <div className="mt-2 flow-root">
        {upcomingAppointment ? (
          <>
            <AppointmentDetailsDatetimeInfo appointment={upcomingAppointment} />
            <AppointmentDetailsTreatments appointment={upcomingAppointment} lightUI />
          </>
        ) : (
          <div className="text-sm text-stone-900">{t("clients.noUpcomingAppointments")}</div>
        )}
      </div>
    </div>
  );
};
