import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteEmployeeCommissionMutationVariables = Types.Exact<{
  id: Types.Scalars['Identifier'];
}>;


export type DeleteEmployeeCommissionMutation = { __typename?: 'RootMutationType', employeesLedgerDeleteSettlementBlueprint: { __typename?: 'CommandResult', id?: string | null } };


export const DeleteEmployeeCommissionDocument = gql`
    mutation DeleteEmployeeCommission($id: Identifier!) {
  employeesLedgerDeleteSettlementBlueprint(id: $id) {
    id
  }
}
    `;
export type DeleteEmployeeCommissionMutationFn = Apollo.MutationFunction<DeleteEmployeeCommissionMutation, DeleteEmployeeCommissionMutationVariables>;

/**
 * __useDeleteEmployeeCommissionMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeCommissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeCommissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeCommissionMutation, { data, loading, error }] = useDeleteEmployeeCommissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeCommissionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeCommissionMutation, DeleteEmployeeCommissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeCommissionMutation, DeleteEmployeeCommissionMutationVariables>(DeleteEmployeeCommissionDocument, options);
      }
export type DeleteEmployeeCommissionMutationHookResult = ReturnType<typeof useDeleteEmployeeCommissionMutation>;
export type DeleteEmployeeCommissionMutationResult = Apollo.MutationResult<DeleteEmployeeCommissionMutation>;
export type DeleteEmployeeCommissionMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeCommissionMutation, DeleteEmployeeCommissionMutationVariables>;