import { useEffect } from "react";

import { useSessionContext } from "@/providers/SessionProvider";
import { MakeOrderInput, MakePaymentInput, namedOperations, ProductType } from "@/types";

import { useChangeSubscriptionPlanMutation } from "../mutations/ChangeSubscriptionPlan.generated";
import { useCreateBillingPortalSessionMutation } from "../mutations/CreateBillingPortalSession.generated";
import { useMakeOrderMutation } from "../mutations/MakeOrder.generated";
import { useMakePaymentMutation } from "../mutations/MakePayment.generated";
import { useFetchProductsWithPricesLazyQuery } from "../queries/FetchProductsWithPrices.generated";
import { useFetchProductsWithPricesByTypeQuery } from "../queries/FetchProductsWithPricesByType.generated";

export const usePaymentsStore = () => {
  const { isEmailVerified } = useSessionContext();

  const [fetchProducts, { loading: loadingFetchProducts }] = useFetchProductsWithPricesLazyQuery();
  const [makeOrder, { loading: loadingMakeOrder }] = useMakeOrderMutation();
  const [makePayment, { loading: loadingMakePayment }] = useMakePaymentMutation();
  const [createBillingPortalSession, { loading: loadingBillingPortalSession }] =
    useCreateBillingPortalSessionMutation();
  const [changeSubscriptionPlan, { loading: loadingChangePlan }] =
    useChangeSubscriptionPlanMutation();

  const { data: salonTier, loading: loadingFetchSalonTier } = useFetchProductsWithPricesByTypeQuery(
    {
      skip: !isEmailVerified,
      variables: {
        // TODO: change to ProductType.SubscriptionSalon
        type: ProductType.Subscription,
      },
    },
  );

  const products = useFetchProductsWithPricesByTypeQuery({
    skip: !isEmailVerified,
    variables: {
      type: ProductType.Product,
    },
  });

  useEffect(() => {
    isEmailVerified && fetchProducts();
  }, []);

  return {
    loading: {
      loadingFetchProducts,
      loadingMakeOrder,
      loadingMakePayment,
      loadingBillingPortalSession,
      loadingChangePlan,
      loadingFetchSalonTier,
    },
    salonTier: salonTier?.fetchProductsWithPricesByType[0],
    smsProducts: products.data?.fetchProductsWithPricesByType,
    fetchProducts() {
      return fetchProducts();
    },
    makeOrder(data: MakeOrderInput) {
      return makeOrder({
        variables: { data },
      });
    },
    makePayment(data: MakePaymentInput) {
      return makePayment({
        variables: { data },
      });
    },
    createBillingPortalSession(stripeCustomerId: string) {
      return createBillingPortalSession({
        variables: { stripeCustomerId },
      });
    },
    changeSubscriptionPlan(subscriptionPriceId: string) {
      return changeSubscriptionPlan({
        variables: { subscriptionPriceId },
        refetchQueries: [namedOperations.Query.CurrentSession],
      });
    },
  };
};
