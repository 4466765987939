import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeesLedgerBlueprintsQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Identifier'];
}>;


export type EmployeesLedgerBlueprintsQuery = { __typename?: 'RootQueryType', employeesLedgerBlueprints: Array<{ __typename?: 'EmployeesLedgerBlueprint', employeeId: any, startsAt: any, treatmentId?: any | null, id: any, commission: { __typename?: 'EmployeesLedgerCommission', type: Types.EmployeesLedgerCommissionType, value: number } }> };


export const EmployeesLedgerBlueprintsDocument = gql`
    query EmployeesLedgerBlueprints($employeeId: Identifier!) {
  employeesLedgerBlueprints(employeeId: $employeeId) {
    commission {
      type
      value
    }
    employeeId
    startsAt
    treatmentId
    id
  }
}
    `;

/**
 * __useEmployeesLedgerBlueprintsQuery__
 *
 * To run a query within a React component, call `useEmployeesLedgerBlueprintsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesLedgerBlueprintsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesLedgerBlueprintsQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useEmployeesLedgerBlueprintsQuery(baseOptions: Apollo.QueryHookOptions<EmployeesLedgerBlueprintsQuery, EmployeesLedgerBlueprintsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesLedgerBlueprintsQuery, EmployeesLedgerBlueprintsQueryVariables>(EmployeesLedgerBlueprintsDocument, options);
      }
export function useEmployeesLedgerBlueprintsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesLedgerBlueprintsQuery, EmployeesLedgerBlueprintsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesLedgerBlueprintsQuery, EmployeesLedgerBlueprintsQueryVariables>(EmployeesLedgerBlueprintsDocument, options);
        }
export type EmployeesLedgerBlueprintsQueryHookResult = ReturnType<typeof useEmployeesLedgerBlueprintsQuery>;
export type EmployeesLedgerBlueprintsLazyQueryHookResult = ReturnType<typeof useEmployeesLedgerBlueprintsLazyQuery>;
export type EmployeesLedgerBlueprintsQueryResult = Apollo.QueryResult<EmployeesLedgerBlueprintsQuery, EmployeesLedgerBlueprintsQueryVariables>;