import { t } from "i18next";

import { timeRange } from "@/features/statistics/models";

export const TIME_RANGES: timeRange[] = ["TODAY", "WEEK", "MONTH"];

export const TIME_RANGE_DIFFERENCE: Record<timeRange, number> = {
  TODAY: -1,
  WEEK: -7,
  MONTH: -30,
};

export const CHART_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        beginAtZero: true,
        maxTicksLimit: 5,
        stepSize: 1,
        callback: (value: string | number) => {
          return `${value} ${t("currencies.PLN")}`;
        },
      },
    },
  },
};
