import { useParams } from "react-router-dom";

import { BreadcrumbComponentProps } from "use-react-router-breadcrumbs";

import { Layout } from "@components/layout/Layout";

import { Spinner } from "@/components/ui/Spinner";
import { EmployeeProfile } from "@/features/employees/components/EmployeeProfile";
import { useEmployeeStore } from "@/features/employees/hooks";

export const EmployeeDetailsBreadcrumb = ({ match }: BreadcrumbComponentProps) => {
  const { employee, loading } = useEmployeeStore(match.params.id);

  return <span>{loading ? "..." : employee?.fullName}</span>;
};

export const EmployeeDetailsView = () => {
  const { id } = useParams();
  const { employee, loading } = useEmployeeStore(id);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Layout className="rounded-2xl bg-stone-100" title="Imię klienta">
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-stone-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        {employee && <EmployeeProfile selectedEmployee={employee} />}
      </div>
    </Layout>
  );
};
