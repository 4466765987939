import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Tab } from "@headlessui/react";

import { Layout } from "@components/layout/Layout";

import { EmployeeList } from "@features/employees/components/EmployeeList";
import { EmployeeListSubheader } from "@features/employees/components/EmployeeListSubheader";

import { sendEvent } from "@//utils/google-analytics";
import { SubscriptionCTA } from "@/features/payments/components/SubscriptionCTA";
import { useProductsStore } from "@/features/payments/hooks/use-products-store";
import { useSessionContext } from "@/providers/SessionProvider";
import { Role } from "@/types";

import { CommissionsSummary } from "./CommissionsSummary";

const TABS = [
  { value: "general", permission: true },
  { value: "commissions", permission: false },
];

export const EmployeesView = () => {
  const { t } = useTranslation();
  const { role, isSalonPlan, trialUsed, permissions } = useSessionContext();
  const { orderSalonPlan } = useProductsStore();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tabs, setTabs] = useState(TABS);

  useEffect(() => {
    const permittedTabs = tabs.filter(
      (tab) => tab.permission || role === Role.Owner || permissions.statistics_view,
    );
    setTabs(permittedTabs);
  }, [role]);

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
  };

  return (
    <Layout title={t("employees.employees")}>
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-stone-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        {isSalonPlan ? null : trialUsed ? (
          <div className="w-full md:mx-auto">
            <SubscriptionCTA
              to="/subscriptions"
              heading={t("subscriptions.cta.employees.trial.heading")}
              description={t("subscriptions.cta.employees.upgrade.description")}
              buttonText={t("subscriptions.cta.generic.seeMore")}
              onClick={() => sendEvent("upgrade_see_more", "payments", "employees")}
            />
          </div>
        ) : (
          <div className="w-full md:mx-auto">
            <SubscriptionCTA
              heading={t("subscriptions.cta.employees.trial.heading")}
              description={t("subscriptions.cta.employees.trial.description")}
              buttonText={t("subscriptions.cta.generic.startTrial")}
              onClick={() => {
                sendEvent("free_trial_click", "payments", "employees");
                orderSalonPlan();
              }}
            />
          </div>
        )}

        <EmployeeListSubheader />
        {role === Role.Owner || permissions.statistics_view ? (
          <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
            <Tab.List className="box-border flex flex-wrap justify-start space-x-4 border-b border-stone-200 pt-4 sm:space-x-8 md:justify-start md:pt-0">
              {tabs.map((tab) => (
                <Tab as={Fragment} key={tab.value}>
                  <div className="w-fit cursor-pointer whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium outline-none transition-colors focus-visible:outline-none ui-selected:border-gold-500 ui-selected:text-stone-900 ui-not-selected:border-transparent ui-not-selected:text-stone-500 ui-not-selected:hover:border-stone-300 ui-not-selected:hover:text-stone-700">
                    <span className="text-xs sm:text-sm">{t(`employees.tabs.${tab.value}`)}</span>
                  </div>
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panel>
              <EmployeeList />
            </Tab.Panel>
            <Tab.Panel>
              <CommissionsSummary />
            </Tab.Panel>
          </Tab.Group>
        ) : (
          <EmployeeList />
        )}
      </div>
    </Layout>
  );
};
