import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { format } from "date-fns";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { Button } from "@/components/ui/Button";
import { DateField } from "@/components/ui/DateField";
import { Radio } from "@/components/ui/Radio";
import { TextField } from "@/components/ui/TextField";
import { formatPrice } from "@/features/payments/utils";
import { TreatmentSelect } from "@/features/services/components/treatments/TreatmentSelect";
import { EmployeesLedgerCommissionType, EmployeesLedgerSetSettlementBlueprintInput } from "@/types";
import { Option } from "@/utils/select-utils";

import { useEmployeeCommission } from "../hooks/use-employee-commission";

type AddEmployeeCommissionDialogProps = {
  open: boolean;
  onClose: () => void;
  employeeId: string;
};

type CommissionOption = {
  value: EmployeesLedgerCommissionType;
  label: string;
};

type TreatmentWithCommission = {
  id: string;
  name: string;
  price: {
    isExactPrice: boolean;
    priceFrom: number;
    priceTo: number;
    price: number;
  };
  commission: {
    isExactPrice: boolean;
    priceFrom: number;
    priceTo: number;
    price: number;
  } | null;
};

export const AddEmployeeCommissionDialog = ({
  open,
  onClose,
  employeeId,
}: AddEmployeeCommissionDialogProps) => {
  const { t } = useTranslation();
  const { addEmployeeCommission, loadingAdd } = useEmployeeCommission({ employeeId: employeeId });
  const [selectedTreatment, setSelectedTreatment] = useState<TreatmentWithCommission | null>(null);

  const commissionTypeOptions: CommissionOption[] = [
    {
      value: EmployeesLedgerCommissionType.Fixed,
      label: t("employees.employeeProfile.commissions.fixedCommission"),
    },
    {
      value: EmployeesLedgerCommissionType.Percentage,
      label: t("employees.employeeProfile.commissions.percentageCommission"),
    },
  ];

  const {
    handleSubmit,
    control,
    register,
    reset,
    setValue,
    watch,
    formState: { isValid },
  } = useForm<EmployeesLedgerSetSettlementBlueprintInput>({
    mode: "all",
    defaultValues: {
      commission: {
        value: 0,
        type: EmployeesLedgerCommissionType.Fixed,
      },
      startsAt: new Date(),
      treatmentId: null,
    },
  });

  const commission = watch("commission");

  const calculateCommission = useCallback(
    (price: number, commission: number, type: EmployeesLedgerCommissionType) => {
      if (type === EmployeesLedgerCommissionType.Percentage) {
        return (price * commission) / 100;
      }

      return commission * 100;
    },
    [],
  );

  const mapTreatmentWithCommission = useCallback(
    (treatment: TreatmentWithCommission) => {
      const { id, name, price: priceObj } = treatment;
      const { isExactPrice, priceFrom, priceTo, price } = priceObj;

      const commissionValue = {
        isExactPrice,
        priceFrom: calculateCommission(priceFrom, commission.value, commission.type),
        priceTo: calculateCommission(priceTo, commission.value, commission.type),
        price: calculateCommission(price, commission.value, commission.type),
      };

      return {
        id,
        name,
        price: {
          isExactPrice,
          priceFrom,
          priceTo,
          price,
        },
        commission: commissionValue,
      };
    },
    [commission.value, commission.type, calculateCommission],
  );

  const handleTreatmentSelect = (data: Option) => {
    const props: TreatmentWithCommission = {
      id: data.value as string,
      name: data.label,
      price: {
        isExactPrice: data.props?.treatmentIsExactPrice,
        priceFrom: data.props?.treatmentPriceFrom || 0,
        priceTo: data.props?.treatmentPriceTo || 0,
        price: data.props?.treatmentPrice || 0,
      },
      commission: null,
    };

    const treatmentWithCommission = mapTreatmentWithCommission(props);

    setSelectedTreatment(treatmentWithCommission);
  };

  const handleFormSubmit = async (data: EmployeesLedgerSetSettlementBlueprintInput) => {
    const payload = {
      ...data,
      commission: {
        ...data.commission,
        value: Number(data.commission.value),
      },
      startsAt: format(data.startsAt, "yyyy-MM-dd"),
      employeeId: employeeId,
    };

    const { errors } = await addEmployeeCommission(payload);
    if (!errors) {
      reset();
      onClose();
      setSelectedTreatment(null);
    }
  };

  const bundleClose = () => {
    reset();
    onClose();
    setSelectedTreatment(null);
  };

  useEffect(() => {
    setValue("commission.value", 0);
  }, [commission.type]);

  useEffect(() => {
    if (commission.type === EmployeesLedgerCommissionType.Percentage && commission.value > 100) {
      setValue("commission.value", 100);
    }

    if (selectedTreatment) {
      const treatmentWithCommission = mapTreatmentWithCommission(selectedTreatment);

      setSelectedTreatment(treatmentWithCommission);
    }
  }, [commission.value]);

  return (
    <DefaultDialog
      open={open}
      onClose={bundleClose}
      title={t("employees.employeeProfile.commissions.addCommission")}>
      <form className="space-y-4" onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <div className="flex flex-col gap-4 py-4">
          <Radio
            name="commission.type"
            control={control}
            options={commissionTypeOptions}
            label={t("employees.employeeProfile.commissions.commissionType")}
          />
          <TextField
            {...register("commission.value", {
              required: true,
              min: 0,
              max: commission.type === EmployeesLedgerCommissionType.Percentage ? 100 : undefined,
            })}
            type="number"
            min={0}
            max={commission.type === EmployeesLedgerCommissionType.Percentage ? 100 : undefined}
            label={t("employees.employeeProfile.commissions.commissionValue")}
            placeholder={t("employees.employeeProfile.commissions.commissionValue")}
          />
          <DateField
            required
            name="startsAt"
            control={control}
            label={t("generic.startDate")}
            placeholder={t("generic.startDate")}
          />
          <TreatmentSelect
            name="treatmentId"
            control={control}
            label={`${t("services.treatments.title")} (${t("generic.optional").toLowerCase()})`}
            onChange={(data) => {
              return !!data ? handleTreatmentSelect(data) : setSelectedTreatment(null);
            }}
          />
          {selectedTreatment && (
            <div className="line-clamp-1 flex flex-col gap-1 rounded-md bg-stone-100 p-4 text-xs text-stone-500">
              <div key={selectedTreatment.id} className="flex justify-between">
                <span>{selectedTreatment.name}</span>
                <div>
                  {`${formatPrice(selectedTreatment.price)} (${formatPrice(
                    selectedTreatment.commission as any,
                  )})`}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="align-center mt-4 flex w-full gap-4">
          <Button variant="primary-outline" fullWidth disabled={loadingAdd} onClick={bundleClose}>
            {t("generic.cancel")}
          </Button>
          <Button type="submit" fullWidth disabled={!isValid || loadingAdd}>
            {t("generic.save")}
          </Button>
        </div>
      </form>
    </DefaultDialog>
  );
};
