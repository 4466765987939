import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { CurrentSessionFragmentDoc } from '../fragments/CurrentSession.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentSessionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentSessionQuery = { __typename?: 'RootQueryType', currentSession: { __typename?: 'CurrentSession', firstName?: string | null, lastName?: string | null, fullName: string, isOnboarded: boolean, salonName?: string | null, salonPlan?: Types.SubscriptionPlan | null, email: string, phone?: string | null, role?: Types.Role | null, permissions?: any | null, accountUuid?: any | null, stripeCustomerId?: string | null, trialUsed?: boolean | null } };


export const CurrentSessionDocument = gql`
    query CurrentSession {
  currentSession {
    ...CurrentSession
  }
}
    ${CurrentSessionFragmentDoc}`;

/**
 * __useCurrentSessionQuery__
 *
 * To run a query within a React component, call `useCurrentSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentSessionQuery(baseOptions?: Apollo.QueryHookOptions<CurrentSessionQuery, CurrentSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentSessionQuery, CurrentSessionQueryVariables>(CurrentSessionDocument, options);
      }
export function useCurrentSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentSessionQuery, CurrentSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentSessionQuery, CurrentSessionQueryVariables>(CurrentSessionDocument, options);
        }
export type CurrentSessionQueryHookResult = ReturnType<typeof useCurrentSessionQuery>;
export type CurrentSessionLazyQueryHookResult = ReturnType<typeof useCurrentSessionLazyQuery>;
export type CurrentSessionQueryResult = Apollo.QueryResult<CurrentSessionQuery, CurrentSessionQueryVariables>;