import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTreatmentsPositionsMutationVariables = Types.Exact<{
  data: Types.UpdateTreatmentsPositionsInput;
}>;


export type UpdateTreatmentsPositionsMutation = { __typename?: 'RootMutationType', updateTreatmentsPositions: string };


export const UpdateTreatmentsPositionsDocument = gql`
    mutation UpdateTreatmentsPositions($data: UpdateTreatmentsPositionsInput!) {
  updateTreatmentsPositions(data: $data)
}
    `;
export type UpdateTreatmentsPositionsMutationFn = Apollo.MutationFunction<UpdateTreatmentsPositionsMutation, UpdateTreatmentsPositionsMutationVariables>;

/**
 * __useUpdateTreatmentsPositionsMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentsPositionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentsPositionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentsPositionsMutation, { data, loading, error }] = useUpdateTreatmentsPositionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTreatmentsPositionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTreatmentsPositionsMutation, UpdateTreatmentsPositionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTreatmentsPositionsMutation, UpdateTreatmentsPositionsMutationVariables>(UpdateTreatmentsPositionsDocument, options);
      }
export type UpdateTreatmentsPositionsMutationHookResult = ReturnType<typeof useUpdateTreatmentsPositionsMutation>;
export type UpdateTreatmentsPositionsMutationResult = Apollo.MutationResult<UpdateTreatmentsPositionsMutation>;
export type UpdateTreatmentsPositionsMutationOptions = Apollo.BaseMutationOptions<UpdateTreatmentsPositionsMutation, UpdateTreatmentsPositionsMutationVariables>;