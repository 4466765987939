import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { EquipmentItemFragmentDoc } from '../fragments/EquipmentItem.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchSalonEquipmentItemsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchSalonEquipmentItemsQuery = { __typename?: 'RootQueryType', fetchSalonEquipmentItems: { __typename?: 'EquipmentItemConnection', count: number, edges: Array<{ __typename?: 'EquipmentItemEdge', node: { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };


export const FetchSalonEquipmentItemsDocument = gql`
    query FetchSalonEquipmentItems($first: Int, $query: String, $after: String) {
  fetchSalonEquipmentItems(first: $first, query: $query, after: $after) {
    edges {
      node {
        ...EquipmentItem
      }
    }
    count
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${EquipmentItemFragmentDoc}`;

/**
 * __useFetchSalonEquipmentItemsQuery__
 *
 * To run a query within a React component, call `useFetchSalonEquipmentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSalonEquipmentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSalonEquipmentItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      query: // value for 'query'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchSalonEquipmentItemsQuery(baseOptions?: Apollo.QueryHookOptions<FetchSalonEquipmentItemsQuery, FetchSalonEquipmentItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSalonEquipmentItemsQuery, FetchSalonEquipmentItemsQueryVariables>(FetchSalonEquipmentItemsDocument, options);
      }
export function useFetchSalonEquipmentItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSalonEquipmentItemsQuery, FetchSalonEquipmentItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSalonEquipmentItemsQuery, FetchSalonEquipmentItemsQueryVariables>(FetchSalonEquipmentItemsDocument, options);
        }
export type FetchSalonEquipmentItemsQueryHookResult = ReturnType<typeof useFetchSalonEquipmentItemsQuery>;
export type FetchSalonEquipmentItemsLazyQueryHookResult = ReturnType<typeof useFetchSalonEquipmentItemsLazyQuery>;
export type FetchSalonEquipmentItemsQueryResult = Apollo.QueryResult<FetchSalonEquipmentItemsQuery, FetchSalonEquipmentItemsQueryVariables>;