import { useState } from "react";
import { useTranslation } from "react-i18next";

import { RadioGroup } from "@headlessui/react";

import BgImage from "@assets/images/subscriptions/talk.png";

import { Button } from "@/components/ui/Button";
import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@/features/account/components/SendFeedbackDialog";
import { PlanCard } from "@/features/payments/components/PlanCard";
import { Plan } from "@/features/payments/models";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { cn } from "@/utils/utils";

type PlansProps = {
  plans: Plan[];
};

export const Plans = ({ plans }: PlansProps) => {
  const { t } = useTranslation();
  const [activePeriod, setActivePeriod] = useState<"monthly" | "annually">("monthly");

  const feedbackDialogState = useDialog<SendFeedbackProps>();

  return (
    <>
      <section id="pricing" aria-labelledby="pricing-title" className="mx-auto mt-8 max-w-6xl px-0">
        <div className="mt-12 flex justify-center py-2">
          <div className="relative">
            <RadioGroup
              value={activePeriod}
              onChange={setActivePeriod}
              className="grid grid-cols-2">
              {["monthly", "annually"].map((period) => (
                <RadioGroup.Option
                  key={period}
                  value={period}
                  className={cn(
                    "cursor-pointer border border-stone-300 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-center text-sm text-stone-700 outline-2 outline-offset-2 transition-colors hover:border-stone-400",
                    period === "monthly" ? "rounded-l-md" : "-ml-px rounded-r-md",
                  )}>
                  {period === "monthly" ? t("pricing.monthly") : t("pricing.annually")}
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <div
              aria-hidden="true"
              className={cn(
                "pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-md bg-gold-700 transition-all duration-300",
                activePeriod === "monthly"
                  ? "[clip-path:inset(0_50%_0_0)]"
                  : "[clip-path:inset(0_0_0_calc(50%-1px))]",
              )}>
              {["monthly", "annually"].map((period) => (
                <div
                  key={period}
                  className={cn(
                    "py-2 text-center text-sm font-semibold text-white [&:not(:focus-visible)]:focus:outline-none",
                    period === "annually" && "-ml-px",
                  )}>
                  {period === "monthly" ? t("pricing.monthly") : t("pricing.annually")}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-auto mt-8 grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:mt-12 md:mt-12 lg:max-w-none lg:grid-cols-2">
          {plans.map((plan) => (
            <PlanCard key={plan.name} plan={plan} activePeriod={activePeriod} />
          ))}
        </div>

        <section className="relative mt-8 rounded-xl bg-gold-800 shadow-lg shadow-stone-900/5">
          <div className="absolute bottom-0 h-72 w-full xl:inset-0 xl:h-full">
            <div className="h-full w-full xl:grid xl:grid-cols-3">
              <div className="col-span-2 h-full xl:relative xl:col-start-2">
                <img
                  className="h-full w-full object-cover object-top opacity-25 xl:absolute xl:inset-0"
                  src={BgImage}
                  alt=""
                />
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gold-800 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                />
              </div>
            </div>
          </div>
          <div className="mx-auto max-w-4xl px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
            <div className="relative pb-64 pt-12 text-center sm:pb-64 sm:pt-12 md:text-left xl:col-start-1 xl:pb-12">
              <h3 className="text-center text-sm font-semibold text-gold-100 md:text-left">
                {t("pricing.tiers.business.name")}
              </h3>
              <h2 className="mt-5 text-center text-3xl tracking-tight text-gold-50 md:text-left">
                {t("pricing.forNegotation")}
              </h2>
              <p className="mx-auto mt-4 text-center text-sm leading-6 text-gold-300 md:ml-0 md:mr-auto md:w-3/4 md:text-left lg:w-2/3">
                {t("pricing.tiers.business.description")}
              </p>
              <Button
                className="mx-auto mt-6 bg-white text-gold-900 hover:bg-stone-100 focus:ring-stone-100 md:ml-0 md:mr-auto lg:w-1/2"
                onClick={() =>
                  feedbackDialogState.open({
                    subject: "partnership",
                  })
                }>
                {t("pricing.tiers.business.cta.landing")}
              </Button>
            </div>
          </div>
        </section>
      </section>
      <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
    </>
  );
};
