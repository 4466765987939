import { useTranslation } from "react-i18next";

import { useSalonPaymentMethodsStore } from "@/features/salon/hooks/use-salon-payment-methods-store";
import { Appointment } from "@/types";

type AppointmentPaymentMethodInfoProps = {
  appointment: Appointment;
};

export const AppointmentPaymentMethodInfo = ({
  appointment,
}: AppointmentPaymentMethodInfoProps) => {
  const { t } = useTranslation();

  const { salonPaymentMethods } = useSalonPaymentMethodsStore();

  const selectedPaymentMethod = salonPaymentMethods?.find(
    (method) => method.uuid === appointment.paymentMethodUuid,
  );

  return (
    <div className="flex justify-end px-4 text-xs text-stone-700">
      {t("paymentMethods.paymentForm", {
        name: t(`paymentMethods.${selectedPaymentMethod?.name}`),
      })}
    </div>
  );
};
