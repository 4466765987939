import * as Types from '../types';

import { gql } from '@apollo/client';
export type TimerangeFragment = { __typename?: 'Timerange', from: any, to: any };

export const TimerangeFragmentDoc = gql`
    fragment Timerange on Timerange {
  from
  to
}
    `;