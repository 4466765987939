import { useTranslation } from "react-i18next";

import {
  BuildingStorefrontIcon,
  EnvelopeIcon,
  LinkIcon,
  PhoneIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";

import { Spinner } from "@/components/ui/Spinner";
import { useSalonOpeningHoursStore, useSalonStore } from "@/features/salon/hooks";
import { prependHttps } from "@/utils/links";
import { cn } from "@/utils/utils";

import { weekdays } from "../utils";

export const SalonProfile = () => {
  const { salon, loading: loadingSalon } = useSalonStore();
  const { regular, loading: loadingOpeningHours } = useSalonOpeningHoursStore();
  const { t } = useTranslation();

  const loading = loadingSalon || loadingOpeningHours;

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!salon) {
    return null;
  }

  return (
    <div className="mx-auto w-full">
      <div>
        <div>
          {salon.backgroundImageUrl ? (
            <img
              crossOrigin="anonymous"
              className="aspect-[3/1] min-h-[200px] w-full rounded-xl object-cover"
              src={salon.backgroundImageUrl}
              alt=""
            />
          ) : null}
        </div>
        <div
          className={cn("flex flex-col items-center sm:flex-row", {
            "-mt-12 sm:mt-4": salon.backgroundImageUrl,
          })}>
          <div className="mb-4 flex-shrink-0 sm:mb-0">
            {salon.logoImageUrl ? (
              <img
                crossOrigin="anonymous"
                className="h-24 w-24 rounded-full border-2 border-white sm:h-32 sm:w-32 sm:border-none"
                src={salon.logoImageUrl}
                alt=""
              />
            ) : (
              <div className="flex h-24 w-24 items-center justify-center rounded-full border-2 border-white bg-stone-100 sm:h-32 sm:w-32 sm:border-none">
                <PhotoIcon className="h-10 w-10 text-stone-300" />
              </div>
            )}
          </div>
          <div className="sm:ml-4">
            <h2 className="text-3xl font-semibold text-stone-700 sm:text-4xl">{salon.name}</h2>
            {salon.street || salon.streetNumber || salon.postalCode || salon.city ? (
              <div className="mt-2 flex items-center gap-2 sm:mt-4">
                <BuildingStorefrontIcon className="-mr-1 h-5 w-5 flex-shrink-0 text-stone-500" />
                <p className="text-sm text-stone-600">
                  {salon.street} {salon.streetNumber}, {salon.postalCode} {salon.city}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className="my-4 border-t border-stone-200" />
      <div className="flex flex-col gap-6 lg:flex-row lg:justify-between lg:gap-6">
        <div className="w-full">
          {salon.description && (
            <p className="mb-4 w-full text-sm text-stone-700">{salon.description}</p>
          )}
          {salon.phone && (
            <div className="mt-2 flex items-center gap-2">
              <PhoneIcon className="h-5 w-5 text-stone-500" />
              <a href={`tel:${salon.phone}`} className="text-sm text-gold-700">
                {salon.phone}
              </a>
            </div>
          )}
          {salon.email && (
            <div className="mt-2 flex items-center gap-2">
              <EnvelopeIcon className="mt-0.5 h-5 w-5 text-stone-500" />
              <a href={`mailto:${salon.email}`} className="text-sm text-gold-600">
                {salon.email}
              </a>
            </div>
          )}
          {salon.socials?.facebook && (
            <div className="mt-2 flex items-center gap-2">
              <LinkIcon className="h-5 w-5 flex-shrink-0 text-stone-500" />
              <a
                href={prependHttps(salon.socials?.facebook)}
                target="_blank"
                rel="noreferrer"
                className="break-all text-sm text-gold-600">
                {prependHttps(salon.socials?.facebook)}
              </a>
            </div>
          )}
          {salon.socials?.instagram && (
            <div className="mt-2 flex items-center gap-2">
              <LinkIcon className="h-5 w-5 flex-shrink-0 text-stone-500" />
              <a
                href={prependHttps(salon.socials?.instagram)}
                target="_blank"
                rel="noreferrer"
                className=" break-all text-sm text-gold-600">
                {prependHttps(salon.socials?.instagram)}
              </a>
            </div>
          )}
        </div>
        <div className="w-full max-w-sm">
          <span className="mb-2 inline-flex font-semibold">
            {t("sidebar.navigation.mySalon.children.openingHours")}
          </span>
          {weekdays.map((weekday) => (
            <div
              key={weekday}
              className="border-b border-b-stone-200/75 py-3 text-sm last-of-type:border-none">
              <div className="flex justify-between">
                <div
                  className={cn(
                    regular[weekday].is_open ? "text-stone-900" : "text-stone-500/90",
                    "whitespace-nowrap px-1 font-semibold",
                  )}>
                  {t(`weekDays.${weekday}`)}
                </div>
                <div className={cn("whitespace-nowrap px-1 text-stone-900")}>
                  {regular[weekday].is_open ? (
                    regular[weekday].ranges.map((range, index) => (
                      <div key={index}>
                        <span>
                          {range.from}
                          {" - "}
                          {range.to}
                        </span>
                      </div>
                    ))
                  ) : (
                    <span className="text-stone-500/90">
                      {t("openingHours.event.isOpen.closed")}
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
