import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import { AppointementCalendarView } from "@views/calendar/CalendarView";
import { ClientsView } from "@views/clients/ClientsView";
import { EmployeeDetailsView } from "@views/employees/EmployeeDetailsView";
import { EmployeesView } from "@views/employees/EmployeesView";
import { SubscriptionsView } from "@views/payments/SubscriptionsView";
import { SuccessfulPaymentView } from "@views/payments/SuccessfulPaymentView";
import { OpeningHoursEditView } from "@views/salon/OpeningHoursEditView";
import { OpeningHoursView } from "@views/salon/OpeningHoursView";
import { SalonProfileEditView } from "@views/salon/SalonProfileEditView";
import { SalonProfileView } from "@views/salon/SalonProfileView";
import { EmailNotVerifiedView } from "@views/session/EmailNotVerifiedView";
import { EmailVerifiedView } from "@views/session/EmailVerifiedView";
import { Maintenance } from "@views/session/Maintenance";
import { NotFoundView } from "@views/session/NotFoundView";
import { SettingsView } from "@views/settings/SettingsView";

import { MainWrapper } from "@components/layout/MainWrapper";

import { useDefaultDateOptions } from "@hooks/use-default-date-options";

import { AuthenticatedRoutes } from "@features/session/components/AuthenticatedRoutes";
import { ProtectedRoutes } from "@features/session/components/ProtectedRoutes";

import { CalendarProvider } from "@/features/calendar/providers/CalendarProvider";
import { EquipmentProvider } from "@/features/equipment/providers/EquipmentProvider";
import { SubscriptionsProvider } from "@/features/payments/providers/SubscriptionsProvider";
import { useRouterUtils } from "@/features/router/hooks/use-router-utils";
import { NotificationProvider } from "@/providers/NotificationProvider";
import { Role, SubscriptionPlan } from "@/types";
import { ClientEditView } from "@/views/clients/ClientsEditView";
import { EmployeeEditView } from "@/views/employees/EmployeeEditView";
import { EquipmentView } from "@/views/equipment/EquipmentView";
import { OnboardingPersonalInfoView } from "@/views/onboarding/OnboardingPersonalInfoView";
import { OnboardingSalonProfileView } from "@/views/onboarding/OnboardingSalonProfileView";
import { OnboardingSalonSmsDetailsView } from "@/views/onboarding/OnboardingSalonSmsDetailsView";
import { StatisticsView } from "@/views/statistics/StatisticsView";

import { useAnalyticsStore } from "./features/settings/hooks/use-analytics-store";
import { ClientDetailsView } from "./views/clients/ClientDetailsView";
import { ServicesView } from "./views/services/ServicesView";

function App() {
  useDefaultDateOptions();
  useAnalyticsStore();

  const { isOnOnboardingPage, isOnEmailVerificationPage, isOnMaintenancePage, isInitial } =
    useRouterUtils();
  const { isAuthenticated } = useAuth0();

  return (
    <MainWrapper>
      <Routes>
        <Route
          path="/"
          element={
            isOnOnboardingPage ||
            isOnEmailVerificationPage ||
            isOnMaintenancePage ||
            isInitial ||
            !isAuthenticated ? (
              <AuthenticatedRoutes />
            ) : (
              <CalendarProvider>
                <NotificationProvider>
                  <SubscriptionsProvider>
                    <AuthenticatedRoutes />
                  </SubscriptionsProvider>
                </NotificationProvider>
              </CalendarProvider>
            )
          }>
          <Route path="" element={<Navigate to="/calendar" />} />
          <Route path="email-not-verified" element={<EmailNotVerifiedView />} />
          <Route path="email-verified" element={<EmailVerifiedView />} />
          <Route path="onboarding/personal" element={<OnboardingPersonalInfoView />} />
          <Route path="onboarding/salon" element={<OnboardingSalonProfileView />} />
          <Route path="onboarding/sms" element={<OnboardingSalonSmsDetailsView />} />

          <Route path="calendar" element={<AppointementCalendarView />} />

          <Route path="clients" element={<Outlet />}>
            <Route index element={<ClientsView />} />
            <Route
              element={
                <ProtectedRoutes
                  roles={[Role.Owner, Role.Employee]}
                  permission={"client_details_view"}
                />
              }>
              <Route path=":id" element={<ClientDetailsView />} />
            </Route>
            <Route
              element={
                <ProtectedRoutes roles={[Role.Owner, Role.Employee]} permission={"edit_client"} />
              }>
              <Route path=":id/edit" element={<ClientEditView />} />
            </Route>
          </Route>

          <Route
            element={
              <ProtectedRoutes roles={[Role.Owner, Role.Employee]} permission={"statistics_view"} />
            }>
            <Route path="statistics" element={<StatisticsView />} />
          </Route>

          <Route path="salon" element={<Outlet />}>
            <Route index element={<SalonProfileView />} />
            <Route
              element={
                <ProtectedRoutes
                  roles={[Role.Owner, Role.Employee]}
                  permission={"manage_salon_profile"}
                />
              }>
              <Route path="edit" element={<SalonProfileEditView />} />
            </Route>
          </Route>

          <Route path="employees" element={<Outlet />}>
            <Route index element={<EmployeesView />} />
            <Route element={<ProtectedRoutes plans={[SubscriptionPlan.Salon]} />}>
              <Route path=":id" element={<EmployeeDetailsView />} />
            </Route>
            <Route
              element={
                <ProtectedRoutes roles={[Role.Owner]} plans={[SubscriptionPlan.Salon]} />
              }></Route>
            <Route
              element={
                <ProtectedRoutes
                  plans={[SubscriptionPlan.Salon]}
                  roles={[Role.Owner, Role.Employee]}
                  permission={"edit_employee"}
                />
              }>
              <Route path=":id/edit" element={<EmployeeEditView />} />
            </Route>
          </Route>

          <Route path="opening-hours" element={<Outlet />}>
            <Route index element={<OpeningHoursView />} />
            <Route
              element={
                <ProtectedRoutes
                  roles={[Role.Owner, Role.Employee]}
                  permission={"manage_opening_hours"}
                />
              }>
              <Route path="edit" element={<OpeningHoursEditView />} />
            </Route>
          </Route>

          <Route path="services" element={<ServicesView />} />

          <Route
            path="equipment"
            element={
              <EquipmentProvider>
                <EquipmentView />
              </EquipmentProvider>
            }
          />

          <Route element={<ProtectedRoutes roles={[Role.Owner]} />}>
            <Route path="subscriptions" element={<SubscriptionsView />} />
            <Route path="payment-successful" element={<SuccessfulPaymentView />} />
          </Route>

          <Route path="maintenance" element={<Maintenance />} />

          <Route path="settings" element={<SettingsView />} />
        </Route>

        <Route path="*" element={<NotFoundView />} />
      </Routes>
    </MainWrapper>
  );
}

export default App;
