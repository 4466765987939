import { useMemo, useReducer } from "react";

import { format } from "date-fns";

type SelectedOption = {
  hour: number;
  minute: number;
};

type TimePickerOptions = {
  hour: number[];
  minute: number[];
};

type MobileTimePickerState = {
  selectedOption: SelectedOption;
  options: TimePickerOptions;
};

const enum ActionType {
  SET_SELECTED_OPTION = "SET_SELECTED_OPTION",
}

type MobileTimePickerActions = { type: ActionType.SET_SELECTED_OPTION; payload: SelectedOption };

const mobileTimePickerReducer = (
  state: MobileTimePickerState,
  action: MobileTimePickerActions,
): MobileTimePickerState => {
  switch (action.type) {
    case ActionType.SET_SELECTED_OPTION:
      return { ...state, selectedOption: action.payload };
    default:
      return state;
  }
};

export const useMobileTimePicker = (initialTime = format(new Date(), "HH:mm")) => {
  const initialOption = {
    hour: parseInt(initialTime.slice(0, 2)) || 0,
    minute: parseInt(initialTime.slice(3, 5)) || 0,
  };

  const [state, dispatch] = useReducer(mobileTimePickerReducer, {
    selectedOption: initialOption,
    options: {
      hour: range(0, 23),
      minute: range(0, 59, 5),
    },
  });

  const setSelectedOption = (option: SelectedOption) => {
    dispatch({ type: ActionType.SET_SELECTED_OPTION, payload: option });
  };

  return useMemo(
    () => ({ options: state.options, selectedOption: state.selectedOption, setSelectedOption }),
    [state.options, state.selectedOption],
  );
};

const range = (start: number, end: number, step = 1) =>
  Array.from({ length: Math.floor((end - start) / step + 1) }, (_, i) => start + i * step);
