import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { formatDuration, intervalToDuration } from "date-fns";

import { useProductsStore } from "@/features/payments/hooks/use-products-store";

import { Alert } from "../ui/Alert";
import { Button } from "../ui/Button";
import { DefaultDialog } from "./DefaultDialog";

type AnnouncementDialogProps = {
  open: boolean;
  onClose: () => void;
  sendFeedback: () => void;
};

export const AnnouncementDialog = ({ open, onClose, sendFeedback }: AnnouncementDialogProps) => {
  const { t } = useTranslation();
  const { orderSalonPlan } = useProductsStore();

  const [duration, setDuration] = useState(
    intervalToDuration({
      start: new Date(2024, 10, 30),
      end: new Date(),
    }),
  );

  const handleSendFeedback = () => {
    sendFeedback();
    onClose();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(
        intervalToDuration({
          start: new Date(2024, 10, 30),
          end: new Date(),
        }),
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("announcement.banner.title")}>
      <div className="flex flex-col gap-6 py-4">
        <h4 className="text-sm font-medium">{t("announcement.content.title")}</h4>
        <p className="rounded-md border border-gold-300 p-4 text-sm">
          {t("announcement.content.introduction")}
        </p>

        <div className="text-sm">
          <p className="text-xs font-semibold text-stone-500">
            {t("announcement.content.offer.title")}:
          </p>
          <ul className="mt-2 flex flex-col gap-1">
            <li>
              <span className="font-semibold text-stone-600">
                {t("announcement.content.offer.monthlySubscription")}:{" "}
              </span>
              {t("announcement.content.offer.monthlySubscriptionPrice")}
            </li>
            <li>
              <span className="font-semibold text-stone-600">
                {t("announcement.content.offer.yearlySubscription")}:{" "}
              </span>
              {t("announcement.content.offer.yearlySubscriptionPrice")}
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-2">
          <Alert textSize="xs">
            <div>
              {t("announcement.content.timeLeft")}{" "}
              <span className="font-semibold">{formatDuration(duration)}</span>
            </div>
          </Alert>
          <p className="text-sm text-stone-500">{t("announcement.content.offer.cta")}</p>
        </div>

        <div className="mt-auto flex flex-col gap-2">
          <Button variant="primary" onClick={orderSalonPlan}>
            {t("announcement.content.actions.useTheOffer")}
          </Button>
          <p className="text-xs">
            {t("announcement.content.offer.noCardPayment")}{" "}
            <Button variant="primary-inline" onClick={handleSendFeedback}>
              {t("announcement.content.offer.write")}
            </Button>{" "}
            {t("announcement.content.offer.otherMethods")}
          </p>
        </div>
      </div>
    </DefaultDialog>
  );
};
