import { ReactNode, createContext, useContext, useState } from "react";

import { GENERAL_CALENDAR_VIEWS as allowedViewsToLocalSave } from "../constants";
import { CalendarViews } from "../models";

type CalendarViewContextProps = {
  view: CalendarViews;
  setView: (view: CalendarViews) => void;
};

const CalendarViewContext = createContext<CalendarViewContextProps | undefined>(undefined);

export const CalendarViewProvider = ({ children }: { children: ReactNode }) => {
  const savedCalendarView = localStorage.getItem("defaultCalendarView");

  let defaultView: CalendarViews = CalendarViews.Week;

  if (savedCalendarView !== null) {
    const parsedView = Number(savedCalendarView);
    if (!isNaN(parsedView) && Object.values(CalendarViews).includes(parsedView as CalendarViews)) {
      defaultView = parsedView as CalendarViews;
    }
  }

  const [view, setViewState] = useState<CalendarViews>(defaultView);

  const handleSetView = (newView: CalendarViews) => {
    setViewState(newView);
    if (allowedViewsToLocalSave.includes(newView)) {
      localStorage.setItem("defaultCalendarView", newView.toString());
    }
  };

  return (
    <CalendarViewContext.Provider value={{ view, setView: handleSetView }}>
      {children}
    </CalendarViewContext.Provider>
  );
};

export const useCalendarView = () => {
  const context = useContext(CalendarViewContext);
  if (!context) {
    throw new Error("useView must be used within a ViewProvider");
  }
  return context;
};
