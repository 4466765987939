import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchIncomeNumbersQueryVariables = Types.Exact<{
  displayFormat: Types.StatisticsDisplayFormat;
  period: Types.StatisticsPeriod;
}>;


export type FetchIncomeNumbersQuery = { __typename?: 'RootQueryType', fetchIncomeNumbers: Array<{ __typename?: 'IncomeNumbers', appointmentsCount: number, date: any, income: number }> };


export const FetchIncomeNumbersDocument = gql`
    query FetchIncomeNumbers($displayFormat: StatisticsDisplayFormat!, $period: StatisticsPeriod!) {
  fetchIncomeNumbers(displayFormat: $displayFormat, period: $period) {
    appointmentsCount
    date
    income
  }
}
    `;

/**
 * __useFetchIncomeNumbersQuery__
 *
 * To run a query within a React component, call `useFetchIncomeNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchIncomeNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchIncomeNumbersQuery({
 *   variables: {
 *      displayFormat: // value for 'displayFormat'
 *      period: // value for 'period'
 *   },
 * });
 */
export function useFetchIncomeNumbersQuery(baseOptions: Apollo.QueryHookOptions<FetchIncomeNumbersQuery, FetchIncomeNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchIncomeNumbersQuery, FetchIncomeNumbersQueryVariables>(FetchIncomeNumbersDocument, options);
      }
export function useFetchIncomeNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchIncomeNumbersQuery, FetchIncomeNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchIncomeNumbersQuery, FetchIncomeNumbersQueryVariables>(FetchIncomeNumbersDocument, options);
        }
export type FetchIncomeNumbersQueryHookResult = ReturnType<typeof useFetchIncomeNumbersQuery>;
export type FetchIncomeNumbersLazyQueryHookResult = ReturnType<typeof useFetchIncomeNumbersLazyQuery>;
export type FetchIncomeNumbersQueryResult = Apollo.QueryResult<FetchIncomeNumbersQuery, FetchIncomeNumbersQueryVariables>;