import { useEffect } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Select } from "@/components/ui/Select";
import { ClientFormDialog } from "@/features/clients/components/ClientFormDialog";
import { useClientSelect } from "@/features/clients/hooks/use-client-select";
import { bindDialogState } from "@/hooks/use-dialog";
import { Client } from "@/types";
import { Option } from "@/utils/select-utils";

import { MIN_OPTIONS_FOR_SEARCH } from "../../constants";
import { ClientSelectButton } from "./ClientSelectButton";
import { ClientSelectOptions } from "./ClientSelectOptions";

type ClientSelectProps = {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  onChange?: (data?: Option) => void;
  onClear?: () => void;
  onClientMutation?: (client: Client) => void;
};

export const ClientSelect = ({
  disabled,
  name,
  control,
  label,
  onChange,
  onClear,
  onClientMutation,
}: ClientSelectProps) => {
  const { t } = useTranslation();

  const {
    clientOptions,
    clientAddDialog,
    clientEditDialog,
    selectedClient,
    setSelectedClient,
    count,
    loading,
    hasMore,
    searchQuery,
    refetchWithQuery,
    fetchMore,
    loadingFetchMore,
  } = useClientSelect({
    name: name,
    control: control,
  });

  const handleChange = (data: string | string[]) => {
    const selectedOption = clientOptions.find((option) => option.value === data);

    onChange && onChange(selectedOption);
    setSelectedClient(selectedOption);
  };

  const handleAddClient = () => {
    clientAddDialog.open();
  };

  const handleEditClient = (clientUuid: string) => {
    clientEditDialog.open({
      clientUuid: clientUuid,
    });
  };

  const handleClearClient = () => {
    onClear && onClear();
    setSelectedClient(undefined);
  };

  useEffect(() => {
    if (selectedClient) {
      onChange && onChange(selectedClient);
    }
  }, [selectedClient]);

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange: formOnChange, value = "" } }) => (
          <Select
            name={name}
            label={label ?? t("clients.client")}
            value={value}
            selectBy="value"
            disabled={loading || disabled}
            options={clientOptions}
            onChange={(data) => {
              formOnChange(data);
              handleChange(data);
            }}
            renderCustomSelectButton={() => (
              <ClientSelectButton
                name={name}
                placeholder={t("placeholders.selectClient")}
                selectedOption={selectedClient}
                onEdit={(clientUuid) => handleEditClient(clientUuid)}
                onClear={handleClearClient}
              />
            )}
            renderCustomSelectOptions={() => (
              <ClientSelectOptions
                options={clientOptions}
                selected={value}
                addClient={handleAddClient}
                hasMore={hasMore}
                loadingFetchMore={loadingFetchMore}
                fetchMore={fetchMore}
                allClientsCount={count}
                onSearch={refetchWithQuery}
                disableSearch={
                  clientOptions.length < MIN_OPTIONS_FOR_SEARCH && searchQuery === undefined
                }
              />
            )}
          />
        )}
      />
      <ClientFormDialog
        {...bindDialogState(clientAddDialog)}
        title={t("clients.addNew")}
        onSave={(data: Client) => {
          onClientMutation && onClientMutation(data);
          setSelectedClient({
            value: data.uuid,
            label: data.fullName,
          });
        }}
      />
      <ClientFormDialog
        {...bindDialogState(clientEditDialog)}
        title={t("clients.edit")}
        onSave={(data: Client) => {
          onClientMutation && onClientMutation(data);
          setSelectedClient({
            value: data.uuid,
            label: data.fullName,
          });
        }}
      />
    </>
  );
};
