import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonOpeningHoursFragmentDoc } from '../fragments/SalonOpeningHours.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSalonOpeningHoursMutationVariables = Types.Exact<{
  data: Types.UpdateSalonOpeningHoursInput;
}>;


export type UpdateSalonOpeningHoursMutation = { __typename?: 'RootMutationType', updateSalonOpeningHours: { __typename?: 'SalonOpeningHours', regular?: any | null, special?: any | null } };


export const UpdateSalonOpeningHoursDocument = gql`
    mutation UpdateSalonOpeningHours($data: UpdateSalonOpeningHoursInput!) {
  updateSalonOpeningHours(data: $data) {
    ...SalonOpeningHours
  }
}
    ${SalonOpeningHoursFragmentDoc}`;
export type UpdateSalonOpeningHoursMutationFn = Apollo.MutationFunction<UpdateSalonOpeningHoursMutation, UpdateSalonOpeningHoursMutationVariables>;

/**
 * __useUpdateSalonOpeningHoursMutation__
 *
 * To run a mutation, you first call `useUpdateSalonOpeningHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalonOpeningHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalonOpeningHoursMutation, { data, loading, error }] = useUpdateSalonOpeningHoursMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSalonOpeningHoursMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalonOpeningHoursMutation, UpdateSalonOpeningHoursMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalonOpeningHoursMutation, UpdateSalonOpeningHoursMutationVariables>(UpdateSalonOpeningHoursDocument, options);
      }
export type UpdateSalonOpeningHoursMutationHookResult = ReturnType<typeof useUpdateSalonOpeningHoursMutation>;
export type UpdateSalonOpeningHoursMutationResult = Apollo.MutationResult<UpdateSalonOpeningHoursMutation>;
export type UpdateSalonOpeningHoursMutationOptions = Apollo.BaseMutationOptions<UpdateSalonOpeningHoursMutation, UpdateSalonOpeningHoursMutationVariables>;