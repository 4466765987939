import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useTreatmentsSearchStore } from "@features/services/hooks";
import { MIN_OPTIONS_FOR_SEARCH } from "@features/services/hooks/use-treatments-search-store";

import { Select } from "@/components/ui/Select";
import { Option, getOptionByValue } from "@/utils/select-utils";

import { AppointmentTreatmentSelectOptions } from "./AppointmentTreatmentSelectOptions";

type AppointmentTreatmentSelectProps = {
  name: string;
  control: Control<any>;
  label: string;
  selectBy?: "value" | undefined;
  disabled?: boolean;
  onChange?: (data?: Option) => void;
};

export const AppointmentTreatmentSelect = ({
  disabled,
  name,
  control,
  label,
  onChange,
  selectBy = undefined,
}: AppointmentTreatmentSelectProps) => {
  const { t } = useTranslation();

  const {
    treatmentOptions,
    loading,
    hasMore,
    loadingFetchMore,
    fetchMore,
    count,
    searchQuery,
    refetchWithQuery,
  } = useTreatmentsSearchStore({
    selectedOptionIds: undefined,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { onChange: formOnChange, value = "" } }) => (
        <Select
          name={name}
          label={label}
          disabled={loading || disabled}
          selectBy={selectBy}
          value={value}
          options={treatmentOptions}
          placeholder={t("placeholders.selectTreatment")}
          renderCustomSelectOptions={() => (
            <AppointmentTreatmentSelectOptions
              options={treatmentOptions}
              selected={value}
              hasMore={hasMore}
              loadingFetchMore={loadingFetchMore}
              allTreatmentsCount={count}
              fetchMore={fetchMore}
              onSearch={refetchWithQuery}
              name={name}
              disableSearch={
                treatmentOptions.length < MIN_OPTIONS_FOR_SEARCH && searchQuery === undefined
              }
            />
          )}
          onChange={(data) => {
            formOnChange(data);
            onChange && onChange(getOptionByValue(data, treatmentOptions) as Option);
          }}
        />
      )}
    />
  );
};
