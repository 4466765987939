import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AccountWithProfileFragmentDoc } from '../fragments/AccountWithProfile.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAccountNameMutationVariables = Types.Exact<{
  data: Types.AccountNameUpdateInput;
}>;


export type UpdateAccountNameMutation = { __typename?: 'RootMutationType', updateAccountName: { __typename?: 'AccountWithProfile', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null } };


export const UpdateAccountNameDocument = gql`
    mutation UpdateAccountName($data: AccountNameUpdateInput!) {
  updateAccountName(data: $data) {
    ...AccountWithProfile
  }
}
    ${AccountWithProfileFragmentDoc}`;
export type UpdateAccountNameMutationFn = Apollo.MutationFunction<UpdateAccountNameMutation, UpdateAccountNameMutationVariables>;

/**
 * __useUpdateAccountNameMutation__
 *
 * To run a mutation, you first call `useUpdateAccountNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountNameMutation, { data, loading, error }] = useUpdateAccountNameMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountNameMutation, UpdateAccountNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountNameMutation, UpdateAccountNameMutationVariables>(UpdateAccountNameDocument, options);
      }
export type UpdateAccountNameMutationHookResult = ReturnType<typeof useUpdateAccountNameMutation>;
export type UpdateAccountNameMutationResult = Apollo.MutationResult<UpdateAccountNameMutation>;
export type UpdateAccountNameMutationOptions = Apollo.BaseMutationOptions<UpdateAccountNameMutation, UpdateAccountNameMutationVariables>;