import { ChatBubbleLeftEllipsisIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

type SMSCharacterCounterProps = {
  messageCharacters: {
    encoding: string;
    length: number;
    characterPerMessage: number;
    inCurrentMessage: number;
    remaining: number;
    messages: number;
    shortenByChars: string[];
  };
};

export const SMSCharacterCounter = ({ messageCharacters }: SMSCharacterCounterProps) => {
  return (
    <p className="flex justify-end text-right text-sm text-stone-500">
      <span className="flex items-center gap-1">
        <ChatBubbleLeftEllipsisIcon className="h-4 w-4" />
        {messageCharacters.inCurrentMessage}
        &nbsp;/&nbsp;{messageCharacters.characterPerMessage} &bull;&nbsp;
      </span>
      <span className="flex items-center gap-1">
        <EnvelopeIcon className="h-4 w-4" />
        {messageCharacters.messages}
      </span>
    </p>
  );
};
