import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";

import { useDebounce } from "@/hooks/use-debounce";
import { FetchTreatmentsQuery, TreatmentEdge } from "@/types";

import { useUpdateTreatmentsPositionsMutation } from "../mutations/UpdateTreatmentsPositions.generated";
import {
  FetchTreatmentsDocument,
  useFetchTreatmentsQuery,
} from "../queries/FetchTreatments.generated";

export const useTreatmentsStore = (categoryUuid?: string) => {
  const { data, networkStatus, fetchMore, refetch, error } = useFetchTreatmentsQuery({
    variables: { first: 100, categoryUuid },
    skip: !categoryUuid,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    console.error("There was an error fetching treatments:", error);
  }

  const [updateTreatmentsPositionsMutation, { loading: loadingUpdate }] =
    useUpdateTreatmentsPositionsMutation();

  const [searchQuery, setSearchQuery] = useState<string>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      refetch({ query: debouncedSearchQuery || undefined });
    }
  }, [debouncedSearchQuery, refetch]);

  const handleUpdateTreatmentsPositions = async ({
    categoryUuid,
    sortedTreatmentUuids,
  }: {
    categoryUuid: string;
    sortedTreatmentUuids: string[];
  }) => {
    await updateTreatmentsPositionsMutation({
      variables: { data: { categoryUuid, sortedTreatmentUuids } },
      optimisticResponse: {
        updateTreatmentsPositions: "successfully_updated",
      },
      update: (cache) => {
        const existingData: FetchTreatmentsQuery | null = cache.readQuery({
          query: FetchTreatmentsDocument,
          variables: { first: 100, categoryUuid },
        });

        if (!existingData) return;

        const { fetchTreatments } = existingData;

        const newEdges = sortedTreatmentUuids
          .map((uuid) =>
            fetchTreatments.edges.find((edge: TreatmentEdge) => edge.node.uuid === uuid),
          )
          .filter(Boolean);

        cache.writeQuery({
          query: FetchTreatmentsDocument,
          variables: { first: 100, categoryUuid },
          data: {
            fetchTreatments: {
              ...fetchTreatments,
              edges: newEdges,
            },
          },
        });
      },
    });
  };

  return {
    loading: [NetworkStatus.loading, NetworkStatus.setVariables].includes(networkStatus),
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    loadingUpdate,
    treatments: data?.fetchTreatments.edges.map(({ node }) => node) || [],
    count: data?.fetchTreatments.count || 0,
    hasMore: data?.fetchTreatments.pageInfo.hasNextPage,
    refetchWithQuery(query: string) {
      setSearchQuery(query);
      if (query !== undefined) {
        refetch({ query });
      }
    },
    fetchMore() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchTreatments.pageInfo.endCursor,
          },
        });
      }
    },
    updateTreatmentsPositions: handleUpdateTreatmentsPositions,
  };
};
