import { Helmet } from "react-helmet-async";

export type MetaProps = {
  title?: string;
};

export const Meta = ({ title }: MetaProps) => {
  return (
    <Helmet>
      <title>{title ? `${title} - Estetify.pl` : "Estetify.pl"}</title>
      <meta name="robots" content="noindex"></meta>
    </Helmet>
  );
};
