import { GSM_7BIT_EXT_CHAR_REGEXP, GSM_7BIT_REGEXP, GSM_7BIT_EXT_REGEXP } from "../constants";

export type Encoding = "GSM_7BIT" | "GSM_7BIT_EXT" | "UTF16";

const messageLength: { [key in Encoding]: number } = {
  GSM_7BIT: 160,
  GSM_7BIT_EXT: 160,
  UTF16: 70,
};

const multiMessageLength: { [key in Encoding]: number } = {
  GSM_7BIT: 153,
  GSM_7BIT_EXT: 153,
  UTF16: 67,
};

export const countCharacters = (text: string) => {
  const encoding = detectEncoding(text);

  const shortenByChars = encoding === "UTF16" ? detectUtf16Chars(text) : [];
  let length = text.length;
  if (encoding === "GSM_7BIT_EXT") {
    length += countGsm7bitExt(text);
  }

  let characterPerMessage = messageLength[encoding];
  if (length > characterPerMessage) {
    characterPerMessage = multiMessageLength[encoding];
  }

  const messages = Math.ceil(length / characterPerMessage);

  let inCurrentMessage = length;
  if (messages > 0) {
    inCurrentMessage = length - characterPerMessage * (messages - 1);
  }

  let remaining = characterPerMessage * messages - length;
  if (remaining === 0 && messages === 0) {
    remaining = characterPerMessage;
  }

  return {
    encoding,
    length,
    characterPerMessage,
    inCurrentMessage,
    remaining,
    messages,
    shortenByChars,
  };
};

const detectEncoding = (text: string): Encoding => {
  if (text.match(GSM_7BIT_REGEXP)) {
    return "GSM_7BIT";
  } else if (text.match(GSM_7BIT_EXT_REGEXP)) {
    return "GSM_7BIT_EXT";
  } else {
    return "UTF16";
  }
};

const detectUtf16Chars = (text: string) => {
  // match all utf16 chars as a single char. Display icons
  const utf16Chars = text.match(/[\u0080-\uFFFF]/g);

  return utf16Chars ? Array.from(new Set(utf16Chars)) : [];
};

const countGsm7bitExt = (text: string) => {
  const match = text.match(GSM_7BIT_EXT_CHAR_REGEXP);
  return match ? match.length : 0;
};
