import { AddCategoryInput, namedOperations, UpdateCategoryInput } from "../../../types";
import { useAddCategoryMutation } from "../mutations/AddCategory.generated";
import {
  useDeleteCategoryMutation,
  DeleteCategoryMutationVariables,
} from "../mutations/DeleteCategory.generated";
import { useUpdateCategoryMutation } from "../mutations/UpdateCategory.generated";
import { useFetchCategoryQuery } from "../queries/FetchCategory.generated";

export const useCategoryStore = (categoryUuid?: string) => {
  const { data, loading } = useFetchCategoryQuery({
    variables: { categoryUuid },
    skip: !categoryUuid,
  });

  const [addCategory, { loading: loadingAdd }] = useAddCategoryMutation();
  const [deleteCategory, { loading: loadingDelete }] = useDeleteCategoryMutation();
  const [updateCategory, { loading: loadingUpdate }] = useUpdateCategoryMutation();

  return {
    loading,
    loadingAdd,
    loadingUpdate,
    loadingDelete,
    category: data?.fetchCategory,
    addCategory(data: AddCategoryInput) {
      return addCategory({
        variables: { data },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
      });
    },
    updateCategory(data: UpdateCategoryInput, categoryUuid: string) {
      return updateCategory({
        variables: { categoryUuid, data },
        refetchQueries: [namedOperations.Query.FetchHeadCategories],
      });
    },
    deleteCategory({ categoryUuid }: DeleteCategoryMutationVariables) {
      return deleteCategory({
        variables: { categoryUuid },
        update(cache) {
          const normalizedId = cache.identify({
            uuid: categoryUuid,
            __typename: "Category",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    },
  };
};
