import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { BlockedSlotFragmentDoc } from '../fragments/BlockedSlot.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBlockedSlotMutationVariables = Types.Exact<{
  data: Types.UpdateBlockedSlotInput;
}>;


export type UpdateBlockedSlotMutation = { __typename?: 'RootMutationType', updateBlockedSlot: { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeUuid: any, title?: string | null, uuid: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } } };


export const UpdateBlockedSlotDocument = gql`
    mutation UpdateBlockedSlot($data: UpdateBlockedSlotInput!) {
  updateBlockedSlot(data: $data) {
    ...BlockedSlot
  }
}
    ${BlockedSlotFragmentDoc}`;
export type UpdateBlockedSlotMutationFn = Apollo.MutationFunction<UpdateBlockedSlotMutation, UpdateBlockedSlotMutationVariables>;

/**
 * __useUpdateBlockedSlotMutation__
 *
 * To run a mutation, you first call `useUpdateBlockedSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockedSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockedSlotMutation, { data, loading, error }] = useUpdateBlockedSlotMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBlockedSlotMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBlockedSlotMutation, UpdateBlockedSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBlockedSlotMutation, UpdateBlockedSlotMutationVariables>(UpdateBlockedSlotDocument, options);
      }
export type UpdateBlockedSlotMutationHookResult = ReturnType<typeof useUpdateBlockedSlotMutation>;
export type UpdateBlockedSlotMutationResult = Apollo.MutationResult<UpdateBlockedSlotMutation>;
export type UpdateBlockedSlotMutationOptions = Apollo.BaseMutationOptions<UpdateBlockedSlotMutation, UpdateBlockedSlotMutationVariables>;