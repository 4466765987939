import { useMemo } from "react";

import { getDisplayedMonthDays } from "@/features/calendar/utils/time";

import { useCalendarDate } from "../../contexts/CalendarDateContext";
import { useCalendarFilters } from "../../contexts/CalendarFiltersContext";
import { useCalendarView } from "../../contexts/CalendarViewContext";
import { useCalendarStore } from "../../hooks/use-calendar-store";
import { CalendarLoader } from "../CalendarLoader";
import { CalendarMonthHeader } from "../CalendarMonth/CalendarMonthHeader";
import { CalendarMonthCell } from "./CalendarMonthCell";

export const CalendarMonthContent = () => {
  const { selectedDate, timeRange } = useCalendarDate();
  const { filters } = useCalendarFilters();
  const { view } = useCalendarView();

  const { slotsAssignedToDays: slotGroupsMap, loading } = useCalendarStore({
    filters: { timeRange, ...filters },
    calendarView: view,
  });

  const days = useMemo(() => {
    if (loading || !selectedDate) return [];

    return getDisplayedMonthDays(selectedDate, slotGroupsMap);
  }, [selectedDate, slotGroupsMap, loading]);

  if (loading) {
    return <CalendarLoader />;
  }

  return (
    <div className="mx-0 h-full pb-20 sm:pb-0 lg:flex lg:flex-col">
      <div className="max-h-full overflow-auto border-y sm:rounded-md sm:border lg:flex lg:flex-auto lg:flex-col">
        <CalendarMonthHeader />

        <div className="flex bg-stone-200 text-xs leading-6 text-stone-700 lg:flex-auto">
          <div
            className="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px"
            style={{
              gridTemplateRows: `repeat(${days.length / 7}, minmax(9rem, 1fr))`,
            }}>
            {days.map((day) => (
              <CalendarMonthCell key={day.date.getTime()} day={day} />
            ))}
          </div>
          <div
            className="isolate grid w-full grid-cols-7 gap-px lg:hidden"
            style={{
              gridTemplateRows: `repeat(${days.length / 7}, minmax(0, 1fr))`,
            }}>
            {days.map((day) => (
              <CalendarMonthCell key={day.date.getTime()} day={day} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
