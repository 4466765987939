import { Fragment } from "react";

import { SCALE_MINUTES_PER_STEP } from "@features/calendar/constants";
import { SlotRow } from "@features/calendar/models";
import { getDaysOffset } from "@utils/datetime";
import isSameDay from "date-fns/isSameDay";

import { useBreakpoint } from "@hooks/use-breakpoint";

import { getDivisionOffset, getDivisionsDistance } from "@/features/calendar/utils/time";

import { useCalendarDate } from "../contexts/CalendarDateContext";
import { CalendarSlot } from "./CalendarSlot";

type CalendarSlotsGroupProps = {
  group: SlotRow;
  employeeCount?: number;
};

export const CalendarSlotsGroup = ({ group, employeeCount }: CalendarSlotsGroupProps) => {
  const { selectedDate, timeRange } = useCalendarDate();
  const { isSm } = useBreakpoint("sm");

  const isEmployeeCountProvided = typeof employeeCount === "number";

  const rowOffset = getDivisionOffset(group.from, 2, SCALE_MINUTES_PER_STEP);
  const rowsCount = getDivisionsDistance(group.from, group.to, SCALE_MINUTES_PER_STEP);
  const colOffset = isEmployeeCountProvided
    ? employeeCount + 1
    : getDaysOffset(new Date(group.from), timeRange.from);
  const isVisible = isSm || isSameDay(new Date(group.from), selectedDate);

  return (
    <li
      className={`relative mt-px ${isVisible ? "flex" : "hidden"}`}
      style={{
        gridColumnStart: colOffset,
        gridRow: `${rowOffset} / span ${rowsCount}`,
      }}>
      <ol
        className="relative grid w-full"
        style={{
          gridTemplateColumns: `repeat(${group.columns.length}, minmax(0, 1fr))`,
          gridTemplateRows: `repeat(${rowsCount}, minmax(0, 1fr))`,
        }}>
        {group.columns.map((column, index) => (
          <Fragment key={index}>
            {column.map((slot) => (
              <CalendarSlot
                key={`${slot.uuid}-${slot.appointmentTreatmentUuid}`}
                slot={slot}
                groupStartsAt={group.from}
                index={index}
                isLast={group.columns.length === index + 1}
              />
            ))}
          </Fragment>
        ))}
      </ol>
    </li>
  );
};
