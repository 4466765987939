import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { ProductFragmentDoc } from '../fragments/Product.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchProductsWithPricesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchProductsWithPricesQuery = { __typename?: 'RootQueryType', fetchProductsWithPrices: Array<{ __typename?: 'Product', name?: string | null, priceId?: string | null, stripeId?: string | null, type?: string | null, price?: { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null } | null }> };


export const FetchProductsWithPricesDocument = gql`
    query FetchProductsWithPrices {
  fetchProductsWithPrices {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

/**
 * __useFetchProductsWithPricesQuery__
 *
 * To run a query within a React component, call `useFetchProductsWithPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProductsWithPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProductsWithPricesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchProductsWithPricesQuery(baseOptions?: Apollo.QueryHookOptions<FetchProductsWithPricesQuery, FetchProductsWithPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchProductsWithPricesQuery, FetchProductsWithPricesQueryVariables>(FetchProductsWithPricesDocument, options);
      }
export function useFetchProductsWithPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchProductsWithPricesQuery, FetchProductsWithPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchProductsWithPricesQuery, FetchProductsWithPricesQueryVariables>(FetchProductsWithPricesDocument, options);
        }
export type FetchProductsWithPricesQueryHookResult = ReturnType<typeof useFetchProductsWithPricesQuery>;
export type FetchProductsWithPricesLazyQueryHookResult = ReturnType<typeof useFetchProductsWithPricesLazyQuery>;
export type FetchProductsWithPricesQueryResult = Apollo.QueryResult<FetchProductsWithPricesQuery, FetchProductsWithPricesQueryVariables>;