import { useTranslation } from "react-i18next";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

import { Spinner } from "@/components/ui/Spinner";

type AvailabilityInformationProps = {
  show: boolean;
  loading: boolean;
};

export const AvailabilityInformation = ({ show, loading }: AvailabilityInformationProps) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="py-2">
        <Spinner />
      </div>
    );
  }

  if (!show) {
    return null;
  }

  return (
    <p className="flex items-center justify-center gap-2 text-sm font-medium text-orange-400">
      <ExclamationTriangleIcon className="h-5 w-5" />
      {t("equipment.notAvailable")}
    </p>
  );
};
