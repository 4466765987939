import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type SalonPaymentMethodFragment = { __typename?: 'SalonPaymentMethod', default: boolean, enabled: boolean, name: Types.SalonPaymentMethods, salonUuid: any, uuid: any };

export const SalonPaymentMethodFragmentDoc = gql`
    fragment SalonPaymentMethod on SalonPaymentMethod {
  default
  enabled
  name
  salonUuid
  uuid
}
    `;