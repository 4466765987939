import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { AppointmentFragmentDoc } from '../fragments/Appointment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduleBulkAppointmentMutationVariables = Types.Exact<{
  data: Types.ScheduleBulkAppointmentInput;
}>;


export type ScheduleBulkAppointmentMutation = { __typename?: 'RootMutationType', scheduleBulkAppointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: Types.AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };


export const ScheduleBulkAppointmentDocument = gql`
    mutation ScheduleBulkAppointment($data: ScheduleBulkAppointmentInput!) {
  scheduleBulkAppointment(data: $data) {
    ...Appointment
  }
}
    ${AppointmentFragmentDoc}`;
export type ScheduleBulkAppointmentMutationFn = Apollo.MutationFunction<ScheduleBulkAppointmentMutation, ScheduleBulkAppointmentMutationVariables>;

/**
 * __useScheduleBulkAppointmentMutation__
 *
 * To run a mutation, you first call `useScheduleBulkAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleBulkAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleBulkAppointmentMutation, { data, loading, error }] = useScheduleBulkAppointmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useScheduleBulkAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleBulkAppointmentMutation, ScheduleBulkAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleBulkAppointmentMutation, ScheduleBulkAppointmentMutationVariables>(ScheduleBulkAppointmentDocument, options);
      }
export type ScheduleBulkAppointmentMutationHookResult = ReturnType<typeof useScheduleBulkAppointmentMutation>;
export type ScheduleBulkAppointmentMutationResult = Apollo.MutationResult<ScheduleBulkAppointmentMutation>;
export type ScheduleBulkAppointmentMutationOptions = Apollo.BaseMutationOptions<ScheduleBulkAppointmentMutation, ScheduleBulkAppointmentMutationVariables>;