import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonSmsDetailsFragmentDoc } from '../fragments/SalonSmsDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSalonSmsDetailsMutationVariables = Types.Exact<{
  data: Types.UpdateSalonSmsDetailsInput;
}>;


export type UpdateSalonSmsDetailsMutation = { __typename?: 'RootMutationType', updateSalonSmsDetails: { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean } };


export const UpdateSalonSmsDetailsDocument = gql`
    mutation UpdateSalonSmsDetails($data: UpdateSalonSmsDetailsInput!) {
  updateSalonSmsDetails(data: $data) {
    ...SalonSmsDetails
  }
}
    ${SalonSmsDetailsFragmentDoc}`;
export type UpdateSalonSmsDetailsMutationFn = Apollo.MutationFunction<UpdateSalonSmsDetailsMutation, UpdateSalonSmsDetailsMutationVariables>;

/**
 * __useUpdateSalonSmsDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSalonSmsDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalonSmsDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalonSmsDetailsMutation, { data, loading, error }] = useUpdateSalonSmsDetailsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSalonSmsDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalonSmsDetailsMutation, UpdateSalonSmsDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalonSmsDetailsMutation, UpdateSalonSmsDetailsMutationVariables>(UpdateSalonSmsDetailsDocument, options);
      }
export type UpdateSalonSmsDetailsMutationHookResult = ReturnType<typeof useUpdateSalonSmsDetailsMutation>;
export type UpdateSalonSmsDetailsMutationResult = Apollo.MutationResult<UpdateSalonSmsDetailsMutation>;
export type UpdateSalonSmsDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateSalonSmsDetailsMutation, UpdateSalonSmsDetailsMutationVariables>;