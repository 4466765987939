import { DATE_FORMAT } from "@constants/date-formats";
import { CalendarViews, DisplayedMonthDay, SlotsAssignedToDays } from "@features/calendar/models";
import {
  add,
  areIntervalsOverlapping,
  differenceInMinutes,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatDuration,
  getDayOfYear,
  isSameDay,
  isSameMonth,
  isToday,
  parseISO,
  roundToNearestMinutes,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from "date-fns";

import {
  DEFAULT_APPOINTMENT_DURATION_SEC,
  SCALE_MINUTES_PER_STEP,
} from "@/features/calendar/constants";
import { Appointment, Timerange } from "@/types";
import { addTime } from "@/utils/datetime";

export const getDateWithOffset = (date: Date, offset: number): Date => add(date, { days: offset });

export const getTimeWithOffset = (offset: number, step: number): string => {
  const totalMinutes = offset * step;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const dateToNearestTime = (date: Date, nearestTo: number): string =>
  format(roundToNearestMinutes(date, { nearestTo }), DATE_FORMAT.TIME);

export const getDisplayedMonthDays = (
  date: Date,
  slotGroupsMap: SlotsAssignedToDays,
): DisplayedMonthDay[] => {
  const start = startOfWeek(startOfMonth(date));
  const end = endOfWeek(endOfMonth(date));

  return eachDayOfInterval({ start, end }).map((day) => ({
    date: day,
    isCurrentMonth: isSameMonth(date, day),
    isToday: isToday(day),
    isSelected: isSameDay(date, day),
    slots: slotGroupsMap[getDayOfYear(day)] || [],
  }));
};

/**
 * @example getDefaultNearestTimerange(new Date())
 * // { from: 12:55, to: 13:25 }
 * */
export const getDefaultNearestTimerange = (date: Date) => {
  const defaultFrom = dateToNearestTime(date ? new Date(date) : new Date(), SCALE_MINUTES_PER_STEP);
  const defaultTo = addTime(defaultFrom, DEFAULT_APPOINTMENT_DURATION_SEC);

  return {
    from: defaultFrom,
    to: defaultTo,
  };
};

/**
 *
 * @example isoTimeRangeToTime({ from: '2021-01-01T12:00:00.000Z', to: '2021-01-01T12:30:00.000Z' })
 * // { from: '12:00', to: '12:30' }
 */
export const isoTimeRangeToTime = (timeRange: Timerange) => ({
  to: format(parseISO(timeRange.to), DATE_FORMAT.TIME),
  from: format(parseISO(timeRange.from), DATE_FORMAT.TIME),
});

export const dateAdd = (date: Date, type: CalendarViews): Date =>
  add(date, {
    days: +(type === CalendarViews.Day) || +(type === CalendarViews.EmployeeDay),
    weeks: +(type === CalendarViews.Week) || +(type === CalendarViews.EmployeeWeek),
    months: +(type === CalendarViews.Month),
  });

export const dateSub = (date: Date, type: CalendarViews): Date =>
  sub(date, {
    days: +(type === CalendarViews.Day) || +(type === CalendarViews.EmployeeDay),
    weeks: +(type === CalendarViews.Week) || +(type === CalendarViews.EmployeeWeek),
    months: +(type === CalendarViews.Month),
  });

/**
 *
 * @example getPeriodTimeRange(new Date(), CalendarViews.Week)
 * // { "from": "2023-03-05T23:00:00.000Z", "to": "2023-03-12T22:59:59.999Z" }
 */
export const getPeriodTimeRange = (date: Date, period: CalendarViews): Timerange => {
  switch (period) {
    case CalendarViews.Day:
    case CalendarViews.EmployeeDay:
      return {
        from: startOfDay(date),
        to: endOfDay(date),
      };
    case CalendarViews.Week:
    case CalendarViews.EmployeeWeek:
      return {
        from: startOfWeek(date, { weekStartsOn: 1 }),
        to: endOfWeek(date, { weekStartsOn: 1 }),
      };
    case CalendarViews.Month:
      return {
        from: startOfMonth(date),
        to: endOfMonth(date),
      };
    default:
      return {
        from: date,
        to: date,
      };
  }
};

export const getDivisionOffset = (
  date: string,
  offset: number,
  step: number,
  startPoint?: string,
): number => {
  const parsedDate = parseISO(date);
  const comparisonPoint = startPoint ? parseISO(startPoint) : startOfDay(parsedDate);

  const timezoneDifference = parsedDate.getTimezoneOffset() - comparisonPoint.getTimezoneOffset();

  const dateDifference = differenceInMinutes(parsedDate, comparisonPoint) - timezoneDifference;
  const divisionResult = dateDifference / step;

  const result = Math.floor(offset + divisionResult);

  return result;
};

export const getDivisionsDistance = (start: string, end: string, step: number): number => {
  const parsedStart = parseISO(start);
  const parsedEnd = parseISO(end);

  const timezoneDifference = parsedEnd.getTimezoneOffset() - parsedStart.getTimezoneOffset();

  return Math.ceil((differenceInMinutes(parsedEnd, parsedStart) - timezoneDifference) / step);
};

/**
 * @example {
 *  "from": "17:00",
 *  "to": "18:00",
 * } overlaps with {
 *  "from": "16:30",
 *  "to": "18:30",
 * }
 */
export const checkIfTimerangesOverlapByTime = (tr1: Timerange, tr2: Timerange) =>
  areIntervalsOverlapping(
    {
      start: parseISO(tr1.from),
      end: parseISO(tr1.to),
    },
    {
      start: parseISO(tr2.from),
      end: parseISO(tr2.to),
    },
  );

export const formatDateTime = (date: string, formatString = "HH:mm") => {
  return format(parseISO(date), formatString);
};

export const formatAppointmentDuration = (appointment: Appointment, language: string) => {
  const firstTreatment = appointment.treatments[0];
  const lastTreatment = appointment.treatments[appointment.treatments.length - 1];

  const from = new Date(firstTreatment.timeRange.from).getTime();
  const to = new Date(lastTreatment.timeRange.to).getTime();

  const duration = to - from;

  const formattedDuration = formatDuration({
    minutes: Math.floor(duration / 1000 / 60) % 60,
    hours: Math.floor(duration / 1000 / 60 / 60),
  });

  // workaround for polish poor pluralization that omits 1 hour in date-fns
  if (language === "pl" && formattedDuration.includes("godzina")) {
    return formattedDuration.replace("godzina", "1 godzina");
  }

  return formattedDuration;
};
