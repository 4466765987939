import { useTranslation } from "react-i18next";

import { DefaultDialog } from "@components/dialogs/DefaultDialog";

import { CalendarFilters } from "./CalendarFilters";

type CalendarFiltersDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const CalendarFiltersDialog = ({ open, onClose }: CalendarFiltersDialogProps) => {
  const { t } = useTranslation();

  return (
    <DefaultDialog open={open} onClose={onClose} title={t("appointments.filter")}>
      <CalendarFilters />
    </DefaultDialog>
  );
};
