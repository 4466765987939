import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";

import { Float } from "@headlessui-float/react";

import { Layout } from "@components/layout/Layout";

import { Subheader } from "@/components/layout/subheader/Subheader";
import { Button } from "@/components/ui/Button";
import {
  CategoryFormDialog,
  CategoryFormProps,
} from "@/features/services/components/categories/CategoryFormDialog";
import {
  HeadCategoryFormDialog,
  HeadCategoryFormProps,
} from "@/features/services/components/headCategories/HeadCategoryFormDialog";
import { Services } from "@/features/services/components/Services";
import {
  TreatmentFormDialog,
  TreatmentFormProps,
} from "@/features/services/components/treatments/TreatmentFormDialog";
import { useHeadCategoriesStore } from "@/features/services/hooks";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useSessionContext } from "@/providers/SessionProvider";
import { cn } from "@/utils/utils";

export const ServicesView = () => {
  const { t } = useTranslation();
  const { permissions } = useSessionContext();
  const { headCategories: groups } = useHeadCategoriesStore();

  const groupDialogState = useDialog<HeadCategoryFormProps>();
  const categoryDialogState = useDialog<CategoryFormProps>();
  const treatmentDialogState = useDialog<TreatmentFormProps>();

  const handleGroupAdd = () => groupDialogState.open();
  const handleCategoryAdd = () => categoryDialogState.open();
  const handleTreatmentAdd = () => treatmentDialogState.open();

  const hasAnyGroup = groups.length > 0;
  const hasAnyCategory = groups.some((group) => group.categories && group.categories.length > 0);

  return (
    <Layout title={t("sidebar.navigation.mySalon.children.servicesAndPrices")}>
      <div className="-ml-4 -mr-4 mt-4 flex h-fit max-w-6xl flex-col gap-8 rounded-xl px-4 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        <Subheader
          title={t("sidebar.navigation.mySalon.children.servicesAndPrices")}
          rightSlot={
            <Menu as="div" className="relative inline-block text-left">
              <Float offset={2} placement={"bottom-end"} portal>
                <Menu.Button as="div">
                  <Button disabled={!permissions.manage_treatments} variant="primary" size="small">
                    <div className="flex items-center gap-2">
                      <span>{t("generic.add")}</span>
                      <ChevronDownIcon className="-mr-1 mt-0.5 size-4" />
                    </div>
                  </Button>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items
                    as="ul"
                    className="w-fill my-2 min-w-28 origin-top-right divide-y divide-stone-100 rounded-md bg-white shadow-lg ring-1 ring-stone-300 focus:outline-none">
                    <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
                      {({ active }) => (
                        <Button
                          variant="neutral"
                          onClick={() => {
                            handleGroupAdd();
                          }}
                          className={cn(
                            "group flex w-full items-center px-4 py-2 text-xs",
                            active && "bg-stone-100",
                          )}>
                          <PlusIcon className="mr-2 size-4" aria-hidden="true" />
                          {t("actions.addGroup")}
                        </Button>
                      )}
                    </Menu.Item>
                    <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
                      {({ active }) => (
                        <Button
                          variant="neutral"
                          disabled={!hasAnyGroup}
                          onClick={() => {
                            handleCategoryAdd();
                          }}
                          className={cn(
                            "group flex w-full items-center px-4 py-2 text-xs",
                            active && "bg-stone-100",
                            !hasAnyGroup && "text-stone-300",
                          )}>
                          <PlusIcon className="mr-2 size-4" aria-hidden="true" />
                          {t("actions.addCategory")}
                        </Button>
                      )}
                    </Menu.Item>
                    <Menu.Item as="li" className="py-1 text-stone-500 group-hover:text-stone-600">
                      {({ active }) => (
                        <Button
                          variant="neutral"
                          disabled={!hasAnyCategory}
                          onClick={() => {
                            handleTreatmentAdd();
                          }}
                          className={cn(
                            "group flex w-full items-center px-4 py-2 text-xs",
                            active && "bg-stone-100",
                            (!hasAnyGroup || !hasAnyCategory) && "text-stone-300",
                          )}>
                          <PlusIcon className="mr-2 size-4" aria-hidden="true" />
                          {t("actions.addTreatment")}
                        </Button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Float>
            </Menu>
          }
        />
        <Services groups={groups} />

        <HeadCategoryFormDialog {...bindDialogState(groupDialogState)} />
        <CategoryFormDialog {...bindDialogState(categoryDialogState)} />
        <TreatmentFormDialog {...bindDialogState(treatmentDialogState)} />
      </div>
    </Layout>
  );
};
