import * as Types from '../../../types';

import { gql } from '@apollo/client';
export type CategoryFragment = { __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any };

export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  color
  headCategoryUuid
  insertedAt
  isDeleted
  name
  updatedAt
  uuid
}
    `;