import { createContext, ReactNode, useCallback, useContext, useState } from "react";

import { AppointmentFilters } from "@/types";

type CalendarFiltersContextProps = {
  filters: Omit<AppointmentFilters, "timeRange">;
  setFilters: (filters: Partial<Omit<AppointmentFilters, "timeRange">>) => void;
};

const CalendarFiltersContext = createContext<CalendarFiltersContextProps | undefined>(undefined);

export const CalendarFiltersProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFiltersState] = useState<Omit<AppointmentFilters, "timeRange">>({});

  const setFilters = useCallback((newFilters: Partial<Omit<AppointmentFilters, "timeRange">>) => {
    setFiltersState((currentFilters) => ({ ...currentFilters, ...newFilters }));
  }, []);

  return (
    <CalendarFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </CalendarFiltersContext.Provider>
  );
};

export const useCalendarFilters = () => {
  const context = useContext(CalendarFiltersContext);
  if (!context) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
};
