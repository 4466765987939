import { cloneElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  BanknotesIcon,
  ClipboardDocumentCheckIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { Spinner } from "@/components/ui/Spinner";
import { integerToPrice } from "@/features/payments/utils";
import { cn } from "@/utils/utils";

import { useGeneralNumbersStore } from "../hooks/use-general-numbers-store";
import { timeRange } from "../models/index";

type StatisticsCardsProps = {
  timeRange: timeRange;
};

export const StatisticsCards = ({ timeRange }: StatisticsCardsProps) => {
  const { t } = useTranslation();
  const { generalNumbers, loading } = useGeneralNumbersStore(timeRange);

  const data = useMemo(
    () => [
      {
        value: integerToPrice(generalNumbers?.totalRevenue || 0),
        label: t("statistics.totalRevenue"),
        icon: <BanknotesIcon />,
        suffix: t("currencies.PLN"),
      },
      {
        value: generalNumbers?.newClients,
        label: t("statistics.newClients"),
        icon: <UserPlusIcon />,
      },
      {
        value: generalNumbers?.canceledAppointments,
        label: t("statistics.canceledAppointements"),
        icon: <XMarkIcon />,
      },
      {
        value: generalNumbers?.finalizedAppointments,
        label: t("statistics.finalizedAppointements"),
        icon: <ClipboardDocumentCheckIcon />,
      },
    ],
    [generalNumbers],
  );

  return (
    <ul className="grid gap-4 md:grid-cols-2 xl:grid-cols-4">
      {data.map((number) => (
        <li key={number.label}>
          <div className="relative overflow-hidden rounded-md border border-stone-300 p-4 px-4">
            <dt>
              <div className="absolute rounded-md bg-gold-500 p-3">
                {cloneElement(number.icon, {
                  className: "w-6 h-6 text-white",
                })}
              </div>
              <p className="ml-16 truncate text-sm text-stone-500">{number.label}</p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <span className={cn("flex text-2xl font-medium", loading && "w-min pt-2")}>
                {loading ? (
                  <Spinner />
                ) : (
                  <span>
                    {number.value} {number.suffix}
                  </span>
                )}
              </span>
            </dd>
          </div>
        </li>
      ))}
    </ul>
  );
};
