export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: any;
  Identifier: any;
  /** JSON field type in postgres */
  JSON: string;
  /**
   * The `Time` scalar type represents a time. The Time appears in a JSON
   * response as an ISO8601 formatted string, without a date component.
   */
  Time: any;
  Timezone: any;
  URI: any;
  /**
   * The `UUID4` scalar type represents UUID4 compliant string data, represented as UTF-8
   * character sequences. The UUID4 type is most often used to represent unique
   * human-readable ID strings.
   */
  UUID4: any;
};

export type AccountNameUpdateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type AccountUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  treatmentsUuids?: InputMaybe<Array<Scalars['UUID4']>>;
};

export type AccountWithProfile = {
  __typename?: 'AccountWithProfile';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type AddCategoryInput = {
  color: Scalars['String'];
  headCategoryUuid: Scalars['UUID4'];
  name: Scalars['String'];
};

export type AddClientInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Sex>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  tagsUuids?: InputMaybe<Array<Scalars['UUID4']>>;
};

export type AddEmployeeInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  imageUrl?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  receiveEmails?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<Role>;
};

export type AddHeadCategoryInput = {
  name: Scalars['String'];
};

export type AddOnboardingCategoriesInput = {
  categories: Array<Scalars['String']>;
  headCategoryName: Scalars['String'];
};

export type AddTreatmentInput = {
  categoryUuid: Scalars['UUID4'];
  description?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  isExactPrice: Scalars['Boolean'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Int']>;
  priceFrom?: InputMaybe<Scalars['Int']>;
  priceTo?: InputMaybe<Scalars['Int']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentUuid: Scalars['UUID4'];
  clientDisplayName: Scalars['String'];
  clientName?: Maybe<Scalars['String']>;
  clientUuid?: Maybe<Scalars['UUID4']>;
  imagesUrls?: Maybe<Array<AppointmentImage>>;
  insertedAt: Scalars['DateTime'];
  isSelfBooked: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  paymentMethodUuid?: Maybe<Scalars['UUID4']>;
  status: AppointmentStatus;
  /** @deprecated Bulk appointments feature - use time range in treatments */
  timeRange?: Maybe<Timerange>;
  totalPrice: Scalars['Int'];
  /** @deprecated Bulk appointments feature - use treatments */
  treatment?: Maybe<AppointmentTreatment>;
  treatments: Array<AppointmentTreatment>;
};

export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  count: Scalars['Int'];
  edges: Array<AppointmentEdge>;
  pageInfo: PageInfo;
};

export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Appointment;
};

export type AppointmentFilters = {
  categoryUuid?: InputMaybe<Scalars['UUID4']>;
  clientUuid?: InputMaybe<Scalars['UUID4']>;
  employeeUuid?: InputMaybe<Scalars['UUID4']>;
  status?: InputMaybe<AppointmentStatus>;
  statuses?: InputMaybe<Array<AppointmentStatus>>;
  timeRange?: InputMaybe<OptionalTimerangeInput>;
};

export type AppointmentImage = {
  __typename?: 'AppointmentImage';
  appointmentUuid: Scalars['ID'];
  imageUrl: Scalars['String'];
  insertedAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type AppointmentImageInput = {
  imageUrl: Scalars['String'];
};

export enum AppointmentStatus {
  /** Canceled by employee */
  Canceled = 'CANCELED',
  /** Canceled by client */
  CanceledByClient = 'CANCELED_BY_CLIENT',
  /** Did not take place */
  Closed = 'CLOSED',
  /** Took place */
  Completed = 'COMPLETED',
  /** Requested by client */
  Requested = 'REQUESTED',
  /** Scheduled to take place */
  Scheduled = 'SCHEDULED'
}

export enum AppointmentSubscriptionAction {
  Create = 'CREATE',
  Update = 'UPDATE'
}

export type AppointmentTreatment = {
  __typename?: 'AppointmentTreatment';
  appointmentTreatmentUuid: Scalars['UUID4'];
  categoryColor?: Maybe<Scalars['String']>;
  employeeName?: Maybe<Scalars['String']>;
  employeeUuid?: Maybe<Scalars['UUID4']>;
  finalPrice: Scalars['Int'];
  suggestedPrice: Scalars['Int'];
  timeRange: Timerange;
  treatmentName: Scalars['String'];
  treatmentUuid: Scalars['UUID4'];
};

export type AppointmentTreatmentPrice = {
  __typename?: 'AppointmentTreatmentPrice';
  appointmentTreatmentUuid: Scalars['UUID4'];
  name: Scalars['String'];
  suggestedPrice: Scalars['Int'];
};

export type AvailableEquipment = {
  __typename?: 'AvailableEquipment';
  equipmentItemDescription?: Maybe<Scalars['String']>;
  equipmentItemName: Scalars['String'];
  equipmentItemUuid: Scalars['UUID4'];
};

export type AvailableEquipmentsForTreatment = {
  __typename?: 'AvailableEquipmentsForTreatment';
  availableEquipments: Array<AvailableEquipment>;
  treatmentUuid: Scalars['UUID4'];
};

export type BillingPortalSession = {
  __typename?: 'BillingPortalSession';
  customer: Scalars['String'];
  id: Scalars['String'];
  url: Scalars['String'];
};

export type BlockSlotInput = {
  description?: InputMaybe<Scalars['String']>;
  employeeUuid: Scalars['UUID4'];
  timeRange: TimerangeInput;
  title?: InputMaybe<Scalars['String']>;
};

export type BlockedSlot = {
  __typename?: 'BlockedSlot';
  description?: Maybe<Scalars['String']>;
  employeeName: Scalars['String'];
  employeeUuid: Scalars['UUID4'];
  insertedAt: Scalars['DateTime'];
  timeRange: Timerange;
  title?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID4'];
};

export type CalendarEmployee = {
  __typename?: 'CalendarEmployee';
  accountUuid: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role: Role;
};

export type CalendarResult = Appointment | BlockedSlot;

export type Category = {
  __typename?: 'Category';
  color: Scalars['String'];
  headCategoryUuid: Scalars['UUID4'];
  insertedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID4'];
};

export type CheckEquipmentsForTreatmentsAvailabilityInput = {
  timeRange: TimerangeInput;
  treatmentUuid: Scalars['UUID4'];
};

export type Client = {
  __typename?: 'Client';
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  imagesUrls: Array<Scalars['String']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  sex?: Maybe<Sex>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  tags: Array<ClientTag>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID4'];
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  count: Scalars['Int'];
  edges: Array<ClientEdge>;
  pageInfo: PageInfo;
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Client;
};

export type ClientFilters = {
  tagsUuids?: InputMaybe<Array<Scalars['UUID4']>>;
};

export enum ClientSortBy {
  InsertedAt = 'INSERTED_AT',
  /** First name and last name */
  Name = 'NAME'
}

export type ClientTag = {
  __typename?: 'ClientTag';
  color: Scalars['String'];
  name: Scalars['String'];
  uuid: Scalars['UUID4'];
};

export type ClientTagInput = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type Color = {
  __typename?: 'Color';
  name: Scalars['String'];
};

export type CommandResult = {
  __typename?: 'CommandResult';
  id?: Maybe<Scalars['String']>;
};

export type CompanyBillingData = {
  __typename?: 'CompanyBillingData';
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  nip?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  salonUuid: Scalars['UUID4'];
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID4'];
};

export type CompanyBillingDataInput = {
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nip?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type CreateEquipmentItemInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  treatmentsUuids: Array<Scalars['UUID4']>;
};

export type CreateSalonInput = {
  name: Scalars['String'];
};

export type CurrentSession = {
  __typename?: 'CurrentSession';
  accountUuid?: Maybe<Scalars['UUID4']>;
  customSenderName?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  employeeLimit?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isOnboarded: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  salonEmployees?: Maybe<Scalars['Int']>;
  salonName?: Maybe<Scalars['String']>;
  salonPlan?: Maybe<SubscriptionPlan>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  trialUsed?: Maybe<Scalars['Boolean']>;
};

export type Employee = {
  __typename?: 'Employee';
  accountUuid: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isAvailableForAppointments: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  receiveEmails: Scalars['Boolean'];
  role: Role;
  treatments: Array<Treatment>;
  workSchedule?: Maybe<Scalars['JSON']>;
};

export type EmployeeCalendarResult = {
  __typename?: 'EmployeeCalendarResult';
  employee: CalendarEmployee;
  entries: Array<CalendarResult>;
};

export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  count: Scalars['Int'];
  edges: Array<EmployeeEdge>;
  pageInfo: PageInfo;
};

export type EmployeeEdge = {
  __typename?: 'EmployeeEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Employee;
};

export type EmployeesLedgerBlueprint = {
  __typename?: 'EmployeesLedgerBlueprint';
  commission: EmployeesLedgerCommission;
  employeeId: Scalars['Identifier'];
  id: Scalars['Identifier'];
  startsAt: Scalars['Date'];
  treatmentId?: Maybe<Scalars['Identifier']>;
};

export type EmployeesLedgerCommission = {
  __typename?: 'EmployeesLedgerCommission';
  type: EmployeesLedgerCommissionType;
  value: Scalars['Int'];
};

export type EmployeesLedgerCommissionInput = {
  type: EmployeesLedgerCommissionType;
  value: Scalars['Int'];
};

export enum EmployeesLedgerCommissionType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type EmployeesLedgerSetSettlementBlueprintInput = {
  commission: EmployeesLedgerCommissionInput;
  employeeId: Scalars['UUID4'];
  startsAt: Scalars['Date'];
  /** If null provided, sets general blueprint for employee */
  treatmentId?: InputMaybe<Scalars['UUID4']>;
};

/** Returns data for displaying employees ledger summary (commissions & turnover) */
export type EmployeesLedgerSummary = {
  __typename?: 'EmployeesLedgerSummary';
  items?: Maybe<Array<EmployeesLedgerSummaryEmployee>>;
  month: Month;
};

export type EmployeesLedgerSummaryEmployee = {
  __typename?: 'EmployeesLedgerSummaryEmployee';
  commission: Money;
  employeeId: Scalars['Identifier'];
  treatmentId?: Maybe<Scalars['Identifier']>;
  turnover: Money;
};

export type EquipmentItem = {
  __typename?: 'EquipmentItem';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  treatments: Array<EquipmentItemTreatment>;
  uuid: Scalars['UUID4'];
};

export type EquipmentItemConnection = {
  __typename?: 'EquipmentItemConnection';
  count: Scalars['Int'];
  edges: Array<EquipmentItemEdge>;
  pageInfo: PageInfo;
};

export type EquipmentItemEdge = {
  __typename?: 'EquipmentItemEdge';
  cursor?: Maybe<Scalars['String']>;
  node: EquipmentItem;
};

export type EquipmentItemTreatment = {
  __typename?: 'EquipmentItemTreatment';
  treatmentName: Scalars['String'];
  treatmentUuid: Scalars['UUID4'];
};

export type ExampleCategory = {
  __typename?: 'ExampleCategory';
  categories: Array<Scalars['String']>;
  headCategoryName: Scalars['String'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Feedback = {
  __typename?: 'Feedback';
  message: Scalars['String'];
  subject: Scalars['String'];
};

export type FeedbackInput = {
  message: Scalars['String'];
  subject: Scalars['String'];
};

export type FinalizeBulkAppointmentInput = {
  appointmentUuid: Scalars['UUID4'];
  paymentMethodUuid?: InputMaybe<Scalars['UUID4']>;
  status: AppointmentStatus;
  treatments: Array<FinalizeBulkTreatmentInput>;
};

export type FinalizeBulkTreatmentInput = {
  appointmentTreatmentUuid: Scalars['UUID4'];
  finalPrice: Scalars['Int'];
};

export type GeneralNumbers = {
  __typename?: 'GeneralNumbers';
  canceledAppointments: Scalars['Int'];
  finalizedAppointments: Scalars['Int'];
  newClients: Scalars['Int'];
  totalRevenue: Scalars['Int'];
};

export type HeadCategory = {
  __typename?: 'HeadCategory';
  categories?: Maybe<Array<Category>>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  salonUuid: Scalars['UUID4'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID4'];
};

export type HeadCategoryConnection = {
  __typename?: 'HeadCategoryConnection';
  count: Scalars['Int'];
  edges: Array<HeadCategoryEdge>;
  pageInfo: PageInfo;
};

export type HeadCategoryEdge = {
  __typename?: 'HeadCategoryEdge';
  cursor?: Maybe<Scalars['String']>;
  node: HeadCategory;
};

export type IncomeNumbers = {
  __typename?: 'IncomeNumbers';
  appointmentsCount: Scalars['Int'];
  date: Scalars['DateTime'];
  income: Scalars['Int'];
};

export type MakeOrderInput = {
  orderItems: Scalars['JSON'];
  paymentMode: Scalars['String'];
};

export type MakePaymentInput = {
  accountUuid: Scalars['UUID4'];
  orderItems: Scalars['JSON'];
  orderUuid: Scalars['UUID4'];
  paymentMode: PaymentMode;
};

export enum MaxLeadTimeOptionsInDays {
  /** 7 days */
  Days_7 = 'DAYS_7',
  /** 14 days */
  Days_14 = 'DAYS_14',
  /** 1 month */
  Days_30 = 'DAYS_30',
  /** 2 months */
  Days_60 = 'DAYS_60',
  /** 3 months */
  Days_90 = 'DAYS_90',
  /** 6 months */
  Days_180 = 'DAYS_180',
  /** 1 year */
  Days_365 = 'DAYS_365',
  /** 2 years */
  Days_730 = 'DAYS_730'
}

export enum MinLeadTimeOptionsInMinutes {
  /** 15 minutes */
  Minutes_15 = 'MINUTES_15',
  /** 30 minutes */
  Minutes_30 = 'MINUTES_30',
  /** 1 hour */
  Minutes_60 = 'MINUTES_60',
  /** 2 hours */
  Minutes_120 = 'MINUTES_120',
  /** 3 hours */
  Minutes_180 = 'MINUTES_180',
  /** 6 hours */
  Minutes_360 = 'MINUTES_360',
  /** 12 hours */
  Minutes_720 = 'MINUTES_720',
  /** 1 day */
  Minutes_1440 = 'MINUTES_1440',
  /** 2 days */
  Minutes_2880 = 'MINUTES_2880',
  /** 3 days */
  Minutes_4320 = 'MINUTES_4320',
  /** 5 days */
  Minutes_7200 = 'MINUTES_7200',
  /** 7 days */
  Minutes_10080 = 'MINUTES_10080',
  /** 10 days */
  Minutes_14400 = 'MINUTES_14400',
  /** 14 days */
  Minutes_20160 = 'MINUTES_20160'
}

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Int'];
  currency: Scalars['String'];
};

export type Month = {
  __typename?: 'Month';
  /** <1, 12> */
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type MonthInput = {
  /** <1, 12> */
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type OptionalTimerangeInput = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type Order = {
  __typename?: 'Order';
  accountEmail: Scalars['String'];
  accountUuid: Scalars['UUID4'];
  orderItems?: Maybe<Scalars['JSON']>;
  paymentMode?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID4'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  accountUuid?: Maybe<Scalars['UUID4']>;
  name?: Maybe<Scalars['String']>;
  orderItems?: Maybe<Scalars['JSON']>;
  orderUuid?: Maybe<Scalars['UUID4']>;
  uuid: Scalars['UUID4'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PaymentMode {
  /** payment mode */
  Payment = 'PAYMENT',
  /** subscription if at least one item in order is subscription */
  Subscription = 'SUBSCRIPTION'
}

export type PersonalBillingData = {
  __typename?: 'PersonalBillingData';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  salonUuid: Scalars['UUID4'];
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID4'];
};

export type PersonalBillingDataInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export enum PhoneType {
  /** Mobile */
  Mobile = 'MOBILE',
  /** Phone */
  Phone = 'PHONE'
}

export type Price = {
  __typename?: 'Price';
  billingScheme?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priceDecimal?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Price>;
  priceId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum ProductType {
  Product = 'PRODUCT',
  Subscription = 'SUBSCRIPTION'
}

export type ReferralScore = {
  __typename?: 'ReferralScore';
  name: Scalars['String'];
  score: Scalars['Int'];
};

export enum Role {
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Owner */
  Owner = 'OWNER'
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  accountResendConfirmation: CommandResult;
  addAppointmentImages?: Maybe<Array<Maybe<AppointmentImage>>>;
  addCategory: Category;
  addClient: Client;
  addHeadCategory: HeadCategory;
  addOnboardingCategories: Scalars['String'];
  addSalonEmployee: Employee;
  addTreatment: Treatment;
  approveAppointmentRequest: Appointment;
  blockSlot: BlockedSlot;
  cancelAppointment: Appointment;
  changeSubscriptionPlan: Scalars['String'];
  createBillingPortalSession: BillingPortalSession;
  createClientTag: ClientTag;
  createCompanyBillingData: CompanyBillingData;
  createEquipmentItem: EquipmentItem;
  createPersonalBillingData: PersonalBillingData;
  createSalon: Salon;
  deleteBlockedSlot: Scalars['String'];
  deleteCategory: Category;
  deleteClient: Client;
  deleteClientTag: Scalars['String'];
  deleteEquipmentItem: Scalars['String'];
  deleteHeadCategory: HeadCategory;
  deleteSalonEmployee: Scalars['String'];
  deleteTreatment: Treatment;
  employeeResendInvitation: CommandResult;
  employeesLedgerDeleteSettlementBlueprint: CommandResult;
  employeesLedgerSetSettlementBlueprint: CommandResult;
  finalizeBulkAppointment: Appointment;
  finishSalonOnboarding: Salon;
  makeOrder: Order;
  /** Returns payment session */
  makePayment: Session;
  scheduleBulkAppointment: Appointment;
  scheduleSetPerDate: CommandResult;
  sendFeedback: Feedback;
  sendSmsToClients: Scalars['String'];
  setSalonDefaultPaymentMethod: SalonPaymentMethod;
  undoAppointmentFinalization: Appointment;
  unsetSalonDefaultPaymentMethod: Array<SalonPaymentMethod>;
  updateAccount: AccountWithProfile;
  updateAccountName: AccountWithProfile;
  updateBlockedSlot: BlockedSlot;
  updateBulkAppointment: Appointment;
  updateCategoriesPositions: Scalars['String'];
  updateCategory: Category;
  updateClient: Client;
  updateClientTag: ClientTag;
  updateCompanyBillingData: CompanyBillingData;
  updateEmployeesPositions: Scalars['String'];
  updateEquipmentItem: EquipmentItem;
  updateHeadCategoriesPositions: Scalars['String'];
  updateHeadCategory: HeadCategory;
  updatePersonalBillingData: PersonalBillingData;
  updateSalon: Salon;
  updateSalonEmployee: Employee;
  updateSalonOpeningHours: SalonOpeningHours;
  updateSalonPaymentMethods: Array<SalonPaymentMethod>;
  updateSalonPaymentMethodsPositions: Array<SalonPaymentMethod>;
  updateSalonSenderName: SalonSmsDetails;
  updateSalonSmsDetails: SalonSmsDetails;
  updateSalonSmsTemplate: Array<SalonSmsTemplate>;
  updateTreatment: Treatment;
  updateTreatmentsPositions: Scalars['String'];
};


export type RootMutationTypeAddAppointmentImagesArgs = {
  appointmentUuid: Scalars['UUID4'];
  images: Array<InputMaybe<AppointmentImageInput>>;
};


export type RootMutationTypeAddCategoryArgs = {
  data: AddCategoryInput;
};


export type RootMutationTypeAddClientArgs = {
  data: AddClientInput;
};


export type RootMutationTypeAddHeadCategoryArgs = {
  data: AddHeadCategoryInput;
};


export type RootMutationTypeAddOnboardingCategoriesArgs = {
  data: Array<AddOnboardingCategoriesInput>;
};


export type RootMutationTypeAddSalonEmployeeArgs = {
  data: AddEmployeeInput;
};


export type RootMutationTypeAddTreatmentArgs = {
  data: AddTreatmentInput;
};


export type RootMutationTypeApproveAppointmentRequestArgs = {
  data: UpdateBulkAppointmentInput;
};


export type RootMutationTypeBlockSlotArgs = {
  data: BlockSlotInput;
};


export type RootMutationTypeCancelAppointmentArgs = {
  appointmentUuid: Scalars['UUID4'];
  sendNotification: Scalars['Boolean'];
};


export type RootMutationTypeChangeSubscriptionPlanArgs = {
  subscriptionPriceId: Scalars['String'];
};


export type RootMutationTypeCreateBillingPortalSessionArgs = {
  stripeCustomerId: Scalars['String'];
};


export type RootMutationTypeCreateClientTagArgs = {
  data: ClientTagInput;
};


export type RootMutationTypeCreateCompanyBillingDataArgs = {
  data: CompanyBillingDataInput;
};


export type RootMutationTypeCreateEquipmentItemArgs = {
  data: CreateEquipmentItemInput;
};


export type RootMutationTypeCreatePersonalBillingDataArgs = {
  data: PersonalBillingDataInput;
};


export type RootMutationTypeCreateSalonArgs = {
  data: CreateSalonInput;
};


export type RootMutationTypeDeleteBlockedSlotArgs = {
  uuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteCategoryArgs = {
  categoryUuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteClientArgs = {
  clientUuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteClientTagArgs = {
  tagUuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteEquipmentItemArgs = {
  equipmentItemUuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteHeadCategoryArgs = {
  categoryUuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteSalonEmployeeArgs = {
  accountUuid: Scalars['UUID4'];
};


export type RootMutationTypeDeleteTreatmentArgs = {
  treatmentUuid: Scalars['UUID4'];
};


export type RootMutationTypeEmployeeResendInvitationArgs = {
  accountId: Scalars['UUID4'];
};


export type RootMutationTypeEmployeesLedgerDeleteSettlementBlueprintArgs = {
  id: Scalars['Identifier'];
};


export type RootMutationTypeEmployeesLedgerSetSettlementBlueprintArgs = {
  data: EmployeesLedgerSetSettlementBlueprintInput;
};


export type RootMutationTypeFinalizeBulkAppointmentArgs = {
  data: FinalizeBulkAppointmentInput;
};


export type RootMutationTypeMakeOrderArgs = {
  data: MakeOrderInput;
};


export type RootMutationTypeMakePaymentArgs = {
  data: MakePaymentInput;
};


export type RootMutationTypeScheduleBulkAppointmentArgs = {
  data: ScheduleBulkAppointmentInput;
};


export type RootMutationTypeScheduleSetPerDateArgs = {
  data: ScheduleSetPerDateInput;
};


export type RootMutationTypeSendFeedbackArgs = {
  data: FeedbackInput;
};


export type RootMutationTypeSendSmsToClientsArgs = {
  clientsUuids: Array<Scalars['UUID4']>;
  message: Scalars['String'];
};


export type RootMutationTypeSetSalonDefaultPaymentMethodArgs = {
  data: Scalars['UUID4'];
};


export type RootMutationTypeUndoAppointmentFinalizationArgs = {
  appointmentUuid: Scalars['UUID4'];
};


export type RootMutationTypeUpdateAccountArgs = {
  data: AccountUpdateInput;
};


export type RootMutationTypeUpdateAccountNameArgs = {
  data: AccountNameUpdateInput;
};


export type RootMutationTypeUpdateBlockedSlotArgs = {
  data: UpdateBlockedSlotInput;
};


export type RootMutationTypeUpdateBulkAppointmentArgs = {
  data: UpdateBulkAppointmentInput;
};


export type RootMutationTypeUpdateCategoriesPositionsArgs = {
  data: UpdateCategoriesPositionsInput;
};


export type RootMutationTypeUpdateCategoryArgs = {
  categoryUuid: Scalars['UUID4'];
  data: UpdateCategoryInput;
};


export type RootMutationTypeUpdateClientArgs = {
  clientUuid: Scalars['UUID4'];
  data: UpdateClientInput;
};


export type RootMutationTypeUpdateClientTagArgs = {
  data: ClientTagInput;
  tagUuid: Scalars['UUID4'];
};


export type RootMutationTypeUpdateCompanyBillingDataArgs = {
  data: CompanyBillingDataInput;
};


export type RootMutationTypeUpdateEmployeesPositionsArgs = {
  data: UpdateEmployeesPositionsInput;
};


export type RootMutationTypeUpdateEquipmentItemArgs = {
  data: UpdateEquipmentItemInput;
  equipmentItemUuid: Scalars['UUID4'];
};


export type RootMutationTypeUpdateHeadCategoriesPositionsArgs = {
  data: UpdateHeadCategoriesPositionsInput;
};


export type RootMutationTypeUpdateHeadCategoryArgs = {
  categoryUuid: Scalars['UUID4'];
  data: UpdateHeadCategoryInput;
};


export type RootMutationTypeUpdatePersonalBillingDataArgs = {
  data: PersonalBillingDataInput;
};


export type RootMutationTypeUpdateSalonArgs = {
  data: UpdateSalonInput;
};


export type RootMutationTypeUpdateSalonEmployeeArgs = {
  accountUuid: Scalars['UUID4'];
  data: UpdateEmployeeInput;
};


export type RootMutationTypeUpdateSalonOpeningHoursArgs = {
  data: UpdateSalonOpeningHoursInput;
};


export type RootMutationTypeUpdateSalonPaymentMethodsArgs = {
  data: Array<SalonPaymentMethodInput>;
};


export type RootMutationTypeUpdateSalonPaymentMethodsPositionsArgs = {
  data: Array<Scalars['UUID4']>;
};


export type RootMutationTypeUpdateSalonSenderNameArgs = {
  senderName: Scalars['String'];
};


export type RootMutationTypeUpdateSalonSmsDetailsArgs = {
  data: UpdateSalonSmsDetailsInput;
};


export type RootMutationTypeUpdateSalonSmsTemplateArgs = {
  data: UpdateSmsTemplateInput;
};


export type RootMutationTypeUpdateTreatmentArgs = {
  data: UpdateTreatmentInput;
  treatmentUuid: Scalars['UUID4'];
};


export type RootMutationTypeUpdateTreatmentsPositionsArgs = {
  data: UpdateTreatmentsPositionsInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  categoryColors: Array<Maybe<Color>>;
  checkEquipmentsForTreatmentsAvailability: Array<AvailableEquipmentsForTreatment>;
  currentSession: CurrentSession;
  employeesLedgerBlueprints: Array<EmployeesLedgerBlueprint>;
  employeesLedgerSummary: EmployeesLedgerSummary;
  featureFlag: FeatureFlag;
  featureFlags: Array<FeatureFlag>;
  fetchAllTreatments: SearchTreatmentConnection;
  fetchAppointment: Appointment;
  fetchAppointmentCancelLink?: Maybe<Scalars['String']>;
  fetchAppointments: Array<Appointment>;
  fetchBulkAppointments: Array<Appointment>;
  fetchBulkAppointmentsPaginated: AppointmentConnection;
  fetchCalendar: Array<CalendarResult>;
  fetchCalendarByEmployee: Array<EmployeeCalendarResult>;
  fetchCategory: Category;
  fetchChosenTreatments: Array<SearchTreatment>;
  fetchClient: Client;
  fetchEmployee: Employee;
  fetchEmployees: EmployeeConnection;
  fetchExampleCategories: Array<ExampleCategory>;
  fetchGeneralNumbers: GeneralNumbers;
  fetchHeadCategories: HeadCategoryConnection;
  fetchHeadCategory: HeadCategory;
  fetchIncomeNumbers: Array<IncomeNumbers>;
  fetchOrder: Order;
  fetchOrderEvents: Array<OrderEvent>;
  fetchOrders: Array<Order>;
  fetchPrice: Price;
  fetchProduct: Product;
  fetchProductByPrice: Product;
  fetchProductsWithPrices: Array<Product>;
  fetchProductsWithPricesByType: Array<Product>;
  fetchReferralScore: ReferralScore;
  fetchRemainingAppointments?: Maybe<Scalars['Int']>;
  fetchSalon: Salon;
  fetchSalonClients: ClientConnection;
  fetchSalonCompanyBillingData: CompanyBillingData;
  fetchSalonEquipmentItems: EquipmentItemConnection;
  fetchSalonOpeningHours: SalonOpeningHours;
  fetchSalonPaymentMethod: SalonPaymentMethod;
  fetchSalonPaymentMethods?: Maybe<Array<SalonPaymentMethod>>;
  fetchSalonPersonalBillingData: PersonalBillingData;
  fetchSalonSmsDetails: SalonSmsDetails;
  fetchSalonSmsTemplate: SalonSmsTemplate;
  fetchSalonSmsTemplates: Array<SalonSmsTemplate>;
  fetchSalonTags: Array<ClientTag>;
  fetchSentSms: SmsConnection;
  fetchSmsLimit: Scalars['Int'];
  fetchSuggestedAppointmentTreatmentsPrices: Array<AppointmentTreatmentPrice>;
  fetchTreatment: Treatment;
  fetchTreatments: TreatmentConnection;
  getEquipmentItem: EquipmentItem;
  scheduleListPerDate?: Maybe<Array<Maybe<SchedulePerDate>>>;
  searchTreatment: SearchTreatment;
};


export type RootQueryTypeCheckEquipmentsForTreatmentsAvailabilityArgs = {
  data: Array<CheckEquipmentsForTreatmentsAvailabilityInput>;
};


export type RootQueryTypeEmployeesLedgerBlueprintsArgs = {
  employeeId: Scalars['Identifier'];
};


export type RootQueryTypeEmployeesLedgerSummaryArgs = {
  month: MonthInput;
};


export type RootQueryTypeFeatureFlagArgs = {
  name?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFeatureFlagsArgs = {
  names?: InputMaybe<Array<Scalars['String']>>;
};


export type RootQueryTypeFetchAllTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFetchAppointmentArgs = {
  appointmentUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchAppointmentCancelLinkArgs = {
  appointmentUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchAppointmentsArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchBulkAppointmentsArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchBulkAppointmentsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters: AppointmentFilters;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortByDatetime?: InputMaybe<SortOrder>;
};


export type RootQueryTypeFetchCalendarArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchCalendarByEmployeeArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchCategoryArgs = {
  categoryUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchChosenTreatmentsArgs = {
  uuids: Array<Scalars['UUID4']>;
};


export type RootQueryTypeFetchClientArgs = {
  clientUuid?: InputMaybe<Scalars['UUID4']>;
};


export type RootQueryTypeFetchEmployeeArgs = {
  accountUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchEmployeesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFetchGeneralNumbersArgs = {
  timeRange: TimerangeInput;
};


export type RootQueryTypeFetchHeadCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFetchHeadCategoryArgs = {
  categoryUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchIncomeNumbersArgs = {
  displayFormat: StatisticsDisplayFormat;
  period: StatisticsPeriod;
};


export type RootQueryTypeFetchOrderArgs = {
  uuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchOrderEventsArgs = {
  uuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchPriceArgs = {
  id: Scalars['String'];
};


export type RootQueryTypeFetchProductArgs = {
  id: Scalars['String'];
};


export type RootQueryTypeFetchProductByPriceArgs = {
  priceId: Scalars['String'];
};


export type RootQueryTypeFetchProductsWithPricesByTypeArgs = {
  type: ProductType;
};


export type RootQueryTypeFetchReferralScoreArgs = {
  referralId: Scalars['String'];
};


export type RootQueryTypeFetchSalonClientsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ClientFilters>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ClientSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type RootQueryTypeFetchSalonEquipmentItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFetchSalonPaymentMethodArgs = {
  data: Scalars['UUID4'];
};


export type RootQueryTypeFetchSalonSmsTemplateArgs = {
  type: Scalars['String'];
};


export type RootQueryTypeFetchSentSmsArgs = {
  after?: InputMaybe<Scalars['String']>;
  clientUuid?: InputMaybe<Scalars['UUID4']>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeFetchSuggestedAppointmentTreatmentsPricesArgs = {
  appointmentUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchTreatmentArgs = {
  treatmentUuid: Scalars['UUID4'];
};


export type RootQueryTypeFetchTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  categoryUuid: Scalars['UUID4'];
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type RootQueryTypeGetEquipmentItemArgs = {
  equipmentItemUuid?: InputMaybe<Scalars['UUID4']>;
};


export type RootQueryTypeScheduleListPerDateArgs = {
  subjectId: Scalars['UUID4'];
  subjectType: ScheduleSubject;
};


export type RootQueryTypeSearchTreatmentArgs = {
  treatmentUuid: Scalars['UUID4'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  appointmentCreatedOrUpdated?: Maybe<SubscriptionAppointment>;
  smsLimitUpdated: Scalars['Int'];
};


export type RootSubscriptionTypeAppointmentCreatedOrUpdatedArgs = {
  filters: AppointmentFilters;
};

export type Salon = {
  __typename?: 'Salon';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isPublic: Scalars['Boolean'];
  links?: Maybe<Scalars['JSON']>;
  logoImageUrl?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phoneType?: Maybe<PhoneType>;
  postalCode?: Maybe<Scalars['String']>;
  salonUuid: Scalars['UUID4'];
  selfBooking: SalonSelfBooking;
  /** @deprecated use self_booking object */
  selfBookingAutomaticalSlotApproval: Scalars['Boolean'];
  /** @deprecated use self_booking object */
  selfBookingAutomaticalSlotApprovalSms: Scalars['Boolean'];
  /** @deprecated use self_booking object */
  selfBookingMaxLeadTime: MaxLeadTimeOptionsInDays;
  /** @deprecated use self_booking object */
  selfBookingMinLeadTime: MinLeadTimeOptionsInMinutes;
  /** @deprecated use self_booking object */
  selfBookingSlotDuration: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  socials?: Maybe<Scalars['JSON']>;
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type SalonOpeningHours = {
  __typename?: 'SalonOpeningHours';
  regular?: Maybe<Scalars['JSON']>;
  special?: Maybe<Scalars['JSON']>;
};

export type SalonPaymentMethod = {
  __typename?: 'SalonPaymentMethod';
  default: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  name: SalonPaymentMethods;
  salonUuid: Scalars['UUID4'];
  uuid: Scalars['UUID4'];
};

export type SalonPaymentMethodInput = {
  enabled: Scalars['Boolean'];
  uuid: Scalars['UUID4'];
};

export enum SalonPaymentMethods {
  /** Bank Transfer */
  BankTransfer = 'BANK_TRANSFER',
  /** BLIK */
  Blik = 'BLIK',
  /** Cash */
  Cash = 'CASH',
  /** Credit Card */
  CreditCard = 'CREDIT_CARD',
  /** Voucher */
  Voucher = 'VOUCHER'
}

export type SalonSelfBooking = {
  __typename?: 'SalonSelfBooking';
  automaticalSlotApproval: Scalars['Boolean'];
  automaticalSlotApprovalSms: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  maxLeadTime: MaxLeadTimeOptionsInDays;
  minLeadTime: MinLeadTimeOptionsInMinutes;
  slotDuration: Scalars['Int'];
  url: Scalars['URI'];
};

export type SalonSelfBookingInput = {
  automaticalSlotApproval: Scalars['Boolean'];
  automaticalSlotApprovalSms: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  maxLeadTime: MaxLeadTimeOptionsInDays;
  minLeadTime: MinLeadTimeOptionsInMinutes;
  slotDuration: Scalars['Int'];
};

export type SalonSmsDetails = {
  __typename?: 'SalonSmsDetails';
  appointmentReminderSendInAdvanceHours: Scalars['Int'];
  sendReminders: Scalars['Boolean'];
  senderName: Scalars['String'];
  senderNameApproved: Scalars['Boolean'];
};

export type SalonSmsTemplate = {
  __typename?: 'SalonSmsTemplate';
  description?: Maybe<Scalars['String']>;
  salonUuid: Scalars['UUID4'];
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID4'];
};

export type ScheduleBulkAppointmentInput = {
  clientUuid?: InputMaybe<Scalars['UUID4']>;
  imagesUrls?: InputMaybe<Array<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  sendNotification: Scalars['Boolean'];
  treatments: Array<ScheduleBulkAppointmentTreatmentInput>;
};

export type ScheduleBulkAppointmentTreatmentInput = {
  employeeUuid: Scalars['UUID4'];
  timeRange: TimerangeInput;
  treatmentUuid: Scalars['UUID4'];
};

export type SchedulePerDate = {
  __typename?: 'SchedulePerDate';
  date: Scalars['Date'];
  name?: Maybe<Scalars['String']>;
  subjectId: Scalars['UUID4'];
  subjectType: ScheduleSubject;
  timeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type ScheduleSetPerDateInput = {
  date?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  subjectId: Scalars['UUID4'];
  subjectType: ScheduleSubject;
  timeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export enum ScheduleSubject {
  Employee = 'EMPLOYEE',
  Salon = 'SALON'
}

export type SearchTreatment = {
  __typename?: 'SearchTreatment';
  categoryColor: Scalars['String'];
  categoryName: Scalars['String'];
  categoryUuid: Scalars['UUID4'];
  treatmentDescription?: Maybe<Scalars['String']>;
  treatmentDuration: Scalars['Int'];
  treatmentIsExactPrice?: Maybe<Scalars['Boolean']>;
  treatmentName: Scalars['String'];
  treatmentPrice?: Maybe<Scalars['Int']>;
  treatmentPriceFrom?: Maybe<Scalars['Int']>;
  treatmentPriceTo?: Maybe<Scalars['Int']>;
  treatmentUuid: Scalars['UUID4'];
};

export type SearchTreatmentConnection = {
  __typename?: 'SearchTreatmentConnection';
  count: Scalars['Int'];
  edges: Array<SearchTreatmentEdge>;
  pageInfo: PageInfo;
};

export type SearchTreatmentEdge = {
  __typename?: 'SearchTreatmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SearchTreatment;
};

export type Session = {
  __typename?: 'Session';
  amountSubtotal: Scalars['Int'];
  amountTotal: Scalars['Int'];
  cancelUrl: Scalars['String'];
  currency: Scalars['String'];
  id: Scalars['String'];
  metadata: Scalars['JSON'];
  mode: Scalars['String'];
  paymentStatus: Scalars['String'];
  status: Scalars['String'];
  successUrl: Scalars['String'];
  url: Scalars['String'];
};

export enum Sex {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE'
}

export type Sms = {
  __typename?: 'Sms';
  clientUuid: Scalars['UUID4'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  salonUuid: Scalars['UUID4'];
  senderName?: Maybe<Scalars['String']>;
  sentAt?: Maybe<Scalars['DateTime']>;
  status: SmsStatus;
  uuid: Scalars['UUID4'];
};

export type SmsConnection = {
  __typename?: 'SmsConnection';
  count: Scalars['Int'];
  edges: Array<SmsEdge>;
  pageInfo: PageInfo;
};

export type SmsEdge = {
  __typename?: 'SmsEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Sms;
};

export enum SmsStatus {
  Discarded = 'DISCARDED',
  Sent = 'SENT'
}

export enum SortOrder {
  /** Ascending */
  Asc = 'ASC',
  /** Descending */
  Desc = 'DESC'
}

export enum StatisticsDisplayFormat {
  Day = 'DAY',
  Hour = 'HOUR'
}

export enum StatisticsPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type SubscriptionAppointment = {
  __typename?: 'SubscriptionAppointment';
  action: AppointmentSubscriptionAction;
  appointment: Appointment;
};

export enum SubscriptionPlan {
  /** Free subscription */
  Free = 'FREE',
  /** Salon subscription */
  Salon = 'SALON'
}

export type TimeRange = {
  __typename?: 'TimeRange';
  from: Scalars['Time'];
  timezone: Scalars['Timezone'];
  to: Scalars['Time'];
};

export type TimeRangeInput = {
  from: Scalars['Time'];
  timezone?: InputMaybe<Scalars['Timezone']>;
  to: Scalars['Time'];
};

export type Timerange = {
  __typename?: 'Timerange';
  /** Range beginning timestamp */
  from: Scalars['DateTime'];
  /** Range end timestamp */
  to: Scalars['DateTime'];
};

export type TimerangeInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type Treatment = {
  __typename?: 'Treatment';
  categoryUuid: Scalars['UUID4'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  hasEmployeesAssigned: Scalars['Boolean'];
  imagesUrls: Array<Scalars['String']>;
  insertedAt?: Maybe<Scalars['DateTime']>;
  isDeleted: Scalars['Boolean'];
  isExactPrice: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Int']>;
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['UUID4'];
};

export type TreatmentConnection = {
  __typename?: 'TreatmentConnection';
  count: Scalars['Int'];
  edges: Array<TreatmentEdge>;
  pageInfo: PageInfo;
};

export type TreatmentEdge = {
  __typename?: 'TreatmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Treatment;
};

export type UpdateBlockedSlotInput = {
  description?: InputMaybe<Scalars['String']>;
  employeeUuid: Scalars['UUID4'];
  timeRange: TimerangeInput;
  title?: InputMaybe<Scalars['String']>;
  uuid: Scalars['UUID4'];
};

export type UpdateBulkAppointmentInput = {
  appointmentUuid: Scalars['UUID4'];
  clientUuid?: InputMaybe<Scalars['UUID4']>;
  imagesUrls?: InputMaybe<Array<Scalars['String']>>;
  note?: InputMaybe<Scalars['String']>;
  sendNotification: Scalars['Boolean'];
  treatments: Array<UpdateBulkAppointmentTreatmentInput>;
};

export type UpdateBulkAppointmentTreatmentInput = {
  appointmentTreatmentUuid?: InputMaybe<Scalars['UUID4']>;
  employeeUuid: Scalars['UUID4'];
  timeRange: TimerangeInput;
  treatmentUuid: Scalars['UUID4'];
};

export type UpdateCategoriesPositionsInput = {
  headCategoryUuid: Scalars['UUID4'];
  sortedCategoryUuids: Array<Scalars['UUID4']>;
};

export type UpdateCategoryInput = {
  color?: InputMaybe<Scalars['String']>;
  headCategoryUuid?: InputMaybe<Scalars['UUID4']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateClientInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  imagesUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lastName?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Sex>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
  tagsUuids?: InputMaybe<Array<Scalars['UUID4']>>;
};

export type UpdateEmployeeInput = {
  firstName?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  isAvailableForAppointments?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Scalars['JSON']>;
  phone?: InputMaybe<Scalars['String']>;
  receiveEmails?: InputMaybe<Scalars['Boolean']>;
  treatmentsUuids?: InputMaybe<Array<Scalars['UUID4']>>;
  workSchedule?: InputMaybe<Scalars['JSON']>;
};

export type UpdateEmployeesPositionsInput = {
  sortedEmployeesUuids: Array<Scalars['UUID4']>;
};

export type UpdateEquipmentItemInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  treatmentsUuids: Array<Scalars['UUID4']>;
};

export type UpdateHeadCategoriesPositionsInput = {
  sortedHeadCategoryUuids: Array<Scalars['UUID4']>;
};

export type UpdateHeadCategoryInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateSalonInput = {
  backgroundImageUrl?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['JSON']>;
  logoImageUrl?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneType?: InputMaybe<PhoneType>;
  postalCode?: InputMaybe<Scalars['String']>;
  selfBooking?: InputMaybe<SalonSelfBookingInput>;
  socials?: InputMaybe<Scalars['JSON']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateSalonOpeningHoursInput = {
  regular?: InputMaybe<Scalars['JSON']>;
  special?: InputMaybe<Scalars['JSON']>;
};

export type UpdateSalonSmsDetailsInput = {
  appointmentReminderSendInAdvanceHours?: InputMaybe<Scalars['Int']>;
  sendReminders?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSmsTemplateInput = {
  description: Scalars['String'];
  templateUuid: Scalars['UUID4'];
  title: Scalars['String'];
};

export type UpdateTreatmentInput = {
  categoryUuid?: InputMaybe<Scalars['UUID4']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  imagesUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isExactPrice?: InputMaybe<Scalars['Boolean']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priceFrom?: InputMaybe<Scalars['Int']>;
  priceTo?: InputMaybe<Scalars['Int']>;
};

export type UpdateTreatmentsPositionsInput = {
  categoryUuid: Scalars['UUID4'];
  sortedTreatmentUuids: Array<Scalars['UUID4']>;
};

export type AccountWithProfileFragment = { __typename?: 'AccountWithProfile', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null };

export type SendFeedbackMutationVariables = Exact<{
  data: FeedbackInput;
}>;


export type SendFeedbackMutation = { __typename?: 'RootMutationType', sendFeedback: { __typename?: 'Feedback', message: string, subject: string } };

export type UpdateAccountMutationVariables = Exact<{
  data: AccountUpdateInput;
}>;


export type UpdateAccountMutation = { __typename?: 'RootMutationType', updateAccount: { __typename?: 'AccountWithProfile', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null } };

export type UpdateAccountNameMutationVariables = Exact<{
  data: AccountNameUpdateInput;
}>;


export type UpdateAccountNameMutation = { __typename?: 'RootMutationType', updateAccountName: { __typename?: 'AccountWithProfile', email?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null } };

export type AppointmentFragment = { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> };

export type AppointmentConnectionFragment = { __typename?: 'AppointmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AppointmentEdge', cursor?: string | null, node: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } }> };

export type AppointmentTreatmentFragment = { __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } };

export type BlockedSlotFragment = { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeUuid: any, title?: string | null, uuid: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } };

export type ApproveAppointmentRequestMutationVariables = Exact<{
  data: UpdateBulkAppointmentInput;
}>;


export type ApproveAppointmentRequestMutation = { __typename?: 'RootMutationType', approveAppointmentRequest: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type BlockSlotMutationVariables = Exact<{
  data: BlockSlotInput;
}>;


export type BlockSlotMutation = { __typename?: 'RootMutationType', blockSlot: { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeUuid: any, title?: string | null, uuid: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } } };

export type CancelAppointmentMutationVariables = Exact<{
  id: Scalars['UUID4'];
  sendNotification: Scalars['Boolean'];
}>;


export type CancelAppointmentMutation = { __typename?: 'RootMutationType', cancelAppointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type DeleteBlockedSlotMutationVariables = Exact<{
  uuid: Scalars['UUID4'];
}>;


export type DeleteBlockedSlotMutation = { __typename?: 'RootMutationType', deleteBlockedSlot: string };

export type FinalizeBulkAppointmentMutationVariables = Exact<{
  data: FinalizeBulkAppointmentInput;
}>;


export type FinalizeBulkAppointmentMutation = { __typename?: 'RootMutationType', finalizeBulkAppointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type UndoAppointmentFinalizationMutationVariables = Exact<{
  appointmentUuid: Scalars['UUID4'];
}>;


export type UndoAppointmentFinalizationMutation = { __typename?: 'RootMutationType', undoAppointmentFinalization: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type UpdateBlockedSlotMutationVariables = Exact<{
  data: UpdateBlockedSlotInput;
}>;


export type UpdateBlockedSlotMutation = { __typename?: 'RootMutationType', updateBlockedSlot: { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeUuid: any, title?: string | null, uuid: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } } };

export type UpdateBulkAppointmentMutationVariables = Exact<{
  data: UpdateBulkAppointmentInput;
}>;


export type UpdateBulkAppointmentMutation = { __typename?: 'RootMutationType', updateBulkAppointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type FetchAppointmentQueryVariables = Exact<{
  id: Scalars['UUID4'];
}>;


export type FetchAppointmentQuery = { __typename?: 'RootQueryType', fetchAppointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type FetchAppointmentsQueryVariables = Exact<{
  filters: AppointmentFilters;
}>;


export type FetchAppointmentsQuery = { __typename?: 'RootQueryType', fetchAppointments: Array<{ __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> }> };

export type FetchBulkAppointmentsQueryVariables = Exact<{
  filters: AppointmentFilters;
}>;


export type FetchBulkAppointmentsQuery = { __typename?: 'RootQueryType', fetchBulkAppointments: Array<{ __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> }> };

export type FetchBulkAppointmentsPaginatedQueryVariables = Exact<{
  filters: AppointmentFilters;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortByDatetime?: InputMaybe<SortOrder>;
}>;


export type FetchBulkAppointmentsPaginatedQuery = { __typename?: 'RootQueryType', fetchBulkAppointmentsPaginated: { __typename?: 'AppointmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AppointmentEdge', cursor?: string | null, node: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } }> } };

export type FetchCalendarQueryVariables = Exact<{
  filters: AppointmentFilters;
}>;


export type FetchCalendarQuery = { __typename?: 'RootQueryType', fetchCalendar: Array<{ __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } | { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeUuid: any, title?: string | null, uuid: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } }> };

export type FetchCalendarByEmployeeQueryVariables = Exact<{
  filters: AppointmentFilters;
}>;


export type FetchCalendarByEmployeeQuery = { __typename?: 'RootQueryType', fetchCalendarByEmployee: Array<{ __typename?: 'EmployeeCalendarResult', employee: { __typename?: 'CalendarEmployee', accountUuid: string, firstName?: string | null, lastName?: string | null, name?: string | null, role: Role, isActive: boolean, imageUrl?: string | null }, entries: Array<{ __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } | { __typename?: 'BlockedSlot', description?: string | null, employeeName: string, employeeUuid: any, title?: string | null, uuid: any, insertedAt: any, timeRange: { __typename?: 'Timerange', from: any, to: any } }> }> };

export type FetchSuggestedAppointmentTreatmentsPricesQueryVariables = Exact<{
  id: Scalars['UUID4'];
}>;


export type FetchSuggestedAppointmentTreatmentsPricesQuery = { __typename?: 'RootQueryType', fetchSuggestedAppointmentTreatmentsPrices: Array<{ __typename?: 'AppointmentTreatmentPrice', appointmentTreatmentUuid: any, suggestedPrice: number, name: string }> };

export type OnAppointmentCreatedOrUpdatedSubscriptionVariables = Exact<{
  filters: AppointmentFilters;
}>;


export type OnAppointmentCreatedOrUpdatedSubscription = { __typename?: 'RootSubscriptionType', appointmentCreatedOrUpdated?: { __typename?: 'SubscriptionAppointment', action: AppointmentSubscriptionAction, appointment: { __typename?: 'Appointment', appointmentUuid: any, clientUuid?: any | null, clientName?: string | null, clientDisplayName: string, note?: string | null, isSelfBooked: boolean, status: AppointmentStatus, totalPrice: number, paymentMethodUuid?: any | null, insertedAt: any, imagesUrls?: Array<{ __typename?: 'AppointmentImage', imageUrl: string, appointmentUuid: string, insertedAt: any, updatedAt: any }> | null, treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } } | null };

export type ClientFragment = { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> };

export type ClientTagFragment = { __typename?: 'ClientTag', color: string, name: string, uuid: any };

export type AddClientMutationVariables = Exact<{
  data: AddClientInput;
}>;


export type AddClientMutation = { __typename?: 'RootMutationType', addClient: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } };

export type DeleteClientMutationVariables = Exact<{
  id: Scalars['UUID4'];
}>;


export type DeleteClientMutation = { __typename?: 'RootMutationType', deleteClient: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['UUID4'];
  data: UpdateClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'RootMutationType', updateClient: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } };

export type CreateClientTagMutationVariables = Exact<{
  data: ClientTagInput;
}>;


export type CreateClientTagMutation = { __typename?: 'RootMutationType', createClientTag: { __typename?: 'ClientTag', color: string, name: string, uuid: any } };

export type DeleteClientTagMutationVariables = Exact<{
  tagUuid: Scalars['UUID4'];
}>;


export type DeleteClientTagMutation = { __typename?: 'RootMutationType', deleteClientTag: string };

export type UpdateClientTagMutationVariables = Exact<{
  data: ClientTagInput;
  tagUuid: Scalars['UUID4'];
}>;


export type UpdateClientTagMutation = { __typename?: 'RootMutationType', updateClientTag: { __typename?: 'ClientTag', color: string, name: string, uuid: any } };

export type FetchClientQueryVariables = Exact<{
  id: Scalars['UUID4'];
}>;


export type FetchClientQuery = { __typename?: 'RootQueryType', fetchClient: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } };

export type FetchSalonClientsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ClientSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
  filters?: InputMaybe<ClientFilters>;
}>;


export type FetchSalonClientsQuery = { __typename?: 'RootQueryType', fetchSalonClients: { __typename?: 'ClientConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'ClientEdge', node: { __typename?: 'Client', uuid: any, email?: string | null, phone?: string | null, firstName: string, lastName: string, fullName: string, sex?: Sex | null, insertedAt?: any | null, updatedAt?: any | null, postalCode?: string | null, city?: string | null, street?: string | null, streetNumber?: string | null, note?: string | null, imagesUrls: Array<string>, tags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> } }> } };

export type FetchSalonTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonTagsQuery = { __typename?: 'RootQueryType', fetchSalonTags: Array<{ __typename?: 'ClientTag', color: string, name: string, uuid: any }> };

export type EmployeeFragment = { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Role, permissions?: string | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: string | null, treatments: Array<{ __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> };

export type AddEmployeeMutationVariables = Exact<{
  data: AddEmployeeInput;
}>;


export type AddEmployeeMutation = { __typename?: 'RootMutationType', addSalonEmployee: { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Role, permissions?: string | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: string | null, treatments: Array<{ __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } };

export type AddEmployeeCommissionMutationVariables = Exact<{
  data: EmployeesLedgerSetSettlementBlueprintInput;
}>;


export type AddEmployeeCommissionMutation = { __typename?: 'RootMutationType', employeesLedgerSetSettlementBlueprint: { __typename?: 'CommandResult', id?: string | null } };

export type DeleteEmployeeMutationVariables = Exact<{
  id: Scalars['UUID4'];
}>;


export type DeleteEmployeeMutation = { __typename?: 'RootMutationType', deleteSalonEmployee: string };

export type DeleteEmployeeCommissionMutationVariables = Exact<{
  id: Scalars['Identifier'];
}>;


export type DeleteEmployeeCommissionMutation = { __typename?: 'RootMutationType', employeesLedgerDeleteSettlementBlueprint: { __typename?: 'CommandResult', id?: string | null } };

export type UpdateEmployeeMutationVariables = Exact<{
  id: Scalars['UUID4'];
  data: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'RootMutationType', updateSalonEmployee: { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Role, permissions?: string | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: string | null, treatments: Array<{ __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } };

export type UpdateEmployeesPositionsMutationVariables = Exact<{
  data: UpdateEmployeesPositionsInput;
}>;


export type UpdateEmployeesPositionsMutation = { __typename?: 'RootMutationType', updateEmployeesPositions: string };

export type EmployeesLedgerBlueprintsQueryVariables = Exact<{
  employeeId: Scalars['Identifier'];
}>;


export type EmployeesLedgerBlueprintsQuery = { __typename?: 'RootQueryType', employeesLedgerBlueprints: Array<{ __typename?: 'EmployeesLedgerBlueprint', employeeId: any, startsAt: any, treatmentId?: any | null, id: any, commission: { __typename?: 'EmployeesLedgerCommission', type: EmployeesLedgerCommissionType, value: number } }> };

export type EmployeesLedgerSummaryQueryVariables = Exact<{
  month: MonthInput;
}>;


export type EmployeesLedgerSummaryQuery = { __typename?: 'RootQueryType', employeesLedgerSummary: { __typename?: 'EmployeesLedgerSummary', items?: Array<{ __typename?: 'EmployeesLedgerSummaryEmployee', employeeId: any, treatmentId?: any | null, commission: { __typename?: 'Money', amount: number, currency: string }, turnover: { __typename?: 'Money', amount: number, currency: string } }> | null, month: { __typename?: 'Month', month: number, year: number } } };

export type FetchEmployeeQueryVariables = Exact<{
  id: Scalars['UUID4'];
}>;


export type FetchEmployeeQuery = { __typename?: 'RootQueryType', fetchEmployee: { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Role, permissions?: string | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: string | null, treatments: Array<{ __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } };

export type FetchEmployeesQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  after?: InputMaybe<Scalars['String']>;
}>;


export type FetchEmployeesQuery = { __typename?: 'RootQueryType', fetchEmployees: { __typename?: 'EmployeeConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, edges: Array<{ __typename?: 'EmployeeEdge', node: { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, fullName: string, phone?: string | null, role: Role, permissions?: string | null, isAvailableForAppointments: boolean, receiveEmails: boolean, isActive: boolean, imageUrl?: string | null, workSchedule?: string | null, treatments: Array<{ __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean }> } }> } };

export type EquipmentItemFragment = { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> };

export type CreateEquipmentItemMutationVariables = Exact<{
  data: CreateEquipmentItemInput;
}>;


export type CreateEquipmentItemMutation = { __typename?: 'RootMutationType', createEquipmentItem: { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> } };

export type DeleteEquipmentItemMutationVariables = Exact<{
  equipmentItemUuid: Scalars['UUID4'];
}>;


export type DeleteEquipmentItemMutation = { __typename?: 'RootMutationType', deleteEquipmentItem: string };

export type GetEquipmentItemQueryVariables = Exact<{
  equipmentItemUuid: Scalars['UUID4'];
}>;


export type GetEquipmentItemQuery = { __typename?: 'RootQueryType', getEquipmentItem: { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> } };

export type UpdateEquipmentItemMutationVariables = Exact<{
  data: UpdateEquipmentItemInput;
  equipmentItemUuid: Scalars['UUID4'];
}>;


export type UpdateEquipmentItemMutation = { __typename?: 'RootMutationType', updateEquipmentItem: { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> } };

export type CheckEquipmentsForTreatmentsAvailabilityQueryVariables = Exact<{
  data: Array<CheckEquipmentsForTreatmentsAvailabilityInput> | CheckEquipmentsForTreatmentsAvailabilityInput;
}>;


export type CheckEquipmentsForTreatmentsAvailabilityQuery = { __typename?: 'RootQueryType', checkEquipmentsForTreatmentsAvailability: Array<{ __typename?: 'AvailableEquipmentsForTreatment', treatmentUuid: any, availableEquipments: Array<{ __typename?: 'AvailableEquipment', equipmentItemUuid: any, equipmentItemName: string, equipmentItemDescription?: string | null }> }> };

export type FetchSalonEquipmentItemsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type FetchSalonEquipmentItemsQuery = { __typename?: 'RootQueryType', fetchSalonEquipmentItems: { __typename?: 'EquipmentItemConnection', count: number, edges: Array<{ __typename?: 'EquipmentItemEdge', node: { __typename?: 'EquipmentItem', uuid: any, name: string, description?: string | null, treatments: Array<{ __typename?: 'EquipmentItemTreatment', treatmentName: string, treatmentUuid: any }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };

export type SalonSmsTemplateFragment = { __typename?: 'SalonSmsTemplate', description?: string | null, salonUuid: any, title: string, type?: string | null, uuid: any };

export type SmsFragment = { __typename?: 'Sms', clientUuid: any, firstName?: string | null, lastName?: string | null, message?: string | null, recipient?: string | null, salonUuid: any, senderName?: string | null, sentAt?: any | null, status: SmsStatus, uuid: any };

export type SendSmsToClientsMutationVariables = Exact<{
  clientsUuids: Array<Scalars['UUID4']> | Scalars['UUID4'];
  message: Scalars['String'];
}>;


export type SendSmsToClientsMutation = { __typename?: 'RootMutationType', sendSmsToClients: string };

export type UpdateSalonSmsTemplateMutationVariables = Exact<{
  data: UpdateSmsTemplateInput;
}>;


export type UpdateSalonSmsTemplateMutation = { __typename?: 'RootMutationType', updateSalonSmsTemplate: Array<{ __typename?: 'SalonSmsTemplate', description?: string | null, salonUuid: any, title: string, type?: string | null, uuid: any }> };

export type FetchSalonSmsTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonSmsTemplatesQuery = { __typename?: 'RootQueryType', fetchSalonSmsTemplates: Array<{ __typename?: 'SalonSmsTemplate', description?: string | null, salonUuid: any, title: string, uuid: any, type?: string | null }> };

export type FetchSentSmsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  clientUuid?: InputMaybe<Scalars['UUID4']>;
}>;


export type FetchSentSmsQuery = { __typename?: 'RootQueryType', fetchSentSms: { __typename?: 'SmsConnection', count: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'SmsEdge', cursor?: string | null, node: { __typename?: 'Sms', clientUuid: any, firstName?: string | null, lastName?: string | null, message?: string | null, recipient?: string | null, salonUuid: any, senderName?: string | null, sentAt?: any | null, status: SmsStatus, uuid: any } }> } };

export type ExampleCategoryFragment = { __typename?: 'ExampleCategory', categories: Array<string>, headCategoryName: string };

export type AddOnboardingCategoriesMutationVariables = Exact<{
  data: Array<AddOnboardingCategoriesInput> | AddOnboardingCategoriesInput;
}>;


export type AddOnboardingCategoriesMutation = { __typename?: 'RootMutationType', addOnboardingCategories: string };

export type FetchExampleCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchExampleCategoriesQuery = { __typename?: 'RootQueryType', fetchExampleCategories: Array<{ __typename?: 'ExampleCategory', categories: Array<string>, headCategoryName: string }> };

export type OrderFragment = { __typename?: 'Order', accountEmail: string, accountUuid: any, orderItems?: string | null, paymentMode?: string | null, uuid: any };

export type PriceFragment = { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null };

export type ProductFragment = { __typename?: 'Product', name?: string | null, priceId?: string | null, stripeId?: string | null, type?: string | null, price?: { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null } | null };

export type ChangeSubscriptionPlanMutationVariables = Exact<{
  subscriptionPriceId: Scalars['String'];
}>;


export type ChangeSubscriptionPlanMutation = { __typename?: 'RootMutationType', changeSubscriptionPlan: string };

export type CreateBillingPortalSessionMutationVariables = Exact<{
  stripeCustomerId: Scalars['String'];
}>;


export type CreateBillingPortalSessionMutation = { __typename?: 'RootMutationType', createBillingPortalSession: { __typename?: 'BillingPortalSession', url: string } };

export type MakeOrderMutationVariables = Exact<{
  data: MakeOrderInput;
}>;


export type MakeOrderMutation = { __typename?: 'RootMutationType', makeOrder: { __typename?: 'Order', accountEmail: string, accountUuid: any, orderItems?: string | null, paymentMode?: string | null, uuid: any } };

export type MakePaymentMutationVariables = Exact<{
  data: MakePaymentInput;
}>;


export type MakePaymentMutation = { __typename?: 'RootMutationType', makePayment: { __typename?: 'Session', url: string } };

export type FetchProductsWithPricesQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchProductsWithPricesQuery = { __typename?: 'RootQueryType', fetchProductsWithPrices: Array<{ __typename?: 'Product', name?: string | null, priceId?: string | null, stripeId?: string | null, type?: string | null, price?: { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null } | null }> };

export type FetchProductsWithPricesByTypeQueryVariables = Exact<{
  type: ProductType;
}>;


export type FetchProductsWithPricesByTypeQuery = { __typename?: 'RootQueryType', fetchProductsWithPricesByType: Array<{ __typename?: 'Product', name?: string | null, priceId?: string | null, stripeId?: string | null, type?: string | null, price?: { __typename?: 'Price', billingScheme?: string | null, currency?: string | null, price?: number | null, priceDecimal?: string | null, productId?: string | null, type?: string | null } | null }> };

export type SalonFragment = { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: string | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: string | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: MinLeadTimeOptionsInMinutes, maxLeadTime: MaxLeadTimeOptionsInDays, url: any } };

export type SalonOpeningHoursFragment = { __typename?: 'SalonOpeningHours', regular?: string | null, special?: string | null };

export type SalonPaymentMethodFragment = { __typename?: 'SalonPaymentMethod', default: boolean, enabled: boolean, name: SalonPaymentMethods, salonUuid: any, uuid: any };

export type SalonSmsDetailsFragment = { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean };

export type CreateSalonMutationVariables = Exact<{
  data: CreateSalonInput;
}>;


export type CreateSalonMutation = { __typename?: 'RootMutationType', createSalon: { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: string | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: string | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: MinLeadTimeOptionsInMinutes, maxLeadTime: MaxLeadTimeOptionsInDays, url: any } } };

export type UpdateSalonMutationVariables = Exact<{
  data: UpdateSalonInput;
}>;


export type UpdateSalonMutation = { __typename?: 'RootMutationType', updateSalon: { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: string | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: string | null, description?: string | null, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: MinLeadTimeOptionsInMinutes, maxLeadTime: MaxLeadTimeOptionsInDays } } };

export type UpdateSalonOpeningHoursMutationVariables = Exact<{
  data: UpdateSalonOpeningHoursInput;
}>;


export type UpdateSalonOpeningHoursMutation = { __typename?: 'RootMutationType', updateSalonOpeningHours: { __typename?: 'SalonOpeningHours', regular?: string | null, special?: string | null } };

export type UpdateSalonPaymentMethodsMutationVariables = Exact<{
  data: Array<SalonPaymentMethodInput> | SalonPaymentMethodInput;
}>;


export type UpdateSalonPaymentMethodsMutation = { __typename?: 'RootMutationType', updateSalonPaymentMethods: Array<{ __typename?: 'SalonPaymentMethod', default: boolean, enabled: boolean, name: SalonPaymentMethods, salonUuid: any, uuid: any }> };

export type UpdateSalonSmsDetailsMutationVariables = Exact<{
  data: UpdateSalonSmsDetailsInput;
}>;


export type UpdateSalonSmsDetailsMutation = { __typename?: 'RootMutationType', updateSalonSmsDetails: { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean } };

export type UpdateSalonSenderNameMutationVariables = Exact<{
  senderName: Scalars['String'];
}>;


export type UpdateSalonSenderNameMutation = { __typename?: 'RootMutationType', updateSalonSenderName: { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean } };

export type FetchSalonQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonQuery = { __typename?: 'RootQueryType', fetchSalon: { __typename?: 'Salon', salonUuid: any, name: string, phoneType?: PhoneType | null, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: string | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, socials?: string | null, description?: string | null, isPublic: boolean, slug?: string | null, selfBooking: { __typename?: 'SalonSelfBooking', enabled: boolean, slotDuration: number, automaticalSlotApproval: boolean, automaticalSlotApprovalSms: boolean, minLeadTime: MinLeadTimeOptionsInMinutes, maxLeadTime: MaxLeadTimeOptionsInDays, url: any } } };

export type FetchSalonOpeningHoursQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonOpeningHoursQuery = { __typename?: 'RootQueryType', fetchSalonOpeningHours: { __typename?: 'SalonOpeningHours', regular?: string | null, special?: string | null } };

export type FetchSalonPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonPaymentMethodsQuery = { __typename?: 'RootQueryType', fetchSalonPaymentMethods?: Array<{ __typename?: 'SalonPaymentMethod', default: boolean, enabled: boolean, name: SalonPaymentMethods, salonUuid: any, uuid: any }> | null };

export type FetchSalonSmsDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonSmsDetailsQuery = { __typename?: 'RootQueryType', fetchSalonSmsDetails: { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean } };

export type CategoryFragment = { __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any };

export type HeadCategoryFragment = { __typename?: 'HeadCategory', name: string, uuid: any, updatedAt?: any | null, salonUuid: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any }> | null };

export type SearchTreatmentFragment = { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null };

export type TreatmentFragment = { __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean };

export type AddCategoryMutationVariables = Exact<{
  data: AddCategoryInput;
}>;


export type AddCategoryMutation = { __typename?: 'RootMutationType', addCategory: { __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any } };

export type AddHeadCategoryMutationVariables = Exact<{
  data: AddHeadCategoryInput;
}>;


export type AddHeadCategoryMutation = { __typename?: 'RootMutationType', addHeadCategory: { __typename?: 'HeadCategory', insertedAt?: any | null } };

export type AddTreatmentMutationVariables = Exact<{
  data: AddTreatmentInput;
}>;


export type AddTreatmentMutation = { __typename?: 'RootMutationType', addTreatment: { __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean } };

export type DeleteCategoryMutationVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
}>;


export type DeleteCategoryMutation = { __typename?: 'RootMutationType', deleteCategory: { __typename?: 'Category', isDeleted: boolean } };

export type DeleteHeadCategoryMutationVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
}>;


export type DeleteHeadCategoryMutation = { __typename?: 'RootMutationType', deleteHeadCategory: { __typename?: 'HeadCategory', isDeleted: boolean } };

export type DeleteTreatmentMutationVariables = Exact<{
  treatmentUuid: Scalars['UUID4'];
}>;


export type DeleteTreatmentMutation = { __typename?: 'RootMutationType', deleteTreatment: { __typename?: 'Treatment', isDeleted: boolean } };

export type UpdateCategoriesPositionsMutationVariables = Exact<{
  data: UpdateCategoriesPositionsInput;
}>;


export type UpdateCategoriesPositionsMutation = { __typename?: 'RootMutationType', updateCategoriesPositions: string };

export type UpdateCategoryMutationVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
  data: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'RootMutationType', updateCategory: { __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any } };

export type UpdateHeadCategoriesPositionsMutationVariables = Exact<{
  data: UpdateHeadCategoriesPositionsInput;
}>;


export type UpdateHeadCategoriesPositionsMutation = { __typename?: 'RootMutationType', updateHeadCategoriesPositions: string };

export type UpdateHeadCategoryMutationVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
  data: UpdateHeadCategoryInput;
}>;


export type UpdateHeadCategoryMutation = { __typename?: 'RootMutationType', updateHeadCategory: { __typename?: 'HeadCategory', name: string, uuid: any, updatedAt?: any | null, salonUuid: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any }> | null } };

export type UpdateTreatmentMutationVariables = Exact<{
  treatmentUuid: Scalars['UUID4'];
  data: UpdateTreatmentInput;
}>;


export type UpdateTreatmentMutation = { __typename?: 'RootMutationType', updateTreatment: { __typename?: 'Treatment', name: string, categoryUuid: any, duration: number } };

export type UpdateTreatmentsPositionsMutationVariables = Exact<{
  data: UpdateTreatmentsPositionsInput;
}>;


export type UpdateTreatmentsPositionsMutation = { __typename?: 'RootMutationType', updateTreatmentsPositions: string };

export type FetchAllTreatmentsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
}>;


export type FetchAllTreatmentsQuery = { __typename?: 'RootQueryType', fetchAllTreatments: { __typename?: 'SearchTreatmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'SearchTreatmentEdge', node: { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null } }> } };

export type FetchCategoryQueryVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
}>;


export type FetchCategoryQuery = { __typename?: 'RootQueryType', fetchCategory: { __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any } };

export type FetchChosenTreatmentsQueryVariables = Exact<{
  uuids: Array<Scalars['UUID4']> | Scalars['UUID4'];
}>;


export type FetchChosenTreatmentsQuery = { __typename?: 'RootQueryType', fetchChosenTreatments: Array<{ __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null }> };

export type FetchHeadCategoriesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type FetchHeadCategoriesQuery = { __typename?: 'RootQueryType', fetchHeadCategories: { __typename?: 'HeadCategoryConnection', count: number, edges: Array<{ __typename?: 'HeadCategoryEdge', node: { __typename?: 'HeadCategory', name: string, uuid: any, updatedAt?: any | null, salonUuid: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any }> | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } };

export type FetchHeadCategoryQueryVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
}>;


export type FetchHeadCategoryQuery = { __typename?: 'RootQueryType', fetchHeadCategory: { __typename?: 'HeadCategory', name: string, uuid: any, updatedAt?: any | null, salonUuid: any, isDeleted: boolean, insertedAt?: any | null, categories?: Array<{ __typename?: 'Category', color: string, headCategoryUuid: any, insertedAt?: any | null, isDeleted: boolean, name: string, updatedAt?: any | null, uuid: any }> | null } };

export type FetchTreatmentQueryVariables = Exact<{
  treatmentUuid: Scalars['UUID4'];
}>;


export type FetchTreatmentQuery = { __typename?: 'RootQueryType', fetchTreatment: { __typename?: 'Treatment', uuid: any, name: string, categoryUuid: any, duration: number, isExactPrice: boolean, price?: number | null, priceFrom?: number | null, priceTo?: number | null } };

export type FetchTreatmentsQueryVariables = Exact<{
  categoryUuid: Scalars['UUID4'];
  first?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
}>;


export type FetchTreatmentsQuery = { __typename?: 'RootQueryType', fetchTreatments: { __typename?: 'TreatmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'TreatmentEdge', node: { __typename?: 'Treatment', categoryUuid: any, duration: number, insertedAt?: any | null, isDeleted: boolean, isExactPrice: boolean, name: string, price?: number | null, priceFrom?: number | null, priceTo?: number | null, updatedAt?: any | null, uuid: any, description?: string | null, isPublic: boolean, imagesUrls: Array<string>, hasEmployeesAssigned: boolean } }> } };

export type SearchTreatmentQueryVariables = Exact<{
  treatmentUuid: Scalars['UUID4'];
}>;


export type SearchTreatmentQuery = { __typename?: 'RootQueryType', searchTreatment: { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentIsExactPrice?: boolean | null, treatmentDescription?: string | null } };

export type CurrentSessionFragment = { __typename?: 'CurrentSession', firstName?: string | null, lastName?: string | null, fullName: string, isOnboarded: boolean, salonName?: string | null, salonPlan?: SubscriptionPlan | null, email: string, phone?: string | null, role?: Role | null, permissions?: string | null, accountUuid?: any | null, stripeCustomerId?: string | null, trialUsed?: boolean | null };

export type CurrentSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentSessionQuery = { __typename?: 'RootQueryType', currentSession: { __typename?: 'CurrentSession', firstName?: string | null, lastName?: string | null, fullName: string, isOnboarded: boolean, salonName?: string | null, salonPlan?: SubscriptionPlan | null, email: string, phone?: string | null, role?: Role | null, permissions?: string | null, accountUuid?: any | null, stripeCustomerId?: string | null, trialUsed?: boolean | null } };

export type FetchSmsLimitQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSmsLimitQuery = { __typename?: 'RootQueryType', fetchSmsLimit: number };

export type RemainingAppointmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type RemainingAppointmentsQuery = { __typename?: 'RootQueryType', fetchRemainingAppointments?: number | null };

export type OnSmsLimitUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSmsLimitUpdatedSubscription = { __typename?: 'RootSubscriptionType', smsLimitUpdated: number };

export type FetchGeneralNumbersQueryVariables = Exact<{
  timeRange: TimerangeInput;
}>;


export type FetchGeneralNumbersQuery = { __typename?: 'RootQueryType', fetchGeneralNumbers: { __typename?: 'GeneralNumbers', canceledAppointments: number, finalizedAppointments: number, newClients: number, totalRevenue: number } };

export type FetchIncomeNumbersQueryVariables = Exact<{
  displayFormat: StatisticsDisplayFormat;
  period: StatisticsPeriod;
}>;


export type FetchIncomeNumbersQuery = { __typename?: 'RootQueryType', fetchIncomeNumbers: Array<{ __typename?: 'IncomeNumbers', appointmentsCount: number, date: any, income: number }> };

export type PageInfoFragment = { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean };

export type TimerangeFragment = { __typename?: 'Timerange', from: any, to: any };


      export type PossibleTypesResultData = {
  "possibleTypes": {
    "CalendarResult": [
      "Appointment",
      "BlockedSlot"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CalendarResult": [
      "Appointment",
      "BlockedSlot"
    ]
  }
};
      export default result;
    
export const namedOperations = {
  Query: {
    FetchAppointment: 'FetchAppointment',
    FetchAppointments: 'FetchAppointments',
    FetchBulkAppointments: 'FetchBulkAppointments',
    FetchBulkAppointmentsPaginated: 'FetchBulkAppointmentsPaginated',
    FetchCalendar: 'FetchCalendar',
    FetchCalendarByEmployee: 'FetchCalendarByEmployee',
    FetchSuggestedAppointmentTreatmentsPrices: 'FetchSuggestedAppointmentTreatmentsPrices',
    FetchClient: 'FetchClient',
    FetchSalonClients: 'FetchSalonClients',
    FetchSalonTags: 'FetchSalonTags',
    EmployeesLedgerBlueprints: 'EmployeesLedgerBlueprints',
    EmployeesLedgerSummary: 'EmployeesLedgerSummary',
    FetchEmployee: 'FetchEmployee',
    FetchEmployees: 'FetchEmployees',
    GetEquipmentItem: 'GetEquipmentItem',
    CheckEquipmentsForTreatmentsAvailability: 'CheckEquipmentsForTreatmentsAvailability',
    FetchSalonEquipmentItems: 'FetchSalonEquipmentItems',
    FetchSalonSmsTemplates: 'FetchSalonSmsTemplates',
    fetchSentSms: 'fetchSentSms',
    FetchExampleCategories: 'FetchExampleCategories',
    FetchProductsWithPrices: 'FetchProductsWithPrices',
    FetchProductsWithPricesByType: 'FetchProductsWithPricesByType',
    FetchSalon: 'FetchSalon',
    FetchSalonOpeningHours: 'FetchSalonOpeningHours',
    FetchSalonPaymentMethods: 'FetchSalonPaymentMethods',
    FetchSalonSmsDetails: 'FetchSalonSmsDetails',
    FetchAllTreatments: 'FetchAllTreatments',
    FetchCategory: 'FetchCategory',
    FetchChosenTreatments: 'FetchChosenTreatments',
    FetchHeadCategories: 'FetchHeadCategories',
    FetchHeadCategory: 'FetchHeadCategory',
    FetchTreatment: 'FetchTreatment',
    FetchTreatments: 'FetchTreatments',
    SearchTreatment: 'SearchTreatment',
    CurrentSession: 'CurrentSession',
    FetchSmsLimit: 'FetchSmsLimit',
    RemainingAppointments: 'RemainingAppointments',
    FetchGeneralNumbers: 'FetchGeneralNumbers',
    FetchIncomeNumbers: 'FetchIncomeNumbers'
  },
  Mutation: {
    SendFeedback: 'SendFeedback',
    UpdateAccount: 'UpdateAccount',
    UpdateAccountName: 'UpdateAccountName',
    ApproveAppointmentRequest: 'ApproveAppointmentRequest',
    BlockSlot: 'BlockSlot',
    CancelAppointment: 'CancelAppointment',
    DeleteBlockedSlot: 'DeleteBlockedSlot',
    FinalizeBulkAppointment: 'FinalizeBulkAppointment',
    UndoAppointmentFinalization: 'UndoAppointmentFinalization',
    UpdateBlockedSlot: 'UpdateBlockedSlot',
    UpdateBulkAppointment: 'UpdateBulkAppointment',
    AddClient: 'AddClient',
    DeleteClient: 'DeleteClient',
    UpdateClient: 'UpdateClient',
    CreateClientTag: 'CreateClientTag',
    DeleteClientTag: 'DeleteClientTag',
    UpdateClientTag: 'UpdateClientTag',
    AddEmployee: 'AddEmployee',
    AddEmployeeCommission: 'AddEmployeeCommission',
    DeleteEmployee: 'DeleteEmployee',
    DeleteEmployeeCommission: 'DeleteEmployeeCommission',
    UpdateEmployee: 'UpdateEmployee',
    UpdateEmployeesPositions: 'UpdateEmployeesPositions',
    CreateEquipmentItem: 'CreateEquipmentItem',
    DeleteEquipmentItem: 'DeleteEquipmentItem',
    UpdateEquipmentItem: 'UpdateEquipmentItem',
    SendSmsToClients: 'SendSmsToClients',
    UpdateSalonSmsTemplate: 'UpdateSalonSmsTemplate',
    AddOnboardingCategories: 'AddOnboardingCategories',
    ChangeSubscriptionPlan: 'ChangeSubscriptionPlan',
    CreateBillingPortalSession: 'CreateBillingPortalSession',
    MakeOrder: 'MakeOrder',
    MakePayment: 'MakePayment',
    CreateSalon: 'CreateSalon',
    UpdateSalon: 'UpdateSalon',
    UpdateSalonOpeningHours: 'UpdateSalonOpeningHours',
    UpdateSalonPaymentMethods: 'UpdateSalonPaymentMethods',
    UpdateSalonSmsDetails: 'UpdateSalonSmsDetails',
    UpdateSalonSenderName: 'UpdateSalonSenderName',
    AddCategory: 'AddCategory',
    AddHeadCategory: 'AddHeadCategory',
    AddTreatment: 'AddTreatment',
    DeleteCategory: 'DeleteCategory',
    DeleteHeadCategory: 'DeleteHeadCategory',
    DeleteTreatment: 'DeleteTreatment',
    UpdateCategoriesPositions: 'UpdateCategoriesPositions',
    UpdateCategory: 'UpdateCategory',
    UpdateHeadCategoriesPositions: 'UpdateHeadCategoriesPositions',
    UpdateHeadCategory: 'UpdateHeadCategory',
    UpdateTreatment: 'UpdateTreatment',
    UpdateTreatmentsPositions: 'UpdateTreatmentsPositions'
  },
  Subscription: {
    OnAppointmentCreatedOrUpdated: 'OnAppointmentCreatedOrUpdated',
    OnSmsLimitUpdated: 'OnSmsLimitUpdated'
  },
  Fragment: {
    AccountWithProfile: 'AccountWithProfile',
    Appointment: 'Appointment',
    AppointmentConnection: 'AppointmentConnection',
    AppointmentTreatment: 'AppointmentTreatment',
    BlockedSlot: 'BlockedSlot',
    Client: 'Client',
    ClientTag: 'ClientTag',
    Employee: 'Employee',
    EquipmentItem: 'EquipmentItem',
    SalonSmsTemplate: 'SalonSmsTemplate',
    Sms: 'Sms',
    ExampleCategory: 'ExampleCategory',
    Order: 'Order',
    Price: 'Price',
    Product: 'Product',
    Salon: 'Salon',
    SalonOpeningHours: 'SalonOpeningHours',
    SalonPaymentMethod: 'SalonPaymentMethod',
    SalonSmsDetails: 'SalonSmsDetails',
    Category: 'Category',
    HeadCategory: 'HeadCategory',
    SearchTreatment: 'SearchTreatment',
    Treatment: 'Treatment',
    CurrentSession: 'CurrentSession',
    PageInfo: 'PageInfo',
    Timerange: 'Timerange'
  }
}