import { useParams } from "react-router-dom";

import { BreadcrumbComponentProps } from "use-react-router-breadcrumbs";

import { Layout } from "@components/layout/Layout";

import { Spinner } from "@/components/ui/Spinner";
import { ClientProfile } from "@/features/clients/components/ClientProfile";
import { useClientStore } from "@/features/clients/hooks";

export const ClientDetailsBreadcrumb = ({ match }: BreadcrumbComponentProps) => {
  const { client, loading } = useClientStore(match.params.id);

  return loading ? <span>...</span> : <span>{client?.fullName}</span>;
};

export const ClientDetailsView = () => {
  const { id } = useParams();
  const { client, loading } = useClientStore(id);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Layout className="rounded-2xl bg-stone-100" title="Imię klienta">
      <div className="-ml-4 -mr-4 mt-4 max-w-6xl rounded-xl bg-stone-50 px-4 py-8 sm:mt-8 sm:px-12 sm:py-12 md:mx-auto md:px-12">
        {client && <ClientProfile client={client} />}
      </div>
    </Layout>
  );
};
