import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { RegularAvailability, RegularAvailabilityDay } from "../models/specialHours";
import { decodeRegularAvailability, weekdays } from "../utils";
import { OpeningHoursItem } from "./OpeningHours/OpeningHoursItem";

type OpeningHoursProps = {
  openingHours: RegularAvailability;
};

export const OpeningHours = ({ openingHours: initialOpeningHours }: OpeningHoursProps) => {
  const [openingHours, setOpeningHours] = useState(initialOpeningHours);
  const { setValue } = useFormContext();

  const updateOpeningHours = (weekday: string, item: RegularAvailabilityDay) => {
    const updatedOpeningHours = { ...openingHours, [weekday]: item };
    setOpeningHours(updatedOpeningHours);
    const updateDecoded = JSON.stringify(decodeRegularAvailability(updatedOpeningHours));

    setValue("regularOpeningHours", updateDecoded, { shouldDirty: true, shouldValidate: true });
  };

  useEffect(() => {
    const decoded = decodeRegularAvailability(openingHours);
    setValue("regularOpeningHours", JSON.stringify(decoded));
  }, []);

  return (
    <div className="w-full">
      <div className="mx-auto w-full space-y-4 rounded-md">
        {weekdays.map((weekday) => (
          <OpeningHoursItem
            key={weekday}
            weekday={weekday}
            item={openingHours[weekday]}
            onUpdate={updateOpeningHours}
          />
        ))}
      </div>
    </div>
  );
};
