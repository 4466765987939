import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OrderFragmentDoc } from '../fragments/Order.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MakeOrderMutationVariables = Types.Exact<{
  data: Types.MakeOrderInput;
}>;


export type MakeOrderMutation = { __typename?: 'RootMutationType', makeOrder: { __typename?: 'Order', accountEmail: string, accountUuid: any, orderItems?: any | null, paymentMode?: string | null, uuid: any } };


export const MakeOrderDocument = gql`
    mutation MakeOrder($data: MakeOrderInput!) {
  makeOrder(data: $data) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type MakeOrderMutationFn = Apollo.MutationFunction<MakeOrderMutation, MakeOrderMutationVariables>;

/**
 * __useMakeOrderMutation__
 *
 * To run a mutation, you first call `useMakeOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeOrderMutation, { data, loading, error }] = useMakeOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMakeOrderMutation(baseOptions?: Apollo.MutationHookOptions<MakeOrderMutation, MakeOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeOrderMutation, MakeOrderMutationVariables>(MakeOrderDocument, options);
      }
export type MakeOrderMutationHookResult = ReturnType<typeof useMakeOrderMutation>;
export type MakeOrderMutationResult = Apollo.MutationResult<MakeOrderMutation>;
export type MakeOrderMutationOptions = Apollo.BaseMutationOptions<MakeOrderMutation, MakeOrderMutationVariables>;