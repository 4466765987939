export const useUserAgent = () => {
  const ua = navigator.userAgent;

  const isMobileApp = ua.includes("EstetifyMobile");
  const isAndroid = isMobileApp && ua.includes("android");
  const isIOS = isMobileApp && ua.includes("ios");

  return {
    isMobileApp,
    isAndroid,
    isIOS,
  };
};
