import { TypePolicy } from "@apollo/client";

import { t } from "i18next";

export const Appointment: TypePolicy = {
  fields: {
    clientDisplayName: {
      read(_, { readField }) {
        const clientName = readField<string>("clientName");

        return clientName || t("appointments.anonymousClient");
      },
    },
  },
};
