import { NetworkStatus } from "@apollo/client";
import { useEffect, useState } from "react";

import { useDebounce } from "@hooks/use-debounce";

import { FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables } from "@/types";
import type { Option } from "@/utils/select-utils";

import { useUpdateHeadCategoriesPositionsMutation } from "../mutations/UpdateHeadCategoriesPositions.generated";
import {
  FetchHeadCategoriesDocument,
  useFetchHeadCategoriesQuery,
} from "../queries/FetchHeadCategories.generated";

export const useHeadCategoriesStore = () => {
  const [searchQuery, setSearchQuery] = useState<string>();
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const { data, fetchMore, refetch, networkStatus } = useFetchHeadCategoriesQuery({
    variables: { first: 100 },
    notifyOnNetworkStatusChange: true,
  });

  const [updateHeadCategoriesPositions, { loading: loadingUpdate }] =
    useUpdateHeadCategoriesPositionsMutation();

  const handleUpdateHeadCategoriesPositions = async ({
    data,
  }: {
    data: { sortedHeadCategoryUuids: string[] };
  }) => {
    await updateHeadCategoriesPositions({
      variables: {
        data,
      },
      optimisticResponse: {
        updateHeadCategoriesPositions: "successfully_updated",
      },
      update: (cache, { data: mutationData }) => {
        if (
          !mutationData ||
          mutationData.updateHeadCategoriesPositions !== "successfully_updated"
        ) {
          return;
        }

        const { sortedHeadCategoryUuids } = data;

        const existingData: FetchHeadCategoriesQuery | null = cache.readQuery<
          FetchHeadCategoriesQuery,
          FetchHeadCategoriesQueryVariables
        >({
          query: FetchHeadCategoriesDocument,
          variables: { first: 100 },
        });

        if (!existingData) return;

        const { fetchHeadCategories } = existingData;

        const newEdges = sortedHeadCategoryUuids
          .map((uuid) => fetchHeadCategories.edges.find((edge) => edge.node.uuid === uuid))
          .filter(Boolean) as FetchHeadCategoriesQuery["fetchHeadCategories"]["edges"];

        cache.writeQuery<FetchHeadCategoriesQuery, FetchHeadCategoriesQueryVariables>({
          query: FetchHeadCategoriesDocument,
          variables: { first: 100 },
          data: {
            fetchHeadCategories: {
              ...fetchHeadCategories,
              edges: newEdges,
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      refetch({ query: debouncedSearchQuery || undefined });
    }
  }, [debouncedSearchQuery]);

  return {
    loading: networkStatus === NetworkStatus.loading,
    loadingFetchMore: networkStatus === NetworkStatus.fetchMore,
    loadingUpdate: loadingUpdate,
    headCategories: data?.fetchHeadCategories.edges.map(({ node }) => node) || [],
    count: data?.fetchHeadCategories.count || 0,
    headCategoryOptions:
      data?.fetchHeadCategories.edges.map(({ node }) => ({
        value: node.uuid,
        label: node.name,
      })) || [],
    groupedCategoryOptions:
      data?.fetchHeadCategories.edges.reduce((groups, { node }) => {
        return groups.concat(
          node.categories?.map((category) => ({
            groupLabel: node.name,
            groupValue: node.uuid,
            label: category?.name || "",
            value: category?.uuid || "",
          })) || [],
        );
      }, [] as Option[]) || [],
    hasMore: data?.fetchHeadCategories.pageInfo.hasNextPage,
    refetchWithQuery(query?: string) {
      setSearchQuery(query);
    },
    fetchMore() {
      if (fetchMore && data) {
        return fetchMore({
          variables: {
            after: data.fetchHeadCategories.pageInfo.endCursor,
          },
        });
      }
    },
    updateHeadCategoriesPositions: handleUpdateHeadCategoriesPositions,
  };
};
