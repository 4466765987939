import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DefaultDialog } from "@/components/dialogs/DefaultDialog";
import { getDefaultNearestTimerange, isoTimeRangeToTime } from "@/features/calendar/utils/time";
import { useSessionContext } from "@/providers/SessionProvider";
import { Timerange } from "@/types";

import { BlockedSlotForm, BlockedSlotFormProps } from "./BlockedSlotForm";

type BlockedSlotDialogProps = {
  props?: BlockedSlotFormProps;
  onClose: () => void;
  open: boolean;
};

const now = new Date();

export const BlockedSlotDialog = ({ props, onClose, open }: BlockedSlotDialogProps) => {
  const { t } = useTranslation();
  const { session } = useSessionContext();
  const date = useMemo(
    () =>
      props?.defaultValues.timeRange.from ? new Date(props?.defaultValues.timeRange.from) : now,
    [props?.defaultValues.timeRange.from],
  );
  const timeRange: Timerange = useMemo(
    () =>
      props?.defaultValues?.timeRange?.from && props.defaultValues.timeRange?.to
        ? isoTimeRangeToTime(props.defaultValues.timeRange)
        : getDefaultNearestTimerange(date),
    [date, props?.defaultValues?.timeRange],
  );

  const defaultValues: BlockedSlotFormProps["defaultValues"] = useMemo(
    () => ({
      ...props?.defaultValues,
      employeeUuid: props?.defaultValues.employeeUuid ?? session?.accountUuid,
      date,
      timeRange,
    }),
    [date, timeRange, session?.accountUuid, props?.defaultValues],
  );

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      title={defaultValues?.uuid ? t("blockedSlots.editBlockSlot") : t("blockedSlots.blockSlot")}>
      <BlockedSlotForm
        onClose={onClose}
        defaultValues={defaultValues}
        action={props?.defaultValues.uuid ? "update" : "create"}
      />
    </DefaultDialog>
  );
};
