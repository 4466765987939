import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { SalonSmsDetailsFragmentDoc } from '../fragments/SalonSmsDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSalonSenderNameMutationVariables = Types.Exact<{
  senderName: Types.Scalars['String'];
}>;


export type UpdateSalonSenderNameMutation = { __typename?: 'RootMutationType', updateSalonSenderName: { __typename?: 'SalonSmsDetails', appointmentReminderSendInAdvanceHours: number, senderName: string, senderNameApproved: boolean, sendReminders: boolean } };


export const UpdateSalonSenderNameDocument = gql`
    mutation UpdateSalonSenderName($senderName: String!) {
  updateSalonSenderName(senderName: $senderName) {
    ...SalonSmsDetails
  }
}
    ${SalonSmsDetailsFragmentDoc}`;
export type UpdateSalonSenderNameMutationFn = Apollo.MutationFunction<UpdateSalonSenderNameMutation, UpdateSalonSenderNameMutationVariables>;

/**
 * __useUpdateSalonSenderNameMutation__
 *
 * To run a mutation, you first call `useUpdateSalonSenderNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalonSenderNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalonSenderNameMutation, { data, loading, error }] = useUpdateSalonSenderNameMutation({
 *   variables: {
 *      senderName: // value for 'senderName'
 *   },
 * });
 */
export function useUpdateSalonSenderNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalonSenderNameMutation, UpdateSalonSenderNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalonSenderNameMutation, UpdateSalonSenderNameMutationVariables>(UpdateSalonSenderNameDocument, options);
      }
export type UpdateSalonSenderNameMutationHookResult = ReturnType<typeof useUpdateSalonSenderNameMutation>;
export type UpdateSalonSenderNameMutationResult = Apollo.MutationResult<UpdateSalonSenderNameMutation>;
export type UpdateSalonSenderNameMutationOptions = Apollo.BaseMutationOptions<UpdateSalonSenderNameMutation, UpdateSalonSenderNameMutationVariables>;